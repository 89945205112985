import React, { useCallback, useEffect } from 'react';
import './SystemAsyncTasks.scss';
import Rcm, { RcmProps } from '../Rcm/Rcm';
import { Dropdown, Icon, Menu } from 'antd';
import {
  SortingDirection,
  SystemAsyncTaskFragment,
  SystemAsyncTaskState,
  useAllSystemAsyncTasksQuery,
  // useUpdateSystemAsyncTaskMutation,
} from '../../typings/graphql';
import { i18n } from '../../helpers/I18n';
import { ASYNC_TASKS_POLL_INTERVAL } from '../../config/constants';
import Popconfirm, { PopconfirmProps } from 'antd/lib/popconfirm';
import { CheckCircleOutlined } from '@ant-design/icons';

interface IExternalProps {
  rcmProps?: Partial<RcmProps>;
}

interface IProps extends IExternalProps {}

type Elem = SystemAsyncTaskFragment;

const SystemAsyncTasks: React.FC<IProps> = props => {
  const { rcmProps } = props;

  const tasksResult = useAllSystemAsyncTasksQuery({
    variables: {
      query: {
        limit: 10,
        filters: [
          {
            field: 'state',
            value: JSON.stringify(SystemAsyncTaskState.PROCESSING),
          },
        ],
        sort: [
          {
            field: 'createdAt',
            direction: SortingDirection.DESC,
          },
        ],
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  // const [updateTask, updateTaskResult] = useUpdateSystemAsyncTaskMutation({
  //   fetchPolicy: 'no-cache',
  // });

  useEffect(() => {
    const tasks = tasksResult?.data?.allSystemAsyncTasks?.edges ?? [];

    if (tasks.length === 0) {
      tasksResult.stopPolling();
    } else {
      tasksResult.startPolling(ASYNC_TASKS_POLL_INTERVAL);
    }

    return () => {
      tasksResult.stopPolling();
    };
  }, [tasksResult]);

  const getStateClassName = useCallback((task: Elem | null) => {
    const classNamesMap: Record<SystemAsyncTaskState, string> = {
      [SystemAsyncTaskState.NEW]: 'systemAsyncTasks_taskState--NEW',
      [SystemAsyncTaskState.PROCESSING]: 'systemAsyncTasks_taskState--PROCESSING',
      [SystemAsyncTaskState.CANCELLED]: 'systemAsyncTasks_taskState--CANCELLED',
      [SystemAsyncTaskState.FINISHED]: 'systemAsyncTasks_taskState--FINISHED',
    };

    return task ? classNamesMap[task.state] : null;
  }, []);

  const getTaskPercentage = useCallback((task: Elem) => {
    const { processed, total } = task.progress;
    const result = Math.round((processed / total) * 100);

    return isNaN(result) ? 100 : result;
  }, []);

  const getTasks = useCallback(() => {
    return tasksResult?.data?.allSystemAsyncTasks?.edges ?? [];
  }, [tasksResult]);

  const isEmpty = getTasks().length === 0;

  const getActiveTask = useCallback(() => {
    const [task] = getTasks();

    return task || null;
  }, [getTasks]);

  const renderTaskState = useCallback(
    (task: Elem | null) => {
      if (!task) {
        return null;
      }

      const percent = getTaskPercentage(task);
      const { processed, total, failed } = task.progress;

      return (
        <div className="systemAsyncTasks_taskState">
          <span className="text--success">{processed}</span>
          <span>&nbsp;/&nbsp;</span>
          <span className="text--danger">{failed}</span>
          <span>&nbsp;/&nbsp;</span>
          <span>
            {total}: {percent}%
          </span>
        </div>
      );
    },
    [getTaskPercentage]
  );

  const renderTaskTitle = useCallback((task: Elem | null) => {
    const taskName = `${task?.operation}:${task?.entity}`;
    const key = task ? taskName : 'no_active_async_tasks';
    return <div className="systemAsyncTasks_taskTitle">{i18n(key)}</div>;
  }, []);

  const renderTaskIcon = useCallback(
    (task: Elem | null) => {
      const types: Record<SystemAsyncTaskState, string> = {
        [SystemAsyncTaskState.NEW]: 'clock-circle',
        [SystemAsyncTaskState.PROCESSING]: 'clock-circle',
        [SystemAsyncTaskState.CANCELLED]: 'close-circle',
        [SystemAsyncTaskState.FINISHED]: 'check-circle',
      };
      const type = task ? types[task.state] : 'info-circle';

      const classNames = ['systemAsyncTasks_taskIcon'];
      const modification = getStateClassName(task);
      if (modification) {
        classNames.push(modification);
      }

      const className = classNames.join(' ');

      return <Icon type={type} className={className} />;
    },
    [getStateClassName]
  );

  const handleTaskDone = useCallback(
    (task: Elem) => () => {
      // updateTask({
      //   variables: {
      //     id: task.id,
      //     data: {
      //       state: SystemAsyncTaskState.FINISHED,
      //     },
      //   },
      //   refetchQueries: ['allSystemAsyncTasks'],
      // });
    },
    []
  );

  const renderControl = useCallback((task: Elem | null) => {
    if (!task) {
      return null;
    }
    const popConfirmConfig: Partial<PopconfirmProps> = {
      onConfirm: handleTaskDone(task),
      cancelText: i18n('action_cancel'),
      okText: i18n('action_run'),
    };
    if (task.state === SystemAsyncTaskState.PROCESSING && task.progress.total === task.progress.processed) {
      return (
        <Popconfirm title={i18n('action_confirm')} {...popConfirmConfig}>
          <CheckCircleOutlined style={{ marginLeft: '10px', cursor: 'pointer' }} />
        </Popconfirm>
      );
    }
  }, []);

  const renderTask = useCallback(
    (task: Elem | null) => {
      const icon = renderTaskIcon(task);
      const state = renderTaskState(task);
      const title = renderTaskTitle(task);
      const control = renderControl(task);
      const key = task ? task.id : 'none';

      return (
        <div className="systemAsyncTasks_task" key={key}>
          {icon}
          {title}
          {state}
          {control}
        </div>
      );
    },
    [renderTaskIcon, renderTaskState, renderTaskTitle, renderControl]
  );

  const renderDropdownTarget = useCallback(() => {
    const task = getActiveTask();

    return (
      <Menu>
        <div className="systemAsyncTasks_target">
          <div className="systemAsyncTasks_targetContent">{renderTask(task)}</div>
          {task && <Icon type="down" />}
        </div>
      </Menu>
    );
  }, [getActiveTask, renderTask]);

  const renderDropdownContent = useCallback(() => {
    const tasks = getTasks();
    return <Menu id="dropdown_content">{tasks.map(renderTask)}</Menu>;
  }, [getTasks, renderTask]);

  const renderEssence = useCallback(() => {
    if (isEmpty) {
      return null;
    }

    const overlay = renderDropdownContent();

    return (
      <Dropdown disabled={isEmpty} overlay={overlay}>
        {renderDropdownTarget()}
      </Dropdown>
    );
  }, [isEmpty, renderDropdownTarget, renderDropdownContent]);

  return <Rcm renderEssence={renderEssence} {...rcmProps} />;
};

export default React.memo(SystemAsyncTasks);
