import { gql } from 'apollo-boost';
import { DISPUTE_CONNECTION_FRAGMENT } from '../fragments/disputeConnection';

export const ALL_DISPUTES_QUERY = gql`
  query allDisputes($query: DefaultQueryInput) {
    allDisputes(query: $query) {
      ...disputeConnection
    }
  }
  ${DISPUTE_CONNECTION_FRAGMENT}
`;
