import React from 'react';

import './InlineLink.scss';
import { Link } from 'react-router-dom';

interface IExternalProps {
  text: string | number;
  to?: string;
  color?: string;
  external?: boolean;
}

interface IProps extends IExternalProps {}

interface IState {}

class InlineLink extends React.PureComponent<IProps, IState> {
  private handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  private getClassName = () => {
    return 'inlineLink';
  };

  private getPath = () => {
    return this.props.to;
  };

  private getStyle = (): React.CSSProperties | undefined => {
    const { color } = this.props;
    if (!color) {
      return undefined;
    }

    return { color };
  };

  private renderContent = () => {
    return this.props.text;
  };

  private renderLink = () => {
    const { external } = this.props;
    const className = this.getClassName();
    const path = this.getPath()!; // Already checked
    const style = this.getStyle();

    if (external) {
      return (
        <a
          onClick={this.handleClick}
          className={className}
          href={path}
          style={style}
          target="_blank"
          rel="noreferrer noopener"
        >
          {this.renderContent()}
        </a>
      );
    }

    return (
      <Link onClick={this.handleClick} className={className} to={path} style={style}>
        {this.renderContent()}
      </Link>
    );
  };

  private renderSpan = () => {
    const className = this.getClassName();
    return (
      <span onClick={this.handleClick} className={className}>
        {this.renderContent()}
      </span>
    );
  };

  render() {
    const path = this.getPath();
    if (!path) {
      return this.renderSpan();
    }

    return this.renderLink();
  }
}

export default InlineLink;
