import { gql } from 'apollo-boost';
import { TOURNAMENT_FRAGMENT } from '../fragments/tournament';

export const TOURNAMENT_QUERY = gql`
  query tournament($id: Int!) {
    tournament(id: $id) {
      ...tournament
    }
  }
  ${TOURNAMENT_FRAGMENT}
`;
