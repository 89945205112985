import { gql } from 'apollo-boost';

export const LOYALTY_PARTNER_FRAGMENT = gql`
  fragment loyaltyPartner on LoyaltyPartner {
    id
    clientId
    state
    name
    description
    logo
    website
    shopId
    providerId
    createdAt
    updatedAt
  }
`;
