import { gql } from 'apollo-boost';

export const SYSTEM_LOCALE_FRAGMENT = gql`
  fragment systemLocale on SystemLocale {
    id
    iso
    title
    createdAt
    updatedAt
  }
`;
