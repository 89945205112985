import { gql } from 'apollo-boost';
import { CATALOGUE_ITEM_PRICE_FRAGMENT } from './catalogueItemPrice';
import { EXTENSION_DATA_FRAGMENT } from './extensionData';

export const CATALOGUE_ITEM_FLAT_FRAGMENT = gql`
  fragment catalogueItemFlat on CatalogueItem {
    id
    catalogueId
    name
    preview
    state
    createdAt
    updatedAt
    parentId
  }
`;

export const CATALOGUE_ITEM_FRAGMENT_IN_COLLECTION = gql`
  fragment catalogueItemInCollection on CatalogueItem {
    ...catalogueItemFlat
    prices {
      ...catalogueItemPrice
    }
  }
  ${CATALOGUE_ITEM_FLAT_FRAGMENT}
  ${CATALOGUE_ITEM_PRICE_FRAGMENT}
`;

export const CATALOGUE_ITEM_FRAGMENT = gql`
  fragment catalogueItem on CatalogueItem {
    ...catalogueItemFlat
    data {
      ...extensionData
    }
  }
  ${CATALOGUE_ITEM_FLAT_FRAGMENT}
  ${EXTENSION_DATA_FRAGMENT}
`;
