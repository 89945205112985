import { gql } from 'apollo-boost';

export const CURRENCY_FRAGMENT = gql`
  fragment currency on Currency {
    id
    iso3
    name
    sign
    isDefault
    isVirtual
    createdAt
    updatedAt
  }
`;
