import { gql } from 'apollo-boost';
import { PAYMENT_ACCOUNT_FLAT_FRAGMENT, PAYMENT_ACCOUNT_FRAGMENT } from './paymentAccount';

export const PAYMENT_ACCOUNT_CONNECTION_FRAGMENT = gql`
  fragment paymentAccountConnection on PaymentAccountConnection {
    total
    limit
    cursor
    sortable
    searchable
    edges {
      ...paymentAccount
    }
  }
  ${PAYMENT_ACCOUNT_FRAGMENT}
`;
