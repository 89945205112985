import { gql } from 'apollo-boost';
import { CLIENT_FRAGMENT } from './client';
import { ACL_FRAGMENT } from './acl';

export const SYSTEM_USER_FRAGMENT = gql`
  fragment systemUser on SystemUser {
    id
    state
    email
    username
    phone
    firstName
    lastName
    middleName
    photo
    createdAt
    updatedAt
    locale
    clients {
      ...client
    }
    ACL {
      ...acl
    }
  }
  ${CLIENT_FRAGMENT}
  ${ACL_FRAGMENT}
`;

export const SYSTEM_USER_FRAGMENT_LIGHT = gql`
  fragment systemUserLight on SystemUser {
    id
    firstName
    lastName
    middleName
    photo
  }
`;
