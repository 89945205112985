import { gql } from 'apollo-boost';
import { DISPUTE_FRAGMENT } from './dispute';

export const DISPUTE_CONNECTION_FRAGMENT = gql`
  fragment disputeConnection on DisputeConnection {
    total
    limit
    cursor
    sortable
    searchable
    edges {
      ...dispute
    }
  }
  ${DISPUTE_FRAGMENT}
`;
