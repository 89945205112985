import { ArticleFragment, EventFragment, TournamentMatchFragment, UserFragment } from '../typings/graphql';
import { i18n } from './I18n';
import { renderDate } from './renderDate';
import { FORMAT } from '../config/constants';
import { getTournamentMatchName } from './getMatchName';
import { getFullName } from './getFullName';

export const renderEventOption = (event: EventFragment): string => {
  let result = event.title || i18n('untitled');

  if (event.startDate) {
    const date = renderDate(event.startDate, { format: FORMAT.DATE_WITH_YEAR });
    result = `${date} | ${result}`;
  }

  return result;
};

export const renderTournamentMatchOption = (match: TournamentMatchFragment): string => {
  let result = getTournamentMatchName(match) || i18n('untitled');

  if (match.startDate) {
    const date = renderDate(match.startDate, { format: FORMAT.DATE_WITH_YEAR });
    result = `${date} | ${result}`;
  }

  return result;
};

export const renderUserOption = (user: UserFragment): string => {
  let result = getFullName(user) || i18n('untitled');

  const contact = user.phone || user.email;
  if (contact) {
    result = `${result} | ${contact}`;
  }

  return result;
};

export const renderArticleOption = (article: ArticleFragment): string => {
  const { locale, title, id } = article;
  const isoText = locale?.iso ? `${locale.iso} | ` : '';
  const articleName = title || `${i18n('article')} ${id}`;

  return `${isoText}${articleName}`;
};
