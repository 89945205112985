import { gql } from 'apollo-boost';

export const EVENT_ORDER_FULL_FRAGMENT = gql`
  fragment eventOrderFull on EventOrder {
    id
    clientId
    state
    paymentState
    discountId
    userId
    code
    total
    paidAmount
    reservedTill
    isFiscal
    meta
    tickets {
      # Cant use fragment: Cannot access 'EVENT_ORDER_FRAGMENT' before initialization
      id
      eventOrderId
      eventId
      barcode
      price
      quantity
      discountId
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
`;

export const EVENT_ORDER_FRAGMENT = gql`
  fragment eventOrder on EventOrder {
    id
    clientId
    state
    paymentState
    discountId
    userId
    code
    total
    paidAmount
    reservedTill
    isFiscal
    meta
    createdAt
    updatedAt
  }
`;
