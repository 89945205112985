import { gql } from 'apollo-boost';
import { COMMERCE_TEMPLATE_FRAGMENT } from './commerceTemplate';

export const COMMERCE_TEMPLATE_CONNECTION_FRAGMENT = gql`
  fragment CommerceTemplateConnection on CommerceTemplateConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...CommerceTemplate
    }
  }
  ${COMMERCE_TEMPLATE_FRAGMENT}
`;
