import React from 'react';

import './QuerySelect.css';
import { IFormField, UFormState } from '../UForm/UForm';
import { Alert, Button, Divider, Empty, message, Modal, Row, Select } from 'antd';
import { Query } from '@apollo/react-components';
import { ApolloError } from 'apollo-boost';
import { DataValue } from '@apollo/react-hoc';
import Loader from '../Loader/Loader';
import FiltersContextHOC from '../FiltersContextHOC/FiltersContextHOC';
import { IFiltersContextValue } from '../FiltersProvider/FiltersProvider';
import { OptionProps, SelectProps } from 'antd/es/select';
import { DEFAULT_PAGE_SIZE, SEARCH_SUBMIT_DELAY } from '../../config/constants';
import { FetchMoreOptions } from 'apollo-client';
import { compose } from 'recompose';
import { handleFail } from '../../helpers/handleMutation';
import { Link } from 'react-router-dom';
import { UTable } from '../../index';
import { SelectionSelectFn, TableProps } from 'antd/es/table';
import FloatPanel from '../FloatPanel/FloatPanel';
import { renderDate } from '../../../helpers/renderDate';

export interface Card {
  id: number;
  preview: string;
  name: string;
  createdAt: string;
}

interface IExternalProps {
  formField: IFormField;
  renderLoader: () => React.ReactElement;
  renderError: (err: ApolloError) => React.ReactElement;
  value: any;
  onChange: (value: any) => void;
  onDeselect?: (value: any) => void;
  onSelect?: (value: any) => void;
  formState: UFormState;
  isMultiSelect?: boolean;
  isDisabled?: boolean;
  getPath?: (value: any) => string | null;
  view?: 'cards';
  cards?: Array<Card>;
  hideAdvancedSelector?: boolean;
  getQueryResult?: (queryResult: any) => void;
  selectProps?: Partial<SelectProps>;
}

interface IProps extends IExternalProps {
  filtersContext: IFiltersContextValue;
}

interface IState {
  cursor: number;
  searchString: string;
  valueEdges: any[];
  isTableModalVisible: boolean;
  selectedRowKeys: any[];
  selectedCardsId: Card['id'][];
}

type RefetchFn = DataValue<any>['refetch'];
type FetchMoreFn = DataValue<any>['fetchMore'];

class QuerySelect extends React.PureComponent<IProps, IState> {
  private getSelectedRowsFromProps = (props: IProps): IState['selectedRowKeys'] => {
    const { value } = props;
    if (!value) {
      return [];
    }

    if (Array.isArray(value)) {
      return value;
    }

    return [value];
  };

  private initState = (): IState => {
    return {
      cursor: 0,
      searchString: '',
      valueEdges: [],
      isTableModalVisible: false,
      selectedRowKeys: this.getSelectedRowsFromProps(this.props),
      selectedCardsId: [],
    };
  };

  state: IState = this.initState();

  private timer: NodeJS.Timer | null = null;

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
    if (prevProps.value !== this.props.value) {
      const selectedRowKeys = this.getSelectedRowsFromProps(this.props);
      this.setState({ selectedRowKeys });
    }
  }

  static getQueryNameFromFormField = (formField: IFormField): string | null => {
    if (!formField || !formField.optionsQuery) {
      return null;
    }

    const { query, queryName } = formField.optionsQuery;
    if (queryName) {
      return queryName;
    }

    const definitions = query?.definitions ?? [];
    const operation = definitions.find((def: any) => def?.kind === 'OperationDefinition');
    if (!operation) {
      return null;
    }

    const result = operation.name?.value;
    if (typeof result !== 'string') {
      return null;
    }

    return result;
  };

  private handleValueFetchComplete = (data: any) => {
    const queryName = this.getQueryName();
    if (!data || !queryName) {
      return;
    }

    const valueEdges = (data && data[queryName] && data[queryName].edges) || [];

    this.setState({ valueEdges });
  };

  private handleError = (err: ApolloError | string) => {
    const { filtersContext } = this.props;
    let text = filtersContext.i18n('error_loading_select_options');
    if (typeof err === 'string') {
      text = err;
    }

    if (err instanceof ApolloError && err.message) {
      text = err.message;
    }

    console.error(err);
    message.error(text);
  };

  private handleChange = (value: any) => {
    this.props.onChange(value);
  };

  private handleDeselect = (value: any) => {
    const { onDeselect } = this.props;
    if (onDeselect) {
      onDeselect(value);
    }
  };

  private handleSelect = (value: any) => {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(value);
    }
  };

  private getVariables = () => {
    const { formField, formState } = this.props;

    const { optionsQuery } = formField;
    if (!optionsQuery) {
      return {};
    }

    let variables = optionsQuery.variables;
    if (typeof optionsQuery.variables === 'function') {
      variables = optionsQuery.variables(formState);
    }

    return this.decorateVariables(variables);
  };

  private decorateVariables = (variables: any, useCursor?: boolean) => {
    const query = (variables && variables.query) || {};

    return {
      ...variables,
      query: {
        ...query,
        search: this.state.searchString,
        cursor: useCursor ? this.state.cursor : query.cursor,
        limit: DEFAULT_PAGE_SIZE,
      },
    };
  };

  private getRefetchVariables = () => {
    const variables = this.getVariables();

    return this.decorateVariables(variables, true);
  };

  private getOptions = (optionEdges: any[]): any[] => {
    const { valueEdges } = this.state;
    const result = [...optionEdges];

    // Get ids for not including duplicates
    const edgesIds = optionEdges.map(edge => edge.id);

    // Iterate over values
    for (const valueInstance of valueEdges) {
      // Check if already exist in provided array
      const isExist = edgesIds.includes(valueInstance.id);
      if (isExist) {
        // Skip if exist in provided array
        continue;
      }

      // Otherwise add it to array
      result.push(valueInstance);
    }

    return result;
  };

  private handleDropdownVisibleChange = (isOpen: boolean) => {
    if (isOpen) {
      return;
    }

    // Reset search if dropwdown is closed
    this.handleSearch()('');
  };

  private handleSearch = (refetchFn?: RefetchFn) => (searchString: string) => {
    if (this.timer !== null) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.setState({ searchString }, () => {
        if (refetchFn) {
          refetchFn(this.getVariables());
        }
      });
    }, SEARCH_SUBMIT_DELAY);
  };

  private handleClickTableAction = () => {
    this.setState({ isTableModalVisible: true });
  };

  private handleCloseTableModal = () => {
    this.setState({ isTableModalVisible: false });
  };

  private handleSubmitSelection = () => {
    const { isMultiSelect } = this.props;
    const { selectedRowKeys } = this.state;
    let value = selectedRowKeys[0] || null;

    if (isMultiSelect) {
      value = selectedRowKeys;
    }

    this.handleChange(value);
    this.handleCloseTableModal();
  };

  private handleMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  private handleFetchMore = (fetchMoreFn: FetchMoreFn) => () => {
    const { i18n } = this.props.filtersContext;
    const cursor = this.state.cursor + DEFAULT_PAGE_SIZE;

    this.setState({ cursor }, () => {
      fetchMoreFn({
        variables: this.getRefetchVariables(),
        updateQuery: this.handleAddToCache,
      }).catch(handleFail('fetch_more', i18n));
    });
  };

  private handleSelectRow: SelectionSelectFn<any> = (selectedRow, isSelected) => {
    const { isMultiSelect } = this.props;
    if (isMultiSelect) {
      return this.handleSelectMultipleRows(isSelected, [selectedRow]);
    }

    return this.handleSelectSingleRow(isSelected, selectedRow);
  };

  private handleSelectMultipleRows = (isSelected: boolean, selectedRows: any[]) => {
    const { selectedRowKeys: previouslySelected } = this.state;
    const selectedRowKeys = selectedRows.map(({ id }) => id);
    let updatedSelectedRowKeys: any[];

    if (isSelected) {
      const idxSet = new Set([...previouslySelected, ...selectedRowKeys]);
      updatedSelectedRowKeys = Array.from(idxSet);
    } else {
      updatedSelectedRowKeys = previouslySelected.filter(existedId => {
        return !previouslySelected.includes(existedId);
      });
    }

    this.setState({ selectedRowKeys: updatedSelectedRowKeys });
  };

  private handleSelectSingleRow = (isSelected: boolean, selectedRow: any) => {
    const selectedRowKey = selectedRow?.id;
    if (selectedRowKey === null || selectedRow === undefined) {
      return;
    }

    const payload = isSelected ? [selectedRowKey] : [];

    this.setState({ selectedRowKeys: payload });
  };

  private handleAddToCache: FetchMoreOptions['updateQuery'] = (previousQueryResult, res) => {
    const { optionsQuery } = this.props.formField;

    if (!optionsQuery || !optionsQuery.queryName) {
      throw new Error(`Not enough arguments`);
    }

    const { queryName } = optionsQuery;

    if (!res.fetchMoreResult) {
      return previousQueryResult;
    }

    // const previousEdges = this.getEdgesFromCache();
    const fetchedEdges = (res.fetchMoreResult[queryName] && res.fetchMoreResult[queryName].edges) || [];
    const previousEdges = (previousQueryResult[queryName] && previousQueryResult[queryName].edges) || [];

    const updatedEdges = [...previousEdges, ...fetchedEdges];
    return {
      ...previousQueryResult,
      [queryName]: {
        ...previousQueryResult[queryName],
        edges: updatedEdges,
      },
    };
  };

  private getQuerySelectOptionValue = (option: any): OptionProps['value'] => {
    const { optionsQuery } = this.props.formField;
    if (!optionsQuery || !option) {
      return undefined;
    }

    const { optionValuePropertyName } = optionsQuery;
    const property = optionValuePropertyName || 'id';

    return option[property];
  };

  private handleSelectCard = (cardId: Card['id']) => () => {
    const { selectedRowKeys } = this.state;
    if (!selectedRowKeys.find(id => id === cardId)) {
      this.setState({ selectedRowKeys: [...selectedRowKeys, cardId] });
    } else {
      this.setState({ selectedRowKeys: selectedRowKeys.filter(id => id !== cardId) });
    }
  };

  private renderCard = (card: Card) => {
    const { selectedRowKeys } = this.state;
    const selected = selectedRowKeys.find(id => id === card.id);
    const className = selected ? 'querySelect_card--selected' : '';

    return (
      <div onClick={this.handleSelectCard(card.id)} className="querySelect_card" key={card.id}>
        <FloatPanel className={className}>
          <img className="querySelect_card--preview" src={card.preview} alt={card.name} />
          <Row type="flex" justify="space-between" align="middle">
            <div>{card.name}</div>
            <div className="querySelect_card--date">{renderDate(card.createdAt)}</div>
          </Row>
        </FloatPanel>
      </div>
    );
  };

  private renderCards = () => {
    const { cards } = this.props;
    const data = cards || [];
    return data.map(this.renderCard);
  };

  private renderTable = () => {
    const { formField, filtersContext } = this.props;
    const queryName = this.getQueryName();
    const query = formField.optionsQuery?.query;
    if (!query || !queryName) {
      return null;
    }

    const tableProps = this.getTableProps();
    const title = filtersContext.i18n('h_select_records');

    return (
      <div className="querySelect_tableWrapper">
        <UTable
          id={queryName}
          zeroElevation
          showSearch
          searchDebounce
          title={title}
          tableProps={tableProps}
          queryName={queryName}
          query={query}
        />
      </div>
    );
  };

  private renderOptionsLoader = () => {
    const text = this.props.filtersContext.i18n('loading_options');
    return <Loader text={text} />;
  };

  private renderNotFoundContent = (isLoading: boolean) => {
    if (isLoading) {
      return this.renderOptionsLoader();
    }

    return <Empty />;
  };

  private renderQueryContent = ({ data, loading, error, refetch, fetchMore }: DataValue<any, any> & { data: any }) => {
    const { renderLoader, renderError, value, isMultiSelect, isDisabled, selectProps } = this.props;
    const queryName = this.getQueryName();

    if (!queryName) {
      return null; // Dont worry, it will be handled in renderOptionsQuery method
    }

    if (error) {
      return renderError(error);
    }

    if (!data) {
      return renderLoader();
    }

    const edges: any[] = (data && data[queryName] && data[queryName].edges) || [];
    const options = this.getOptions(edges).map(item => {
      return {
        ...item,
        id: Number(item.id),
      };
    });
    const notFoundContent = this.renderNotFoundContent(loading);
    const onSearch = this.handleSearch(refetch);
    const dropdownRender = this.renderDropdown(fetchMore, data, loading);
    const mode = isMultiSelect ? 'multiple' : undefined;

    return (
      <div className="querySelect">
        <Select
          className="querySelect_select"
          mode={mode}
          disabled={isDisabled}
          notFoundContent={notFoundContent}
          filterOption={false}
          value={value}
          showSearch
          autoClearSearchValue={false}
          onSearch={onSearch}
          onDropdownVisibleChange={this.handleDropdownVisibleChange}
          onChange={this.handleChange}
          dropdownRender={dropdownRender}
          onDeselect={this.handleDeselect}
          onSelect={this.handleSelect}
          {...selectProps}
        >
          {this.renderOptions(options)}
        </Select>
        {this.renderTableAction()}
        {this.renderGoToLink()}
      </div>
    );
  };

  private renderDropdown = (fetchMoreFn: FetchMoreFn, data: any, isLoading: boolean) => (menu?: React.ReactNode) => {
    if (isLoading) {
      return this.renderOptionsLoader();
    }

    return (
      <div>
        {menu}
        {this.renderFetchMoreAction(fetchMoreFn, data)}
      </div>
    );
  };

  private renderFetchMoreAction = (fetchMoreFn: FetchMoreFn, data: any) => {
    const queryName = this.getQueryName();
    const total = data && queryName && data[queryName] && data[queryName].total;
    if (typeof total === 'number' && total < DEFAULT_PAGE_SIZE) {
      return null;
    }

    const { i18n } = this.props.filtersContext;
    const btnText = i18n('action_fetch_more');
    const onClick = this.handleFetchMore(fetchMoreFn);

    return (
      <>
        <Divider />
        <div className="querySelect_fetchMoreWrapper" onMouseDown={this.handleMouseDown}>
          <Button onClick={onClick}>{btnText}</Button>
        </div>
      </>
    );
  };

  private renderOptions = (options: any[]) => {
    return options.map(this.renderQuerySelectOption);
  };

  private renderTableAction = () => {
    const { isDisabled, hideAdvancedSelector } = this.props;
    if (hideAdvancedSelector) {
      return null;
    }

    return (
      <Button
        disabled={isDisabled}
        className="querySelect_tableAction"
        onClick={this.handleClickTableAction}
        icon="table"
      />
    );
  };

  private renderModalContent = () => {
    const { view } = this.props;
    if (view === 'cards') {
      return this.renderCards();
    }

    return this.renderTable();
  };

  private renderModal = () => {
    const { isTableModalVisible } = this.state;

    return (
      <Modal
        width="80vw"
        maskClosable
        destroyOnClose
        visible={isTableModalVisible}
        onCancel={this.handleCloseTableModal}
        onOk={this.handleSubmitSelection}
      >
        {this.renderModalContent()}
      </Modal>
    );
  };

  private renderGoToLink = () => {
    const { getPath, value } = this.props;
    if (!getPath) {
      return null;
    }

    const path = getPath(value);
    const isDisabled = !path;

    const button = <Button className="querySelect_linkAction" disabled={isDisabled} icon="link" />;
    if (!path || isDisabled) {
      return button;
    }

    return (
      <Link to={path} target="_blank">
        {button}
      </Link>
    );
  };

  private renderVariantText = (option: any, index: number, field: string): React.ReactNode => {
    const { id, name, title, __typename } = option;
    if (title || name) {
      return title || name;
    }

    let composed = `${__typename || field} ${index}`;
    if (id) {
      composed += ` (${id})`;
    }

    return composed;
  };

  private renderQuerySelectOption = (option: any, index: number) => {
    const { field, optionsQuery } = this.props.formField;

    let variantText = this.renderVariantText(option, index, field);
    if (optionsQuery && optionsQuery.render) {
      variantText = optionsQuery.render(option);
    }

    const value = this.getQuerySelectOptionValue(option);
    const key = option.id || `${field}-${index}`;

    return (
      <Select.Option key={key} value={value}>
        {variantText}
      </Select.Option>
    );
  };

  private handleClickRow = (record: any) => () => {
    const { selectedRowKeys } = this.state;
    if (selectedRowKeys.find(item => item === record.id)) {
      this.handleSelectMultipleRows(false, [record.id]);
    } else {
      this.handleSelectMultipleRows(true, [record.id]);
    }
  };

  private getTableProps = (): TableProps<any> => {
    const { isMultiSelect } = this.props;
    const { selectedRowKeys } = this.state;
    const type = isMultiSelect ? 'checkbox' : 'radio';

    return {
      onRow: record => ({
        onClick: this.handleClickRow(record),
      }),
      rowSelection: {
        type: type,
        selectedRowKeys: selectedRowKeys,
        onSelect: this.handleSelectRow,
        onSelectAll: this.handleSelectMultipleRows,
        onSelectMultiple: this.handleSelectMultipleRows,
      },
    };
  };

  private getQueryName = () => {
    const { formField } = this.props;
    return QuerySelect.getQueryNameFromFormField(formField);
  };

  private renderValuesQuery = () => {
    const { formField, value } = this.props;
    if (value === undefined) {
      return null;
    }

    const { optionsQuery } = formField;
    if (!optionsQuery) {
      return null;
    }

    const queryName = this.getQueryName();
    if (!queryName) {
      return this.renderNoQueryName();
    }

    const variables = {
      query: {
        filters: [
          {
            field: 'id',
            value: JSON.stringify(value),
          },
        ],
      },
    };

    return (
      <Query
        query={optionsQuery.query}
        variables={variables}
        onCompleted={this.handleValueFetchComplete} // TODO add onCompleted={this.handleQueryComplition} here
        onError={this.handleError}
      >
        {this.renderValueQueryContent}
      </Query>
    );
  };

  private renderValueQueryContent = () => null;

  private renderNoQueryName = () => {
    return <Alert message="QueryName is not provided" />;
  };

  private renderQueries = () => {
    const queryName = this.getQueryName();
    if (!queryName) {
      return this.renderNoQueryName();
    }

    return (
      <>
        {this.renderValuesQuery()}
        {this.renderMainQuery()}
      </>
    );
  };

  private handleQueryComplition = (result: any) => {
    const { getQueryResult } = this.props;
    getQueryResult && getQueryResult(result);
  };

  private renderMainQuery = () => {
    const { formField } = this.props;

    const { optionsQuery } = formField;
    if (!optionsQuery) {
      return null;
    }

    if (!optionsQuery.queryName) {
      return <Alert message="QueryName is not provided" />;
    }

    const variables = this.getVariables();

    return (
      <Query
        query={optionsQuery.query}
        variables={variables}
        onError={this.handleError}
        onCompleted={this.handleQueryComplition}
      >
        {this.renderQueryContent}
      </Query>
    );
  };

  render() {
    return (
      <>
        {this.renderModal()}
        {this.renderQueries()}
      </>
    );
  }
}

export default compose<IProps, IExternalProps>(
  FiltersContextHOC<IProps, IExternalProps>({
    name: 'filtersContext',
  })
)(QuerySelect);
