import { Alert } from 'antd';
import { ApolloError } from 'apollo-boost';
import * as React from 'react';
import { getWithExpiry, setWithExpiry } from '../../universal/helpers/withExpiry';
import Loader from '../Loader/Loader';

import './RootBoundary.scss';

interface IProps {}

interface IState {
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
  reloading: boolean;
}

class RootBoundary extends React.PureComponent<IProps, IState> {
  public state: IState = {
    error: null,
    errorInfo: null,
    reloading: false,
  };

  componentDidCatch(error: Error | ApolloError, errorInfo: React.ErrorInfo): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (error?.message && chunkFailedMessage.test(error.message)) {
      if (!getWithExpiry('chunk_failed')) {
        setWithExpiry('chunk_failed', 'true', 10000);
        window.location.reload();
        this.setState({ reloading: true });
        return;
      }
    }
    this.setState({ error, errorInfo });
  }

  public render() {
    const { error, errorInfo, reloading } = this.state;

    if (reloading) {
      return (
        <div className="rootBoundary">
          <Loader />
        </div>
      );
    }

    if (error && errorInfo) {
      return <Alert type="error" message={error.message} description={errorInfo.componentStack} />;
    }

    return this.props.children;
  }
}

export default RootBoundary;
