import { gql } from 'apollo-boost';

export const ARTICLE_LOG_FRAGMENT = gql`
  fragment articleLog on ArticleLog {
    articleId
    authorId
    data
    state
    createdAt
  }
`;
