import { gql } from 'apollo-boost';
import { CATALOGUE_ITEM_FRAGMENT_IN_COLLECTION } from './catalogueItem';

export const CATALOGUE_ITEM_CONNECTION_FRAGMENT = gql`
  fragment catalogueItemConnection on CatalogueItemConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...catalogueItemInCollection
    }
  }
  ${CATALOGUE_ITEM_FRAGMENT_IN_COLLECTION}
`;
