import { gql } from 'apollo-boost';
import { EVENT_PLACE_FRAGMENT } from './eventPlace';
import { COMMERCE_SHOP_FLAT_FRAGMENT } from './commerceShop';

export const CATERING_FLAT_FRAGMENT = gql`
  fragment cateringFlat on Catering {
    id
    clientId
    eventPlaceId
    commerceShopId
    state
    printReceiptTemplateId
    name
    sectors
    createdAt
    updatedAt
  }
`;

export const CATERING_FRAGMENT = gql`
  fragment catering on Catering {
    ...cateringFlat
    eventPlace {
      ...eventPlace
    }
    commerceShop {
      ...commerceShopFlat
    }
  }
  ${COMMERCE_SHOP_FLAT_FRAGMENT}
  ${CATERING_FLAT_FRAGMENT}
  ${EVENT_PLACE_FRAGMENT}
`;
