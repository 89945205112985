import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Button } from 'antd';
import { ButtonProps } from 'antd/es/button';
import FiltersContextHOC from '../FiltersContextHOC/FiltersContextHOC';
import { compose } from 'recompose';
import { IFiltersContextValue } from '../FiltersProvider/FiltersProvider';

interface IExternalProps extends ButtonProps {
  path?: string;
  onClick?: () => void;
  buttonProps?: ButtonProps;
}

interface IProps extends IExternalProps, RouteComponentProps<any> {
  filtersContext: IFiltersContextValue;
}

interface IState {}

class CreateNewAction extends React.Component<IProps, IState> {
  private handleClick = () => {
    const { path, history, onClick } = this.props;

    if (onClick) {
      return onClick();
    }

    if (!path) {
      return;
    }

    history.push(path);
  };

  public render() {
    const { buttonProps, filtersContext, children } = this.props;
    const content = children || filtersContext.i18n('action_create');

    return (
      <Button {...buttonProps} onClick={this.handleClick}>
        {content}
      </Button>
    );
  }
}

export default compose<IProps, IExternalProps>(withRouter, FiltersContextHOC({}))(CreateNewAction);
