import { MutationUpdaterFn } from 'apollo-boost';
import { DefaultQueryInput } from '../typings/graphql';
import { ITableState, UTABLE_CONFIG_PREFIX } from '../universal/components/UTable/UTable';
import { getFromLocalStorage } from './getFromLocalStorage';
import { DEFAULT_PAGE_SIZE } from '../config/constants';

export interface IManipulateCacheConfig {
  mutationQueryName: string; // createLoyaltyPartner
  collectionQueryName: string; // allLoyaltyPartners
  collectionQuery: any; // ALL_LOYALTY_PARTNERS_QUERY
  queryInput?: DefaultQueryInput;
  ignoreAutoCursor?: boolean;
  queryId?: string;
}

export const getCursorFromCache = (config: IManipulateCacheConfig) => {
  const { queryId, collectionQueryName } = config;

  let cursor = 0;
  let limit = DEFAULT_PAGE_SIZE;
  let search = '';

  // Get config for AllArticleLinksTable
  const id = queryId || collectionQueryName;
  const tableConfigKey = `${UTABLE_CONFIG_PREFIX}_${id}`;
  const tableConfig: ITableState | null = getFromLocalStorage(tableConfigKey);

  if (tableConfig) {
    limit = tableConfig.rowsCount || 20;
    cursor = ((tableConfig.currentPage || 1) - 1) * limit;
    search = tableConfig.search;
  }

  return { cursor, limit, search };
};

export function addToCache<TMutation = any>(config: IManipulateCacheConfig): MutationUpdaterFn<TMutation> {
  return (cacheClient, res) => {
    const { mutationQueryName, collectionQueryName, collectionQuery, queryInput } = config;

    // @ts-ignore
    const created = res && res.data && res.data[mutationQueryName];
    if (!cacheClient || !created) {
      return;
    }

    const query = collectionQuery;
    const variables: { query?: DefaultQueryInput } = {};

    if (queryInput) {
      variables.query = { ...queryInput };
    }

    if (!config.ignoreAutoCursor) {
      const { cursor, limit, search } = getCursorFromCache(config);

      variables.query = {
        ...variables.query,
        cursor,
        limit,
        search,
      };
    }

    const cache = cacheClient.readQuery({
      query: query,
      variables: variables,
    });

    // @ts-ignore
    if (!cache || !cache[collectionQueryName]) {
      return;
    }

    // @ts-ignore
    const edges = cache[collectionQueryName].edges || [];
    const updatedEdges = [...edges, created];

    cacheClient.writeQuery({
      query: query,
      variables: variables,
      data: {
        // @ts-ignore
        ...cache,
        [collectionQueryName]: {
          ...cache[collectionQueryName],
          edges: updatedEdges,
        },
      },
    });
  };
}

export function removeFromCache<TMutation = any>(config: IManipulateCacheConfig): MutationUpdaterFn<TMutation> {
  return (cacheClient, res) => {
    const { mutationQueryName, collectionQueryName, collectionQuery, queryInput } = config;

    // @ts-ignore
    const mutated = res && res.data && res.data[mutationQueryName];
    if (!cacheClient || !mutated) {
      return;
    }

    const query = collectionQuery;
    const variables: { query?: DefaultQueryInput } = {};

    if (queryInput) {
      variables.query = { ...queryInput };
    }

    if (!config.ignoreAutoCursor) {
      const { cursor, limit, search } = getCursorFromCache(config);

      variables.query = {
        ...variables.query,
        cursor,
        limit,
        search,
      };
    }

    const cache = cacheClient.readQuery({
      query: query,
      variables: variables,
    });

    // @ts-ignore
    if (!cache || !cache[collectionQueryName]) {
      return;
    }

    // @ts-ignore
    const edges = cache[collectionQueryName].edges || [];
    const updatedEdges = edges.filter((existed: any) => existed.id !== mutated.id);

    cacheClient.writeQuery({
      query: query,
      variables: variables,
      data: {
        // @ts-ignore
        ...cache,
        [collectionQueryName]: {
          ...cache[collectionQueryName],
          edges: updatedEdges,
        },
      },
    });
  };
}
