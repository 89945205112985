import { gql } from 'apollo-boost';

export const SEASON_TEAM_STATS_FRAGMENT = gql`
  fragment seasonTeamStats on TournamentSeasonTeamStat {
    id
    position
    points
    games
    win
    loss
    draw
    yellowCards
    redCards
    goals
    shutouts
    missedGoals
  }
`;
