import React from 'react';

import './Rcm.scss';
import FloatPanel, { FloatPanelProps } from '../FloatPanel/FloatPanel';
import { ApolloError } from 'apollo-boost';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Loader from '../Loader/Loader';
import { Empty, Skeleton } from 'antd';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

type Maybe<T> = T | null;

interface IExternalProps {
  // Quick props for wrapper rendering
  isWrapped?: Maybe<boolean>;
  title?: Maybe<string>;
  wrapperProps?: Maybe<Partial<FloatPanelProps>>;
  className?: string;

  // For automatic renderContent
  error?: Maybe<ApolloError>;
  isLoading?: Maybe<boolean>;
  isEmpty?: Maybe<boolean>;
  useSkeleton?: boolean;

  // Main renderers
  renderContent?: Maybe<() => React.ReactNode>;
  renderEssence?: Maybe<() => React.ReactNode>;
  renderCustomWrapper?: Maybe<() => React.ReactNode>;

  // Content block renderers
  renderLoader?: Maybe<() => React.ReactNode>;
  renderEmpty?: Maybe<() => React.ReactNode>;
  renderError?: Maybe<(error: ApolloError) => React.ReactNode>;
}

export type RcmProps = Partial<IExternalProps>;

interface IProps extends IExternalProps {}

interface IState {}

// RCM stands for RenderContentMethods
class Rcm extends React.Component<IProps, IState> {
  private renderEssence = () => {
    const { renderEssence } = this.props;
    if (renderEssence) {
      return renderEssence();
    }

    return this.props.children;
  };

  private renderContent = () => {
    const { renderContent } = this.props;
    if (renderContent) {
      return renderContent();
    }

    const { isLoading, error, isEmpty } = this.props;
    if (error) {
      return this.renderError(error);
    }

    if (isLoading) {
      return this.renderLoader();
    }

    if (isEmpty) {
      return this.renderEmpty();
    }

    return this.renderEssence();
  };

  private renderEmpty = () => {
    const { renderEmpty } = this.props;
    if (renderEmpty) {
      return renderEmpty();
    }

    return <Empty />;
  };

  private renderLoader = () => {
    const { renderLoader, useSkeleton } = this.props;
    if (renderLoader) {
      return renderLoader();
    }

    if (useSkeleton) {
      return <Skeleton />;
    }

    return <Loader isFull />;
  };

  private renderError = (error: NonNullable<IProps['error']>) => {
    const { renderError } = this.props;
    if (renderError) {
      return renderError(error);
    }

    return <ErrorMessage isFull error={error} />;
  };

  private renderWrapper = () => {
    const { isWrapped, renderCustomWrapper, className, wrapperProps, title } = this.props;
    if (renderCustomWrapper) {
      return renderCustomWrapper();
    }

    if (!isWrapped) {
      return (
        <div className={className}>
          {title && <h3>{title}</h3>}
          {this.renderContent()}
        </div>
      );
    }

    return (
      <FloatPanel className={className} title={title} {...wrapperProps}>
        <ErrorBoundary>{this.renderContent()}</ErrorBoundary>
      </FloatPanel>
    );
  };

  private renderBoundary = () => {
    return <ErrorBoundary>{this.renderWrapper()}</ErrorBoundary>;
  };

  render() {
    return this.renderBoundary();
  }
}

export default Rcm;
