import { gql } from 'apollo-boost';
import { CATALOGUE_ITEM_CONNECTION_FRAGMENT } from '../fragments/catalogueItemConnection';

export const ALL_CATALOGUE_ITEMS_QUERY = gql`
  query allCatalogueItems($query: DefaultQueryInput) {
    allCatalogueItems(query: $query) {
      ...catalogueItemConnection
    }
  }
  ${CATALOGUE_ITEM_CONNECTION_FRAGMENT}
`;
