import { gql } from 'apollo-boost';

export const USER_SUBSCRIPTION_FRAGMENT = gql`
  fragment userSubscription on UserSubscription {
    id
    clientId
    state
    name
    description
    subscribersCount
    isSubscriber
    access
    createdAt
    updatedAt
  }
`;
