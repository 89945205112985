import React from 'react';
import { IS_DEVELOPMENT } from '../../config/constants';

const DevelopmentWarning = () => {
  const iconSrc = '/img/devWarning.png';
  if (IS_DEVELOPMENT) {
    return (
      <img
        style={{
          backgroundImage: `url(${iconSrc})`,
          width: '100%',
          height: '20px',
          padding: ' 0px',
          margin: '0px',
          zIndex: 500,
          position: 'absolute',
          bottom: '0px',
          left: '0px',
        }}
        alt=""
      />
    );
  }
  return null;
};

export default DevelopmentWarning;
