import { gql } from 'apollo-boost';
import { TOURNAMENT_MATCH_EVENT_FRAGMENT } from '../fragments/tournamentMatchEvent';

export const TOURNAMENT_MATCH_EVENT_QUERY = gql`
  query tournamentMatchEvent($id: Int!) {
    tournamentMatchEvent(id: $id) {
      ...tournamentMatchEvent
    }
  }
  ${TOURNAMENT_MATCH_EVENT_FRAGMENT}
`;
