import React from 'react';

import './DataFieldsForm.css';
import { compose } from 'recompose';
import { MutationUpdaterFn } from 'apollo-boost';
import {
  // CatalogueFieldFragment,
  // CatalogueItemDataCreateInput,
  // CatalogueItemDataFragment,
  // CreateCatalogueItemDataProps,
  FilterDataType,
  Maybe,
  Scalars,
  // UpdateCatalogueItemDataProps,
  // UpdateCatalogueItemDatasMutationVariables,
  // UpdateCatalogueItemDatasProps,
  // CreateCatalogueItemDataBatchProps,
} from '../../typings/graphql';
import { getMutateProps } from '../../helpers/mutationOperationOptions';
import UForm, { FormComponent, IFormField, IOptionsQuery, UFormState } from '../UForm/UForm';
import { handleFail, handleSuccess, OPERATIONS } from '../../helpers/handleMutation';
import FiltersContextHOC from '../FiltersContextHOC/FiltersContextHOC';
import { IFiltersContextValue } from '../FiltersProvider/FiltersProvider';
import { IUUploadConfig } from '../UUpload/UUpload';
import { parseJSON } from '../../helpers/parseJSON';

interface IExternalProps {
  // Array of DataFields, like CatalogueItemDataFragment[] or CommerceOrderDataFragment[]
  data: any[];

  // Array of field configurations, like CatalogueFieldFragment[] or CommerceOrderFieldFragment[]
  fields: any[];

  // CatalogueItemFragment['id']
  parentInstanceId: any;

  // String representing property name in payload-object to create payload for for multiple updates.
  // Like 'catalogueFieldId' or 'orderFieldId'
  fieldPropertyName: string;

  // String representing property name in payload-object to create payload for for multiple updates.
  // Like 'catalogueItemId' or 'orderItemId'
  parentPropertyName: string;

  // Generated HOC for updating 1 instance, like 'withUpdateCatalogueItemData'
  updateHOC: any;

  // Generated HOC for mass update, like 'withUpdateCatalogueItemDatas'
  updateBatchHOC?: any;

  // Generated HOC for creating 1 instance, like 'withCreateCatalogueItemData'
  createHOC: any;

  // Generated HOC for mass creating, like 'withCreateCatalogueItemDataBatch'
  createBatchHOC?: any;

  // Function to update cache after updating
  afterUpdateUpdaterFn?: MutationUpdaterFn<any>;

  // Function to update cache after creating
  afterCreateUpdaterFn?: MutationUpdaterFn<any>;

  // Function to call after creating
  onCreate?: () => void;

  // Function to generate options query config for searching entities if field.dataType === ENTITY
  entityOptionsQueryConstructor?: EntityOptionsQueryConstructor;

  // Function to generate config for Uploader
  uploaderConfigConstructor?: UploaderConfigConstructor;

  // To rewrite or extend fields
  mapFormField?: (formField: IFormField, dataFields: any[]) => IFormField;
}

// field argument is actually CatalogueFieldFragment, but typing may differ from project to project, so we make it Generic
export type EntityOptionsQueryConstructor<TField = any> = (field: TField) => IOptionsQuery;
export type UploaderConfigConstructor<TField = any> = (field: TField) => IUUploadConfig;

interface IProps extends IExternalProps {
  create: any; // CreateCatalogueItemDataProps;
  createBatch?: any; // CreateCatalogueItemDataBatchProps;
  update: any; // UpdateCatalogueItemDataProps;
  updateBatch?: any; // UpdateCatalogueItemDatasProps;
  filtersContext: IFiltersContextValue;
}

interface IState {}

interface IPayloads {
  update: any; // UpdateCatalogueItemDatasMutationVariables['data'];
  create: any[]; // CatalogueItemDataCreateInput[];
}

type FieldDataFragment = {
  id: Scalars['Int'];
  locale?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  // catalogueFieldId: Scalars['Int'];
  // catalogueItemId: Scalars['Int'];
  key?: Maybe<Scalars['String']>;
  field: any;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

type FieldFragment = {
  id: Scalars['Int'];
  // catalogueId: Scalars['Int'];
  name: Scalars['String'];
  dataType: string; // CatalogueFieldDataType;
  // type: CatalogueFieldType;
  hasLocale: Scalars['Boolean'];
  sort: Scalars['Int'];
  translations?: Maybe<Array<any>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

class DataFieldsForm extends React.PureComponent<IProps, IState> {
  private getError = () => {
    const { update, updateBatch, create, createBatch } = this.props;

    const errorUpdatingBatch = updateBatch && updateBatch.result && updateBatch.result.error;
    const errorCreatingBatch = createBatch && createBatch.result && createBatch.result.error;

    const { error: errorUpdating } = update.result;
    const { error: errorCreating } = create.result;

    return errorUpdating || errorCreating || errorUpdatingBatch || errorCreatingBatch;
  };

  private isPending = () => {
    const { update, updateBatch, create, createBatch } = this.props;

    const isUpdatingBatch = updateBatch && updateBatch.result && updateBatch.result.loading;
    const isCreatingBatch = createBatch && createBatch.result && createBatch.result.loading;

    const { loading: isUpdating } = update.result;
    const { loading: isCreating } = create.result;

    return isUpdating || isCreating || isUpdatingBatch || isCreatingBatch;
  };

  private getFieldDataByField = (field: FieldFragment) => {
    const { data, fieldPropertyName } = this.props;
    if (!data) {
      return null;
    }

    return data.find(fieldData => {
      if (!fieldData) {
        return false;
      }

      if (fieldPropertyName) {
        return fieldData[fieldPropertyName] === field.id;
      }

      if (!fieldData.field) {
        return false;
      }

      return fieldData.field.id === field.id;
    });
  };

  private getFieldValue = (field: FieldFragment): IFormField['defaultValue'] => {
    const fieldData = this.getFieldDataByField(field);
    if (!fieldData || !fieldData.value) {
      return null;
    }

    return fieldData.value;
  };

  private convertFilesToUFormField = (field: FieldFragment): IFormField => {
    let uploadConfig: IUUploadConfig | undefined = undefined;

    const { uploaderConfigConstructor } = this.props;
    if (uploaderConfigConstructor) {
      uploadConfig = uploaderConfigConstructor(field);
    }

    return {
      field: field.name,
      component: FormComponent.UPLOAD,
      defaultValue: this.getFieldValue(field),
      uploadConfig: uploadConfig,
    };
  };

  private convertArrayToUFormField = (field: FieldFragment): IFormField => {
    let value = this.getFieldValue(field) ?? [];

    if (typeof value === 'string') {
      value = parseJSON(value, true);
    }

    if (!Array.isArray(value)) {
      value = [];
    }

    return {
      field: field.name,
      component: FormComponent.SELECT,
      defaultValue: value,
      selectOptionsConfig: {
        options: value,
        componentProps: {
          mode: 'tags',
        },
      },
    };
  };

  private convertNumberToUFormField = (field: FieldFragment): IFormField => {
    return {
      field: field.name,
      type: FilterDataType.NUMBER,
      component: FormComponent.INPUT,
      defaultValue: this.getFieldValue(field),
    };
  };

  private convertStringToUFormField = (field: FieldFragment): IFormField => {
    const defaultValue = this.getFieldValue(field);

    const isLong = defaultValue && defaultValue.length > 70;
    const component = isLong ? FormComponent.TEXTAREA : FormComponent.INPUT;

    return {
      field: field.name,
      component: component,
      defaultValue: defaultValue,
    };
  };

  private convertEnumToUFormField = (field: FieldFragment): IFormField => {
    return {
      field: field.name,
      component: FormComponent.SELECT,
      defaultValue: this.getFieldValue(field),
    };
  };

  private convertBooleanToUFormField = (field: FieldFragment): IFormField => {
    return {
      field: field.name,
      component: FormComponent.INPUT,
      defaultValue: this.getFieldValue(field),
    };
  };

  private convertEntityToUFormField = (field: FieldFragment): IFormField => {
    let optionsQuery: IOptionsQuery | undefined = undefined;

    const { entityOptionsQueryConstructor } = this.props;
    if (entityOptionsQueryConstructor) {
      optionsQuery = entityOptionsQueryConstructor(field);
    }

    return {
      field: field.name,
      component: FormComponent.SELECT,
      defaultValue: this.getFieldValue(field),
      optionsQuery: optionsQuery,
    };
  };

  private convertFieldToUFormField = (field: FieldFragment): IFormField => {
    const dataType = field.dataType;

    if (dataType === 'FILES') {
      return this.convertFilesToUFormField(field);
    }

    if (dataType === 'ARRAY') {
      return this.convertArrayToUFormField(field);
    }

    if (dataType === 'NUMBER') {
      return this.convertNumberToUFormField(field);
    }

    if (dataType === 'STRING') {
      return this.convertStringToUFormField(field);
    }

    if (dataType === 'ENUM') {
      return this.convertEnumToUFormField(field);
    }

    if (dataType === 'BOOLEAN') {
      return this.convertBooleanToUFormField(field);
    }

    if (dataType === 'ENTITY') {
      return this.convertEntityToUFormField(field);
    }

    throw new Error(`Wrong field.dataType = ${dataType}`);
  };

  private getFields = (): IFormField[] => {
    const fields = this.props.fields || [];

    return fields.map(this.convertFieldToUFormField).map(this.withCustomFormFields(fields));
  };

  // If we want to decorate formFields from outside
  private withCustomFormFields = (dataFields: any[]) => (formField: IFormField): IFormField => {
    const { mapFormField } = this.props;
    if (!mapFormField) {
      return formField;
    }

    return mapFormField(formField, dataFields);
  };

  private getFieldDataByKey = (key: string): FieldDataFragment | null => {
    const field = this.getFieldByKey(key);
    if (!field) {
      return null;
    }

    return this.getFieldDataByField(field);
  };

  private getFieldByKey = (key: string): FieldFragment | null => {
    const { fields } = this.props;
    if (!fields) {
      return null;
    }

    const field = fields.find(({ name }) => name === key);
    return field || null;
  };

  private convertFormStateToUpdatePayloads = (formState: UFormState): IPayloads['update'] => {
    const { parentInstanceId, fieldPropertyName, parentPropertyName } = this.props;
    const result: IPayloads['update'] = [];

    for (const key in formState) {
      if (!formState.hasOwnProperty(key)) {
        continue;
      }

      const fieldData = this.getFieldDataByKey(key);
      if (!fieldData) {
        continue;
      }

      const rawValue = formState[key] || '';
      const value = typeof rawValue === 'string' ? rawValue : JSON.stringify(rawValue);
      result.push({
        value: value,
        id: fieldData.id,
        // @ts-ignore
        [fieldPropertyName]: fieldData[fieldPropertyName] || fieldData.field.id,
        [parentPropertyName]: parentInstanceId,
      });
    }

    return result;
  };

  private convertFormStateToCreatePayloads = (formState: UFormState): IPayloads['create'] => {
    const { parentInstanceId, fieldPropertyName, parentPropertyName } = this.props;
    const result: IPayloads['create'] = [];

    for (const key in formState) {
      if (!formState.hasOwnProperty(key)) {
        continue;
      }

      const dataField = this.getFieldDataByKey(key);
      if (dataField) {
        continue;
      }

      const field = this.getFieldByKey(key);
      if (!field) {
        continue;
      }

      const rawValue = formState[key];
      const value = typeof rawValue === 'string' ? rawValue : JSON.stringify(rawValue);
      // @ts-ignore
      result.push({
        value: value,
        [fieldPropertyName]: field.id,
        [parentPropertyName]: parentInstanceId,
      });
    }

    return result;
  };

  private convertFormStateToPayload = (formState: UFormState): IPayloads => {
    const update = this.convertFormStateToUpdatePayloads(formState);
    const create = this.convertFormStateToCreatePayloads(formState);

    return { update, create };
  };

  private handleSubmit = (formState: UFormState) => {
    const payload = this.convertFormStateToPayload(formState);

    if (payload.update.length > 0) {
      this.handleUpdate(payload.update);
    }

    if (payload.create.length > 0) {
      this.handleCreate(payload.create);
    }
  };

  private handleUpdate = (payload: IPayloads['update']) => {
    const { filtersContext, update, updateBatch, afterUpdateUpdaterFn } = this.props;
    const { i18n } = filtersContext;

    // Update batch if available
    if (updateBatch) {
      updateBatch
        .mutate({
          update: afterUpdateUpdaterFn,
          variables: {
            data: payload,
          },
        })
        .then(handleSuccess(OPERATIONS.UPDATE, i18n))
        .catch(handleFail(OPERATIONS.UPDATE, i18n));
    } else {
      // Update each if batch is not available
      for (const data of payload) {
        // Extract id from data
        const { id, ...updateData } = data;

        update
          .mutate({
            update: afterUpdateUpdaterFn,
            variables: {
              id: data.id,
              data: updateData,
            },
          })
          .catch(handleFail(OPERATIONS.UPDATE, i18n));
      }
    }
  };

  private handleCreate = (payload: IPayloads['create']) => {
    const { filtersContext, create, createBatch, afterCreateUpdaterFn } = this.props;
    const { i18n } = filtersContext;

    if (createBatch) {
      createBatch
        .mutate({
          update: afterCreateUpdaterFn,
          variables: {
            data: payload,
          },
        })
        .then(this.handleCreateSuccess)
        .catch(handleFail(OPERATIONS.CREATE, i18n));
    } else {
      const promises = payload.map(data => {
        return create.mutate({
          update: afterCreateUpdaterFn,
          variables: {
            data: data,
          },
        });
      });

      Promise.all(promises)
        .then(this.handleCreateSuccess)
        .catch(handleFail(OPERATIONS.CREATE, i18n));
    }
  };

  private handleCreateSuccess = () => {
    const { filtersContext, onCreate } = this.props;
    const { i18n } = filtersContext;

    handleSuccess(OPERATIONS.CREATE, i18n)();

    if (onCreate) {
      onCreate();
    }
  };

  private renderForm = () => {
    const fields = this.getFields();
    const error = this.getError();
    const isPending = this.isPending();

    return (
      <UForm forCreating={false} fields={fields} error={error} isLoading={isPending} onSubmit={this.handleSubmit} />
    );
  };

  private renderContent = () => {
    return this.renderForm();
  };

  render() {
    return <div>{this.renderContent()}</div>;
  }
}

const Composer: React.FunctionComponent<IExternalProps> = props => {
  const { updateHOC, updateBatchHOC, createHOC, createBatchHOC } = props;

  // Required HOCs
  const HOCs = [FiltersContextHOC({}), updateHOC(getMutateProps('update')), createHOC(getMutateProps('create'))];

  // Optional batch updating HOC
  if (updateBatchHOC) {
    HOCs.push(updateBatchHOC(getMutateProps('updateBatch')));
  }

  // Optional batch creating HOC
  if (createBatchHOC) {
    HOCs.push(createBatchHOC(getMutateProps('createBatch')));
  }

  // Compose result
  const Result = compose<IProps, IExternalProps>(...HOCs)(DataFieldsForm);

  // And render it
  return <Result {...props} />;
};

export default Composer;
