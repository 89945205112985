import React from 'react';

import './PaymentTransactionsTable.scss';
import Rcm, { RcmProps } from '../Rcm/Rcm';
import { UTable } from '../../universal/index';
import { ALL_PAYMENT_TRANSACTIONS_QUERY } from '../../graph/queries/allPaymentTransactions';
import {
  DefaultQueryInput,
  FilterInput,
  PaymentAccount,
  PaymentTransactionFragment,
  PaymentTransactionOrderType,
  PaymentTransactionSide,
  PaymentTransactionState,
} from '../../typings/graphql';
import { Descriptions } from 'antd';
import { i18n } from '../../helpers/I18n';
import { ColumnProps } from 'antd/lib/table';
import StatusTag from '../StatusTag/StatusTag';
import { renderDate } from '../../helpers/renderDate';
import InlineUser from '../InlineUser/InlineUser';
import { Link } from 'react-router-dom';
import { OPEN_IN_NEW_TAB_LINK_PROPS } from '../../config/constants';
import { getCssVariableValue } from '../../helpers/getCssVariableValue';

interface IExternalProps {
  queryInput?: DefaultQueryInput;
  accountId?: PaymentAccount['id'] | null;
  rcmProps?: RcmProps;
}

interface IProps extends IExternalProps {}

interface IState {}

class PaymentTransactionsTable extends React.PureComponent<IProps, IState> {
  private handleRowClick = (record: PaymentTransactionFragment) => {
    // Modal.info({
    //   content: this.renderModalContent(record),
    //   maskClosable: true,
    //   okText: i18n('action_ok'),
    //   okType: 'default',
    //   cancelText: i18n('action_cancel'),
    //   width: '50vw',
    // });
  };

  private getQueryInput = (): DefaultQueryInput => {
    const { accountId, queryInput } = this.props;
    const providedFilters = queryInput?.filters ?? [];

    const filters: FilterInput[] = [...providedFilters];

    if (accountId) {
      filters.push({
        field: 'accountId',
        value: JSON.stringify(accountId),
      });
    }

    return {
      ...queryInput,
      filters: filters,
    };
  };

  private renderModalContent = (record: PaymentTransactionFragment) => {
    const title = i18n('h_transaction');
    const recordKeys = Object.keys(record).filter(key => key !== '__typename') as unknown;
    const keys = recordKeys as (keyof PaymentTransactionFragment)[];
    const fields = keys.map(this.renderTransactionField(record));

    return (
      <Descriptions bordered title={title} size="small">
        {fields}
      </Descriptions>
    );
  };

  private renderTransactionField = (record: PaymentTransactionFragment) => (key: keyof PaymentTransactionFragment) => {
    const label = i18n(key);
    let value = record[key];
    const type = typeof value;

    if (type !== 'string' && type !== 'number' && type !== 'boolean') {
      value = JSON.stringify(value, null, 2);
    }

    return (
      <Descriptions.Item label={label} key={key}>
        {value}
      </Descriptions.Item>
    );
  };

  private renderDate = (date: string) => {
    return renderDate(date);
  };

  private renderUser = (userId: string, record: PaymentTransactionFragment) => {
    return <InlineUser user={record.user} userId={Number(userId)} />;
  };

  private getKeys = () => [
    'userId',
    'amount',
    'currencyId',
    'state',
    'orderType',
    'orderId',
    'comment',
    'providerId',
    'createdAt',
  ];

  private getTableId = () => {
    const { accountId } = this.props;
    let id = `allPaymentTransactions`;

    if (accountId) {
      id = `${id}-account-${accountId}`;
    }

    return id;
  };

  private renderAmount = (amount: number, record: PaymentTransactionFragment) => {
    // функция используется для вывода ПРИХОДА или РАСХОДА
    const color =
      record.side === PaymentTransactionSide.CHARGE
        ? getCssVariableValue('--color-danger')
        : getCssVariableValue('--color-success');
    return <div style={{ color: color }}>{amount}</div>;
  };

  private renderOrderId = (orderId: number, record: PaymentTransactionFragment) => {
    if (!record.providerId) {
      return orderId;
    }
    let link =
      record.orderType === PaymentTransactionOrderType.TICKET
        ? `/event-orders/${orderId}`
        : `/commerce/shops/${record.providerId}/orders/${orderId}`;
    return (
      <Link to={link} style={{ cursor: 'pointer' }} {...OPEN_IN_NEW_TAB_LINK_PROPS}>
        <span style={{ color: getCssVariableValue('--color-primary') }}>{orderId}</span>
      </Link>
    );
  };

  private renderCurryncy = (currencyId: number, record: PaymentTransactionFragment) => {
    return <div>{record.currency.name}</div>;
  };

  private renderProvider = (providerId: number, record: PaymentTransactionFragment) => {
    if (!providerId) {
      return '-';
    }
    return <Link to={`/payment/providers/${providerId}`}>{record.provider?.name || '-'}</Link>;
  };

  private getCustomColumns = (): ColumnProps<PaymentTransactionFragment>[] => {
    return [
      {
        key: 'userId',
        render: this.renderUser,
      },
      {
        key: 'currencyId',
        render: this.renderCurryncy,
      },
      {
        key: 'providerId',
        render: this.renderProvider,
      },
      {
        key: 'state',
        render: this.renderEnum('paymentTransactionState'),
      },
      {
        key: 'orderType',
        render: this.renderEnum('paymentTransactionOrderType'),
      },
      {
        key: 'createdAt',
        render: this.renderDate,
      },
      {
        key: 'orderId',
        render: this.renderOrderId,
      },
      {
        key: 'amount',
        title: i18n('transaction'),
        render: this.renderAmount,
      },
    ];
  };

  private renderEnum = (enumName: string | any) => (
    value: PaymentTransactionFragment['state'] | PaymentTransactionFragment['orderType']
  ) => {
    if (!value) {
      return null;
    }

    return <StatusTag enumName={enumName} value={value} />;
  };

  private getRowClassname = (record: PaymentTransactionFragment) => {
    if (record.state === PaymentTransactionState.CANCELLED) {
      return 'payment_row_cancelled';
    }
    return '';
  };

  private renderEssence = () => {
    const query = ALL_PAYMENT_TRANSACTIONS_QUERY;
    const queryInput = this.getQueryInput();
    const keys = this.getKeys();
    const tableId = this.getTableId();
    const customColumns = this.getCustomColumns();
    const title = i18n('h_payment_transactions');
    const bannedKeys = ['side'];
    return (
      <UTable
        tableProps={{
          rowClassName: this.getRowClassname,
        }}
        id={tableId}
        keys={keys}
        title={title}
        queryInput={queryInput}
        query={query}
        onRowClick={this.handleRowClick}
        customColumns={customColumns}
        bannedKeys={bannedKeys}
      />
    );
  };

  private renderRcm = () => {
    const { rcmProps } = this.props;

    return <Rcm {...rcmProps} renderEssence={this.renderEssence} />;
  };

  render() {
    return this.renderRcm();
  }
}

export default PaymentTransactionsTable;
