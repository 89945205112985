import { gql } from 'apollo-boost';

export const LOCALE_FRAGMENT = gql`
  fragment locale on Locale {
    id
    iso
    title
    createdAt
    updatedAt
  }
`;
