import { gql } from 'apollo-boost';
import { TOURNAMENT_TEAM_FRAGMENT } from './tournamentTeam';

export const TOURNAMENT_SEASON_FULL_FRAGMENT = gql`
  fragment tournamentSeasonFull on TournamentSeason {
    id
    title
    description
    tournamentId
    startDate
    finishDate
    state
    sort
    teams {
      ...tournamentTeam
    }
    createdAt
    updatedAt
  }
  ${TOURNAMENT_TEAM_FRAGMENT}
`;

export const TOURNAMENT_SEASON_TITLE_FRAGMENT = gql`
  fragment tournamentSeasonTitle on TournamentSeason {
    id
    title
  }
`;

export const TOURNAMENT_SEASON_FRAGMENT = gql`
  fragment tournamentSeason on TournamentSeason {
    id
    title
    description
    tournamentId
    startDate
    finishDate
    state
    sort
    createdAt
    updatedAt
  }
`;
