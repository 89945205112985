import * as React from 'react';
import { Icon, Menu } from 'antd';
import { NavLink } from 'react-router-dom';

import './MainMenu.scss';
import { i18n } from '../../helpers/I18n';
import { NavigationFragment } from '../../typings/graphql';

const SubMenu = Menu.SubMenu;
interface Props {
  isCollapsed?: boolean;
  items: NavigationFragment[];
  toggleMenu?: () => void;
}

interface State {
  collapsed: boolean;
}

class MainMenu extends React.Component<Props, State> {
  public readonly state: State = {
    collapsed: true,
  };

  private getMenuClassName = () => {
    const { isCollapsed } = this.props;

    let className = 'menu';

    if (isCollapsed) {
      className += ' menu--collapsed';
    } else {
      className += ' menu--opened';
    }

    return className;
  };

  private renderMenuItems = (items: NavigationFragment[] = [], toggleMenu: Props['toggleMenu']) =>
    items.map(item => {
      // @ts-ignore
      const { icon, children, id, name, url, external } = item;
      if (children) {
        return (
          <SubMenu
            popupClassName="menu_popup"
            key={id}
            title={
              <div className="menu_item">
                <Icon className="menu_icon" type={icon || 'minus'} />
                <span className="menu_name">{i18n(name)}</span>
              </div>
            }
          >
            {this.renderMenuItems(children as NavigationFragment[], toggleMenu)}
          </SubMenu>
        );
      }
      return (
        <Menu.Item key={id} onClick={() => (toggleMenu ? toggleMenu() : null)}>
          {external ? (
            <a
              href={url}
              target={'_blank'}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Icon className="menu_icon" type={icon} />
              <span className="menu_name">{i18n(name)}</span>
            </a>
          ) : (
            <NavLink to={url} className="menu_item" key={id}>
              <Icon className="menu_icon" type={icon} />
              <span className="menu_name">{i18n(name)}</span>
            </NavLink>
          )}
        </Menu.Item>
      );
    });

  public render() {
    const { toggleMenu, items } = this.props;
    const className = this.getMenuClassName();

    return (
      <Menu mode="inline" className={className}>
        {this.renderMenuItems(items, toggleMenu)}
      </Menu>
    );
  }
}

export default MainMenu;
