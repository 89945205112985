import { gql } from 'apollo-boost';
import { TOURNAMENT_MATCH_PROTOCOL_FRAGMENT } from '../fragments/TournamentMatchProtocol';

export const TOURNAMENT_MATCH_PROTOCOL_QUERY = gql`
  query tournamentMatchProtocol($id: Int!) {
    tournamentMatchProtocol(id: $id) {
      ...tournamentMatchProtocol
    }
  }
  ${TOURNAMENT_MATCH_PROTOCOL_FRAGMENT}
`;
