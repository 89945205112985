import { gql } from 'apollo-boost';
import { ARTICLE_CATEGORY_FRAGMENT } from '../fragments/articleCategory';

export const ARTICLE_CATEGORY_QUERY = gql`
  query articleCategory($id: Int!) {
    articleCategory(id: $id) {
      ...articleCategory
    }
  }
  ${ARTICLE_CATEGORY_FRAGMENT}
`;
