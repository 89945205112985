import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';
import './Helmet.scss';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { getFromLocalStorage } from '../../helpers/getFromLocalStorage';
import { ClientFragment } from '../../typings/graphql';

interface IExternalProps {}

interface IProps extends IExternalProps, RouteComponentProps {}

interface IState {}

const DEFAULT_TITLE = 'Sport Star';

class Helmet extends React.PureComponent<IProps, IState> {
  private getClient = (): ClientFragment | null => getFromLocalStorage('client');

  private isLoginPage = () => {
    const { pathname } = this.props.location;
    return pathname === '/login';
  };

  private getClientTitle = () => {
    const fallback = 'SportStar';

    const isLoginPage = this.isLoginPage();
    if (isLoginPage) {
      return fallback;
    }

    const client = this.getClient();
    if (!client) {
      return fallback;
    }

    if (client.title) {
      return client.title;
    }

    return fallback;
  };

  private getFavicon = () => {
    const fallback = '/favicon.ico';

    const isLoginPage = this.isLoginPage();
    if (isLoginPage) {
      return fallback;
    }

    const client = this.getClient();
    if (!client) {
      return fallback;
    }

    if (client.logo) {
      return client.logo;
    }

    return fallback;
  };

  private getTitleTemplate = (): string | undefined => {
    const clientTitle = this.getClientTitle();
    if (!clientTitle) {
      return undefined;
    }

    return `%s :: ${clientTitle}`;
  };

  private renderHelmet = () => {
    const favicon = this.getFavicon();
    const titleTemplate = this.getTitleTemplate();

    return (
      <ReactHelmet defaultTitle={DEFAULT_TITLE} titleTemplate={titleTemplate}>
        <link rel="icon" href={favicon} data-react-helmet="true" />
      </ReactHelmet>
    );
  };

  render() {
    return this.renderHelmet();
  }
}

export default withRouter(Helmet);
