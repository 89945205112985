import { gql } from 'apollo-boost';
import { SYSTEM_PRINTER_CONNECTION_FRAGMENT } from '../fragments/systemPrinterConnection';

export const ALL_SYSTEM_PRINTERS_QUERY = gql`
  query allSystemPrinters($query: DefaultQueryInput) {
    allSystemPrinters(query: $query) {
      ...systemPrinterConnection
    }
  }
  ${SYSTEM_PRINTER_CONNECTION_FRAGMENT}
`;
