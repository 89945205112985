import React, { useCallback } from 'react';
import './CreateDisputeAction.scss';
import useDisputeSimulators from '../../hooks/useDisputeSimulators';
import { SettingsAction } from '../../universal';
import { i18n } from '../../helpers/I18n';
import DisputeForm from '../DisputeForm/DisputeForm';
import { DisputeFragment } from '../../typings/graphql';
import { useHistory } from 'react-router-dom';
import { ModalProps } from 'antd/lib/modal';

interface IExternalProps {}

interface IProps extends IExternalProps {}

const CreateDisputeAction: React.FC<IProps> = props => {
  const history = useHistory();
  const simulators = useDisputeSimulators();

  const onCreate = useCallback(
    (created: DisputeFragment) => {
      const path = `/tournaments/disputes/${created.id}`;
      history.push(path);
    },
    [history]
  );

  const renderContent = useCallback(() => {
    return <DisputeForm rcmProps={{ isWrapped: false }} simulators={simulators} onCreate={onCreate} />;
  }, [onCreate, simulators]);

  const modalProps: ModalProps = {
    title: i18n('h_create_dispute'),
  };

  return (
    <SettingsAction modalProps={modalProps} renderContent={renderContent}>
      {i18n('action_create')}
    </SettingsAction>
  );
};

export default React.memo(CreateDisputeAction);
