import { gql } from 'apollo-boost';
import { TOURNAMENT_TEAM_MEMBER_FRAGMENT } from './tournamentTeamMember';

export const TOURNAMENT_PLAYER_STAT_FRAGMENT = gql`
  fragment tournamentPlayerStat on TournamentPlayerStat {
    id
    playerId
    seasonId
    games
    goals
    penaltyKicks
    passes
    yellowCards
    redCards
    missedGoals
    shutouts
    bombardierValue
    assistantValue
    goalsAndPassesValue
    goalkeeperValue
    warningValue
    createdAt
    updatedAt
  }
`;

export const TOURNAMENT_PLAYER_STAT_FRAGMENT_FULL = gql`
  fragment tournamentPlayerStatFull on TournamentPlayerStat {
    id
    playerId
    seasonId
    games
    goals
    penaltyKicks
    passes
    yellowCards
    redCards
    missedGoals
    shutouts
    bombardierValue
    assistantValue
    goalsAndPassesValue
    goalkeeperValue
    warningValue
    player {
      ...tournamentTeamMember
    }
    createdAt
    updatedAt
  }
  ${TOURNAMENT_TEAM_MEMBER_FRAGMENT}
`;
