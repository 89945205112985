import React from 'react';
import { compose } from 'recompose';
import Rcm from '../Rcm/Rcm';
import {
  withLoyaltyPartnerEntityByProviderCode,
  LoyaltyPartnerEntityByProviderCodeProps,
  PaymentAccountFragment,
} from '../../typings/graphql';
import { Table, Modal } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { TableProps } from 'antd/es/table';
import { i18n } from '../../helpers/I18n';
import { parseJSON } from '../../universal/helpers/parseJSON';

interface IExternalProps {
  paymentAccount: PaymentAccountFragment;
}

interface IProps extends IExternalProps {
  loyaltyPartner: LoyaltyPartnerEntityByProviderCodeProps['data'];
}

interface IState {
  record: string | null;
  showModal: boolean;
}

class LoyaltyPartner extends React.PureComponent<IProps, IState> {
  state: IState = {
    record: null,
    showModal: false,
  };

  private getLoyaltyPartner = () => {
    const { loyaltyPartner } = this.props;

    if (!loyaltyPartner || !loyaltyPartner.loyaltyPartnerEntityByProviderCode) {
      return null;
    }
    return loyaltyPartner.loyaltyPartnerEntityByProviderCode || null;
  };

  private renderEssence = () => {
    const loyaltyPartner = this.getLoyaltyPartner();
    if (!loyaltyPartner) {
      return null;
    }

    return (
      <div>
        {this.renderTable()}
        {this.renderModal()}
      </div>
    );
  };

  private getKeys = (): string[] => {
    const edges = this.getDataSource();
    const [firstElem] = edges;
    if (!firstElem) {
      return [];
    }

    const parsed = parseJSON<any>(firstElem);
    if (!parsed) {
      return [];
    }

    return Object.keys(parsed);
  };

  private getDataSource = () => {
    const loyaltyPartner = this.getLoyaltyPartner();
    if (!loyaltyPartner) {
      return [];
    }
    return [loyaltyPartner];
  };

  private renderApplicationCell = (key: string) => (_: any, record: string) => JSON.parse(record)[key];

  private getCustomColumns = (): ColumnProps<any>[] => {
    const keys = this.getKeys();
    return keys.map(key => ({
      key: key,
      title: key,
      render: this.renderApplicationCell(key),
    }));
  };

  private handleClickRow = (record: string) => () => {
    this.setState({ record, showModal: true });
  };

  private onRow: TableProps<string>['onRow'] = record => {
    return {
      onClick: this.handleClickRow(record),
    };
  };

  private getTableRowKey: TableProps<any>['rowKey'] = (_, index) => String(index);

  private getTableProps = (): TableProps<any> => {
    return {
      onRow: this.onRow,
      rowKey: this.getTableRowKey,
    };
  };

  private renderTable = () => {
    const tableProps = this.getTableProps();
    const customColumns = this.getCustomColumns();
    const dataSource = this.getDataSource();
    return (
      <Table
        scroll={{ x: true }}
        dataSource={dataSource}
        key="loyaltyPartner"
        columns={customColumns}
        {...tableProps}
      />
    );
  };

  private isLoading = () => {
    const { loyaltyPartner } = this.props;
    return loyaltyPartner.loading;
  };

  private getError = () => {
    const { loyaltyPartner } = this.props;
    return loyaltyPartner.error;
  };

  private closeModal = () => {
    this.setState({
      showModal: false,
      record: null,
    });
  };

  private renderModalContent = () => {
    const { record } = this.state;
    if (!record) {
      return null;
    }

    return (
      <div>
        <pre>{JSON.stringify(JSON.parse(record), null, 2)}</pre>
      </div>
    );
  };

  private renderModal = () => {
    const { showModal } = this.state;
    const footer = null;
    const title = i18n('h_loyalty_application');

    return (
      <Modal title={title} visible={showModal} onCancel={this.closeModal} footer={footer}>
        {this.renderModalContent()}
      </Modal>
    );
  };

  private renderRcm = () => {
    const isLoading = this.isLoading();
    const isError = this.getError();
    const isEmpty = !Boolean(this.getLoyaltyPartner());
    return <Rcm isLoading={isLoading} error={isError} isEmpty={isEmpty} renderEssence={this.renderEssence} />;
  };

  render() {
    return this.renderRcm();
  }
}

export default compose<IProps, IExternalProps>(
  withLoyaltyPartnerEntityByProviderCode<IProps>({
    name: 'loyaltyPartner',
    options: props => ({
      variables: {
        providerId: props.paymentAccount.providerId,
        providerCode: props.paymentAccount.providerCode,
      },
    }),
  })
)(LoyaltyPartner);
