import { TournamentMatchFragment } from '../typings/graphql';
import { i18n } from './I18n';

export const getTournamentMatchName = (match: TournamentMatchFragment): string => {
  const fallback = i18n('match');

  if (!match || !match.team1 || !match.team2) {
    return fallback;
  }

  const { team1, team2 } = match;
  if (!team1.name || !team2.name) {
    return fallback;
  }

  return `${team1.name} - ${team2.name}`;
};
