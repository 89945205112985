import { gql } from 'apollo-boost';
import { PAYMENT_ACCOUNT_FRAGMENT } from '../fragments/paymentAccount';

export const PAYMENT_ACCOUNT_QUERY = gql`
  query paymentAccount($id: Int!) {
    paymentAccount(id: $id) {
      ...paymentAccount
    }
  }
  ${PAYMENT_ACCOUNT_FRAGMENT}
`;
