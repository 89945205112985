import { gql } from 'apollo-boost';
import { TOURNAMENT_TEAM_FRAGMENT, TOURNAMENT_TEAM_SMALL_FRAGMENT } from './tournamentTeam';

export const TOURNAMENT_MATCH_WIDGET_FRAGMENT = gql`
  fragment tournamentMatchWidget on TournamentMatch {
    id
    team1Id
    team2Id
    state
    startDate
    startTime
    finishedAt
    result
    team1 {
      ...tournamentTeamSmall
    }
    team2 {
      ...tournamentTeamSmall
    }
  }
  ${TOURNAMENT_TEAM_SMALL_FRAGMENT}
`;

export const TOURNAMENT_MATCH_FRAGMENT = gql`
  fragment tournamentMatch on TournamentMatch {
    id
    team1Id
    team2Id
    team1 {
      ...tournamentTeamSmall
    }
    team2 {
      ...tournamentTeamSmall
    }
    team1IdGoals
    team2IdGoals
    seasonId
    tourId
    state
    videoStreams
    stadiumName
    stadiumAddress
    startDate
    startTime
    finishDate
    startedAt
    finishedAt
    createdAt
    updatedAt
  }
  ${TOURNAMENT_TEAM_SMALL_FRAGMENT}
`;

export const TOURNAMENT_MATCH_DETAILS_FRAGMENT = gql`
  fragment tournamentMatchDetails on TournamentMatch {
    ...tournamentMatch
    team1 {
      ...tournamentTeam
    }
    team2 {
      ...tournamentTeam
    }
  }
  ${TOURNAMENT_MATCH_FRAGMENT}
  ${TOURNAMENT_TEAM_FRAGMENT}
`;
