import React from 'react';
import { LocaleEnum, AllSystemTranslationsComponent, AllSystemTranslationsQueryVariables } from '../../typings/graphql';
import {
  ISystemTranslationsContextValue,
  SystemTranslationsContext,
} from '../TranslationContextHOC/TranslationContextHOC';
import Loader from '../Loader/Loader';
import { i18n, init, onReload, populate, reload } from '../../helpers/I18n';
import Page from '../Page/Page';
import 'moment/locale/ru';
import { changeMomentLocale } from '../../universal/helpers/getMoment';

interface IExternalProps {}

interface IProps extends IExternalProps {}

interface IState {
  locale: LocaleEnum;
}

class TranslationProvider extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const locale = init();
    this.state = { locale };
    changeMomentLocale(locale);
  }

  componentDidMount = () => {
    onReload(this.handleChangeLocale);
  };

  private handleChangeLocale = (locale: LocaleEnum) => {
    changeMomentLocale(locale);
    this.setState({ locale });
  };

  private getContextValue = (): ISystemTranslationsContextValue => {
    return {
      locale: this.state.locale,
      changeLocale: reload,
    };
  };

  private getVariables = (): AllSystemTranslationsQueryVariables => {
    return {
      query: {
        limit: 10000,
        filters: [
          {
            field: 'locale',
            value: JSON.stringify(this.state.locale),
          },
        ],
      },
    };
  };

  render() {
    const { children } = this.props;
    const variables = this.getVariables();

    return (
      <SystemTranslationsContext.Provider value={this.getContextValue()}>
        <AllSystemTranslationsComponent fetchPolicy="network-only" variables={variables}>
          {({ loading, data }) => {
            if (loading) {
              return (
                <Page>
                  <Page.Content>
                    <Loader text={i18n('loading_system_translations')} isFull />
                  </Page.Content>
                </Page>
              );
            }

            // DIrty hack
            if (
              data &&
              data.allSystemTranslations &&
              data.allSystemTranslations.edges &&
              data.allSystemTranslations.edges.length !== 0
            ) {
              populate(this.state.locale, data.allSystemTranslations.edges);
            }

            return <>{children}</>;
          }}
        </AllSystemTranslationsComponent>
      </SystemTranslationsContext.Provider>
    );
  }
}

export default TranslationProvider;
