import { gql } from 'apollo-boost';
import { SYSTEM_TRANSLATION_CONNECTION_FRAGMENT } from '../fragments/systemTranslationConnection';

export const ALL_SYSTEM_TRANSLATIONS_QUERY = gql`
  query allSystemTranslations($query: DefaultQueryInput) {
    allSystemTranslations(query: $query) {
      ...systemTranslationConnection
    }
  }
  ${SYSTEM_TRANSLATION_CONNECTION_FRAGMENT}
`;
