import { gql } from 'apollo-boost';
import { USER_CONNECTION_FRAGMENT } from '../fragments/userConnectionResponse';

export const ALL_USERS_QUERY = gql`
  query allUsers($query: DefaultQueryInput) {
    allUsers(query: $query) {
      ...userConnection
    }
  }
  ${USER_CONNECTION_FRAGMENT}
`;
