import * as React from 'react';
import { Tag } from 'antd';
import {
  ArticleAuthorRole,
  ArticleBlockType,
  ArticleLinkEntityType,
  ArticleLogState,
  ArticleState,
  CatalogueCategoryState,
  CatalogueItemState,
  CatalogueState,
  ClientType,
  CommerceOrderFieldDataType,
  CommerceOrderItemState,
  CommerceOrderPaymentState,
  CommerceOrderState,
  CommerceShopState,
  DeviceOsEnum,
  EntityDataType,
  EntityType,
  EventOrderPaymentState,
  EventOrderState,
  EventState,
  ExportFormat,
  FilterDataType,
  FilterType,
  LastPurchaseEnum,
  LoyaltyApplicationState,
  LoyaltyPartnerState,
  LoyaltyPromoCodeType,
  LoyaltyPromoState,
  MarketPlaceEnum,
  MessengerMailingChannel,
  MessengerMailingState,
  NotificationTransportEnum,
  PaymentAccountState,
  PaymentBankCardState,
  PaymentBasis,
  PaymentGatewayState,
  PaymentMethod,
  PaymentProviderState,
  PaymentReceiptType,
  PaymentTransactionLogOperation,
  PaymentTransactionOrderType,
  PaymentTransactionSide,
  PaymentTransactionState,
  SocialProfileTypeEnum,
  SortingDirection,
  // SubscriptionState,
  // SubscriptionType,
  SystemTaskState,
  SystemUserState,
  SystemWebHookEntity,
  SystemWebHookOperation,
  TaxationSystemType,
  TaxRateType,
  TournamentDisqualificationState,
  TournamentMatchEventEvent,
  TournamentMatchLineUpFinalRole,
  TournamentMatchLineUpFinalState,
  TournamentMatchLineUpInitialRole,
  TournamentMatchLineUpInitialState,
  TournamentMatchLineUpType,
  TournamentMatchProtocolEvent,
  TournamentMatchState,
  TournamentSeasonState,
  TournamentState,
  TournamentTeamForm,
  TournamentTeamMemberRole,
  TournamentTeamMemberType,
  TournamentTourState,
  TournamentTranslationEntity,
  LocaleEnum,
  UserDataFieldDataType,
  UserDocumentState,
  UserDocumentType,
  UserFilterDataType,
  UserFilterType,
  UserGenderEnum,
  UserIncomeEnum,
  UserStateEnum,
  UserSubscriptionState,
} from '../../typings/graphql';
import { getCssVariableValue } from '../../helpers/getCssVariableValue';
import { i18n } from '../../helpers/I18n';
import './StatusTag.scss';
import { RANDOM_COLORS } from '../../config/constants';

export enum TagThemes {
  PRIMARY = 'PRIMARY',
  DANGER = 'DANGER',
  SUCCESS = 'SUCCESS',
  DISABLED = 'DISABLED',
  WARNING = 'WARNING',
}

const getDangerColor = () => getCssVariableValue('--color-danger');
const getDisabledColor = () => getCssVariableValue('--color-contrast-3');
const getSuccessColor = () => getCssVariableValue('--color-success');
const getPrimaryColor = () => getCssVariableValue('--color-primary');
const getWarningColor = () => getCssVariableValue('--color-warning');
const getRandomColor = (index: number) => () => RANDOM_COLORS[index] || null;

const enums = {
  eventState: {
    [EventState.ACTIVE]: getSuccessColor,
    [EventState.DELETED]: getDangerColor,
    [EventState.INACTIVE]: getDisabledColor,
  },

  tagThemes: {
    [TagThemes.DANGER]: getDangerColor,
    [TagThemes.DISABLED]: getDisabledColor,
    [TagThemes.PRIMARY]: getPrimaryColor,
    [TagThemes.SUCCESS]: getSuccessColor,
    [TagThemes.WARNING]: getWarningColor,
  },
  commerceOrderState: {
    [CommerceOrderState.NEW]: getDisabledColor,
    [CommerceOrderState.PROCESSING]: getPrimaryColor,
    [CommerceOrderState.ON_DELIVERY]: getPrimaryColor,
    [CommerceOrderState.DELIVERED]: getPrimaryColor,
    [CommerceOrderState.DONE]: getSuccessColor,
    [CommerceOrderState.CANCELLED]: getDangerColor,
  },
  commerceOrderPaymentState: {
    [CommerceOrderPaymentState.PAID]: getSuccessColor,
    [CommerceOrderPaymentState.UNPAID]: getDisabledColor,
  },
  tournamentSeasonState: {
    [TournamentSeasonState.CURRENT]: getSuccessColor,
    [TournamentSeasonState.FINISHED]: getDisabledColor,
    [TournamentSeasonState.FUTURE]: getPrimaryColor,
  },
  tournamentState: {
    [TournamentState.CURRENT]: getSuccessColor,
    [TournamentState.FINISHED]: getDisabledColor,
    [TournamentState.FUTURE]: getPrimaryColor,
  },
  catalogueItemState: {
    [CatalogueItemState.DELETED]: getDangerColor,
    [CatalogueItemState.ACTIVE]: getSuccessColor,
    [CatalogueItemState.INACTIVE]: getDisabledColor,
  },
  paymentAccountState: {
    [PaymentAccountState.INACTIVE]: getDisabledColor,
    [PaymentAccountState.ACTIVE]: getPrimaryColor,
    [PaymentAccountState.DELETED]: getDangerColor,
  },
  loyaltyPartnerState: {
    [LoyaltyPartnerState.INACTIVE]: getDisabledColor,
    [LoyaltyPartnerState.ACTIVE]: getSuccessColor,
    [LoyaltyPartnerState.DELETED]: getDangerColor,
  },
  articleAuthorRole: {
    [ArticleAuthorRole.AUTHOR]: getRandomColor(0),
    [ArticleAuthorRole.EDITOR]: getRandomColor(1),
    [ArticleAuthorRole.PHOTO_EDITOR]: getRandomColor(2),
    [ArticleAuthorRole.PHOTOGRAPHER]: getRandomColor(3),
    [ArticleAuthorRole.JOURNALIST]: getRandomColor(4),
  },
  messengerMailingChannel: {
    [MessengerMailingChannel.SMS]: getRandomColor(0),
    [MessengerMailingChannel.EMAIL]: getRandomColor(1),
    [MessengerMailingChannel.PUSH]: getRandomColor(2), // TODO: Fill this map automatic
    [MessengerMailingChannel.SERVICE_EMAIL]: getRandomColor(3),
    [MessengerMailingChannel.TELEGRAM]: getRandomColor(3),
    [MessengerMailingChannel.SLACK]: getRandomColor(3),
  },
  messengerMailingState: {
    [MessengerMailingState.ACTIVE]: getSuccessColor,
    [MessengerMailingState.CANCELLED]: getDangerColor,
    [MessengerMailingState.FINISHED]: getPrimaryColor,
    [MessengerMailingState.INACTIVE]: getDisabledColor,
    [MessengerMailingState.PAUSED]: getDisabledColor,
    [MessengerMailingState.QUEUE_PROCESSING]: getSuccessColor,
    [MessengerMailingState.START]: getSuccessColor,
  },
  articleBlockType: {
    [ArticleBlockType.VIDEO]: null,
    [ArticleBlockType.CODE]: null,
    [ArticleBlockType.EMBED]: null,
    [ArticleBlockType.ENTITY]: null,
    [ArticleBlockType.HTML]: null,
    [ArticleBlockType.INCUT]: null,
    [ArticleBlockType.PHOTO_GALLERY]: null,
    [ArticleBlockType.SUB_TITLE]: null,
    [ArticleBlockType.TITLE]: null,
  },
  articleLinkEntityType: {
    [ArticleLinkEntityType.TOUR]: null,
    [ArticleLinkEntityType.EVENT]: null,
    [ArticleLinkEntityType.MATCH]: null,
    [ArticleLinkEntityType.PLAYER]: null,
    [ArticleLinkEntityType.TEAM]: null,
  },
  articleLogState: {
    [ArticleLogState.CHECKING]: null,
    [ArticleLogState.DELETED]: getDangerColor,
    [ArticleLogState.DRAFT]: null,
    [ArticleLogState.FACT_CHECKING]: null,
    [ArticleLogState.PUBLISHED]: null,
  },
  articleState: {
    [ArticleState.CHECKING]: getRandomColor(0),
    [ArticleState.DELETED]: getDangerColor,
    [ArticleState.DRAFT]: getDisabledColor,
    [ArticleState.FACT_CHECKING]: getRandomColor(1),
    [ArticleState.PUBLISHED]: getSuccessColor,
  },
  catalogueCategoryState: {
    [CatalogueCategoryState.ACTIVE]: getSuccessColor,
    [CatalogueCategoryState.DELETED]: getDangerColor,
    [CatalogueCategoryState.INACTIVE]: getDisabledColor,
  },
  catalogueState: {
    [CatalogueState.ACTIVE]: getSuccessColor,
    [CatalogueState.DELETED]: getDangerColor,
    [CatalogueState.INACTIVE]: getDisabledColor,
  },
  clientType: {
    [ClientType.INDIVIDUAL]: null,
    [ClientType.LEGAL_ENTITY]: null,
  },
  commerceOrderFieldDataType: {
    [CommerceOrderFieldDataType.ARRAY]: null,
    [CommerceOrderFieldDataType.BOOLEAN]: null,
    [CommerceOrderFieldDataType.ENUM]: null,
    [CommerceOrderFieldDataType.FILES]: null,
    [CommerceOrderFieldDataType.NUMBER]: null,
    [CommerceOrderFieldDataType.STRING]: null,
  },
  commerceOrderItemState: {
    [CommerceOrderItemState.ACTIVE]: getSuccessColor,
    [CommerceOrderItemState.DELETED]: getDangerColor,
    [CommerceOrderItemState.INACTIVE]: getDisabledColor,
  },
  commerceShopState: {
    [CommerceShopState.ACTIVE]: getSuccessColor,
    [CommerceShopState.DELETED]: getDangerColor,
    [CommerceShopState.INACTIVE]: getDisabledColor,
  },
  deviceOsEnum: {
    [DeviceOsEnum.ANDROID]: null,
    [DeviceOsEnum.IOS]: null,
    [DeviceOsEnum.OTHER]: null,
  },
  entityDataType: {
    [EntityDataType.ARRAY]: null,
    [EntityDataType.BOOLEAN]: null,
    [EntityDataType.ENUM]: null,
    [EntityDataType.FILES]: null,
    [EntityDataType.NUMBER]: null,
    [EntityDataType.STRING]: null,
  },
  entityType: {
    [EntityType.EVENT]: null,
    [EntityType.MATCH]: null,
    [EntityType.PLAYER]: null,
    [EntityType.TEAM]: null,
    [EntityType.TOUR]: null,
  },
  eventOrderPaymentState: {
    [EventOrderPaymentState.PAID]: getSuccessColor,
    [EventOrderPaymentState.UNPAID]: getDangerColor,
  },
  eventOrderState: {
    [EventOrderState.CANCELLED]: getDangerColor,
    [EventOrderState.DELIVERED]: null,
    [EventOrderState.DONE]: getSuccessColor,
    [EventOrderState.NEW]: null,
    [EventOrderState.ON_DELIVERY]: null,
    [EventOrderState.PROCESSING]: null,
  },
  exportFormat: {
    [ExportFormat.CSV]: null,
    [ExportFormat.JSON]: null,
    [ExportFormat.XLS]: null,
  },
  filterDataType: {
    [FilterDataType.ARRAY]: null,
    [FilterDataType.BOOLEAN]: null,
    [FilterDataType.ENUM]: null,
    [FilterDataType.NUMBER]: null,
    [FilterDataType.STRING]: null,
    [FilterDataType.TIME]: null,
    [FilterDataType.DATE]: null,
  },
  filterType: {
    [FilterType.CHECKBOX]: null,
    [FilterType.INPUT]: null,
    [FilterType.RANGE]: null,
    [FilterType.SELECT]: null,
    [FilterType.SELECT_MULTIPLE]: null,
  },
  lastPurchaseEnum: {
    [LastPurchaseEnum.LESS_THAN_HALF_YEAR]: null,
    [LastPurchaseEnum.LESS_THAN_MONTH]: null,
    [LastPurchaseEnum.MORE_THAN_HALF_YEAR]: null,
    [LastPurchaseEnum.MORE_THAN_MONTH]: null,
  },
  localeEnum: {
    [LocaleEnum.EN]: null,
    [LocaleEnum.RU]: null,
  },
  loyaltyApplicationState: {
    [LoyaltyApplicationState.ACCEPTED]: null,
    [LoyaltyApplicationState.DECLINED]: null,
    [LoyaltyApplicationState.NEW]: null,
    [LoyaltyApplicationState.PROCESSING]: null,
  },
  loyaltyPromoCodeType: {
    [LoyaltyPromoCodeType.SINGLE]: null,
    [LoyaltyPromoCodeType.UNIQUE]: null,
  },
  loyaltyPromoState: {
    [LoyaltyPromoState.ACTIVE]: getSuccessColor,
    [LoyaltyPromoState.DRAFT]: null,
    [LoyaltyPromoState.FINISHED]: null,
  },
  marketPlaceEnum: {
    [MarketPlaceEnum.SHOP]: null,
    [MarketPlaceEnum.TICKET]: null,
  },
  notificationTransportEnum: {
    [NotificationTransportEnum.EMAIL]: null,
    [NotificationTransportEnum.PUSH]: null,
    [NotificationTransportEnum.SMS]: null,
  },
  paymentBankCardState: {
    [PaymentBankCardState.ACTIVE]: getSuccessColor,
    [PaymentBankCardState.DELETED]: getDangerColor,
    [PaymentBankCardState.INACTIVE]: getDisabledColor,
  },
  paymentBasis: {
    [PaymentBasis.ANOTHER]: null,
    [PaymentBasis.COMMODITY]: null,
    [PaymentBasis.COMPOSITE]: null,
    [PaymentBasis.JOB]: null,
    [PaymentBasis.PAYMENT]: null,
    [PaymentBasis.SERVICE]: null,
    [PaymentBasis.UNKNOWN]: null,
  },
  paymentGatewayState: {
    [PaymentGatewayState.ACTIVE]: getSuccessColor,
    [PaymentGatewayState.DELETED]: getDangerColor,
    [PaymentGatewayState.INACTIVE]: getDisabledColor,
  },
  paymentMethod: {
    [PaymentMethod.ADVANCE]: null,
    [PaymentMethod.CREDIT]: null,
    [PaymentMethod.CREDIT_PAYMENT]: null,
    [PaymentMethod.FULL]: null,
    [PaymentMethod.FULL_PREPAYMENT]: null,
    [PaymentMethod.PARTIAL_PAY_AND_CREDIT]: null,
    [PaymentMethod.PARTIAL_PREPAYMENT]: null,
    [PaymentMethod.UNKNOWN]: null,
  },
  paymentProviderState: {
    [PaymentProviderState.ACTIVE]: getSuccessColor,
    [PaymentProviderState.DELETED]: getDangerColor,
    [PaymentProviderState.INACTIVE]: getDisabledColor,
  },
  paymentReceiptType: {
    [PaymentReceiptType.EXPENSE]: null,
    [PaymentReceiptType.EXPENSE_RETURN]: null,
    [PaymentReceiptType.INCOME]: null,
    [PaymentReceiptType.INCOME_RETURN]: null,
  },
  paymentTransactionLogOperation: {
    [PaymentTransactionLogOperation.AUTH]: null,
    [PaymentTransactionLogOperation.CANCEL]: null,
    [PaymentTransactionLogOperation.CHARGE]: null,
    [PaymentTransactionLogOperation.CONFIRM_AUTH]: null,
    [PaymentTransactionLogOperation.CONFIRM_HOLD]: null,
    [PaymentTransactionLogOperation.DEPOSIT]: null,
    [PaymentTransactionLogOperation.HOLD]: null,
    [PaymentTransactionLogOperation.REFUND]: null,
  },
  paymentTransactionOrderType: {
    [PaymentTransactionOrderType.ECOMMERCE]: getPrimaryColor,
    [PaymentTransactionOrderType.TICKET]: getPrimaryColor,
  },
  paymentTransactionSide: {
    [PaymentTransactionSide.CHARGE]: null,
    [PaymentTransactionSide.DEPOSIT]: null,
  },
  socialProfileTypeEnum: {
    [SocialProfileTypeEnum.FACEBOOK]: null,
    [SocialProfileTypeEnum.INSTAGRAM]: null,
    [SocialProfileTypeEnum.OK]: null,
    [SocialProfileTypeEnum.TWITTER]: null,
    [SocialProfileTypeEnum.VK]: null,
  },
  sortingDirection: {
    [SortingDirection.ASC]: null,
    [SortingDirection.DESC]: null,
  },
  // subscriptionState: {
  //   [SubscriptionState.ACTIVE]: getSuccessColor,
  //   [SubscriptionState.INACTIVE]: getDisabledColor,
  // },
  // subscriptionType: {
  //   [SubscriptionType.EMAIL]: null,
  //   [SubscriptionType.PUSH]: null,
  //   [SubscriptionType.SMS]: null,
  // },
  systemTaskState: {
    [SystemTaskState.CANCELLED]: getDangerColor,
    [SystemTaskState.DONE]: getSuccessColor,
    [SystemTaskState.PROCESSING]: null,
  },
  systemUserState: {
    [SystemUserState.ACTIVE]: getSuccessColor,
    [SystemUserState.BLOCKED]: null,
    [SystemUserState.INACTIVE]: getDisabledColor,
  },
  systemWebHookEntity: {
    [SystemWebHookEntity.EVENT]: null,
    [SystemWebHookEntity.ORDER]: null,
    [SystemWebHookEntity.USER]: null,
  },
  systemWebHookOperation: {
    [SystemWebHookOperation.CREATE]: null,
    [SystemWebHookOperation.DELETE]: null,
    [SystemWebHookOperation.IMPORT]: null,
    [SystemWebHookOperation.LOAD]: null,
    [SystemWebHookOperation.UPDATE]: null,
  },
  taxationSystemType: {
    [TaxationSystemType.ENVD]: null,
    [TaxationSystemType.ESN]: null,
    [TaxationSystemType.GENERAL]: null,
    [TaxationSystemType.PATENT]: null,
    [TaxationSystemType.SIMPLIFIED_INCOME]: null,
    [TaxationSystemType.SIMPLIFIED_INCOME_EXPENSE]: null,
  },
  taxRateType: {
    [TaxRateType.NO_VAT]: null,
    [TaxRateType.VAT_0]: null,
    [TaxRateType.VAT_10]: null,
    [TaxRateType.VAT_20]: null,
    [TaxRateType.VAT_110]: null,
    [TaxRateType.VAT_120]: null,
  },
  tournamentDisqualificationState: {
    [TournamentDisqualificationState.CURRENT]: null,
    [TournamentDisqualificationState.FINISH]: null,
  },
  tournamentMatchEventEvent: {
    [TournamentMatchEventEvent.ASSIST]: null,
    [TournamentMatchEventEvent.AUTO_GOAL]: null,
    [TournamentMatchEventEvent.CORNER_KICK]: null,
    [TournamentMatchEventEvent.FREE_KICK]: null,
    [TournamentMatchEventEvent.GOAL]: null,
    [TournamentMatchEventEvent.IN]: null,
    [TournamentMatchEventEvent.MATCH_FINISH]: null,
    [TournamentMatchEventEvent.MATCH_HALFTIME]: null,
    [TournamentMatchEventEvent.MATCH_START]: null,
    [TournamentMatchEventEvent.OUT]: null,
    [TournamentMatchEventEvent.PASS]: null,
    [TournamentMatchEventEvent.PENALTY]: null,
    [TournamentMatchEventEvent.PENALTY_NO]: null,
    [TournamentMatchEventEvent.RED_CARD]: null,
    [TournamentMatchEventEvent.TRAUMA]: null,
    [TournamentMatchEventEvent.YELLOW_CARD]: null,
    [TournamentMatchEventEvent.YELLOW_CARD2]: null,
  },
  tournamentMatchLineUpFinalRole: {
    [TournamentMatchLineUpFinalRole.DEFENDER]: null,
    [TournamentMatchLineUpFinalRole.FORWARD]: null,
    [TournamentMatchLineUpFinalRole.GOALKEEPER]: null,
    [TournamentMatchLineUpFinalRole.HALFBACK]: null,
  },
  tournamentMatchLineUpFinalState: {
    [TournamentMatchLineUpFinalState.MAIN]: null,
    [TournamentMatchLineUpFinalState.SUBSTITUTE]: null,
  },
  tournamentMatchLineUpInitialRole: {
    [TournamentMatchLineUpInitialRole.DEFENDER]: null,
    [TournamentMatchLineUpInitialRole.FORWARD]: null,
    [TournamentMatchLineUpInitialRole.GOALKEEPER]: null,
    [TournamentMatchLineUpInitialRole.HALFBACK]: null,
  },
  tournamentMatchLineUpInitialState: {
    [TournamentMatchLineUpInitialState.MAIN]: null,
    [TournamentMatchLineUpInitialState.SUBSTITUTE]: null,
  },
  tournamentMatchLineUpType: {
    [TournamentMatchLineUpType.COACH]: null,
    [TournamentMatchLineUpType.MEDIC]: null,
    [TournamentMatchLineUpType.PLAYER]: null,
    [TournamentMatchLineUpType.REFEREE]: null,
    [TournamentMatchLineUpType.REPRESENTATIVE]: null,
  },
  tournamentMatchProtocolEvent: {
    [TournamentMatchProtocolEvent.AUTO_GOAL]: null,
    [TournamentMatchProtocolEvent.CORNER_KICK]: null,
    [TournamentMatchProtocolEvent.FREE_KICK]: null,
    [TournamentMatchProtocolEvent.GOAL]: null,
    [TournamentMatchProtocolEvent.IN]: null,
    [TournamentMatchProtocolEvent.MATCH_FINISH]: null,
    [TournamentMatchProtocolEvent.MATCH_HALFTIME]: null,
    [TournamentMatchProtocolEvent.MATCH_START]: null,
    [TournamentMatchProtocolEvent.OUT]: null,
    [TournamentMatchProtocolEvent.PASS]: null,
    [TournamentMatchProtocolEvent.ASSIST]: null,
    [TournamentMatchProtocolEvent.PENALTY]: null,
    [TournamentMatchProtocolEvent.PENALTY_NO]: null,
    [TournamentMatchProtocolEvent.RED_CARD]: null,
    [TournamentMatchProtocolEvent.TRAUMA]: null,
    [TournamentMatchProtocolEvent.YELLOW_CARD]: null,
    [TournamentMatchProtocolEvent.YELLOW_CARD2]: null,
  },
  tournamentMatchState: {
    [TournamentMatchState.FINISHED]: null,
    [TournamentMatchState.HALF_TIME]: null,
    [TournamentMatchState.OVER_TIME]: null,
    [TournamentMatchState.PENDING]: null,
    [TournamentMatchState.PROGRESS]: null,
  },
  tournamentTeamForm: {
    [TournamentTeamForm.DRAW]: null,
    [TournamentTeamForm.LOSE]: null,
    [TournamentTeamForm.WIN]: null,
  },
  tournamentTeamMemberRole: {
    [TournamentTeamMemberRole.DEFENDER]: null,
    [TournamentTeamMemberRole.FORWARD]: null,
    [TournamentTeamMemberRole.GOALKEEPER]: null,
    [TournamentTeamMemberRole.HALFBACK]: null,
  },
  tournamentTeamMemberType: {
    [TournamentTeamMemberType.COACH]: null,
    [TournamentTeamMemberType.PLAYER]: null,
    [TournamentTeamMemberType.REPRESENTATIVE]: null,
  },
  tournamentTourState: {
    [TournamentTourState.CURRENT]: null,
    [TournamentTourState.FINISHED]: null,
    [TournamentTourState.FUTURE]: null,
  },
  tournamentTranslationEntity: {
    [TournamentTranslationEntity.MATCH]: null,
    [TournamentTranslationEntity.TEAM]: null,
    [TournamentTranslationEntity.TEAM_MEMBER]: null,
    [TournamentTranslationEntity.TOURNAMENT]: null,
  },
  tournamentTranslationLocale: {
    [LocaleEnum.EN]: null,
    [LocaleEnum.RU]: null,
  },
  userDataFieldDataType: {
    [UserDataFieldDataType.BOOLEAN]: null,
    [UserDataFieldDataType.ENUM]: null,
    [UserDataFieldDataType.NUMBER]: null,
    [UserDataFieldDataType.STRING]: null,
  },
  userDocumentState: {
    [UserDocumentState.ACTIVE]: getSuccessColor,
    [UserDocumentState.DELETED]: getDangerColor,
    [UserDocumentState.DELETED]: getDangerColor,
  },
  userDocumentType: {
    [UserDocumentType.FAN_ID]: null,
  },
  userFilterDataType: {
    [UserFilterDataType.ARRAY]: null,
    [UserFilterDataType.BOOLEAN]: null,
    [UserFilterDataType.NUMBER]: null,
    [UserFilterDataType.STRING]: null,
  },
  userFilterType: {
    [UserFilterType.SELECT]: null,
    [UserFilterType.CHECKBOX]: null,
    [UserFilterType.RADIO]: null,
    [UserFilterType.TEXT]: null,
    [UserFilterType.INPUT]: null,
    [UserFilterType.MULTISELECT]: null,
    [UserFilterType.RANGESLIDER]: null,
  },
  userGenderEnum: {
    [UserGenderEnum.FEMALE]: getRandomColor(0),
    [UserGenderEnum.MALE]: getRandomColor(1),
  },
  userIncomeEnum: {
    [UserIncomeEnum.HIGH]: null,
    [UserIncomeEnum.LOW]: null,
    [UserIncomeEnum.MEDIUM]: null,
  },
  userStateEnum: {
    [UserStateEnum.ACTIVE]: getSuccessColor,
    [UserStateEnum.BLOCKED]: null,
    [UserStateEnum.DELETED]: getDangerColor,
    [UserStateEnum.INACTIVE]: getDisabledColor,
  },
  userSubscriptionState: {
    [UserSubscriptionState.ACTIVE]: getSuccessColor,
    [UserSubscriptionState.INACTIVE]: getDisabledColor,
  },
  paymentTransactionState: {
    [PaymentTransactionState.FAILED]: getDangerColor,
    [PaymentTransactionState.CANCELLED]: getDisabledColor,
    [PaymentTransactionState.AUTH]: getWarningColor,
    [PaymentTransactionState.PROCESSED]: getSuccessColor,
    [PaymentTransactionState.HOLD]: getPrimaryColor,
  },
};

const abstractValueMap = {
  CURRENT: getSuccessColor,
  FINISHED: getDisabledColor,
  FUTURE: getPrimaryColor,
  DELETED: getDangerColor,
  ACTIVE: getSuccessColor,
  INACTIVE: getDisabledColor,
  PAID: getSuccessColor,
  UNPAID: getDisabledColor,
  NEW: getDisabledColor,
  PROCESSING: getPrimaryColor,
  ON_DELIVERY: getPrimaryColor,
  DELIVERED: getPrimaryColor,
  DONE: getSuccessColor,
  CANCELLED: getDangerColor,
  DANGER: getDangerColor,
  DISABLED: getDisabledColor,
  PRIMARY: getPrimaryColor,
  SUCCESS: getSuccessColor,
  WARNING: getWarningColor,
};

type IEnums = typeof enums;
type Folders = Exclude<keyof IEnums, 'number' | 'symbol'>;
type Value<TFolderName extends Folders> = Exclude<keyof IEnums[TFolderName], 'number' | 'symbol' | 'string'>;

interface IExternalProps<TFolderName extends Folders | undefined> {
  enumName?: TFolderName;
  value: TFolderName extends Folders ? Value<TFolderName> : string;
}

interface IProps<TFolderName extends Folders | undefined> extends IExternalProps<TFolderName> {}
interface IState {}

class StatusTag<TFolderName extends Folders | undefined> extends React.Component<IProps<TFolderName>, IState> {
  static enums = enums;

  static getStatusColor = function getStatusColor<TFolderName extends Folders>(
    enumName: TFolderName,
    key: Value<TFolderName>
  ) {
    const colorExtractorFn = enums[enumName][key];
    return StatusTag.extractColor(colorExtractorFn);
  };

  static extractColor = (colorExtractorFn: (() => string) | any) => {
    if (!colorExtractorFn || typeof colorExtractorFn !== 'function') {
      return undefined;
    }

    return colorExtractorFn() || undefined;
  };

  private getFoldedValue = (enumName: NonNullable<TFolderName>, value: Value<NonNullable<TFolderName>>) => {
    return StatusTag.getStatusColor(enumName, value);
  };

  private getAbstractValue = (value: string) => {
    const abstractKey = value as keyof typeof abstractValueMap;
    const colorExtractorFn = abstractValueMap[abstractKey];
    return StatusTag.extractColor(colorExtractorFn);
  };

  private getClassName = (): string => {
    const className = 'statusTag';
    const classNames = [className];
    const color = this.getColor();

    if (color === getDisabledColor()) {
      classNames.push(`${className}--disabled`);
    } else if (color === getDangerColor()) {
      classNames.push(`${className}--danger`);
    } else if (color === getSuccessColor()) {
      classNames.push(`${className}--success`);
    } else if (color === getPrimaryColor()) {
      classNames.push(`${className}--primary`);
    } else if (color === getWarningColor()) {
      classNames.push(`${className}--warning`);
    } else {
      classNames.push(`${className}--default`);
    }

    return classNames.join(' ');
  };

  private getColor = () => {
    const { value, enumName } = this.props;
    if (enumName) {
      return this.getFoldedValue(enumName as NonNullable<TFolderName>, value as any);
    }

    return this.getAbstractValue(String(value));
  };

  private getContent = () => {
    const { value, children, enumName } = this.props;
    if (children) {
      return children;
    }

    if (!enumName) {
      return i18n(value);
    }

    return i18n(`${enumName}_${value}`);
  };

  private renderTag = () => {
    const color = this.getColor();
    const content = this.getContent();
    const className = this.getClassName();

    return (
      <Tag className={className} color={color}>
        {content}
      </Tag>
    );
  };

  public render() {
    return this.renderTag();
  }
}

export default StatusTag;
