import './styles/index.scss';
import * as serviceWorker from './serviceWorker';
import App from './components/App/App';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import SuspenseFallback from './components/SuspenseFallback/SuspenseFallback';
import RootBoundary from './components/RootBoundary/RootBoundary';

const fallback = <SuspenseFallback />;

ReactDOM.render(
  <RootBoundary>
    <Suspense fallback={fallback}>
      <App />
    </Suspense>
  </RootBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
