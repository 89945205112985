import { gql } from 'apollo-boost';

export const EVENT_TYPE_FRAGMENT = gql`
  fragment eventType on EventType {
    id
    name
    description
    image
    templates
    createdAt
    updatedAt
  }
`;
