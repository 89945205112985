import { gql } from 'apollo-boost';
import { LOYALTY_PARTNER_FRAGMENT } from './loyaltyPartner';

export const LOYALTY_PARTNER_CONNECTION_FRAGMENT = gql`
  fragment loyaltyPartnerConnection on LoyaltyPartnerConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...loyaltyPartner
    }
  }
  ${LOYALTY_PARTNER_FRAGMENT}
`;
