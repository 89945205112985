import { gql } from 'apollo-boost';
import { CURRENCY_FRAGMENT } from './currency';
import { CATALOGUE_FLAT_FRAGMENT } from './catalogue';

export const COMMERCE_SHOP_FLAT_FRAGMENT = gql`
  fragment commerceShopFlat on CommerceShop {
    id
    clientId
    state
    name
    description
    codeMask
    catalogueId
    currencyId
    createdAt
    updatedAt
    settings
  }
`;

export const COMMERCE_SHOP_FRAGMENT = gql`
  fragment commerceShop on CommerceShop {
    ...commerceShopFlat
    currency {
      ...currency
    }
    catalogue {
      ...catalogueFlat
    }
  }
  ${COMMERCE_SHOP_FLAT_FRAGMENT}
  ${CURRENCY_FRAGMENT}
  ${CATALOGUE_FLAT_FRAGMENT}
`;
