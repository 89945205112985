import React from 'react';

import './CommerceOrdersWidgets.scss';
import { i18n } from '../../helpers/I18n';
import FloatPanel from '../FloatPanel/FloatPanel';
import { compose } from 'recompose';
import {
  CommerceOrderState,
  CommerceOrderStatesWidgetDataFragment,
  CommerceOrderStatesWidgetProps,
  CommerceShop,
  withCommerceOrderStatesWidget,
} from '../../typings/graphql';
import { FiltersContextHOC, FiltersProvider } from '../../universal/index';
import { IExtendedFilterInput, IFiltersContextValue } from '../../universal/components/FiltersProvider/FiltersProvider';
import { QUERY_NAME } from '../CommerceShopPage/CommerceShopPage';
import { parseJSON } from '../../helpers/parseJSON';
import StatusTag from '../StatusTag/StatusTag';
import { OrderUpdateEvent } from '../ShopOrders/ShopOrders';
import { EVENTS } from '../../universal/services/EventService';

interface IExternalProps {
  shopId: CommerceShop['id'];
  isWrapped?: boolean;
  onClick?: (state: CommerceOrderState | null) => void;
}

interface IProps extends IExternalProps {
  commerceOrderStatesWidget: CommerceOrderStatesWidgetProps['data'];
  filtersContext: IFiltersContextValue;
}

interface IState {}

type Record = CommerceOrderStatesWidgetDataFragment;

// We gonna operate FilterInput, so we give him an ID and be able find it later to change value
export const COMMERCE_ORDERS_FILTER_INPUT_ID = 'state_widget';

class CommerceOrdersWidgets extends React.PureComponent<IProps, IState> {
  componentDidMount() {
    document.addEventListener(EVENTS.COMMERCE_ORDER_UPDATE, this.refetchOnOrderUpdate);
  }

  private refetchOnOrderUpdate = async (event: Event) => {
    const refetchEvent = (event as unknown) as OrderUpdateEvent;
    if (!refetchEvent || !refetchEvent.detail || !refetchEvent.detail.order) {
      return;
    }

    const { refetch } = this.props.commerceOrderStatesWidget;
    await refetch();
  };

  private getActiveWidgets = (): CommerceOrderState[] => {
    // Get exact FilterInput which is operate for searching orders with exact CommerceOrderState
    const filterInput = this.getFilterInput();

    // And get states from FilterInput
    return this.getStatesFromFilterInput(filterInput);
  };

  private getFilterInput = () => {
    const { filtersContext } = this.props;

    // Get ID of "FilterInput collection" in FiltersProvider state
    const filterStateId = this.getFilterStateId();

    // Get array of FilterInputs we sending to server
    const filterState = filtersContext.getFilterState(filterStateId);

    // Get exact FilterInput which is operate for searching orders with exact CommerceOrderState
    return filterState.find(({ id }) => id === COMMERCE_ORDERS_FILTER_INPUT_ID);
  };

  private getStatesFromFilterInput = (filterInput?: IExtendedFilterInput): CommerceOrderState[] => {
    if (!filterInput) {
      return [];
    }

    // Parse FilterInput value
    const orderStates = parseJSON(filterInput.value);

    // And check it for validity
    if (!orderStates || !Array.isArray(orderStates)) {
      return [];
    }

    return orderStates;
  };

  private getWidgets = () => {
    const { commerceOrderStatesWidget } = this.props;
    if (!commerceOrderStatesWidget) {
      return [];
    }

    return commerceOrderStatesWidget.commerceOrderStatesWidget || [];
  };

  private getWidgetClassName = (record: Record) => {
    const className = 'commerceOrdersWidgets_widget';
    const classNames = [className];

    // Highlight active
    const activeWidgets = this.getActiveWidgets();
    if (activeWidgets.includes(record.state)) {
      const activeClassName = `${className}--active`;
      classNames.push(activeClassName);
    }

    return classNames.join(' ');
  };

  private getTableId = () => {
    const { shopId } = this.props;
    let id = `allCommerceOrders`;

    if (typeof shopId === 'number') {
      id = `${id}-shop-${shopId}`;
    }

    return id;
  };

  private getFilterStateId = () => {
    const tableId = this.getTableId();
    return FiltersProvider.getFilterId(QUERY_NAME, tableId);
  };

  private handleClick = (record: Record) => () => {
    const { filtersContext } = this.props;

    // Get ID of "FilterInput collection" in FiltersProvider state
    const filterStateId = this.getFilterStateId();

    const filterInput = this.getFilterInput();

    // Correct FilterInput value
    let value = [record.state];
    if (filterInput) {
      const activeWidgets = this.getStatesFromFilterInput(filterInput);

      // Toggle value off, if click on active widget
      if (activeWidgets.includes(record.state)) {
        value = [];
      }
    }

    // If FilterInput exist - we will change it, otherwise create new
    const methodName = filterInput ? 'changeFilter' : 'addFilter';

    // Mutate FiltersContext
    filtersContext[methodName](filterStateId, {
      ...filterInput,
      value: JSON.stringify(value),
      field: 'state',
      id: COMMERCE_ORDERS_FILTER_INPUT_ID,
    });

    // And use callbacks if provided
    const { onClick } = this.props;
    if (!onClick) {
      return;
    }

    onClick(record.state);
  };

  private renderWidgets = () => {
    const widgets = this.getWidgets();
    return <div className="commerceOrdersWidgets">{widgets.map(this.renderWidget)}</div>;
  };

  private renderWidget = (record: Record) => {
    const className = this.getWidgetClassName(record);
    const onClick = this.handleClick(record);
    const { state, count } = record;

    const style: React.CSSProperties = {
      color: StatusTag.getStatusColor('commerceOrderState', state),
    };

    return (
      <FloatPanel key={state} className={className} onClick={onClick}>
        <p>{i18n(state)}</p>
        <h2 style={style}>{count}</h2>
      </FloatPanel>
    );
  };

  private renderContent = () => {
    return this.renderWidgets();
  };

  private renderWrapper = () => {
    const { isWrapped } = this.props;
    if (!isWrapped) {
      return this.renderContent();
    }

    const title = i18n('h_widgets');

    return <FloatPanel title={title}>{this.renderContent()}</FloatPanel>;
  };

  render() {
    return this.renderWrapper();
  }
}

export default compose<IProps, IExternalProps>(
  FiltersContextHOC({}),
  withCommerceOrderStatesWidget({ name: 'commerceOrderStatesWidget' })
)(CommerceOrdersWidgets);
