import { gql } from 'apollo-boost';
import { TOURNAMENT_MATCH_FRAGMENT } from './tournamentMatch';

export const TOURNAMENT_MATCH_CONNECTION_FRAGMENT = gql`
  fragment tournamentMatchConnection on TournamentMatchConnection {
    total
    limit
    cursor
    sortable
    searchable
    edges {
      ...tournamentMatch
    }
  }
  ${TOURNAMENT_MATCH_FRAGMENT}
`;
