import { gql } from 'apollo-boost';
import { TOURNAMENT_TEAM_MEMBER_FULL_FRAGMENT } from '../fragments/tournamentTeamMember';

export const TOURNAMENT_TEAM_MEMBER_QUERY = gql`
  query tournamentTeamMember($id: Int!) {
    tournamentTeamMember(id: $id) {
      ...tournamentTeamMemberFull
    }
  }
  ${TOURNAMENT_TEAM_MEMBER_FULL_FRAGMENT}
`;
