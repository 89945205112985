import { gql } from 'apollo-boost';
import { PAYMENT_TRANSACTION_FRAGMENT } from './paymentTransaction';

export const PAYMENT_TRANSACTION_CONNECTION_FRAGMENT = gql`
  fragment paymentTransactionConnection on PaymentTransactionConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...paymentTransaction
    }
  }
  ${PAYMENT_TRANSACTION_FRAGMENT}
`;
