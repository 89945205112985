import { gql } from 'apollo-boost';
import { PAYMENT_TRANSACTION_CONNECTION_FRAGMENT } from '../fragments/paymentTransactionConnection';

export const ALL_PAYMENT_TRANSACTIONS_QUERY = gql`
  query allPaymentTransactions($query: DefaultQueryInput) {
    allPaymentTransactions(query: $query) {
      ...paymentTransactionConnection
    }
  }
  ${PAYMENT_TRANSACTION_CONNECTION_FRAGMENT}
`;
