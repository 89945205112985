import { gql } from 'apollo-boost';
import { DISPUTE_DISPUTE_RESPONSE_CONNECTION_FRAGMENT } from '../fragments/disputeDisputeResponseConnection';

export const ALL_DISPUTE_DISPUTE_RESPONSES_QUERY = gql`
  query allDisputeDisputeResponses($query: DefaultQueryInput) {
    allDisputeDisputeResponses(query: $query) {
      ...disputeDisputeResponseConnection
    }
  }
  ${DISPUTE_DISPUTE_RESPONSE_CONNECTION_FRAGMENT}
`;
