import * as React from 'react';
import { compose } from 'recompose';
import {
  AclProps,
  SystemUserFragment,
  SystemUserProps,
  UpdateSystemUserProps,
  withSystemUser,
  withUpdateSystemUser,
} from '../../typings/graphql';
import { getMutateProps } from '../../helpers/mutationOperationOptions';
import { handleFail, handleSuccess, OPERATIONS } from '../../helpers/handleMutation';
import { FormComponent, IFormField, UFormState } from '../../universal/components/UForm/UForm';
import { UForm } from '../../universal';

interface IExternalProps {
  userId: NonNullable<SystemUserFragment['id']>;
}

interface IProps extends IExternalProps {
  acl: AclProps['data'];
  systemUser: SystemUserProps['data'];
  updateSystemUser: UpdateSystemUserProps;
}

interface IState extends Partial<SystemUserFragment> {}

class SystemUserForm extends React.Component<IProps, IState> {
  public readonly state: IState = {};

  private getInstance = () => {
    const { systemUser } = this.props.systemUser;
    return systemUser || null;
  };

  private getError = () => {
    const { error: errorLoading } = this.props.systemUser;
    return errorLoading;
  };

  private isLoading = () => {
    const { loading: isLoading } = this.props.systemUser;
    return isLoading;
  };

  private isPending = () => {
    const isLoading = this.isLoading();
    const { loading: isUpdating } = this.props.updateSystemUser.result;

    return isLoading || isUpdating;
  };

  private getFields = (): IFormField[] => {
    return [
      { field: 'lastName', component: FormComponent.INPUT },
      { field: 'firstName', component: FormComponent.INPUT },
      { field: 'middleName', component: FormComponent.INPUT },
      { field: 'phone', component: FormComponent.INPUT },
      { field: 'email', component: FormComponent.INPUT },
      { field: 'password', component: FormComponent.INPUT },
    ];
  };

  private handleSubmit = (formState: UFormState, changes: UFormState) => {
    const { userId, updateSystemUser } = this.props;

    updateSystemUser
      .mutate({
        variables: {
          id: userId,
          data: changes,
        },
      })
      .then(handleSuccess(OPERATIONS.UPDATE))
      .catch(handleFail(OPERATIONS.UPDATE));
  };

  private renderForm = () => {
    const error = this.getError();
    const instance = this.getInstance();
    const isLoading = this.isPending();
    const fields = this.getFields();

    return (
      <UForm onSubmit={this.handleSubmit} instance={instance} error={error} isLoading={isLoading} fields={fields} />
    );
  };

  public render() {
    return <div>{this.renderForm()}</div>;
  }
}

export default compose<IProps, IExternalProps>(
  withSystemUser<IExternalProps>({
    name: 'systemUser',
    options: props => ({
      variables: {
        id: props.userId,
      },
    }),
  }),
  withUpdateSystemUser(getMutateProps('updateSystemUser'))
)(SystemUserForm);
