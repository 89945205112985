import { gql } from 'apollo-boost';
import { LOYALTY_PARTNER_CONNECTION_FRAGMENT } from '../fragments/loyaltyPartnerConnection';

export const ALL_LOYALTY_PARTNERS_QUERY = gql`
  query allLoyaltyPartners($query: DefaultQueryInput) {
    allLoyaltyPartners(query: $query) {
      ...loyaltyPartnerConnection
    }
  }
  ${LOYALTY_PARTNER_CONNECTION_FRAGMENT}
`;
