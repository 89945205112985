import { gql } from 'apollo-boost';

export const TEMPLATE_PDF_FRAGMENT = gql`
  fragment templatePDF on TemplatePDF {
    id
    clientId
    name
    preview
    body
    createdAt
    updatedAt
  }
`;
