import { gql } from 'apollo-boost';
import { ARTICLE_FRAGMENT_FULL } from '../fragments/article';

export const ARTICLE_QUERY = gql`
  query article($id: Int!) {
    article(id: $id) {
      ...articleFull
    }
  }
  ${ARTICLE_FRAGMENT_FULL}
`;
