import { gql } from 'apollo-boost';
import { EVENT_CONNECTION_FRAGMENT } from '../fragments/eventConnection';

export const ALL_EVENTS_QUERY = gql`
  query allEvents($query: DefaultQueryInput) {
    allEvents(query: $query) {
      ...eventConnection
    }
  }
  ${EVENT_CONNECTION_FRAGMENT}
`;
