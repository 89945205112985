import { gql } from 'apollo-boost';

export const SYSTEM_TRANSLATION_FRAGMENT = gql`
  fragment systemTranslation on SystemTranslation {
    id
    locale
    key
    value
    updatedAt
  }
`;
