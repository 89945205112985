import { gql } from 'apollo-boost';
import { SYSTEM_USER_FRAGMENT } from './systemUser';

export const ARTICLE_AUTHOR_FRAGMENT = gql`
  fragment articleAuthor on ArticleAuthor {
    id
    articleId
    userId
    user {
      ...systemUser
    }
    role
    createdAt
    updatedAt
  }
  ${SYSTEM_USER_FRAGMENT}
`;

export const ARTICLE_AUTHOR_FRAGMENT_FULL = gql`
  fragment articleAuthorFull on ArticleAuthor {
    userId
    role
    user {
      ...systemUser
    }
  }
  ${SYSTEM_USER_FRAGMENT}
`;
