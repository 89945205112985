import { PaginationConfig, TableProps } from 'antd/es/table';
import { DrawerProps } from 'antd/es/drawer';
import { LinkProps } from 'react-router-dom';
import showTotal from '../helpers/showTotal';

export const DEFAULT_TASK_POLLING_TIME = 2000;

export const STAGE = process.env.REACT_APP_STAGE;

export const ASYNC_TASKS_POLL_INTERVAL = 30000;

export const IS_PRODUCTION = STAGE === 'production';
export const IS_DEVELOPMENT = STAGE === 'dev' || STAGE === 'development';

export const ROW_GUTTER = 20;

export const DEFAULT_LOGO = '/img/logos/sportstar-logo.png';

export const DEFAULT_PAGE_SIZE = 20;

export const UPLOAD_ENDPOINT = '/api/upload';

export const COLORS = {
  NONE: '#c3c3c3',
  TRUE: '#87d068',
  FALSE: '#ff5500',
  PRIMARY: '#40a9ff',
};

export const FORMAT = {
  TIME_SHORT: 'HH:mm',
  TIME: 'HH:mm:ss',
  DATE: 'DD MMM',
  DATE_WITH_YEAR: 'DD MMM YYYY',
  FULL: 'DD MMMM YYYY HH:mm:ss',
  YEAR: 'YYYY',
  SEMI_FULL: 'DD MMM HH:mm',
};

export const COMMON_TABLE_PROPS: Partial<TableProps<any>> = {
  size: 'small',
  rowKey: 'id',
  bordered: false,
};

export const COMMON_PAGINATION_PROPS: PaginationConfig = {
  pageSize: DEFAULT_PAGE_SIZE,
  showTotal,
  size: 'small',
  position: 'bottom',
  hideOnSinglePage: true,
};

export const COMMON_DRAWER_PROPS: DrawerProps = {
  closable: true,
};

export const SEARCH_SUBMIT_DELAY = 500;

export const OPEN_IN_NEW_TAB_LINK_PROPS: Partial<LinkProps> = {
  target: '_blank',
  onClick: event => {
    event.stopPropagation();
  },
};

export const VALUE_ALL = 'ALL';

export const RANDOM_COLORS: string[] = [
  '#fa6284',
  '#36a2eb',
  '#ffb0eb',
  '#5b90ff',
  '#fecd56',
  '#911eb4',
  '#46f0f0',
  '#f032e6',
  '#bcf60c',
  '#fabebe',
  '#008080',
  '#e6beff',
  '#9a6324',
  '#fffac8',
  '#800000',
  '#aaffc3',
  '#808000',
  '#ffd8b1',
  '#000075',
  '#808080',
  '#003f5c',
  '#2f4b7c',
  '#665191',
  '#a05195',
  '#d45087',
  '#f95d6a',
  '#ff7c43',
  '#ffa600',
  '#4FC3F7',
  '#F44336',
  '#E91E63',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#2196F3',
  '#03A9F4',
  '#00BCD4',
  '#01708c',
  '#5355ba',
  '#4bc635',
  '#bcc902',
  '#ba373a',
  '#543ad8',
  '#a6e835',
  '#ac1de0',
  '#fc8607',
];

export const CATALOGUE_ITEM_EXTENSION_DATA = { id: 29, key: 'catalogueItemId' };
export const CATALOGUE_EVENT_EXTENSION_DATA = [
  { id: 106, key: 'eventId' },
  { id: 18, key: 'guid' },
];
