import { gql } from 'apollo-boost';
import { TOURNAMENT_MATCH_CONNECTION_FRAGMENT } from '../fragments/tournamentMatchConnection';

export const ALL_TOURNAMENT_MATCHES_QUERY = gql`
  query allTournamentMatches($query: DefaultQueryInput) {
    allTournamentMatches(query: $query) {
      ...tournamentMatchConnection
    }
  }
  ${TOURNAMENT_MATCH_CONNECTION_FRAGMENT}
`;
