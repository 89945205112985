import { gql } from 'apollo-boost';
import { USER_SUBSCRIPTION_FRAGMENT } from '../fragments/userSubscription';

export const USER_SUBSCRIPTION_QUERY = gql`
  query userSubscription($id: Int!) {
    userSubscription(id: $id) {
      ...userSubscription
    }
  }
  ${USER_SUBSCRIPTION_FRAGMENT}
`;
