import { gql } from 'apollo-boost';
import { MESSENGER_TRANSPORT_FRAGMENT } from '../fragments/messengerTransport';

export const MESSENGER_TRANSPORT_QUERY = gql`
  query messengerTransport($id: Int!) {
    messengerTransport(id: $id) {
      ...messengerTransport
    }
  }
  ${MESSENGER_TRANSPORT_FRAGMENT}
`;
