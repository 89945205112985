import { gql } from 'apollo-boost';
import { COMMERCE_TEMPLATE_CONNECTION_FRAGMENT } from '../fragments/commerceTemplateConnection';

export const ALL_COMMERCE_TEMPLATES_QUERY = gql`
  query allCommerceTemplates($query: DefaultQueryInput) {
    allCommerceTemplates(query: $query) {
      ...CommerceTemplateConnection
    }
  }
  ${COMMERCE_TEMPLATE_CONNECTION_FRAGMENT}
`;
