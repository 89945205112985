import React from 'react';
import { compose } from 'recompose';
import UForm, { FormComponent, IFormField, UFormState } from '../../universal/components/UForm/UForm';
import { handleFail, handleSuccess } from '../../helpers/handleMutation';
import { getMutateProps } from '../../helpers/mutationOperationOptions';
import { ExecutionResult } from 'graphql';
import {
  CommerceOrder,
  CommerceOrderPrintMutation,
  CommerceOrderPrintProps,
  withCommerceOrderPrint,
  Catering,
} from '../../typings/graphql';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Rcm, { RcmProps } from '../Rcm/Rcm';
import { getErrorFromProps, getLoadingFromProps } from '../../helpers/getInfoFromProps';
import { ALL_SYSTEM_PRINTERS_QUERY } from '../../graph/queries/allSystemPrinters';
import { ALL_COMMERCE_TEMPLATES_QUERY } from '../../graph/queries/allCommerceTemplates';
import { i18n } from '../../helpers/I18n';

interface IExternalProps {
  printReceiptTemplateId?: Catering['printReceiptTemplateId'];
  orderId: CommerceOrder['id'];
  onPrint?: () => void;
  rcmProps?: Partial<RcmProps>;
}

interface IProps extends IExternalProps, RouteComponentProps {
  print: CommerceOrderPrintProps;
}

interface IState {}

class CommerceOrderPrintForm extends React.PureComponent<IProps, IState> {
  private operation = 'print';

  private handlePrintSuccess = (res: ExecutionResult<CommerceOrderPrintMutation>) => {
    const result = res && res.data && res.data.commerceOrderPrint;
    if (!result) {
      return handleFail(this.operation)();
    }

    handleSuccess(this.operation)();

    const { onPrint } = this.props;
    if (onPrint) {
      onPrint();
    }
  };

  private handleSubmit = (formState: UFormState) => {
    const { print, orderId } = this.props;

    print
      .mutate({
        variables: {
          id: orderId,
          printerId: formState.printerId,
          templateId: formState.templateId,
        },
      })
      .then(this.handlePrintSuccess)
      .catch(handleFail(this.operation));
  };

  private getFields = (): IFormField[] => {
    const { printReceiptTemplateId } = this.props;
    return [
      {
        field: 'printerId',
        component: FormComponent.SELECT,
        optionsQuery: {
          query: ALL_SYSTEM_PRINTERS_QUERY,
          queryName: 'allSystemPrinters',
        },
        isRequired: true,
      },
      {
        field: 'templateId',
        component: FormComponent.SELECT,
        optionsQuery: {
          query: ALL_COMMERCE_TEMPLATES_QUERY,
          queryName: 'allCommerceTemplates',
        },
        defaultValue: printReceiptTemplateId,
        isRequired: true,
      },
    ];
  };

  private isPending = (): boolean => {
    return getLoadingFromProps(this.props).isMutating;
  };

  private getError = () => {
    return getErrorFromProps(this.props).error;
  };

  private renderEssence = () => {
    const fields = this.getFields();
    const isLoading = this.isPending();
    const error = this.getError();
    const submitBtnText = i18n('action_print');

    return (
      <UForm
        fields={fields}
        isLoading={isLoading}
        error={error}
        onSubmit={this.handleSubmit}
        submitBtnText={submitBtnText}
      />
    );
  };

  private renderRcm = () => {
    const { rcmProps } = this.props;
    return <Rcm renderEssence={this.renderEssence} {...rcmProps} />;
  };

  render() {
    return this.renderRcm();
  }
}

export default compose<IProps, IExternalProps>(
  withRouter,
  withCommerceOrderPrint<IProps>(getMutateProps('print'))
)(CommerceOrderPrintForm);
