import * as React from 'react';
import { ThemeContext } from '../ThemeProvider/ThemeProvider';

interface IOptions {
  name?: string;
}

export default function ThemeContextHOC<TProps, TExternalProps>(options?: IOptions) {
  const name = (options && options.name) || 'themeContext';

  return (WrappedComponent: React.ComponentType<TProps>): React.FunctionComponent<TExternalProps> => props => (
    <ThemeContext.Consumer>
      {contextValue => <WrappedComponent {...(props as any)} {...{ [name]: contextValue }} />}
    </ThemeContext.Consumer>
  );
}
