import { gql } from 'apollo-boost';
import { TEMPLATE_PDF_FRAGMENT } from '../fragments/templatePdf';

export const TEMPLATE_PDF_QUERY = gql`
  query templatePDF($id: Int!) {
    templatePDF(id: $id) {
      ...templatePDF
    }
  }
  ${TEMPLATE_PDF_FRAGMENT}
`;
