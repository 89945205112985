export const getMutateProps = (name: string) => {
  return {
    name: name,
    options: {
      ignoreResults: false,
    },
    props: (props: any) => {
      return {
        [name]: {
          mutate: props[name],
          result: props[`${name}Result`],
        },
      } as any;
    },
  };
};
