import { gql } from 'apollo-boost';

export const PAYMENT_CURRENCY_FRAGMENT = gql`
  fragment paymentCurrency on PaymentCurrency {
    id
    clientId
    iso3
    name
    sign
    isDefault
    isVirtual
    createdAt
    updatedAt
  }
`;
