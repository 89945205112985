import { gql } from 'apollo-boost';
import { COMMERCE_ORDER_ITEM_FRAGMENT } from '../fragments/commerceOrderItem';

export const COMMERCE_ORDER_ITEM_QUERY = gql`
  query commerceOrderItem($id: Int!) {
    commerceOrderItem(id: $id) {
      ...commerceOrderItem
    }
  }
  ${COMMERCE_ORDER_ITEM_FRAGMENT}
`;
