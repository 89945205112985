import { gql } from 'apollo-boost';

export const COMMERCE_TEMPLATE_FRAGMENT = gql`
  fragment CommerceTemplate on CommerceTemplate {
    id
    clientId
    name
    preview
    body
    createdAt
    updatedAt
  }
`;
