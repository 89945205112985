import { gql } from 'apollo-boost';

export const USER_GROUP_FRAGMENT = gql`
  fragment userGroup on UserGroup {
    id
    clientId
    name
    usersCount
    sort
    createdAt
    updatedAt
  }
`;
