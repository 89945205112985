import * as React from 'react';
import { Modal, Tabs } from 'antd';

import { ModalProps } from 'antd/es/modal/Modal';
import { i18n } from '../../helpers/I18n';
import SystemUserForm from '../SystemUserForm/SystemUserForm';
import SystemUserACL from '../SystemUserACL/SystemUserACL';
import { getFromLocalStorage } from '../../helpers/getFromLocalStorage';
import SystemUserUISettings from '../SystemUserUISettings/SystemUserUISettings';

interface IExternalProps extends ModalProps {}

interface IProps extends IExternalProps {}

interface IState {
  activeKey: 'profile' | 'access' | 'settings' | string;
}

class SystemUserProfileModal extends React.Component<IProps, IState> {
  public readonly state: IState = {
    activeKey: 'profile',
  };

  private handleChangeTab = (activeKey: string) => {
    this.setState({ activeKey });
  };

  private getUserId = () => getFromLocalStorage('userId');

  private renderProfile = () => {
    const userId = this.getUserId();
    return <SystemUserForm userId={userId} />;
  };

  private renderContent = () => {
    const { activeKey } = this.state;

    return (
      <Tabs activeKey={activeKey} onChange={this.handleChangeTab}>
        <Tabs.TabPane tab={i18n('profile')} key="profile">
          {this.renderProfile()}
        </Tabs.TabPane>
        <Tabs.TabPane tab={i18n('access')} key="access">
          {this.renderAccess()}
        </Tabs.TabPane>
        <Tabs.TabPane tab={i18n('ui_settings')} key="settings">
          {this.renderUISettings()}
        </Tabs.TabPane>
      </Tabs>
    );
  };

  private renderAccess = () => {
    const userId = this.getUserId();
    return <SystemUserACL userId={userId} />;
  };

  private renderUISettings = () => {
    const userId = this.getUserId();
    return <SystemUserUISettings userId={userId} />;
  };

  public render() {
    return (
      <Modal footer={null} {...this.props}>
        {this.renderContent()}
      </Modal>
    );
  }
}

export default SystemUserProfileModal;
