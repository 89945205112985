import * as React from 'react';
import { LocaleEnum } from '../../typings/graphql';

interface IOptions {
  name?: string;
}

export type ISystemTranslationsContextValue = {
  locale: LocaleEnum;
  changeLocale: (locale: LocaleEnum) => void;
};
export const SystemTranslationsContext = React.createContext<ISystemTranslationsContextValue>({
  locale: LocaleEnum.EN,
  changeLocale: () => {},
});

SystemTranslationsContext.displayName = 'SystemTranslationsContext';

export default function TranslationContextHOC<TProps, TExternalProps>(options?: IOptions) {
  const name = (options && options.name) || 'systemTranslationsContext';

  return (WrappedComponent: React.ComponentType<TProps>): React.FunctionComponent<TExternalProps> => props => (
    <SystemTranslationsContext.Consumer>
      {contextValue => <WrappedComponent {...(props as any)} {...{ [name]: contextValue }} />}
    </SystemTranslationsContext.Consumer>
  );
}
