import { gql } from 'apollo-boost';
import { EVENT_PLACE_FRAGMENT } from '../fragments/eventPlace';

export const EVENT_PLACE_QUERY = gql`
  query eventPlace($id: Int!) {
    eventPlace(id: $id) {
      ...eventPlace
    }
  }
  ${EVENT_PLACE_FRAGMENT}
`;
