import React from 'react';
import { DataValue } from '@apollo/react-hoc';

interface IExternalProps {
  dataValue: DataValue<any>;
  onCatch: (dataValue: IExternalProps['dataValue']) => void;
}

interface IProps extends IExternalProps {}

interface IState {}

class RefetchCatcher extends React.Component<IProps, IState> {
  componentDidMount() {
    const { onCatch, dataValue } = this.props;
    onCatch(dataValue);
  }

  render() {
    return null;
  }
}

export default RefetchCatcher;
