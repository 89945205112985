import { gql } from 'apollo-boost';
import { TOURNAMENT_SEASON_FULL_FRAGMENT } from '../fragments/season';

export const TOURNAMENT_SEASON_QUERY = gql`
  query tournamentSeason($id: Int!) {
    tournamentSeason(id: $id) {
      ...tournamentSeasonFull
    }
  }
  ${TOURNAMENT_SEASON_FULL_FRAGMENT}
`;
