import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import React, { lazy } from 'react';
import PrivateLayout from '../PrivateLayout/PrivateLayout';
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import { CATALOGUE_QUERY } from '../../graph/queries/catalogue';
import { CATALOGUE_ITEM_QUERY } from '../../graph/queries/catalogueItem';
import { CATALOGUE_CATEGORY_QUERY } from '../../graph/queries/catalogueCategory';
import { TOURNAMENT_TEAM_QUERY } from '../../graph/queries/tournamentTeam';
import { TOURNAMENT_TEAM_MEMBER_QUERY } from '../../graph/queries/tournamentTeamMember';
import { TOURNAMENT_PLAYER_STAT_QUERY } from '../../graph/queries/tournamentPlayerStat';
import { USER_SUBSCRIPTION_QUERY } from '../../graph/queries/userSubscription';
import { TOURNAMENT_QUERY } from '../../graph/queries/tournament';
import { TOURNAMENT_SEASON_QUERY } from '../../graph/queries/tournamentSeason';
import { TOURNAMENT_DISQUALIFICATION_QUERY } from '../../graph/queries/tournamentDisqualification';
import { TOURNAMENT_TOUR_QUERY } from '../../graph/queries/tournamentTour';
import { TOURNAMENT_MATCH_QUERY } from '../../graph/queries/tournamentMatch';
import { TOURNAMENT_MATCH_EVENT_QUERY } from '../../graph/queries/tournamentMatchEvent';
import { TOURNAMENT_MATCH_PROTOCOL_QUERY } from '../../graph/queries/tournamentMatchProtocol';
import { LOYALTY_PROMO_QUERY } from '../../graph/queries/loyaltyPromo';
import { COMMERCE_SHOP_QUERY } from '../../graph/queries/commerceShop';
import { COMMERCE_ORDER_QUERY } from '../../graph/queries/commerceOrder';
import { COMMERCE_ORDER_ITEM_QUERY } from '../../graph/queries/commerceOrderItem';
import { SYSTEM_USER_QUERY } from '../../graph/queries/systemUser';
import { LOYALTY_PARTNER_QUERY } from '../../graph/queries/loyaltyPartner';
import { PAYMENT_ACCOUNT_QUERY } from '../../graph/queries/paymentAccount';
import { PAYMENT_CURRENCY_QUERY } from '../../graph/queries/paymentCurrency';
import { ARTICLE_QUERY } from '../../graph/queries/article';
import { ARTICLE_AUTHOR_QUERY } from '../../graph/queries/articleAuthor';
import { ARTICLE_BLOCK_QUERY } from '../../graph/queries/articleBlock';
import { EVENT_PLACE_QUERY } from '../../graph/queries/eventPlace';
import { EVENT_QUERY } from '../../graph/queries/event';
import { EVENT_ORDER_QUERY } from '../../graph/queries/eventOrder';
import { USER_QUERY } from '../../graph/queries/user';
import { MOBILE_TRANSLATION_QUERY } from '../../graph/queries/mobileTranslation';
import { TEMPLATE_APPLE_PASS_QUERY } from '../../graph/queries/templateApplePass';
import { TEMPLATE_PDF_QUERY } from '../../graph/queries/templatePdf';
import { PAYMENT_PROVIDER_QUERY } from '../../graph/queries/paymentProvider';
import { MESSENGER_TEMPLATE_QUERY } from '../../graph/queries/messengerTemplate';

const getComponent = (pageName: string) => lazy(() => import(`../${pageName}/${pageName}`));

const HomePage = getComponent('HomePage');
const LoginPage = getComponent('LoginPage');
const NotFoundPage = getComponent('NotFoundPage');
const AllSystemUsersPage = getComponent('AllSystemUsersPage');
const SystemUserPage = getComponent('SystemUserPage');
const AllUsersPage = getComponent('AllUsersPage');
const UserPage = getComponent('UserPage');
const DashboardPage = getComponent('DashboardPage');
const AllTeamsPage = getComponent('AllTeamsPage');
const AllDisputesPage = getComponent('AllDisputesPage');
const DisputePage = getComponent('DisputePage');
const TeamPage = getComponent('TeamPage');
const TournamentTeamMemberPage = getComponent('TournamentTeamMemberPage');
const TournamentPlayerStatPage = getComponent('TournamentPlayerStatPage');
const AllTournamentsPage = getComponent('AllTournamentsPage');
const TournamentPage = getComponent('TournamentPage');
const DisqualificationsPage = getComponent('DisqualificationsPage');
const SeasonPage = getComponent('SeasonPage');
const TourPage = getComponent('TourPage');
const MatchPage = getComponent('MatchPage');
const MatchEventPage = getComponent('MatchEventPage');
const MatchProtocolPage = getComponent('MatchProtocolPage');
const AllCataloguesPage = getComponent('AllCataloguesPage');
const CataloguePage = getComponent('CataloguePage');
const CatalogueItemPage = getComponent('CatalogueItemPage');
const CatalogueCategoryPage = getComponent('CatalogueCategoryPage');
const CommercePage = getComponent('CommercePage');
const CommerceShopPage = getComponent('CommerceShopPage');
const CommerceOrderPage = getComponent('CommerceOrderPage');
const CommerceOrderItemPage = getComponent('CommerceOrderItemPage');
const AllLoyaltyPartnersPage = getComponent('AllLoyaltyPartnersPage');
const LoyaltyPartnerPage = getComponent('LoyaltyPartnerPage');
const PaymentAccountPage = getComponent('PaymentAccountPage');
const AllPaymentCurrenciesPage = getComponent('AllPaymentCurrenciesPage');
const AllPaymentAccountsPage = getComponent('AllPaymentAccountsPage');
const AllPaymentTransactionsPage = getComponent('AllPaymentTransactionsPage');
const PaymentPage = getComponent('PaymentPage');
const PaymentCurrencyPage = getComponent('PaymentCurrencyPage');
const AllArticlesPage = getComponent('AllArticlesPage');
const ArticlePage = getComponent('ArticlePage');
const ArticleAuthorPage = getComponent('ArticleAuthorPage');
const ArticleBlockPage = getComponent('ArticleBlockPage');
const MobileTranslationsPage = getComponent('MobileTranslationsPage');
const MobileTranslationPage = getComponent('MobileTranslationPage');
const SystemSettingsPage = getComponent('SystemSettingsPage');
const TemplatesPage = getComponent('TemplatesPage');
const TemplateApplePassPage = getComponent('TemplateApplePassPage');
const TemplateNotificationPage = getComponent('TemplateNotificationPage');
const TemplatePdfPage = getComponent('TemplatePdfPage');
const AllCateringsPage = getComponent('AllCateringsPage');
const CateringPage = getComponent('CateringPage');
const DeveloperPreviewPage = getComponent('DeveloperPreviewPage');
const EventPage = getComponent('EventPage');
const EventPlacePage = getComponent('EventPlacePage');
const AllEventPlacesPage = getComponent('AllEventPlacesPage');
const AllLoyaltyPromosPage = getComponent('AllLoyaltyPromosPage');
const LoyaltyPromoPage = getComponent('LoyaltyPromoPage');
const AllUserSubscriptionsPage = getComponent('AllUserSubscriptionsPage');
const AllMessengerMailingsPage = getComponent('AllMessengerMailingsPage');
const AllMessengerTransportsPage = getComponent('AllMessengerTransportsPage');
const MessengerTransportPage = getComponent('MessengerTransportPage');
const MessengerMailingPage = getComponent('MessengerMailingPage');
const UserSubscriptionPage = getComponent('UserSubscriptionPage');
const TranslationsPage = getComponent('TranslationsPage');
const EventOrderPage = getComponent('EventOrderPage');
const AllEventOrdersPage = getComponent('AllEventOrdersPage');
const ApplicationsPage = getComponent('ApplicationsPage');
const AllUserKidsPage = getComponent('AllUserKidsPage');
const AllPaymentGatewaysPage = getComponent('AllPaymentGatewaysPage');
const PaymentGatewayProvider = getComponent('PaymentGatewayProvider');
const TournamentRefereesPage = getComponent('TournamentRefereesPage');
const CreateCommerceShopPage = getComponent('CreateCommerceShopPage');
const MessengerTemplatesPage = getComponent('MessengerTemplatesPage');
const StadiumsPage = getComponent('StadiumsPage');
const StadiumPage = getComponent('StadiumPage');

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        {/* Public routes */}
        <Route exact path="/login" component={LoginPage} />

        {/* Private routes*/}
        <Route path="/">
          <PrivateLayout>
            <Switch>
              <PrivateRoute exact path="/" component={HomePage} />
              <PrivateRoute exact path="/dashboard" component={DashboardPage} />
              <PrivateRoute exact path="/tournaments/disputes" component={AllDisputesPage} />
              <PrivateRoute exact path="/tournaments/disputes/:disputeId" component={DisputePage} />

              {/*  Stadiums*/}
              <PrivateRoute exact path="/tournaments/stadiums" component={StadiumsPage} />
              <PrivateRoute exact path="/tournaments/stadiums/:id" component={StadiumPage} />

              <PrivateRoute exact path="/tournaments/teams" component={AllTeamsPage} />
              <PrivateRoute exact path="/tournaments/referees" component={TournamentRefereesPage} />
              <PrivateRoute
                exact
                path="/tournaments/teams/:id"
                component={TeamPage}
                argumentQuery={{ id: TOURNAMENT_TEAM_QUERY }}
              />
              <PrivateRoute
                exact
                path="/tournaments/teams/:teamId/(members?)/:id"
                argumentQuery={{ id: TOURNAMENT_TEAM_MEMBER_QUERY }}
                component={TournamentTeamMemberPage}
              />
              <PrivateRoute
                exact
                path="/tournaments/teams/:teamId/(members?)/:memberId/(stats?)/:id"
                argumentQuery={{
                  id: TOURNAMENT_PLAYER_STAT_QUERY,
                }}
                component={TournamentPlayerStatPage}
              />
              <PrivateRoute exact path="/tournaments" component={AllTournamentsPage} />
              <PrivateRoute
                exact
                path="/tournaments/:id"
                component={TournamentPage}
                argumentQuery={{ id: TOURNAMENT_QUERY }}
              />
              <PrivateRoute
                exact
                path="/tournaments/:tournamentId/(seasons?)/:id"
                component={SeasonPage}
                argumentQuery={{ id: TOURNAMENT_SEASON_QUERY }}
              />
              <PrivateRoute
                exact
                path="/tournaments/:tournamentId/(disqualifications?)/:id"
                argumentQuery={{ id: TOURNAMENT_DISQUALIFICATION_QUERY }}
                component={DisqualificationsPage}
              />
              <PrivateRoute
                exact
                path="/tournaments/:tournamentId/(seasons?)/:seasonId/(tours?)/:id"
                argumentQuery={{ id: TOURNAMENT_TOUR_QUERY }}
                component={TourPage}
              />
              <PrivateRoute
                exact
                path="/tournaments/:tournamentId/(seasons?)/:seasonId/(tours?)/:tourId/(matches?)/:id"
                argumentQuery={{ id: TOURNAMENT_MATCH_QUERY }}
                component={MatchPage}
              />
              <PrivateRoute
                exact
                path="/tournaments/:tournamentId/(seasons?)/:seasonId/(tours?)/:tourId/(matches?)/:matchId/(match-events?)/:id"
                argumentQuery={{ id: TOURNAMENT_MATCH_EVENT_QUERY }}
                component={MatchEventPage}
              />
              <PrivateRoute
                exact
                path="/tournaments/:tournamentId/(seasons?)/:seasonId/(tours?)/:tourId/(matches?)/:matchId/(protocols?)/:id"
                argumentQuery={{ id: TOURNAMENT_MATCH_PROTOCOL_QUERY }}
                component={MatchProtocolPage}
              />
              {/* Mailings */}
              <PrivateRoute exact path="/subscriptions" component={AllUserSubscriptionsPage} />
              <PrivateRoute exact path="/mailings" component={AllMessengerMailingsPage} />
              <PrivateRoute exact path="/mailings/transport" component={AllMessengerTransportsPage} />
              <PrivateRoute exact path="/mailings/templates" component={MessengerTemplatesPage} />
              <PrivateRoute exact path="/mailings/transport/:transportId" component={MessengerTransportPage} />
              <PrivateRoute exact path="/mailings/:mailingId/:tab?" component={MessengerMailingPage} />
              {/* Subscriptions */}
              <PrivateRoute exact path="/subscriptions" component={AllUserSubscriptionsPage} />
              <PrivateRoute
                exact
                path="/subscriptions/:id"
                component={UserSubscriptionPage}
                argumentQuery={{ id: USER_SUBSCRIPTION_QUERY }}
              />
              {/* Promos */}
              <PrivateRoute exact path="/promos" component={AllLoyaltyPromosPage} />
              <PrivateRoute
                exact
                path="/promos/:id"
                component={LoyaltyPromoPage}
                argumentQuery={{ id: LOYALTY_PROMO_QUERY }}
              />
              {/* Catalogues */}
              <PrivateRoute exact path="/catalogues" component={AllCataloguesPage} />
              <PrivateRoute
                exact
                path="/catalogues/:id"
                component={CataloguePage}
                argumentQuery={{ id: CATALOGUE_QUERY }}
              />
              <PrivateRoute
                exact
                path="/catalogues/:catalogueId/(categories?)/:id"
                argumentQuery={{ id: CATALOGUE_CATEGORY_QUERY }}
                component={CatalogueCategoryPage}
              />
              <PrivateRoute
                exact
                path="/catalogues/:catalogueId/(items?)/:id"
                argumentQuery={{ id: CATALOGUE_ITEM_QUERY }}
                component={CatalogueItemPage}
              />
              {/* Commerce */}
              <PrivateRoute exact path="/commerce" component={CommercePage} />
              <PrivateRoute exact path="/commerce/(shops?)/new" component={CreateCommerceShopPage} />
              <PrivateRoute
                exact
                path="/commerce/(shops?)/:id"
                component={CommerceShopPage}
                argumentQuery={{ id: COMMERCE_SHOP_QUERY }}
              />
              <PrivateRoute
                exact
                path="/commerce/(shops?)/:shopId/(orders?)/:id"
                component={CommerceOrderPage}
                argumentQuery={{ id: COMMERCE_ORDER_QUERY }}
              />
              <PrivateRoute
                exact
                path="/commerce/(shops?)/:shopId/(orders?)/:orderId/(items?)/:id"
                argumentQuery={{ orderId: COMMERCE_ORDER_QUERY, id: COMMERCE_ORDER_ITEM_QUERY }}
                component={CommerceOrderItemPage}
              />
              {/* System */}
              <PrivateRoute exact path="/(system?)/settings" component={SystemSettingsPage} />
              <PrivateRoute exact path="/(system?)/users" component={AllSystemUsersPage} />
              <PrivateRoute
                exact
                path="/(system?)/users/:id"
                component={SystemUserPage}
                argumentQuery={{ id: SYSTEM_USER_QUERY }}
              />
              <PrivateRoute exact path="/(system?)/translations" component={TranslationsPage} />
              <Redirect from="/(system?)" to="/(system?)/settings" />
              {/* Templates */}
              <PrivateRoute
                exact
                path="/templates/apple-pass/:templateId"
                component={TemplateApplePassPage}
                argumentQuery={{ templateId: TEMPLATE_APPLE_PASS_QUERY }}
              />
              <PrivateRoute
                exact
                path="/templates/notification/:templateId"
                component={TemplateNotificationPage}
                argumentQuery={{ templateId: MESSENGER_TEMPLATE_QUERY }}
              />
              <PrivateRoute
                exact
                path="/templates/pdf/:templateId"
                component={TemplatePdfPage}
                argumentQuery={{ templateId: TEMPLATE_PDF_QUERY }}
              />
              <PrivateRoute exact path="/templates/:tab" component={TemplatesPage} />
              <Redirect from="/templates" to="/templates/apple-pass" />
              {/* Loyalty */}
              <PrivateRoute exact path="/loyalty" component={AllLoyaltyPartnersPage} />
              <PrivateRoute
                exact
                path="/loyalty/:id"
                component={LoyaltyPartnerPage}
                argumentQuery={{ id: LOYALTY_PARTNER_QUERY }}
              />
              {/* Payment */}
              <PrivateRoute exact path="/payment" component={PaymentPage} />
              <PrivateRoute exact path="/payment/transactions/" component={AllPaymentTransactionsPage} />
              <PrivateRoute exact path="/payment/accounts/" component={AllPaymentAccountsPage} />
              <PrivateRoute exact path="/payment/providers/" component={AllPaymentGatewaysPage} />
              <PrivateRoute
                exact
                path="/payment/accounts/:id"
                component={PaymentAccountPage}
                argumentQuery={{ id: PAYMENT_ACCOUNT_QUERY }}
              />
              <PrivateRoute
                exact
                path="/payment/providers/:id"
                component={PaymentGatewayProvider}
                argumentQuery={{ id: PAYMENT_PROVIDER_QUERY }}
              />
              <PrivateRoute exact path="/payment/currencies/" component={AllPaymentCurrenciesPage} />
              <PrivateRoute
                exact
                path="/payment/currencies/:id"
                component={PaymentCurrencyPage}
                argumentQuery={{ id: PAYMENT_CURRENCY_QUERY }}
              />
              {/* Articles */}
              <PrivateRoute exact path="/articles/:articleCategoryId?" component={AllArticlesPage} />
              <PrivateRoute
                exact
                path="/articles/:articleCategoryId/:id"
                component={ArticlePage}
                argumentQuery={{ id: ARTICLE_QUERY }}
              />
              <PrivateRoute
                exact
                path="/articles/:articleCategoryId/:articleId/(authors?)/:id"
                component={ArticleAuthorPage}
                argumentQuery={{ id: ARTICLE_AUTHOR_QUERY }}
              />
              <PrivateRoute
                exact
                path="/articles/:articleCategoryId/:articleId/(blocks?)/:id"
                component={ArticleBlockPage}
                argumentQuery={{ id: ARTICLE_BLOCK_QUERY }}
              />
              {/* EventPlaces */}
              <PrivateRoute exact path="/event-places" component={AllEventPlacesPage} />
              <PrivateRoute
                exact
                path="/event-places/:id"
                component={EventPlacePage}
                argumentQuery={{ id: EVENT_PLACE_QUERY }}
              />
              <PrivateRoute
                exact
                path="/event-places/:placeId/(events?)/:id/:tab?"
                component={EventPage}
                argumentQuery={{ id: EVENT_QUERY }}
              />
              <PrivateRoute exact path="/event-orders/" component={AllEventOrdersPage} />
              <PrivateRoute
                exact
                path="/event-orders/:orderId"
                component={EventOrderPage}
                argumentQuery={{ orderId: EVENT_ORDER_QUERY }}
              />
              {/* Users */}
              <PrivateRoute exact path="/users" component={AllUsersPage} />
              <PrivateRoute exact path="/users/kids" component={AllUserKidsPage} />
              <PrivateRoute exact path="/users/:id/:tab?" component={UserPage} argumentQuery={{ id: USER_QUERY }} />
              {/* MobileTranslations */}
              <PrivateRoute exact path="/(mobile?)/translations" component={MobileTranslationsPage} />
              <PrivateRoute
                exact
                path="/(mobile?)/translations/:id"
                component={MobileTranslationPage}
                argumentQuery={{ id: MOBILE_TRANSLATION_QUERY }}
              />
              <Redirect from="/(mobile?)" to="/(mobile?)/translations" />
              {/* Catering */}
              <PrivateRoute exact path="/catering" component={AllCateringsPage} />
              <PrivateRoute exact path="/catering/:cateringId" component={CateringPage} />
              {/* Applications */}
              <PrivateRoute exact path="/applications" component={ApplicationsPage} />

              {/* Other */}
              <PrivateRoute exact path="/dev-preview" component={DeveloperPreviewPage} />
              <PrivateRoute component={NotFoundPage} />
            </Switch>
          </PrivateLayout>
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
