import { gql } from 'apollo-boost';

export const MESSENGER_QUEUE_TASK_FRAGMENT = gql`
  fragment messengerQueueTask on MessengerQueueTask {
    id
    mailingId
    operation
    state
    itemsToProcess
    itemsProcessed
    itemsInvalid
    createdAt
    updatedAt
  }
`;
