import { gql } from 'apollo-boost';
import { TOURNAMENT_TOUR_FRAGMENT } from '../fragments/tournamentTour';

export const TOURNAMENT_TOUR_QUERY = gql`
  query tournamentTour($id: Int!) {
    tournamentTour(id: $id) {
      ...tournamentTour
    }
  }
  ${TOURNAMENT_TOUR_FRAGMENT}
`;
