export const getFromLocalStorage = (key: string, parse = true): string | null | any => {
  if (!window || !window.localStorage) {
    return null;
  }

  let result = window.localStorage.getItem(key);
  if (!result) {
    return null;
  }

  if (parse) {
    try {
      result = JSON.parse(result);
    } catch (e) {
      console.error(e);
    }
  }

  return result;
};
