export type PropertyName =
  | '--color-main-1'
  | '--color-main-2'
  | '--color-main-3'
  | '--color-contrast-1'
  | '--color-contrast-2'
  | '--color-contrast-3'
  | '--color-danger'
  | '--color-success'
  | '--color-warning'
  | '--color-primary'
  | '--color-shadow'
  | '--color-label';

export const getCssVariableValue = (variable: PropertyName): string => {
  const themeProvider = document.querySelector('.themeProvider');
  if (!themeProvider) {
    return '';
  }

  const result = getComputedStyle(themeProvider).getPropertyValue(variable);
  if (!result) {
    return '';
  }

  return result.trim();
};
