export const EVENTS = {
  FILTERS_CHANGED: 'FILTERS_CHANGED',
  LOGOUT: 'LOGOUT',
  STORAGE_CLEARED: 'STORAGE_CLEARED',
};

export const DEFAULT_PAGE_SIZE = 20;

export const FORMAT = {
  MODIFY_DATE_FULL: 'HH:mm, DD.MM.YYYY',
  FULL: 'DD MMMM YYYY HH:mm:ss',
};

export const SEARCH_SUBMIT_DELAY = 500;
