import { Moment } from 'moment';
import { FORMAT } from '../config/constants';
import { ColumnProps } from 'antd/es/table';
import { moment } from '../universal/helpers/getMoment';
import 'moment/locale/ru';
import { getActiveLocale } from '../universal/helpers/getActiveLocale';

export type RenderDateInput = string | number | Date | Moment;

interface IRenderDateConfig {
  format?: string;
}

export const renderDate = (input: RenderDateInput, config?: IRenderDateConfig): string => {
  const activeLocale = getActiveLocale();
  moment.locale(activeLocale.toLowerCase());

  const isValid = moment(input).isValid();
  if (!isValid) {
    return '';
  }

  if (config && config.format) {
    return moment(input).format(config.format);
  }

  const isSameYear = moment().format(FORMAT.YEAR) === moment(input).format(FORMAT.YEAR);
  const dateFormat = isSameYear ? FORMAT.DATE : FORMAT.DATE_WITH_YEAR;

  const date = moment(input).format(dateFormat);
  const time = moment(input).format(FORMAT.TIME_SHORT);

  return `${date}, ${time}`;
};

export const renderDatesRange = (
  inputFrom: RenderDateInput,
  inputTo: RenderDateInput,
  config?: IRenderDateConfig
): string => {
  if (!inputFrom || !inputTo) {
    return '';
  }

  const start = renderDate(inputFrom, config);
  const finish = renderDate(inputTo, config);

  return `${start} - ${finish}`;
};

export const getTableDateColumn = <TInstance>(key: keyof TInstance): ColumnProps<TInstance> => {
  return {
    key: String(key),
    render: date => renderDate(date),
  };
};
