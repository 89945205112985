import { gql } from 'apollo-boost';
import { EXTENSION_FIELD_FRAGMENT } from './extensionField';

export const EXTENSION_DATA_FRAGMENT = gql`
  fragment extensionData on ExtensionData {
    id
    fieldId
    localeId
    instanceId
    value
    key
    field {
      ...extensionField
    }
    createdAt
    updatedAt
  }
  ${EXTENSION_FIELD_FRAGMENT}
`;
