import { gql } from 'apollo-boost';
import { TOURNAMENT_DISQUALIFICATION_FRAGMENT_FULL } from '../fragments/tournamentDisqualification';

export const TOURNAMENT_DISQUALIFICATION_QUERY = gql`
  query tournamentDisqualification($id: Int!) {
    tournamentDisqualification(id: $id) {
      ...tournamentDisqualificationFull
    }
  }
  ${TOURNAMENT_DISQUALIFICATION_FRAGMENT_FULL}
`;
