import * as React from 'react';
import { Button, Drawer, Modal, Icon } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import './SettingsAction.css';
import { compose } from 'recompose';
import FiltersContextHOC from '../FiltersContextHOC/FiltersContextHOC';
import { IFiltersContextValue } from '../FiltersProvider/FiltersProvider';
import { ModalProps } from 'antd/es/modal';
import { DrawerProps } from 'antd/es/drawer';

export type CloseModalFn = () => void;

interface IExternalProps {
  type?: 'drawer' | 'modal'; // Modal by drfault
  buttonText?: React.ReactNode | null;
  icon?: string;
  buttonProps?: Omit<ButtonProps, 'onClick'>;
  modalProps?: Omit<ModalProps, 'visible'>;
  drawerProps?: Omit<DrawerProps, 'visible'>;
  renderContent?: (closeModalFn: CloseModalFn) => React.ReactNode;
  onClick?: () => void;
  onClose?: () => void;
}

export interface ISettingsActionProps extends IExternalProps {}

interface IProps extends IExternalProps {
  filtersContext: IFiltersContextValue;
}

interface IState {
  isPopOverVisible: boolean;
}

class SettingsAction extends React.Component<IProps, IState> {
  state: IState = {
    isPopOverVisible: false,
  };

  private openPopOver = () => {
    this.setState({ isPopOverVisible: true }, () => {
      const { onClick } = this.props;
      if (onClick) {
        onClick();
      }
    });
  };

  private closePopOver = () => {
    this.setState({ isPopOverVisible: false }, () => {
      const { onClose } = this.props;
      if (onClose) {
        onClose();
      }
    });
  };

  private handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();

    this.openPopOver();
  };

  private getText = () => {
    const { buttonText } = this.props;
    return buttonText || null;
  };

  private getButtonProps = (): ButtonProps => {
    const { buttonProps } = this.props;
    const className = buttonProps?.className ? `${buttonProps.className} settingsAction` : 'settingsAction';

    return {
      ...buttonProps,
      className: className,
      onClick: this.handleClick,
    };
  };

  private getModalProps = (): ModalProps => {
    const { modalProps } = this.props;
    const { isPopOverVisible } = this.state;

    return {
      ...modalProps,
      visible: isPopOverVisible,
      onCancel: this.closePopOver,
      footer: this.renderModalFooter(),
    };
  };

  private getDrawerProps = (): DrawerProps => {
    const { drawerProps } = this.props;
    const { isPopOverVisible } = this.state;

    return {
      width: '30%',
      ...drawerProps,
      bodyStyle: {
        height: '100%',
        ...drawerProps?.bodyStyle,
      },
      visible: isPopOverVisible,
      onClose: this.closePopOver,
    };
  };

  private renderPopOverContent = () => {
    const { renderContent } = this.props;
    if (!renderContent) {
      return null;
    }

    return renderContent(this.closePopOver);
  };

  private renderModalFooter = () => {
    const { modalProps } = this.props;
    const footer = modalProps && modalProps.footer;

    return footer || null;
  };

  private renderButton = () => {
    const { children, icon } = this.props;
    const text = this.getText();
    const buttonProps = this.getButtonProps();
    const type = icon || 'setting';

    const content = children || text || <Icon type={type} />;

    return <Button {...buttonProps}>{content}</Button>;
  };

  private renderModal = () => {
    const props = this.getModalProps();
    const content = this.renderPopOverContent();

    return <Modal {...props}>{content}</Modal>;
  };

  private renderDrawer = () => {
    const props = this.getDrawerProps();
    const content = this.renderPopOverContent();

    return <Drawer {...props}>{content}</Drawer>;
  };

  private renderPopOver = () => {
    const { type } = this.props;
    if (type === 'drawer') {
      return this.renderDrawer();
    }

    return this.renderModal();
  };

  private renderEssence = () => {
    return (
      <>
        {this.renderPopOver()}
        {this.renderButton()}
      </>
    );
  };

  private renderContent = () => {
    return this.renderEssence();
  };

  render() {
    return this.renderContent();
  }
}

export default compose<IProps, IExternalProps>(FiltersContextHOC({}))(SettingsAction);
