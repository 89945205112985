import React from 'react';
import { compose } from 'recompose';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import FiltersContextHOC from '../FiltersContextHOC/FiltersContextHOC';
import { IFiltersContextValue, TranslateHandler } from '../FiltersProvider/FiltersProvider';

const VALUE_ALL = 'ALL';

interface IExternalProps {
  enumValue: any;
  isAllSelectAvailable?: boolean;
  defaultValue?: string | null;
  selectProps?: SelectProps;
  onChange?: SelectProps['onChange'];
  sortOptions?: (valueA: string | number, valueB: string | number) => number;
}

interface IProps extends IExternalProps {
  filtersContext: IFiltersContextValue;
}

interface IState {}

class EnumSelect extends React.PureComponent<IProps, IState> {
  state: IState = {};

  private i18n: TranslateHandler = key => {
    const { i18n } = this.props.filtersContext;

    return i18n(key);
  };

  private getOptions = () => {
    const { isAllSelectAvailable, enumValue, sortOptions } = this.props;

    const values = [];
    if (isAllSelectAvailable) {
      values.push(VALUE_ALL);
    }

    const enumKeys = Object.keys(enumValue);
    const enumValues = enumKeys.map(key => enumValue[key]);
    values.push(...enumValues);

    if (sortOptions) {
      values.sort(sortOptions);
    }

    return values;
  };

  private getDefaultValue = () => {
    const { defaultValue, isAllSelectAvailable } = this.props;
    if (defaultValue) {
      return defaultValue;
    }

    if (isAllSelectAvailable) {
      return VALUE_ALL;
    }

    return undefined;
  };

  private renderOptions = () => {
    const options = this.getOptions();

    return options.map(option => {
      return (
        <Select.Option value={option} key={option}>
          {this.i18n(option)}
        </Select.Option>
      );
    });
  };

  render() {
    const { selectProps, onChange } = this.props;
    const defaultValue = this.getDefaultValue();

    return (
      <Select {...selectProps} onChange={onChange} defaultValue={defaultValue}>
        {this.renderOptions()}
      </Select>
    );
  }
}

export default compose<IProps, IExternalProps>(FiltersContextHOC())(EnumSelect);
