import React from 'react';
import { Editor, EditorState, ContentState, convertFromHTML, RichUtils } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import './RichEditor.css';
import FloatPanel from '../FloatPanel/FloatPanel';
import ButtonGroup from 'antd/es/button/button-group';
import { Button } from 'antd';
import { ButtonProps } from 'antd/es/button';
import { compose } from 'recompose';
import FiltersContextHOC from '../FiltersContextHOC/FiltersContextHOC';
import { IFiltersContextValue } from '../FiltersProvider/FiltersProvider';

interface IExternalProps {
  value: string;
  onChange?: (value: string) => void;
  isHTML?: boolean;
}

interface IProps extends IExternalProps {
  filtersContext: IFiltersContextValue;
}

export interface IRichEditorProps extends IProps {}

interface IState {
  editorState: EditorState;
  initialValue: IProps['value'];
  value: IProps['value'];
  editorMode: EditorMode;
}

enum EditorMode {
  CODE = 'CODE',
  TEXT = 'TEXT',
}

class RichEditor extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = this.getInitialState(props);
  }

  private getInitialState = (props: IProps): IState => {
    return {
      initialValue: props.value,
      value: props.value,
      editorState: RichEditor.createEditorStateFromProps(props),
      editorMode: props.isHTML ? EditorMode.CODE : EditorMode.TEXT,
    };
  };

  static createEditorStateFromProps = (props: IProps): EditorState => {
    // Create ContentState from provided value string
    const contentState = ContentState.createFromText(props.value);

    // Create new EditorState
    const editorState = EditorState.createWithContent(contentState);

    // If HTML return without transform
    if (props.value && props.isHTML) {
      return editorState;
    }

    return RichEditor.createEditorStateForMode(editorState, EditorMode.TEXT);
  };

  static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
    if (!prevState.value && nextProps.value) {
      return {
        value: nextProps.value,
        editorState: RichEditor.createEditorStateFromProps(nextProps),
      };
    }

    return null;
  }

  static createEditorStateForMode = (currentEditorState: EditorState, editorMode: EditorMode): EditorState => {
    if (editorMode === EditorMode.TEXT) {
      // Get contentState
      const currentContentState = currentEditorState.getCurrentContent();

      // Get plain HTML from contentState
      const html = currentContentState.getPlainText();
      if (!html) {
        return EditorState.createEmpty();
      }

      // Get building blocks
      const { contentBlocks, entityMap } = convertFromHTML(html);

      // Create ContentState
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

      // Create and return EditorState
      return EditorState.createWithContent(contentState);
    }

    if (editorMode === EditorMode.CODE) {
      // Get contentState
      const currentContentState = currentEditorState.getCurrentContent();

      const html = stateToHTML(currentContentState);
      const contentState = ContentState.createFromText(html);

      // Create new EditorState
      return EditorState.createWithContent(contentState);
    }

    return currentEditorState;
  };

  private handleChange = (editorState: EditorState) => {
    const contentState = editorState.getCurrentContent();
    const value = contentState.getPlainText();

    this.setState({ editorState, value }, () => {
      const { onChange } = this.props;
      if (onChange) {
        onChange(value);
      }
    });
  };

  private handleToggleBlockType = (blockType: any) => {
    const editorState = RichUtils.toggleBlockType(this.state.editorState, blockType);
    this.setState({ editorState });
  };

  private handleToggleInlineType = (inlineStyle: any) => {
    const editorState = RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle);
    this.setState({ editorState });
  };

  private handleChangeMode = (editorMode: EditorMode) => () => {
    this.setState({ editorMode }, () => {
      const { editorState: currentEditorState } = this.state;
      const editorState = RichEditor.createEditorStateForMode(currentEditorState, editorMode);

      this.handleChange(editorState);
    });
  };

  private renderEditorTypeControls = () => {
    const { i18n } = this.props.filtersContext;
    const { editorMode } = this.state;
    const isEditorInTextMode = editorMode === EditorMode.TEXT;

    const textBtnType: ButtonProps['type'] = isEditorInTextMode ? 'primary' : 'default';
    const htmlBtnType: ButtonProps['type'] = isEditorInTextMode ? 'default' : 'primary';

    return (
      <ButtonGroup>
        <Button type={textBtnType} onClick={this.handleChangeMode(EditorMode.TEXT)}>
          {i18n('action_text')}
        </Button>
        <Button type={htmlBtnType} onClick={this.handleChangeMode(EditorMode.CODE)}>
          {i18n('action_code')}
        </Button>
      </ButtonGroup>
    );
  };

  private renderBlockStyleControls = () => {
    const { editorState, editorMode } = this.state;
    if (editorMode === EditorMode.CODE) {
      return null;
    }

    return <BlockStyleControls editorState={editorState} onToggle={this.handleToggleBlockType} />;
  };

  private renderInlineStyleControls = () => {
    const { editorState, editorMode } = this.state;
    if (editorMode === EditorMode.CODE) {
      return null;
    }

    return <InlineStyleControls editorState={editorState} onToggle={this.handleToggleInlineType} />;
  };

  private renderContent = () => {
    return (
      <div>
        {this.renderBlockStyleControls()}
        {this.renderInlineStyleControls()}
        {this.renderEditor()}
        {this.renderEditorTypeControls()}
      </div>
    );
  };

  private renderEditor = () => {
    const { editorState } = this.state;

    return <Editor editorState={editorState} onChange={this.handleChange} />;
  };

  render() {
    return <FloatPanel>{this.renderContent()}</FloatPanel>;
  }
}

// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------
interface IStyleButtonProps {
  key: string;
  active: boolean;
  label: string;
  onToggle: Function;
  style: any;
}

class StyleButton extends React.Component<IStyleButtonProps> {
  private handleToggle = (e: React.MouseEvent<HTMLSpanElement>) => {
    const { onToggle, style } = this.props;

    e.preventDefault();

    onToggle(style);
  };

  private getClassName = () => {
    const classNames = ['RichEditor-styleButton'];

    if (this.props.active) {
      classNames.push('RichEditor-activeButton');
    }

    return classNames.join(' ');
  };

  render() {
    const className = this.getClassName();
    const { label } = this.props;

    return (
      <span className={className} onMouseDown={this.handleToggle}>
        {label}
      </span>
    );
  }
}

const BlockStyleControls = (props: any) => {
  const BLOCK_TYPES = [
    { label: 'H1', style: 'header-one' },
    { label: 'H2', style: 'header-two' },
    { label: 'H3', style: 'header-three' },
    { label: 'H4', style: 'header-four' },
    { label: 'H5', style: 'header-five' },
    { label: 'H6', style: 'header-six' },
    { label: 'Blockquote', style: 'blockquote' },
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
    { label: 'Code Block', style: 'code-block' },
  ];

  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map(type => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

const InlineStyleControls = (props: any) => {
  const INLINE_STYLES = [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
    { label: 'Monospace', style: 'CODE' },
  ];
  const currentStyle = props.editorState.getCurrentInlineStyle();

  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

export default compose<IProps, IExternalProps>(FiltersContextHOC({}))(RichEditor);
