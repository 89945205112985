import { gql } from 'apollo-boost';
import { TEMPLATE_APPLE_PASS_FRAGMENT } from '../fragments/templateApplePass';

export const TEMPLATE_APPLE_PASS_QUERY = gql`
  query templateApplePass($id: Int!) {
    templateApplePass(id: $id) {
      ...templateApplePass
    }
  }
  ${TEMPLATE_APPLE_PASS_FRAGMENT}
`;
