import { gql } from 'apollo-boost';
import { USER_DEVICE_FRAGMENT } from './userDevice';
import { EXTENSION_DATA_FRAGMENT } from './extensionData';
import { USER_GROUP_FRAGMENT } from './userGroup';

export const USER_FLAT_FRAGMENT = gql`
  fragment userFlat on User {
    id
    uuid
    state
    username
    firstName
    lastName
    middleName
    birthday
    email
    phone
    gender
    isVerifiedEmail
    isVerifiedPhone
    createdAt
    updatedAt
  }
`;

export const USER_FRAGMENT = gql`
  fragment user on User {
    ...userFlat
    devices {
      ...userDevice
    }
    analytics {
      ...extensionData
    }
    data {
      ...extensionData
    }
    groups {
      ...userGroup
    }
  }
  ${USER_FLAT_FRAGMENT}
  ${USER_DEVICE_FRAGMENT}
  ${EXTENSION_DATA_FRAGMENT}
  ${USER_GROUP_FRAGMENT}
`;

export const USER_SIMPLE_FRAGMENT = gql`
  fragment userSimple on User {
    id
    firstName
    lastName
    middleName
  }
`;
