import { gql } from 'apollo-boost';
import { COMMERCE_ORDER_FRAGMENT } from './commerceOrder';

export const COMMERCE_ORDER_CONNECTION_FRAGMENT = gql`
  fragment commerceOrderConnection on CommerceOrderConnection {
    total
    limit
    cursor
    searchable
    sortable
    edges {
      ...commerceOrder
    }
  }
  ${COMMERCE_ORDER_FRAGMENT}
`;
