import { gql } from 'apollo-boost';
import { SYSTEM_TRANSLATION_FRAGMENT } from '../fragments/systemTranslation';

export const CREATE_SYSTEM_TRANSLATION_MUTATION = gql`
  mutation createSystemTranslation($data: SystemTranslationCreateInput!) {
    createSystemTranslation(data: $data) {
      ...systemTranslation
    }
  }
  ${SYSTEM_TRANSLATION_FRAGMENT}
`;
