import { i18n } from './I18n';

export const getFullName = (elem: any): string => {
  const fallback = i18n('untitled');

  if (!elem) {
    return fallback;
  }

  if (elem.fullName) {
    return elem.fullName;
  }

  const firstName = elem.firstName && elem.firstName.trim();
  const lastName = elem.lastName && elem.lastName.trim();

  if (!firstName && !lastName) {
    return fallback;
  }

  return [lastName, firstName].join(' ');
};
