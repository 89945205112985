import { gql } from 'apollo-boost';
import { PAYMENT_ACCOUNT_CONNECTION_FRAGMENT } from '../fragments/paymentAccountConnection';

export const ALL_PAYMENT_ACCOUNTS_QUERY = gql`
  query allPaymentAccounts($query: DefaultQueryInput) {
    allPaymentAccounts(query: $query) {
      ...paymentAccountConnection
    }
  }
  ${PAYMENT_ACCOUNT_CONNECTION_FRAGMENT}
`;
