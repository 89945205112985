import { gql } from 'apollo-boost';
import { LOCALE_FRAGMENT } from './locale';
import { CURRENCY_FRAGMENT } from './currency';
import { EXTENSION_DATA_FRAGMENT } from './extensionData';

export const CATALOGUE_FLAT_FRAGMENT = gql`
  fragment catalogueFlat on Catalogue {
    id
    clientId
    state
    name
    description
    localeIdx
    preview
    currencyIdx
    createdAt
    updatedAt
  }
`;

export const CATALOGUE_FRAGMENT = gql`
  fragment catalogue on Catalogue {
    ...catalogueFlat
    locales {
      ...locale
    }
    currencies {
      ...currency
    }
  }
  ${CATALOGUE_FLAT_FRAGMENT}
  ${LOCALE_FRAGMENT}
  ${CURRENCY_FRAGMENT}
`;

export const CATALOGUE_FULL_FRAGMENT = gql`
  fragment catalogueFull on Catalogue {
    ...catalogueFlat
    locales {
      ...locale
    }
    currencies {
      ...currency
    }
    data {
      ...extensionData
    }
  }
  ${CATALOGUE_FLAT_FRAGMENT}
  ${LOCALE_FRAGMENT}
  ${CURRENCY_FRAGMENT}
  ${EXTENSION_DATA_FRAGMENT}
`;
