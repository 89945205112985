import React from 'react';
import { compose } from 'recompose';
import UForm, { FormComponent, IFormField, UFormState } from '../../universal/components/UForm/UForm';
import { handleFail, handleSuccess, OPERATIONS } from '../../helpers/handleMutation';
import { getMutateProps } from '../../helpers/mutationOperationOptions';
import { ExecutionResult } from 'graphql';
import {
  CreateDisputeMutation,
  CreateDisputeProps,
  DefaultQueryInput,
  DisputeConfirmation,
  DisputeCreateInput,
  DisputeFragment,
  DisputeProps,
  DisputeResult,
  DisputeState,
  DisputeUpdateInput,
  SortingDirection,
  UpdateDisputeMutation,
  UpdateDisputeProps,
  User,
  withCreateDispute,
  withDispute,
  withUpdateDispute,
} from '../../typings/graphql';
import { ALL_DISPUTES_QUERY } from '../../graph/queries/allDisputes';
import { getErrorFromProps, getLoadingFromProps } from '../../helpers/getInfoFromProps';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Rcm, { RcmProps } from '../Rcm/Rcm';
import { ALL_USERS_QUERY } from '../../graph/queries/allUsers';
import { getQueryNameFromQuery } from '../../helpers/getQueryNameFromQuery';
import { renderTournamentMatchOption, renderUserOption } from '../../helpers/renderOption';
import { ALL_TOURNAMENT_MATCHES_QUERY } from '../../graph/queries/allTournamentMatches';
import { FilterDataType } from '../../universal/typings/graphql';

type Elem = DisputeFragment;

interface IExternalProps {
  simulators: User['id'][];

  instanceId?: Elem['id'] | null;
  instance?: Elem | null;
  onCreate?: (entity: Elem) => void;
  onUpdate?: (entity: Elem) => void;
  onDelete?: (entity: Elem) => void;
  rcmProps?: Partial<RcmProps>;
}

interface IProps extends IExternalProps, RouteComponentProps {
  dispute?: DisputeProps['data'];

  create: CreateDisputeProps;
  update: UpdateDisputeProps;
}

interface IState {}

class DisputeForm extends React.PureComponent<IProps, IState> {
  private getInstanceId = () => {
    const { instanceId, instance } = this.props;
    if (instanceId) {
      return instanceId;
    }

    if (instance && instance.id) {
      return instance.id;
    }

    return null;
  };

  private handleUpdateSuccess = (res: ExecutionResult<UpdateDisputeMutation>) => {
    const updated = res && res.data && res.data.updateDispute;
    if (!updated) {
      return handleFail(OPERATIONS.UPDATE)();
    }

    handleSuccess(OPERATIONS.UPDATE)();

    const { onUpdate } = this.props;
    if (onUpdate) {
      onUpdate(updated);
    }
  };

  private handleUpdate = (formState: UFormState) => {
    const instanceId = this.getInstanceId();
    if (!instanceId) {
      return handleFail(OPERATIONS.UPDATE)();
    }

    this.props.update
      .mutate({
        variables: {
          id: instanceId,
          data: formState as DisputeUpdateInput,
        },
        refetchQueries: [getQueryNameFromQuery(ALL_DISPUTES_QUERY)],
      })
      .then(this.handleUpdateSuccess)
      .catch(handleFail(OPERATIONS.UPDATE));
  };

  private handleCreateSuccess = (res: ExecutionResult<CreateDisputeMutation>) => {
    const created = res && res.data && res.data.createDispute;
    if (!created) {
      return handleFail(OPERATIONS.CREATE)();
    }

    handleSuccess(OPERATIONS.CREATE)();

    const { onCreate } = this.props;
    if (onCreate) {
      onCreate(created);
    }
  };

  private handleCreate = (formState: UFormState) => {
    this.props.create
      .mutate({
        variables: {
          data: formState as DisputeCreateInput,
        },
        refetchQueries: [getQueryNameFromQuery(ALL_DISPUTES_QUERY)],
      })
      .then(this.handleCreateSuccess)
      .catch(handleFail(OPERATIONS.CREATE));
  };

  private isNew = () => this.getInstanceId() === null;

  private handleSubmit = (formState: UFormState, changes: UFormState) => {
    const instanceId = this.getInstanceId();
    if (!instanceId) {
      return this.handleCreate(formState);
    }

    return this.handleUpdate(changes);
  };

  private getInstance = (): Elem | null => {
    const { dispute, instance } = this.props;
    if (instance) {
      return instance;
    }

    if (!dispute || !dispute.dispute) {
      return null;
    }

    return dispute.dispute;
  };

  private getUserIdField = (): IFormField => {
    const isNew = this.isNew();

    const queryInput: DefaultQueryInput = {};

    if (isNew) {
      queryInput.filters = [
        {
          field: 'id',
          value: JSON.stringify(this.props.simulators),
        },
      ];
    }

    return {
      field: 'userId',
      component: FormComponent.SELECT,
      optionsQuery: {
        query: ALL_USERS_QUERY,
        queryName: getQueryNameFromQuery(ALL_USERS_QUERY),
        render: renderUserOption,
        variables: {
          query: queryInput,
        },
        hideAdvancedSelector: true,
      },
      isDisabled: !isNew,
    };
  };

  private getMatchIdField = (): IFormField => {
    const isNew = this.isNew();

    const field: IFormField = {
      field: 'matchId',
      component: FormComponent.SELECT,
      optionsQuery: {
        query: ALL_TOURNAMENT_MATCHES_QUERY,
        queryName: getQueryNameFromQuery(ALL_TOURNAMENT_MATCHES_QUERY),
        render: renderTournamentMatchOption,
      },
      isDisabled: !isNew,
    };

    if (isNew) {
      const now = new Date().toISOString();

      field.optionsQuery!.variables = {
        query: {
          sort: [
            {
              field: 'startDate',
              direction: SortingDirection.ASC,
            },
          ],
          filters: [
            {
              field: 'startDate',
              value: JSON.stringify([now]),
              isRange: true,
            },
          ],
        } as DefaultQueryInput,
      };
    }

    return field;
  };

  private getFields = (): IFormField[] => {
    const isNew = this.isNew();

    return [
      {
        field: 'text',
        component: FormComponent.INPUT,
        type: FilterDataType.STRING,
      },
      {
        field: 'amount',
        component: FormComponent.INPUT,
        type: FilterDataType.NUMBER,
        isDisabled: !isNew,
      },
      this.getUserIdField(),
      this.getMatchIdField(),
      {
        field: 'state',
        component: FormComponent.SELECT,
        enumValue: DisputeState,
        isDisabled: true,
        isVisible: !isNew,
      },
      {
        field: 'result',
        component: FormComponent.SELECT,
        enumValue: DisputeResult,
        isDisabled: true,
        isVisible: !isNew,
      },
      {
        field: 'confirmation',
        component: FormComponent.SELECT,
        enumValue: DisputeConfirmation,
        isDisabled: true,
        isVisible: !isNew,
      },
    ];
  };

  private isPending = (): boolean => {
    const { isLoading, isMutating } = getLoadingFromProps(this.props);
    return isLoading || isMutating;
  };

  private getError = () => {
    return getErrorFromProps(this.props).error;
  };

  private renderEssence = () => {
    const fields = this.getFields();
    const instance = this.getInstance();
    const isLoading = this.isPending();
    const error = this.getError();

    return (
      <UForm fields={fields} instance={instance} isLoading={isLoading} error={error} onSubmit={this.handleSubmit} />
    );
  };

  private renderRcm = () => {
    const { rcmProps } = this.props;
    return <Rcm isWrapped renderEssence={this.renderEssence} {...rcmProps} />;
  };

  render() {
    return this.renderRcm();
  }
}

export default compose<IProps, IExternalProps>(
  withRouter,
  withCreateDispute(getMutateProps('create')),
  withUpdateDispute(getMutateProps('update')),
  withDispute<IProps>({
    name: 'dispute',
    skip: props => !props.instanceId, // Skip if ID is not provided
    options: props => ({
      variables: {
        id: props.instanceId!,
      },
    }),
  })
)(DisputeForm);
