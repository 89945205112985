import { gql } from 'apollo-boost';
import { COMMERCE_ORDER_ITEM_FRAGMENT } from './commerceOrderItem';

export const COMMERCE_ORDER_ITEM_CONNECTION_FRAGMENT = gql`
  fragment commerceOrderItemConnection on CommerceOrderItemConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...commerceOrderItem
    }
  }
  ${COMMERCE_ORDER_ITEM_FRAGMENT}
`;
