import { gql } from 'apollo-boost';

export const TEAM_HISTORY_FRAGMENT = gql`
  fragment teamHistory on TournamentTeamHistory {
    id
    field
    value
    translationId
    createdAt
    updatedAt
  }
`;
