import { gql } from 'apollo-boost';
import { ARTICLE_CATEGORY_FRAGMENT } from './articleCategory';
import { ARTICLE_BLOCK_FRAGMENT } from './articleBlock';
import { ARTICLE_AUTHOR_FRAGMENT } from './articleAuthor';
import { ARTICLE_LINK_FRAGMENT } from './articleLink';
import { ARTICLE_LOG_FRAGMENT } from './articleLog';
import { SYSTEM_LOCALE_FRAGMENT } from './systemLocale';

export const ARTICLE_FRAGMENT = gql`
  fragment article on Article {
    id
    clientId
    categoryId
    state
    tags
    localeId
    locale {
      ...systemLocale
    }
    createdAt
    publishedAt
    title
    authors {
      ...articleAuthor
    }
  }
  ${SYSTEM_LOCALE_FRAGMENT}
  ${ARTICLE_AUTHOR_FRAGMENT}
`;

export const ARTICLE_FRAGMENT_FULL = gql`
  fragment articleFull on Article {
    ...article
    category {
      ...articleCategory
    }
    blocks {
      ...articleBlock
    }
    preview {
      ...articleBlock
    }
    authors {
      ...articleAuthor
    }
    links {
      ...articleLink
    }
    log {
      ...articleLog
    }
  }
  ${ARTICLE_CATEGORY_FRAGMENT}
  ${ARTICLE_BLOCK_FRAGMENT}
  ${ARTICLE_AUTHOR_FRAGMENT}
  ${ARTICLE_LINK_FRAGMENT}
  ${ARTICLE_LOG_FRAGMENT}
  ${ARTICLE_FRAGMENT}
`;
