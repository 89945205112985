import React from 'react';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import { i18n } from '../../helpers/I18n';
import FloatPanel from '../FloatPanel/FloatPanel';
import { IParamsWithId } from '../../typings/local';
import {
  CommerceShopCreateInput,
  CommerceShopProps,
  CommerceShopState,
  CommerceShopUpdateInput,
  CreateCommerceShopMutation,
  CreateCommerceShopProps,
  DeleteCommerceShopProps,
  FilterDataType,
  UpdateCommerceShopProps,
  withCommerceShop,
  withCreateCommerceShop,
  withDeleteCommerceShop,
  withUpdateCommerceShop,
} from '../../typings/graphql';
import { getMutateProps } from '../../helpers/mutationOperationOptions';
import UForm, { FormComponent, IFormField, UFormState } from '../../universal/components/UForm/UForm';
import { ALL_CATALOGUES_QUERY } from '../../graph/queries/allCatalogues';
import { ALL_PAYMENT_CURRENCIES_QUERY } from '../../graph/queries/allPaymentCurrencies';
import { handleFail, handleSuccess, OPERATIONS } from '../../helpers/handleMutation';
import { parseJSON } from '../../helpers/parseJSON';
import { ExecutionResult } from 'graphql';
import { withRouter } from 'react-router-dom';

interface IExternalProps {
  id?: string;
  isWrapped?: boolean;
  onCreateSuccess?: () => void;
  onUpdateSuccess?: () => void;
}

interface IProps extends IExternalProps, RouteComponentProps<IParamsWithId> {
  commerceShop?: CommerceShopProps['data'];

  delete: DeleteCommerceShopProps;
  update: UpdateCommerceShopProps;
  create: CreateCommerceShopProps;
}

interface IState {
  showFormModal: boolean;
}

class CommerceShopForm extends React.PureComponent<IProps, IState> {
  state: IState = {
    showFormModal: false,
  };

  private isNew = () => this.props.id === 'new';

  private handleCreateSuccess = (res: ExecutionResult<CreateCommerceShopMutation>) => {
    if (!res || !res.data || !res.data.createCommerceShop) {
      return handleFail(OPERATIONS.CREATE)();
    }

    const mutationResult: CreateCommerceShopMutation = res.data;
    const created = mutationResult.createCommerceShop;
    if (!created || !created.id) {
      return handleFail(OPERATIONS.CREATE)();
    }

    const { onCreateSuccess } = this.props;
    if (onCreateSuccess) {
      onCreateSuccess();
    }
    this.props.history.push(`/commerce/shops/${created.id}`);
  };

  private handleCreate = (formState: UFormState) => {
    const { create } = this.props;

    const data = this.getPayloadFromFormState(formState) as CommerceShopCreateInput;

    create
      .mutate({
        variables: {
          data: data,
        },
      })
      .then(this.handleCreateSuccess)
      .catch(handleFail(OPERATIONS.CREATE));
  };

  private handleUpdateSuccess = () => {
    const { onUpdateSuccess } = this.props;
    handleSuccess(OPERATIONS.UPDATE);
    if (onUpdateSuccess) {
      onUpdateSuccess();
    }
  };

  private getPayloadFromFormState = (formState: UFormState) => {
    const { settings, minOrderPrice, ...payload } = formState;

    if (minOrderPrice !== undefined && minOrderPrice !== null) {
      payload.settings = JSON.stringify(
        {
          ...parseJSON(settings),
          minOrderPrice: minOrderPrice,
        },
        null,
        2
      );
    }

    return payload;
  };

  private handleUpdate = (formState: UFormState) => {
    const { update, id } = this.props;

    const data: CommerceShopUpdateInput = this.getPayloadFromFormState(formState);

    update
      .mutate({
        variables: {
          id: Number(id),
          data: data,
        },
      })
      .then(this.handleUpdateSuccess)
      .catch(handleFail(OPERATIONS.UPDATE));
  };

  private handleSubmit = (formState: UFormState) => {
    if (this.isNew()) {
      return this.handleCreate(formState);
    }

    return this.handleUpdate(formState);
  };

  private isPending = (): boolean => {
    const { commerceShop } = this.props;
    const isLoading = commerceShop && commerceShop.loading;

    const { loading: isUpdating } = this.props.update.result;
    const { loading: isCreating } = this.props.create.result;
    const { loading: isDeleting } = this.props.delete.result;

    return isLoading || isUpdating || isCreating || isDeleting;
  };

  private getShop = () => {
    const { commerceShop } = this.props;
    if (!commerceShop) {
      return null;
    }

    return commerceShop.commerceShop || null;
  };

  private getInstance = () => {
    const shop = this.getShop();
    if (!shop) {
      return null;
    }

    let minOrderPrice: number | undefined;

    const settings = parseJSON(shop.settings);
    if (settings) {
      minOrderPrice = settings.minOrderPrice;
    }

    return {
      ...shop,
      minOrderPrice,
    };
  };

  private getFields = () => {
    const commonFields: IFormField[] = [
      {
        field: 'state',
        component: FormComponent.SELECT,
        enumValue: CommerceShopState,
        isRequired: true,
      },
      {
        field: 'name',
        component: FormComponent.INPUT,
        isRequired: true,
      },
      {
        field: 'description',
        component: FormComponent.INPUT,
      },
      {
        field: 'codeMask',
        component: FormComponent.INPUT,
      },
      {
        field: 'catalogueId',
        component: FormComponent.SELECT,
        optionsQuery: {
          query: ALL_CATALOGUES_QUERY,
          queryName: 'allCatalogues',
        },
        isRequired: true,
      },
      {
        field: 'currencyId',
        component: FormComponent.SELECT,
        optionsQuery: {
          query: ALL_PAYMENT_CURRENCIES_QUERY,
          queryName: 'allPaymentCurrencies',
        },
        type: FilterDataType.NUMBER,
      },
      {
        field: 'saleEnabled',
        component: FormComponent.CHECKBOX,
      },
      {
        field: 'minOrderPrice',
        component: FormComponent.INPUT,
        type: FilterDataType.NUMBER,
      },
    ];

    return commonFields;
  };

  private getError = () => {
    const { commerceShop } = this.props;
    const loadingError = commerceShop && commerceShop.error;

    const { error: errorUpdating } = this.props.update.result;
    const { error: errorCreating } = this.props.create.result;
    const { error: errorDeleting } = this.props.delete.result;

    return loadingError || errorCreating || errorUpdating || errorDeleting;
  };

  private renderForm = () => {
    const error = this.getError();
    const fields = this.getFields();
    const instance = this.getInstance();
    const isLoading = this.isPending();

    return (
      <UForm fields={fields} instance={instance} error={error} isLoading={isLoading} onSubmit={this.handleSubmit} />
    );
  };

  private renderContent = () => {
    return this.renderForm();
  };

  private renderWrapper = () => {
    const { isWrapped } = this.props;
    if (!isWrapped) {
      return this.renderContent();
    }

    const title = i18n('h_shop');

    return <FloatPanel title={title}>{this.renderContent}</FloatPanel>;
  };

  render() {
    return this.renderWrapper();
  }
}

export default compose<IProps, IExternalProps>(
  withRouter,
  withCreateCommerceShop(getMutateProps('create')),
  withDeleteCommerceShop(getMutateProps('delete')),
  withUpdateCommerceShop(getMutateProps('update')),
  withCommerceShop<IProps>({
    name: 'commerceShop',
    skip: props => props.id === 'new',
    options: props => ({
      variables: {
        id: Number(props.id),
      },
    }),
  })
)(CommerceShopForm);
