import { gql } from 'apollo-boost';
import { EVENT_FULL_FRAGMENT } from './event';

export const EVENT_CONNECTION_FRAGMENT = gql`
  fragment eventConnection on EventConnection {
    total
    limit
    cursor
    sortable
    searchable
    edges {
      ...eventFull
    }
  }
  ${EVENT_FULL_FRAGMENT}
`;
