import { FormComponent, IFormField, UFormState } from '../components/UForm/UForm';
import { EntityDataType, ExtensionFieldFragment, FilterDataType, Maybe, Scalars } from '../typings/graphql';

export type ExtensionData = {
  __typename?: 'ExtensionData';
  id: Scalars['Int'];
  fieldId: Scalars['Int'];
  localeId?: Maybe<Scalars['Int']>;
  instanceId: Scalars['Int'];
  value?: Maybe<Scalars['JSON']>;
  key: Scalars['String'];
  field: ExtensionField;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ExtensionField = {
  __typename?: 'ExtensionField';
  id: Scalars['Int'];
  relationId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  dataType: EntityDataType;
  isReadOnly: Scalars['Boolean'];
  sort: Scalars['Int'];
  translations: Array<ExtensionFieldTranslation>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ExtensionFieldTranslation = {
  __typename?: 'ExtensionFieldTranslation';
  id: Scalars['Int'];
  localeId: Scalars['Int'];
  fieldId: Scalars['Int'];
  value: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ExtensionUpdateDataInput = {
  key?: Scalars['String'];
  fieldId?: Scalars['Int'];
  localeId?: Scalars['Int'];
  value?: Scalars['String'];
};

const defaultFieldIndex = 'extensionField';

class Extension {
  static convertFilesToUFormField = (extensionField: ExtensionField): IFormField => {
    return {
      field: extensionField.name || defaultFieldIndex,
      component: FormComponent.UPLOAD,
    };
  };

  static convertArrayToUFormField = (extensionField: ExtensionField): IFormField => {
    return {
      field: extensionField.name || defaultFieldIndex,
      component: FormComponent.SELECT,
      isMultiSelect: true,
      selectOptionsConfig: {
        options: [],
      },
    };
  };

  static convertNumberToUFormField = (extensionField: ExtensionField): IFormField => {
    return {
      field: extensionField.name || defaultFieldIndex,
      component: FormComponent.INPUT,
      type: FilterDataType.NUMBER,
    };
  };

  static convertStringToUFormField = (extensionField: ExtensionField): IFormField => {
    return {
      field: extensionField.name || defaultFieldIndex,
      component: FormComponent.INPUT,
      type: FilterDataType.STRING,
    };
  };

  static convertEnumToUFormField = (extensionField: ExtensionField): IFormField => {
    return {
      field: extensionField.name || defaultFieldIndex,
      component: FormComponent.INPUT,
      type: FilterDataType.STRING,
    };
  };

  static convertBooleanToUFormField = (extensionField: ExtensionField): IFormField => {
    return {
      field: extensionField.name || defaultFieldIndex,
      component: FormComponent.CHECKBOX,
    };
  };

  static getFormFieldConfigByExtensionField = (extensionField: ExtensionField): IFormField | null => {
    if (!extensionField || !extensionField.dataType) {
      return null;
    }

    const { dataType } = extensionField;

    if (dataType === 'FILES') {
      return Extension.convertFilesToUFormField(extensionField);
    }

    if (dataType === 'ARRAY') {
      return Extension.convertArrayToUFormField(extensionField);
    }

    if (dataType === 'NUMBER') {
      return Extension.convertNumberToUFormField(extensionField);
    }

    if (dataType === 'STRING') {
      return Extension.convertStringToUFormField(extensionField);
    }

    if (dataType === 'ENUM') {
      return Extension.convertEnumToUFormField(extensionField);
    }

    if (dataType === 'BOOLEAN') {
      return Extension.convertBooleanToUFormField(extensionField);
    }

    return null;
  };

  static getUpdateDataInput = (
    extensionData: UFormState,
    extensionFields?: ExtensionFieldFragment[]
  ): ExtensionUpdateDataInput[] => {
    const data: ExtensionUpdateDataInput[] = [];
    const fields = extensionFields || [];

    for (const field of fields) {
      const fieldName = field.name;
      if (!fieldName) {
        continue;
      }

      let value = extensionData[fieldName];
      if (value === undefined) {
        continue;
      }

      // Number, booleans, Objects, Arrays will be converted to JSON string
      // Plain string will be ignored
      if (typeof value !== 'string' && value !== null) {
        value = JSON.stringify(value);
      }

      data.push({
        key: fieldName,
        value: value,
        fieldId: field.id,
      });
    }

    return data;
  };
}

export default Extension;
