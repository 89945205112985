import { gql } from 'apollo-boost';
import { MOBILE_TRANSLATION_FRAGMENT } from '../fragments/mobileTranslation';

export const MOBILE_TRANSLATION_QUERY = gql`
  query mobileTranslation($id: Int!) {
    mobileTranslation(id: $id) {
      ...mobileTranslation
    }
  }
  ${MOBILE_TRANSLATION_FRAGMENT}
`;
