import { gql } from 'apollo-boost';
import { TOURNAMENT_TEAM_MEMBER_FULL_FRAGMENT } from './tournamentTeamMember';
import { TOURNAMENT_FRAGMENT } from './tournament';

export const TOURNAMENT_DISQUALIFICATION_FRAGMENT = gql`
  fragment tournamentDisqualification on TournamentDisqualification {
    id
    playerId
    reason
    tournamentId
    resolutionDate
    disqualificationValue
    leftMatches
    state
    createdAt
    updatedAt
  }
`;

export const TOURNAMENT_DISQUALIFICATION_FRAGMENT_FULL = gql`
  fragment tournamentDisqualificationFull on TournamentDisqualification {
    id
    playerId
    reason
    tournamentId
    resolutionDate
    disqualificationValue
    leftMatches
    state
    player {
      ...tournamentTeamMemberFull
    }
    tournament {
      ...tournament
    }
    createdAt
    updatedAt
  }
  ${TOURNAMENT_TEAM_MEMBER_FULL_FRAGMENT}
  ${TOURNAMENT_FRAGMENT}
`;
