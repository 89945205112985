import React from 'react';

import './InlineUser.scss';
import Rcm, { RcmProps } from '../Rcm/Rcm';
import { User } from '../../typings/graphql';
import InlineLink from '../InlineLink/InlineLink';
import { getFullName } from '../../helpers/getFullName';
import { i18n } from '../../helpers/I18n';

interface IExternalProps {
  rcmProps?: Partial<RcmProps>;
  user?: Partial<User>;
  userId?: User['id'] | null;
}

interface IProps extends IExternalProps {}

interface IState {}

class InlineUser extends React.PureComponent<IProps, IState> {
  private getText = () => {
    const { user, userId } = this.props;

    const fullName = getFullName(user);
    if (fullName) {
      return fullName;
    }

    if (userId !== null && userId !== undefined) {
      return String(userId);
    }

    return i18n('untitled');
  };

  private getPath = (): string | undefined => {
    const { user, userId } = this.props;
    let id: string | number | null = null;

    if (userId !== null && userId !== undefined) {
      id = userId;
    }

    if (user && user.id !== null && user.id !== undefined) {
      id = user.id;
    }

    if (id === null) {
      return;
    }

    return `/users/${id}`;
  };

  private renderEssence = () => {
    const text = this.getText();
    const to = this.getPath();

    return <InlineLink text={text} to={to} />;
  };

  private renderRcm = () => {
    const { rcmProps } = this.props;
    return <Rcm renderEssence={this.renderEssence} {...rcmProps} />;
  };

  render() {
    return this.renderRcm();
  }
}

export default InlineUser;
