import { message } from 'antd';
import { i18n } from './I18n';

export const OPERATIONS = {
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete',
};

const duration = 0.5;

export const handleFail = (operation: string) => () => {
  return message.error(i18n(`${operation}_fail`), duration);
};

export const handleSuccess = (operation: string) => () => {
  return message.success(i18n(`${operation}_success`), duration);
};
