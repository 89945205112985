import React from 'react';

import Page from '../Page/Page';
import Loader from '../Loader/Loader';
import { i18n } from '../../helpers/I18n';

const SuspenseFallback = () => (
  <Page>
    <Page.Content>
      <div className="container container--center">
        <Loader text={i18n('loading_app')} />
      </div>
    </Page.Content>
  </Page>
);

export default SuspenseFallback;
