import React from 'react';

import './Overlay.scss';

interface IExternalProps {
  content?: React.ReactNode | null;
  isVisible?: boolean; // true by default
}

export interface IOverlayProps extends IExternalProps {}

interface IProps extends IExternalProps {}

interface IState {}

class Overlay extends React.PureComponent<IProps, IState> {
  private getClassName = () => {
    const className = 'overlay_mask';
    const classNames = [className];

    const { isVisible } = this.props;
    if (isVisible === false) {
      classNames.push(`${className}--hidden`);
    } else {
      classNames.push(`${className}--visible`);
    }

    return classNames.join(' ');
  };

  private renderOverlay = () => {
    const { content } = this.props;
    const className = this.getClassName();

    return <div className={className}>{content}</div>;
  };

  private renderElements = () => {
    const { children } = this.props;

    return (
      <div className="overlay_wrapper">
        {children}
        {this.renderOverlay()}
      </div>
    );
  };

  render() {
    return this.renderElements();
  }
}

export default Overlay;
