import { gql } from 'apollo-boost';

export const TEMPLATE_APPLE_PASS_FRAGMENT = gql`
  fragment templateApplePass on TemplateApplePass {
    id
    clientId
    preview
    name
    pass
    files
    certificates
    createdAt
    updatedAt
  }
`;
