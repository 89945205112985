import { gql } from 'apollo-boost';
import { SYSTEM_LOCALE_CONNECTION_FRAGMENT } from '../fragments/systemLocaleConnection';

export const ALL_SYSTEM_LOCALES_QUERY = gql`
  query allSystemLocales($query: DefaultQueryInput) {
    allSystemLocales(query: $query) {
      ...systemLocaleConnection
    }
  }
  ${SYSTEM_LOCALE_CONNECTION_FRAGMENT}
`;
