import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import configureApollo from '../../config/configureApollo';
import Routes from '../Routes/Routes';
import TranslationProvider from '../TranslationProvider/TranslationProvider';
import SystemUserProfileLoader from '../UserProfileLoader/UserProfileLoader';
import ThemeProvider from '../ThemeProvider/ThemeProvider';
import { FiltersProvider } from '../../universal/index';
import { i18n } from '../../helpers/I18n';
import { getFiltersProviderInitialStates } from '../../helpers/getFiltersProviderInitialStates';
import AntConfigProvider from '../AntConfigProvider/AntConfigProvider';
import { getFromLocalStorage } from '../../helpers/getFromLocalStorage';
import DevelopmentWarning from '../DevelopmentWarning/DevelopmentWarning';

const client = configureApollo();
const initialStates = getFiltersProviderInitialStates();

const App: React.FC = () => {
  const filtersConfig = { i18n, getMediaServiceToken: () => getFromLocalStorage('token', true) };

  return (
    <ApolloProvider client={client}>
      <TranslationProvider>
        <SystemUserProfileLoader>
          <FiltersProvider initialStates={initialStates} config={filtersConfig}>
            <ThemeProvider>
              <AntConfigProvider>
                <Routes />
                <DevelopmentWarning />
              </AntConfigProvider>
            </ThemeProvider>
          </FiltersProvider>
        </SystemUserProfileLoader>
      </TranslationProvider>
    </ApolloProvider>
  );
};

export default App;
