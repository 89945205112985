import { gql } from 'apollo-boost';
import { COMMERCE_ORDER_FRAGMENT } from '../fragments/commerceOrder';

export const COMMERCE_ORDER_QUERY = gql`
  query commerceOrder($id: Int!) {
    commerceOrder(id: $id) {
      ...commerceOrder
    }
  }
  ${COMMERCE_ORDER_FRAGMENT}
`;
