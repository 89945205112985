import { gql } from 'apollo-boost';
import { EXTENSION_DATA_FRAGMENT } from './extensionData';

export const TOURNAMENT_TEAM_MEMBER_SMALL_FRAGMENT = gql`
  fragment tournamentTeamMemberSmall on TournamentTeamMember {
    id
    teamId
    firstName
    lastName
    middleName
    number
    role
    photo
    createdAt
    updatedAt
  }
`;

export const TOURNAMENT_TEAM_MEMBER_FULL_FRAGMENT = gql`
  fragment tournamentTeamMemberFull on TournamentTeamMember {
    id
    type
    teamId
    firstName
    lastName
    middleName
    birthday
    weight
    height
    number
    role
    bio
    photo
    data {
      ...extensionData
    }
    team {
      id
      name
      logo
      website
    }
    createdAt
    updatedAt
  }
  ${EXTENSION_DATA_FRAGMENT}
`;

export const TOURNAMENT_TEAM_MEMBER_FRAGMENT = gql`
  fragment tournamentTeamMember on TournamentTeamMember {
    id
    type
    teamId
    firstName
    lastName
    middleName
    birthday
    weight
    height
    number
    role
    bio
    photo
    createdAt
    updatedAt
  }
`;
