import * as React from 'react';
import { Alert } from 'antd';
import { ApolloError } from 'apollo-client';
import { compose } from 'recompose';
import FiltersContextHOC from '../FiltersContextHOC/FiltersContextHOC';
import { IFiltersContextValue, TranslateHandler } from '../FiltersProvider/FiltersProvider';

interface IExternalProps {
  error: ApolloError;
  className?: string;
  isFull?: boolean;
}

interface IProps extends IExternalProps {
  filtersContext: IFiltersContextValue;
}

class ErrorMessage extends React.Component<IProps, {}> {
  private joinMessages = (arr: any): string => arr.map((err: any) => err.message).join('\n');

  private i18n: TranslateHandler = key => {
    const { i18n } = this.props.filtersContext;

    return i18n(key);
  };

  private getMessageFromError = (error: ApolloError) => {
    const { networkError, graphQLErrors, message } = error;
    const graphqlErrorsCount = (graphQLErrors || []).length;

    if (networkError && !graphqlErrorsCount) {
      console.warn('Network error:\n', networkError);
      const message = `${networkError.message}\n`;

      // @ts-ignore
      if (networkError.result && networkError.result.errors) {
        return {
          title: message,
          // @ts-ignore
          description: this.joinMessages(networkError.result.errors),
        };
      }

      return {
        title: 'Network error',
        description: message,
      };
    }

    if (graphqlErrorsCount === 1) {
      const [exactError] = graphQLErrors;

      const code = exactError.extensions && exactError.extensions.code;
      const title = code ? this.i18n(code) : this.i18n('error_happened');
      const description = exactError.message;

      return { title, description };
    }

    return {
      title: message,
      description: graphqlErrorsCount ? this.joinMessages(graphQLErrors) : undefined,
    };
  };

  private getMessage = () => {
    let title = '';
    let description = '';

    const { error } = this.props;
    if (error) {
      const message = this.getMessageFromError(error);

      if (message.title) {
        title = message.title;
      }

      if (message.description) {
        description = message.description;
      }
    }

    return {
      title: title || this.i18n('error'),
      description: description || this.i18n('no_error_details_found'),
    };
  };

  private getClassName = (): string => {
    const { isFull, className } = this.props;
    const classNames = [];

    if (isFull) {
      classNames.push('container', 'container--center');
    }

    if (className) {
      classNames.push(className);
    }

    return classNames.join(' ');
  };

  public render() {
    const { title, description } = this.getMessage();
    const className = this.getClassName();

    return (
      <div className={className}>
        <Alert type="error" description={description} message={title} />
      </div>
    );
  }
}

export default compose<IProps, IExternalProps>(FiltersContextHOC())(ErrorMessage);
