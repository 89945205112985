import React from 'react';
import { compose } from 'recompose';
import UForm, { FormComponent, IFormField, UFormState } from '../../universal/components/UForm/UForm';
import { handleFail, OPERATIONS, handleSuccess } from '../../helpers/handleMutation';
import { getMutateProps } from '../../helpers/mutationOperationOptions';
import { IManipulateCacheConfig, removeFromCache } from '../../helpers/updateInCache';
import { ExecutionResult } from 'graphql';
import {
  CreateCommerceOrderItemMutation,
  UpdateCommerceOrderItemMutation,
  DeleteCommerceOrderItemMutation,
  CreateCommerceOrderItemProps,
  DeleteCommerceOrderItemProps,
  UpdateCommerceOrderItemProps,
  CommerceOrderItemProps,
  CommerceOrderItemCreateInput,
  CommerceOrderItemUpdateInput,
  withCreateCommerceOrderItem,
  withDeleteCommerceOrderItem,
  withUpdateCommerceOrderItem,
  withCommerceOrderItem,
  CommerceOrderItem,
  CommerceOrderItemFragment,
  AllCatalogueItemsQueryVariables,
  CommerceShopProps,
  withCommerceShop,
  CommerceShop,
  CommerceOrder,
} from '../../typings/graphql';
import { ALL_COMMERCE_ORDER_ITEMS_QUERY } from '../../graph/queries/allCommerceOrderItems';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Rcm, { RcmProps } from '../Rcm/Rcm';
import { ALL_CATALOGUE_ITEMS_QUERY } from '../../graph/queries/allCatalogueItems';
import { FilterDataType } from '../../universal/typings/graphql';
import { ALL_COMMERCE_ORDERS_QUERY } from '../../graph/queries/allCommerceOrders';
import { COMMERCE_ORDER_QUERY } from '../../graph/queries/commerceOrder';

type Elem = CommerceOrderItemFragment;

interface IExternalProps {
  instanceId: CommerceOrderItem['id'] | null;
  instance?: Elem | null;
  orderId?: CommerceOrder['id'] | null;

  shopId: CommerceShop['id'];

  onCreate?: (entity: Elem) => void;
  onUpdate?: (entity: Elem) => void;
  onDelete?: (entity: Elem) => void;
  rcmProps?: Partial<RcmProps>;
}

interface IProps extends IExternalProps, RouteComponentProps {
  commerceOrderItem?: CommerceOrderItemProps['data'];
  commerceShop: CommerceShopProps['data'];

  create: CreateCommerceOrderItemProps;
  update: UpdateCommerceOrderItemProps;
  delete: DeleteCommerceOrderItemProps;
}

interface IState {}

// const addToCacheConfig: IManipulateCacheConfig = {
//   collectionQueryName: 'allCommerceOrderItem',
//   collectionQuery: ALL_COMMERCE_ORDER_ITEMS_QUERY,
//   mutationQueryName: 'createCommerceOrderItem',
// };

const removeFromCacheConfig: IManipulateCacheConfig = {
  collectionQueryName: 'allCommerceOrderItem',
  collectionQuery: ALL_COMMERCE_ORDER_ITEMS_QUERY,
  mutationQueryName: 'deleteCommerceOrderItem',
};

class CommerceOrderItemForm extends React.PureComponent<IProps, IState> {
  private getInstanceId = () => {
    const { instanceId, instance } = this.props;
    if (instanceId) {
      return instanceId;
    }

    if (instance && instance.id) {
      return instance.id;
    }

    return null;
  };

  private handleUpdateSuccess = (res: ExecutionResult<UpdateCommerceOrderItemMutation>) => {
    const updated = res && res.data && res.data.updateCommerceOrderItem;
    if (!updated) {
      return handleFail(OPERATIONS.UPDATE)();
    }

    handleSuccess(OPERATIONS.UPDATE)();

    const { onUpdate } = this.props;
    if (onUpdate) {
      onUpdate(updated);
    }
  };

  private handleUpdate = (formState: UFormState) => {
    const { update } = this.props;

    const instanceId = this.getInstanceId();
    if (!instanceId) {
      return handleFail(OPERATIONS.UPDATE)();
    }
    update
      .mutate({
        refetchQueries: this.getRefetchQuery(),
        awaitRefetchQueries: true,
        variables: {
          id: instanceId,
          data: formState as CommerceOrderItemUpdateInput,
        },
      })
      .then(this.handleUpdateSuccess)
      .catch(handleFail(OPERATIONS.UPDATE));
  };

  private handleCreateSuccess = (res: ExecutionResult<CreateCommerceOrderItemMutation>) => {
    const created = res && res.data && res.data.createCommerceOrderItem;
    if (!created) {
      return handleFail(OPERATIONS.CREATE)();
    }

    handleSuccess(OPERATIONS.CREATE)();

    const { onCreate } = this.props;
    if (onCreate) {
      onCreate(created);
    }
  };

  private handleDeleteSuccess = (res: ExecutionResult<DeleteCommerceOrderItemMutation>) => {
    const deleted = res && res.data && res.data.deleteCommerceOrderItem;
    if (!deleted) {
      return handleFail(OPERATIONS.DELETE)();
    }

    handleSuccess(OPERATIONS.DELETE)();
    const { onDelete } = this.props;
    if (onDelete) {
      onDelete(deleted);
    }
  };

  private getRefetchQuery = () => {
    const { orderId } = this.props;
    const formattedOrderId = orderId ? JSON.stringify(orderId) : null;
    const refetchQueries = [];
    if (formattedOrderId) {
      refetchQueries.push({
        query: COMMERCE_ORDER_QUERY,
        variables: { id: orderId },
      });
    }
    return refetchQueries;
  };

  private handleCreate = (formState: UFormState) => {
    const { orderId } = this.props;
    if (!orderId) {
      return;
    }
    this.props.create
      .mutate({
        refetchQueries: this.getRefetchQuery(),
        awaitRefetchQueries: true,
        variables: {
          data: { ...(formState as CommerceOrderItemCreateInput), orderId: orderId },
        },
      })
      .then(this.handleCreateSuccess)
      .catch(handleFail(OPERATIONS.CREATE));
  };

  private handleSubmit = (formState: UFormState, changes: UFormState) => {
    const instanceId = this.getInstanceId();
    if (!instanceId) {
      return this.handleCreate(formState);
    }

    return this.handleUpdate(changes);
  };

  private handleDelete = () => {
    const instanceId = this.getInstanceId();
    if (!instanceId) {
      return handleFail(OPERATIONS.DELETE)();
    }
    this.props.delete
      .mutate({
        refetchQueries: this.getRefetchQuery(),
        awaitRefetchQueries: true,
        variables: {
          id: instanceId,
        },
        update: removeFromCache(removeFromCacheConfig),
      })
      .then(this.handleDeleteSuccess)
      .catch(handleFail(OPERATIONS.DELETE));
  };

  private getInstance = (): Elem | null => {
    const { commerceOrderItem, instance } = this.props;
    if (instance) {
      return instance;
    }

    if (!commerceOrderItem || !commerceOrderItem.commerceOrderItem) {
      return null;
    }

    return commerceOrderItem.commerceOrderItem;
  };

  private getShop = () => {
    const { commerceShop } = this.props.commerceShop;

    return commerceShop || null;
  };

  private getFields = (): IFormField[] => {
    const shop = this.getShop();
    if (!shop) {
      return [];
    }

    return [
      {
        field: 'catalogueItemId',
        component: FormComponent.SELECT,
        optionsQuery: {
          query: ALL_CATALOGUE_ITEMS_QUERY,
          queryName: 'allCatalogueItems',
          variables: {
            query: {
              filters: [
                {
                  field: 'catalogueId',
                  value: JSON.stringify(shop.catalogueId),
                },
              ],
            },
          } as AllCatalogueItemsQueryVariables,
        },
        isRequired: true,
      },
      {
        field: 'qty',
        component: FormComponent.INPUT,
        type: FilterDataType.NUMBER,
        isRequired: true,
      },
    ];
  };

  private isPending = (): boolean => {
    const isLoading = this.props.commerceOrderItem?.loading;
    const { loading: isUpdating } = this.props.update.result;
    const { loading: isCreating } = this.props.create.result;
    const { loading: isDeleting } = this.props.delete.result;

    return isLoading || isUpdating || isCreating || isDeleting;
  };

  private getError = () => {
    const errorLoading = this.props.commerceOrderItem?.error;
    const { error: errorUpdating } = this.props.update.result;
    const { error: errorCreating } = this.props.create.result;
    const { error: errorDeleting } = this.props.delete.result;

    return errorLoading || errorUpdating || errorCreating || errorDeleting;
  };

  private renderEssence = () => {
    const fields = this.getFields();
    const instance = this.getInstance();
    const isLoading = this.isPending();
    const error = this.getError();

    return (
      <UForm
        fields={fields}
        instance={instance}
        isLoading={isLoading}
        error={error}
        onDelete={this.handleDelete}
        onSubmit={this.handleSubmit}
      />
    );
  };

  private renderRcm = () => {
    const { rcmProps } = this.props;
    return <Rcm renderEssence={this.renderEssence} {...rcmProps} />;
  };

  render() {
    return this.renderRcm();
  }
}

export default compose<IProps, IExternalProps>(
  withRouter,
  withCreateCommerceOrderItem(getMutateProps('create')),
  withUpdateCommerceOrderItem(getMutateProps('update')),
  withDeleteCommerceOrderItem(getMutateProps('delete')),
  withCommerceShop<IProps>({
    name: 'commerceShop',
    options: props => {
      return {
        variables: {
          id: props.shopId,
        },
      };
    },
  }),
  withCommerceOrderItem<IProps>({
    name: 'commerceOrderItem',
    skip: props => !props.instanceId, // Skip if ID is not provided
    options: props => ({
      variables: {
        id: props.instanceId!,
      },
    }),
  })
)(CommerceOrderItemForm);
