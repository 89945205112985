import { gql } from 'apollo-boost';
import { ARTICLE_AUTHOR_FRAGMENT } from '../fragments/articleAuthor';

export const ARTICLE_AUTHOR_QUERY = gql`
  query articleAuthor($id: Int!) {
    articleAuthor(id: $id) {
      ...articleAuthor
    }
  }
  ${ARTICLE_AUTHOR_FRAGMENT}
`;
