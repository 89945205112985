import { gql } from 'apollo-boost';

export const MESSENGER_TRANSPORT_FRAGMENT = gql`
  fragment messengerTransport on MessengerTransport {
    id
    clientId
    name
    settings
    providerId
    state
    createdAt
    updatedAt
    channel
  }
`;
