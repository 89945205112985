import { gql } from 'apollo-boost';

export const ARTICLE_LINK_FRAGMENT = gql`
  fragment articleLink on ArticleLink {
    articleId
    entityId
    entityType
  }
`;

export const ARTICLE_LINK_FRAGMENT_FULL = gql`
  fragment articleLinkFull on ArticleLink {
    articleId
    entityId
    entityType
    # entity
  }
`;
