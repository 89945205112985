import React, { useCallback } from 'react';
import './DisputeSettingsAction.scss';
import { SettingsAction, UForm } from '../../universal';
import { i18n } from '../../helpers/I18n';
import { FormComponent, IFormField, UFormState } from '../../universal/components/UForm/UForm';
import { ALL_USERS_QUERY } from '../../graph/queries/allUsers';
import { getQueryNameFromQuery } from '../../helpers/getQueryNameFromQuery';
import { renderUserOption } from '../../helpers/renderOption';
import { useDisputeSettingsQuery, User, useSaveDisputeSettingsMutation } from '../../typings/graphql';
import { handleFail, handleSuccess, OPERATIONS } from '../../helpers/handleMutation';
import { CloseModalFn } from '../../universal/components/SettingsAction/SettingsAction';
import useDisputeSimulators from '../../hooks/useDisputeSimulators';

interface IExternalProps {}

interface IProps extends IExternalProps {}

type UserID = User['id'];

export interface IDisputeSettingsStructure {
  simulators: UserID[];
}

const DisputeSettingsAction: React.FC<IProps> = props => {
  const modalProps = { title: i18n('h_disputes_settings') };

  const [saveSettingsMutation, saveSettingsResult] = useSaveDisputeSettingsMutation();

  const disputeSettingsResult = useDisputeSettingsQuery();
  const simulators = useDisputeSimulators();
  // const settings = disputeSettingsResult?.data?.disputeSettings ?? null;
  const isLoading = disputeSettingsResult.loading || saveSettingsResult.loading;
  const error = disputeSettingsResult.error || saveSettingsResult.error;

  const handleSubmit = useCallback(
    (closeModalFn: CloseModalFn) => (formState: UFormState) => {
      saveSettingsMutation({
        variables: {
          data: {
            simulators: formState.simulators ?? [],
          },
        },
      })
        .then(() => {
          handleSuccess(OPERATIONS.UPDATE)();
          closeModalFn();
        })
        .catch(handleFail(OPERATIONS.UPDATE));
    },
    [saveSettingsMutation]
  );

  const getInstance = useCallback((): IDisputeSettingsStructure => {
    return {
      simulators: simulators,
    };
  }, [simulators]);

  const getFields = useCallback((): IFormField[] => {
    return [
      {
        field: 'simulators',
        component: FormComponent.SELECT,
        isMultiSelect: true,
        optionsQuery: {
          query: ALL_USERS_QUERY,
          queryName: getQueryNameFromQuery(ALL_USERS_QUERY),
          render: renderUserOption,
        },
      },
    ];
  }, []);

  const renderSettings = useCallback(
    (closeModalFn: CloseModalFn) => {
      const fields = getFields();
      const instance = getInstance();

      return (
        <UForm
          instance={instance}
          isLoading={isLoading}
          error={error}
          fields={fields}
          onSubmit={handleSubmit(closeModalFn)}
        />
      );
    },
    [getFields, getInstance, isLoading, error, handleSubmit]
  );

  return <SettingsAction type="modal" modalProps={modalProps} renderContent={renderSettings} />;
};

export default React.memo(DisputeSettingsAction);
