import * as React from 'react';
import { EVENTS, EventService } from '../../universal/services/EventService';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import PrivateLayout from '../PrivateLayout/PrivateLayout';
import { compose } from 'recompose';
import { withApollo } from '@apollo/react-hoc';

interface IExternalProps extends RouteProps {
  argumentQuery?: IArgumentQuery;
}

export interface IArgumentQuery {
  [argumentName: string]: any; // gql
}

interface Props extends IExternalProps {
  client: any;
}

interface State {
  isAuthorized: boolean;
}

export const BreadCrumbsArgumentQueryContext = React.createContext<IArgumentQuery>({});

export class PrivateRoute extends React.PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      isAuthorized: PrivateLayout.isAuthorized(),
    };

    document.addEventListener(EVENTS.LOGOUT, this.handleLogout);
  }

  private handleLogout = () => {
    this.setState({ isAuthorized: false }, () => {
      const { client } = this.props;
      const { queryManager } = client;

      if (queryManager) {
        queryManager
          .clearStore() // We need to clear inflight/active queries first
          .then(() => client.resetStore()) // Then clear cache
          .then(() => {
            localStorage.clear();

            EventService.fire(EVENTS.STORAGE_CLEARED);
          });
      }
    });
  };

  public render() {
    const { component, argumentQuery } = this.props;
    const { isAuthorized } = this.state;

    if (!isAuthorized) {
      return <Redirect to="/login" />;
    }

    const contextValue = argumentQuery || {};

    return (
      <ErrorBoundary>
        <BreadCrumbsArgumentQueryContext.Provider value={contextValue}>
          <Route {...this.props} component={component} />
        </BreadCrumbsArgumentQueryContext.Provider>
      </ErrorBoundary>
    );
  }
}

export default compose<Props, IExternalProps>(withApollo)(PrivateRoute);
