import React from 'react';

import './CommerceOrdersTable.scss';
import Rcm, { RcmProps } from '../Rcm/Rcm';
import { ALL_COMMERCE_ORDERS_QUERY } from '../../graph/queries/allCommerceOrders';
import { UTable } from '../../universal';
import { i18n } from '../../helpers/I18n';
import {
  CommerceOrder,
  CommerceOrderData,
  CommerceOrderFragment,
  CommerceOrderItemFragment,
  CommerceOrderPaymentState,
  CommerceOrderState,
  CommerceShop,
  DefaultQueryInput,
  FilterInput,
  SortingDirection,
  User,
  UserFragment,
} from '../../typings/graphql';
import { ColumnProps } from 'antd/es/table';
import StatusTag from '../StatusTag/StatusTag';
import { getFullName } from '../../helpers/getFullName';
import InlineLink from '../InlineLink/InlineLink';
import { renderDate } from '../../helpers/renderDate';
import { UTableProps } from '../../universal/components/UTable/UTable';
import { Button, Select } from 'antd';
import { compose } from 'recompose';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import QuerySelect from '../../universal/components/QuerySelect/QuerySelect';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { FormComponent, IFormField } from '../../universal/components/UForm/UForm';
import { getQueryNameFromQuery } from '../../helpers/getQueryNameFromQuery';
import { ALL_USERS_QUERY } from '../../graph/queries/allUsers';
import { FloatPanelProps } from '../FloatPanel/FloatPanel';

interface IExternalProps {
  rcmProps?: Partial<RcmProps>;
  shopId?: CommerceShop['id'] | null;
  userId?: User['id'];
  onRowClick?: (commerceOrder: CommerceOrderFragment) => void;
  onEditClick?: (commerceOrder: CommerceOrderFragment) => void;
  uTableProps?: Partial<UTableProps>;
  codeMask?: CommerceShop['codeMask'];
}

interface IProps extends IExternalProps, RouteComponentProps {}

interface IState {
  selectedUsersIdx: number[];
}

class CommerceOrdersTable extends React.PureComponent<IProps, IState> {
  state: IState = {
    selectedUsersIdx: [],
  };

  private getKeys = (): Array<string | keyof CommerceOrder> => {
    return ['code', 'user', 'items', 'total', 'paidAmount', 'state', 'createdAt', 'updatedAt', 'edit_action'];
  };

  private getTableId = () => {
    const { shopId, userId } = this.props;
    let id = `allCommerceOrders`;

    if (typeof shopId === 'number') {
      id = `${id}-shop-${shopId}`;
    }

    if (typeof userId === 'number') {
      id = `${id}-user-${userId}`;
    }

    return id;
  };

  private getBannedKeys = (): string[] => {
    return ['id', 'clientId', 'externalEntityId', 'shopId', 'userId'];
  };

  private getQueryInput = (): DefaultQueryInput => {
    const { shopId, userId } = this.props;

    const filters: FilterInput[] = [];

    if (shopId) {
      filters.push({
        field: 'shopId',
        value: JSON.stringify(shopId),
      });
    }

    if (userId) {
      filters.push({
        field: 'userId',
        value: String(userId),
      });
    }

    if (this.state.selectedUsersIdx.length >= 1) {
      filters.push({
        field: 'userId',
        value: JSON.stringify(this.state.selectedUsersIdx),
      });
    }

    return {
      filters: filters,
      sort: [{ field: 'createdAt', direction: SortingDirection.DESC }],
    };
  };

  private getCustomColumns = (): ColumnProps<any>[] => {
    return [
      {
        title: i18n('state'),
        key: 'state',
        render: this.renderState,
      },
      {
        title: i18n('paymentState'),
        key: 'paymentState',
        render: this.renderState,
      },
      {
        title: i18n('user'),
        key: 'user',
        render: this.renderUser,
      },
      {
        title: i18n('code'),
        key: 'code',
        render: this.renderCode,
      },
      {
        title: i18n('createdAt'),
        key: 'createdAt',
        render: this.renderDate,
      },
      {
        title: i18n('updatedAt'),
        key: 'updatedAt',
        render: this.renderDate,
      },
      {
        title: i18n('edit'),
        key: 'edit_action',
        render: this.renderEditButton,
      },
      {
        title: i18n('info'),
        key: 'data',
        render: this.renderData,
      },
      {
        title: i18n('positions'),
        key: 'items',
        render: this.renderItems,
      },
    ];
  };

  private handleRowClick = (order: CommerceOrderFragment) => {
    const { onRowClick } = this.props;
    if (onRowClick) {
      return onRowClick(order);
    }
  };

  private handleOrderEditClick = (order: CommerceOrderFragment) => (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    const { onEditClick } = this.props;
    if (onEditClick) {
      return onEditClick(order);
    }

    const path = `/commerce/shops/${order.shopId}/orders/${order.id}`;
    this.props.history.push(path);
  };

  private renderDataRecord = (order: CommerceOrderFragment) => (item: CommerceOrderData, index: number) => {
    const key = `${order.id}-${index}`;
    const label = i18n(item.key || '');

    return (
      <div className="commerceOrdersTable_dataRecord" key={key}>
        <span>{label}: </span>
        {item.value}
      </div>
    );
  };

  private renderData = (data: CommerceOrder['data'], order: CommerceOrderFragment) => {
    if (!data) {
      return null;
    }

    return <div>{data.map(this.renderDataRecord(order))}</div>;
  };

  private renderItem = (order: CommerceOrderFragment) => (item: CommerceOrderItemFragment, index: number) => {
    const key = `${order.id}-${index}`;
    const { catalogueItem, qty, price } = item;
    const { preview, name } = catalogueItem;
    const src = preview || undefined;

    return (
      <div className="commerceOrdersTable_item" key={key}>
        <div className="commerceOrdersTable_itemPreview">
          <img alt="preview" src={src} />
        </div>
        <div className="commerceOrdersTable_itemDescription">
          {name}
          <div>
            {qty} x {price}
          </div>
        </div>
      </div>
    );
  };

  private renderItems = (items: CommerceOrderFragment['items'], order: CommerceOrderFragment) => {
    if (!items) {
      return null;
    }

    return <div>{items.map(this.renderItem(order))}</div>;
  };

  private renderState = (value: CommerceOrderState | CommerceOrderPaymentState) => {
    return <StatusTag value={value} />;
  };

  private renderUser = (user: CommerceOrderFragment['user'], record: CommerceOrderFragment) => {
    const userPath = `/users/${record.userId}`;
    const text = getFullName(user) || i18n('untitled');

    return (
      <>
        <InlineLink text={text} to={userPath} />
        <div>{user.phone}</div>
        <div>{user.email}</div>
      </>
    );
  };

  private renderCode = (value: string, order: CommerceOrderFragment) => {
    if (value) {
      return value;
    }

    const { codeMask } = this.props;
    if (codeMask) {
      return `${codeMask}${order.id}`;
    }

    return String(order.id);
  };

  private renderDate = (value: string) => {
    return renderDate(value);
  };

  private renderEditButton = (_: any, order: CommerceOrderFragment) => {
    return <Button icon="edit" onClick={this.handleOrderEditClick(order)} />;
  };

  private renderEssence = () => {
    const { uTableProps } = this.props;
    const customColumns = this.getCustomColumns();
    const queryInput = this.getQueryInput();
    const bannedKeys = this.getBannedKeys();
    const keys = this.getKeys();
    const tableId = this.getTableId();
    return (
      <UTable
        actions={this.renderActions()}
        showSearch
        id={tableId}
        customColumns={customColumns}
        query={ALL_COMMERCE_ORDERS_QUERY}
        onRowClick={this.handleRowClick}
        queryInput={queryInput}
        bannedKeys={bannedKeys}
        keys={keys}
        {...uTableProps}
      />
    );
  };

  private renderUserSelectOption = (record: UserFragment) => {
    const username = getFullName(record);
    return <div key={record.id}>{username}</div>;
  };

  private getQuerySelectFormField = () => {
    return {
      field: 'id',
      component: FormComponent.SELECT,
      optionsQuery: {
        queryName: getQueryNameFromQuery(ALL_USERS_QUERY),
        query: ALL_USERS_QUERY,
        optionValuePropertyName: 'id',
        render: this.renderUserSelectOption,
        // variables: variables,
      },
    };
  };

  private handleUserSelect = (value: any) => {
    this.setState({
      selectedUsersIdx: value,
    });
  };

  private renderSearchByUserAction = () => {
    const formField: IFormField = this.getQuerySelectFormField();

    return (
      <div style={{ minWidth: '300px' }}>
        <QuerySelect
          value={this.state.selectedUsersIdx}
          isDisabled={false}
          isMultiSelect={true}
          formState={{}}
          onChange={this.handleUserSelect}
          renderError={error => <ErrorMessage error={error} />}
          renderLoader={() => <Select disabled loading />}
          formField={formField}
          selectProps={{
            placeholder: i18n('search_by_user'),
          }}
        />
      </div>
    );
  };

  private renderActions = () => {
    return [this.renderSearchByUserAction()];
  };

  private renderRcm = () => {
    const { rcmProps } = this.props;
    return <Rcm className="commerceOrdersTable" renderEssence={this.renderEssence} {...rcmProps} />;
  };

  render() {
    return this.renderRcm();
  }
}

export default compose<IProps, IExternalProps>(withRouter)(CommerceOrdersTable);
