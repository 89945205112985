import React, { useCallback } from 'react';
import './ModerateDisputeAction.scss';
import { i18n } from '../../helpers/I18n';
import { Button, Popconfirm } from 'antd';
import { ButtonProps } from 'antd/es/button';
import {
  Dispute,
  DisputeResult,
  DisputeState,
  useConfirmDisputeDisputeModeratorMutation,
  useDisputeQuery,
} from '../../typings/graphql';
import { handleFail, handleSuccess, OPERATIONS } from '../../helpers/handleMutation';
import { getQueryNameFromQuery } from '../../helpers/getQueryNameFromQuery';
import { ALL_DISPUTE_DISPUTE_RESPONSES_QUERY } from '../../graph/queries/allDisputeDisputeResponses';
import { PopconfirmProps } from 'antd/es/popconfirm';

interface IExternalProps {
  disputeId: Dispute['id'];
  type: 'cancel' | 'win' | 'loss';
  className?: string;
}

interface IProps extends IExternalProps {}

export interface IModerateDisputeActionProps extends IExternalProps {}

const ModerateDisputeAction: React.FC<IProps> = props => {
  const { type, disputeId, className } = props;
  const isCancelType = type === 'cancel';
  const isWinType = type === 'win';
  const isLossType = type === 'loss';

  const disputeResult = useDisputeQuery({
    variables: {
      id: disputeId,
    },
  });
  const dispute = disputeResult?.data?.dispute ?? null;

  const [confirmMutation, confirmResult] = useConfirmDisputeDisputeModeratorMutation();

  const isLoading = confirmResult.loading || disputeResult.loading;

  const getDisputeResult = useCallback((): DisputeResult => {
    if (isWinType) {
      return DisputeResult.WIN;
    }

    if (isCancelType) {
      return DisputeResult.CANCELLED;
    }

    return DisputeResult.LOSS;
  }, [isCancelType, isWinType]);

  const handleClick = useCallback(() => {
    const result = getDisputeResult();

    confirmMutation({
      variables: {
        id: disputeId,
        result: result,
      },
      refetchQueries: [getQueryNameFromQuery(ALL_DISPUTE_DISPUTE_RESPONSES_QUERY)],
    })
      .then(handleSuccess(OPERATIONS.UPDATE))
      .catch(handleFail(OPERATIONS.UPDATE));
  }, [disputeId, confirmMutation, getDisputeResult]);

  const getActionText = useCallback((): string => {
    if (isCancelType) {
      return i18n('action_cancel_dispute');
    }

    if (isWinType) {
      return i18n('action_win_dispute');
    }

    if (isLossType) {
      return i18n('action_lose_dispute');
    }

    return i18n('action_moderate');
  }, [isCancelType, isWinType, isLossType]);

  const getActionType = useCallback((): NonNullable<ButtonProps['type']> => {
    if (isCancelType) {
      return 'danger';
    }

    if (isWinType) {
      return 'primary';
    }

    return 'default';
  }, [isCancelType, isWinType]);

  const getIsDisabled = useCallback(() => {
    // Disabled because of loading state
    if (isLoading) {
      return true;
    }

    // Disabled because of no dispute loaded
    if (!dispute) {
      return true;
    }

    // We can set win\loss only fro finished
    // Apparently we can now CRM-2197
    // const isFinished = dispute.state === DisputeState.FINISHED;
    // if ((isLossType || isWinType) && !isFinished) {
    //   return true;
    // }

    return false;
  }, [isLoading, dispute]);

  const getPopConfirmProps = useCallback((): PopconfirmProps => {
    const isDisabled = getIsDisabled();

    return {
      title: i18n('are_you_sure?'),
      onConfirm: handleClick,
      cancelText: i18n('action_cancel'),
      okText: i18n('action_confirm'),
      disabled: isDisabled,
    };
  }, [getIsDisabled, handleClick]);

  const actionText = getActionText();
  const actionType = getActionType();
  const isDisabled = getIsDisabled();
  const popConfirmProps = getPopConfirmProps();

  return (
    <Popconfirm {...popConfirmProps}>
      <Button disabled={isDisabled} type={actionType} className={className}>
        {actionText}
      </Button>
    </Popconfirm>
  );
};

export default React.memo(ModerateDisputeAction);
