import { FiltersStates } from '../universal/components/FiltersProvider/FiltersProvider';
import { FiltersProvider } from '../universal/index';
import { CommerceOrderState, DisputeState, FilterDataType, PaymentAccountState } from '../typings/graphql';
import { COMMERCE_ORDERS_FILTER_INPUT_ID } from '../components/CommerceOrdersWidgets/CommerceOrdersWidgets';
import { PAYMENT_ACCOUNTS_FILTER_INPUT_ID } from '../components/PaymentAccountPage/PaymentAccountPage';
import { DISPUTES_FILTER_INPUT_ID } from '../components/AllDisputesPage/AllDisputesPage';

export const getFiltersProviderInitialStates = (): FiltersStates => {
  const ordersKey = FiltersProvider.getFilterId('allCommerceOrders');
  const paymentAccountsKey = FiltersProvider.getFilterId('allPaymentAccounts');
  const disputesKey = FiltersProvider.getFilterId('allDisputes', DISPUTES_FILTER_INPUT_ID);

  return {
    [ordersKey]: [
      {
        id: COMMERCE_ORDERS_FILTER_INPUT_ID,
        field: 'state',
        dataType: FilterDataType.ENUM,
        value: JSON.stringify([CommerceOrderState.PROCESSING]),
      },
    ],
    [paymentAccountsKey]: [
      {
        id: PAYMENT_ACCOUNTS_FILTER_INPUT_ID,
        field: 'state',
        dataType: FilterDataType.ENUM,
        value: JSON.stringify([PaymentAccountState.INACTIVE]),
        isExclude: true,
      },
    ],
    [disputesKey]: [
      {
        id: DISPUTES_FILTER_INPUT_ID,
        field: 'state',
        dataType: FilterDataType.ENUM,
        value: JSON.stringify([DisputeState.ACTIVE, DisputeState.FINISHED]),
      },
    ],
  };
};
