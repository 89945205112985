import { ApolloError } from 'apollo-boost';

export const getErrorFromProps = (props: Record<string, any>) => {
  const errors: ApolloError[] = [];

  for (const propName in props) {
    if (!props.hasOwnProperty(propName)) {
      continue;
    }

    const prop = props[propName];
    if (!prop) {
      continue;
    }

    if (typeof prop !== 'object') {
      continue;
    }

    // Get error from query
    if (prop.error && prop.error instanceof ApolloError) {
      errors.push(prop.error);
    }

    // Get error from mutation
    if (prop.result && prop.result.error instanceof ApolloError) {
      errors.push(prop.result.error);
    }
  }

  const count = errors.length;
  const error: ApolloError | null = errors[0] || null;

  return { errors, count, error };
};

export const getLoadingFromProps = (props: Record<string, any>) => {
  let isMutating = false;
  let isLoading = false;

  for (const propName in props) {
    if (!props.hasOwnProperty(propName)) {
      continue;
    }

    const prop = props[propName];
    if (!prop) {
      continue;
    }

    if (typeof prop !== 'object') {
      continue;
    }

    // Get error from query
    if (prop.loading === true) {
      isLoading = true;
    }

    // Get error from mutation
    if (prop.result && prop.result.loading === true) {
      isMutating = true;
    }
  }

  return { isMutating, isLoading };
};
