import { gql } from 'apollo-boost';
import { TOURNAMENT_TEAM_DETAILS_FRAGMENT } from '../fragments/tournamentTeam';

export const TOURNAMENT_TEAM_QUERY = gql`
  query tournamentTeam($id: Int!) {
    tournamentTeam(id: $id) {
      ...tournamentTeamDetails
    }
  }
  ${TOURNAMENT_TEAM_DETAILS_FRAGMENT}
`;
