import React, { useCallback } from 'react';

import './AllDisputesPage.scss';
import Page from '../Page/Page';
import { i18n } from '../../helpers/I18n';
import Header from '../Header/Header';
import DisputesTable from '../DisputesTable/DisputesTable';
import DisputeSettingsAction from '../DisputeSettingsAction/DisputeSettingsAction';

interface IExternalProps {}

interface IProps extends IExternalProps {}

export const DISPUTES_FILTER_INPUT_ID = 'allDisputesPage';

const AllDisputesPage: React.FC<IProps> = props => {
  const renderHeader = useCallback(() => {
    const title = i18n('h_all_disputes');
    const actions = [<DisputeSettingsAction key="settings" />];

    return <Header title={title} actions={actions} />;
  }, []);

  return (
    <Page>
      {renderHeader()}
      <Page.Content>
        <DisputesTable tableId={DISPUTES_FILTER_INPUT_ID} />
      </Page.Content>
    </Page>
  );
};

export default AllDisputesPage;
