import { gql } from 'apollo-boost';

export const ACL_FRAGMENT = gql`
  fragment acl on ACL {
    id
    parentId
    name
    createdAt
    updatedAt
  }
`;
