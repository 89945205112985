import { gql } from 'apollo-boost';
import { USER_FLAT_FRAGMENT } from './user';
import { TOURNAMENT_MATCH_FRAGMENT } from './tournamentMatch';

export const DISPUTE_FLAT_FRAGMENT = gql`
  fragment disputeFlat on Dispute {
    id
    state
    result
    confirmation
    userId
    matchId
    amount
    winAmount
    text
    createdAt
    updatedAt
  }
`;

export const DISPUTE_FRAGMENT = gql`
  fragment dispute on Dispute {
    ...disputeFlat
    user {
      ...userFlat
    }
    match {
      ...tournamentMatch
    }
  }
  ${DISPUTE_FLAT_FRAGMENT}
  ${USER_FLAT_FRAGMENT}
  ${TOURNAMENT_MATCH_FRAGMENT}
`;
