import { gql } from 'apollo-boost';
import { USER_FLAT_FRAGMENT } from './user';
import { PAYMENT_CURRENCY_FRAGMENT } from './paymentCurrency';
import { PAYMENT_PROVIDER_FRAGMENT } from './paymentProvider';

export const PAYMENT_ACCOUNT_FRAGMENT = gql`
  fragment paymentAccount on PaymentAccount {
    id
    state
    userId
    user {
      ...userFlat
    }
    currencyId
    currency {
      ...paymentCurrency
    }
    provider {
      ...paymentProvider
    }
    balance
    spent
    providerId
    providerCode
    createdAt
    updatedAt
  }
  ${USER_FLAT_FRAGMENT}
  ${PAYMENT_CURRENCY_FRAGMENT}
  ${PAYMENT_PROVIDER_FRAGMENT}
`;

export const PAYMENT_ACCOUNT_FLAT_FRAGMENT = gql`
  fragment paymentAccountFlat on PaymentAccount {
    id
    state
    userId
    currencyId
    user {
      id
      firstName
      lastName
      middleName
      data {
        id
        value
        key
      }
    }
    balance
    spent
    providerId
    providerCode
    createdAt
    updatedAt
  }
`;
