import React from 'react';
import { compose } from 'recompose';
import UForm, { IFormField, FormComponent, UFormState } from '../../universal/components/UForm/UForm';
import { getMutateProps } from '../../helpers/mutationOperationOptions';
import Rcm, { RcmProps } from '../Rcm/Rcm';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  withDepositToAccount,
  withChargeFromAccount,
  DepositToAccountProps,
  ChargeFromAccountProps,
  PaymentAccount,
  FilterDataType,
  ChargeFromAccountMutation,
  DepositToAccountMutation,
  PaymentAccountOperationInput,
  PaymentAccountFragment,
  PaymentAccountState,
} from '../../typings/graphql';
import { handleFail, handleSuccess } from '../../helpers/handleMutation';
import { ExecutionResult } from 'graphql';
import { i18n } from '../../helpers/I18n';
import { getQueryNameFromQuery } from '../../helpers/getQueryNameFromQuery';
import { ALL_PAYMENT_ACCOUNTS_QUERY } from '../../graph/queries/allPaymentAccounts';
import { PureQueryOptions } from 'apollo-boost';
import { PAYMENT_ACCOUNT_QUERY } from '../../graph/queries/paymentAccount';

interface IExternalProps {
  accountId?: PaymentAccount['id'];
  userId?: number;
  operation: 'charge' | 'deposit';
  onCharge?: () => void;
  onDeposit?: () => void;
  rcmProps?: RcmProps;
}

interface IProps extends IExternalProps, RouteComponentProps {
  deposit: DepositToAccountProps;
  charge: ChargeFromAccountProps;
}

interface IState {}

class LoyaltyPointsForm extends React.PureComponent<IProps, IState> {
  private updateDepositSuccess = (res: ExecutionResult<DepositToAccountMutation>) => {
    const updated = res && res.data && res.data.depositToAccount;
    if (!updated) {
      return handleFail('deposit')();
    }

    handleSuccess('deposit')();
    const { onDeposit } = this.props;
    if (onDeposit) {
      onDeposit();
    }
  };

  private updateChargeSuccess = (res: ExecutionResult<ChargeFromAccountMutation>) => {
    const updated = res && res.data && res.data.chargeFromAccount;
    if (!updated) {
      return handleFail('charge')();
    }

    handleSuccess('charge')();
    const { onCharge } = this.props;
    if (onCharge) {
      onCharge();
    }
  };

  private getRefetchQuery: (paymentId: number) => Array<string | PureQueryOptions> = paymentId => {
    return [{ query: PAYMENT_ACCOUNT_QUERY, variables: { id: paymentId } }];
  };

  private handleCharge = (formState: UFormState) => {
    const { accountId } = this.props;
    const formAccountId = formState.accountId as number;
    const paymentId = accountId ?? formAccountId;
    if (!paymentId) {
      return;
    }
    this.props.charge
      .mutate({
        variables: {
          data: {
            ...(formState as PaymentAccountOperationInput),
            accountId: paymentId,
          },
        },
        refetchQueries: this.getRefetchQuery(paymentId),
      })
      .then(this.updateChargeSuccess)
      .catch(handleFail('charge'));
  };

  private handleDeposit = (formState: UFormState) => {
    const { accountId } = this.props;
    const formAccountId = formState.accountId;
    const paymentId = accountId ?? formAccountId;
    if (!paymentId) {
      return;
    }
    this.props.deposit
      .mutate({
        variables: {
          data: {
            ...(formState as PaymentAccountOperationInput),
            accountId: paymentId,
          },
        },
        refetchQueries: this.getRefetchQuery(paymentId),
      })
      .then(this.updateDepositSuccess)
      .catch(handleFail('deposit'));
  };

  private handleSubmitForm = (formState: UFormState) => {
    const { operation } = this.props;
    if (operation === 'charge') {
      return this.handleCharge(formState);
    }
    return this.handleDeposit(formState);
  };

  private renderPaymentAccountField = (item: PaymentAccountFragment) => {
    if (!item.currency?.isVirtual) {
      return;
    }
    return `${item.providerCode} - ${item.currency?.name}`;
  };

  private getFields = (): IFormField[] => {
    const fields: IFormField[] = [];
    const { accountId, userId } = this.props;
    fields.push({
      field: 'amount',
      component: FormComponent.INPUT,
      type: FilterDataType.NUMBER,
    });
    if (!accountId && userId) {
      fields.push({
        field: 'accountId',
        component: FormComponent.SELECT,
        optionsQuery: {
          variables: {
            query: {
              filters: [
                { field: 'userId', value: String(userId) },
                { field: 'state', value: PaymentAccountState.ACTIVE },
              ],
              limit: 1000,
            },
          },
          query: ALL_PAYMENT_ACCOUNTS_QUERY,
          queryName: getQueryNameFromQuery(ALL_PAYMENT_ACCOUNTS_QUERY),
          render: this.renderPaymentAccountField,
        },

        isRequired: true,
      });
    }

    fields.push({
      field: 'comment',
      component: FormComponent.TEXTAREA,
    });
    return fields;
  };

  private getSubmitBtnText = () => {
    const { operation } = this.props;
    return i18n(`action_${operation}`);
  };

  private renderEssence = () => {
    const fields = this.getFields();
    const submitBtnText = this.getSubmitBtnText();

    return <UForm fields={fields} onSubmit={this.handleSubmitForm} submitBtnText={submitBtnText} confirm />;
  };

  private renderRcm = () => {
    return <Rcm renderEssence={this.renderEssence} />;
  };

  render() {
    return this.renderRcm();
  }
}

export default compose<IProps, IExternalProps>(
  withRouter,
  withDepositToAccount(getMutateProps('deposit')),
  withChargeFromAccount(getMutateProps('charge'))
)(LoyaltyPointsForm);
