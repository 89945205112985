import { gql } from 'apollo-boost';
import { TOURNAMENT_MATCH_DETAILS_FRAGMENT } from '../fragments/tournamentMatch';

export const TOURNAMENT_MATCH_QUERY = gql`
  query tournamentMatch($id: Int!) {
    tournamentMatch(id: $id) {
      ...tournamentMatchDetails
    }
  }
  ${TOURNAMENT_MATCH_DETAILS_FRAGMENT}
`;
