import {
  CreateSystemTranslationMutation,
  CreateSystemTranslationMutationVariables,
  LocaleEnum,
  SystemTranslationFragment,
} from '../typings/graphql';
import { getFromLocalStorage } from './getFromLocalStorage';
import { setToLocalStorage } from './setToLocalStorage';
import mutate from './mutate';
import { CREATE_SYSTEM_TRANSLATION_MUTATION } from '../graph/mutations/createSystemTranslation';
import { IS_DEVELOPMENT } from '../config/constants';

let DICTIONARY: Record<string, string> = {};

const DEFAULT_LOCALE = LocaleEnum.RU;

let _reload: any;

export function onReload(handler: any) {
  _reload = handler;
}

export function reload(locale: LocaleEnum) {
  _reload(locale);
}

export function init(): LocaleEnum {
  const translations = getFromLocalStorage('translations');
  if (translations) {
    const { locale, dictionary } = translations;
    DICTIONARY = dictionary;
    return locale;
  }

  const user = getFromLocalStorage('user');
  if (user && user.locale) {
    return user.locale;
  }

  return DEFAULT_LOCALE;
}

function save(locale: LocaleEnum) {
  const translations = {
    locale,
    dictionary: DICTIONARY,
  };

  setToLocalStorage('translations', translations);
}

export function populate(locale: LocaleEnum, translations: SystemTranslationFragment[]) {
  DICTIONARY = {};

  translations.forEach(({ key, value }) => {
    DICTIONARY[key] = value!;
  });

  save(locale);
}

export function i18n(key: string): string {
  // Get value from translations
  if (DICTIONARY[key]) {
    return DICTIONARY[key];
  }

  if (key && IS_DEVELOPMENT) {
    const translations = getFromLocalStorage('translations');
    if (translations && translations.locale) {
      // Send request
      mutate<CreateSystemTranslationMutation, CreateSystemTranslationMutationVariables>({
        mutation: CREATE_SYSTEM_TRANSLATION_MUTATION,
        variables: {
          data: {
            key: key,
            value: key,
            locale: translations.locale,
          },
        },
      });
    }
  }

  // Remember untranslated to prevent spam and flood in console
  DICTIONARY[key] = key;

  return key;
}
