import React from 'react';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import { i18n } from '../../helpers/I18n';
import Page from '../Page/Page';
import Header from '../Header/Header';
import { IParamsWithId } from '../../typings/local';
import {
  withCommerceShop,
  CommerceShopProps,
  withDeleteCommerceShop,
  DeleteCommerceShopProps,
} from '../../typings/graphql';
import CommerceShopForm from './CommerceShopForm';
import './CommerceShopPage.scss';
import ShopOrders from '../ShopOrders/ShopOrders';
import { DeleteAction, SettingsAction } from '../../universal/index';
import Rcm from '../Rcm/Rcm';
import { getLoadingFromProps, getErrorFromProps } from '../../helpers/getInfoFromProps';
import { getMutateProps } from '../../helpers/mutationOperationOptions';
import { handleFail, OPERATIONS } from '../../helpers/handleMutation';

export const QUERY_NAME = 'allCommerceOrders';

interface IExternalProps {}

interface IProps extends IExternalProps, RouteComponentProps<IParamsWithId> {
  commerceShop: CommerceShopProps['data'];
  delete: DeleteCommerceShopProps;
}

interface IState {
  isOrderVisible: boolean;
}

class CommerceShopPage extends React.PureComponent<IProps, IState> {
  state: IState = {
    isOrderVisible: false,
  };

  private getShop = () => {
    const { commerceShop } = this.props;
    if (!commerceShop || !commerceShop.commerceShop) {
      return null;
    }
    return commerceShop.commerceShop;
  };

  private renderForm = (closeModalFn: () => void) => {
    const { id } = this.props.match.params;

    return <CommerceShopForm onCreateSuccess={closeModalFn} onUpdateSuccess={closeModalFn} id={id} />;
  };

  private renderEssence = () => {
    const { params } = this.props.match;
    const shopId = Number(params.id);

    return <ShopOrders shopId={shopId} />;
  };

  private getTitle = () => {
    const title = i18n('h_commerce_shop');

    const shop = this.getShop();
    if (shop) {
      return shop.name;
    }

    return title;
  };

  private renderSettingsAction = () => {
    return <SettingsAction type="drawer" renderContent={this.renderForm} />;
  };

  private handleDeleteSuccess = () => {
    this.props.history.replace(`/commerce`);
  };

  private handleDelete = () => {
    const { match } = this.props;

    this.props.delete
      .mutate({
        variables: {
          id: Number(match.params.id),
        },
      })
      .then(this.handleDeleteSuccess)
      .catch(handleFail(OPERATIONS.DELETE));
  };

  private renderDeleteAction = () => {
    return <DeleteAction onConfirm={this.handleDelete} />;
  };

  private renderHeaderActions = () => {
    return [this.renderSettingsAction(), this.renderDeleteAction()];
  };

  private getDescription = () => {
    const shop = this.getShop();
    if (!shop) {
      return null;
    }
    return <div>{shop.description}</div>;
  };

  private renderHeader = () => {
    const title = this.getTitle();
    const actions = this.renderHeaderActions();
    const description = this.getDescription();

    return (
      <>
        <Header title={title} actions={actions} description={description} />
      </>
    );
  };

  private isLoading = () => {
    const { isLoading } = getLoadingFromProps(this.props);
    return isLoading;
  };

  private getError = () => {
    const { error } = getErrorFromProps(this.props);
    return error;
  };

  private renderRcm = () => {
    const error = this.getError();
    const isLoading = this.isLoading();
    return <Rcm error={error} isLoading={isLoading} renderEssence={this.renderEssence} />;
  };

  render() {
    return (
      <Page>
        {this.renderHeader()}
        <Page.Content>{this.renderRcm()}</Page.Content>
      </Page>
    );
  }
}

export default compose<IProps, IExternalProps>(
  withDeleteCommerceShop(getMutateProps('delete')),
  withCommerceShop<IProps>({
    name: 'commerceShop',
    options: props => ({
      variables: {
        id: Number(props.match.params.id),
      },
    }),
  })
)(CommerceShopPage);
