import { gql } from 'apollo-boost';
import { SYSTEM_PRINTER_FRAGMENT } from './systemPrinter';

export const SYSTEM_PRINTER_CONNECTION_FRAGMENT = gql`
  fragment systemPrinterConnection on SystemPrinterConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...systemPrinter
    }
  }
  ${SYSTEM_PRINTER_FRAGMENT}
`;
