import { gql } from 'apollo-boost';
import { USER_FLAT_FRAGMENT } from './user';

export const DISPUTE_DISPUTE_RESPONSE_FLAT_FRAGMENT = gql`
  fragment disputeDisputeResponseFlat on DisputeDisputeResponse {
    id
    clientId
    disputeId
    state
    userId
    amount
    comment
    respondedAt
    createdAt
    updatedAt
  }
`;

export const DISPUTE_DISPUTE_RESPONSE_FRAGMENT = gql`
  fragment disputeDisputeResponse on DisputeDisputeResponse {
    ...disputeDisputeResponseFlat
    user {
      ...userFlat
    }
  }
  ${DISPUTE_DISPUTE_RESPONSE_FLAT_FRAGMENT}
  ${USER_FLAT_FRAGMENT}
`;
