import { gql } from 'apollo-boost';

export const MESSENGER_TEMPLATE_FRAGMENT = gql`
  fragment messengerTemplate on MessengerTemplate {
    id
    clientId
    state
    channel
    sender
    name
    title
    richText
    preview
    text
    createdAt
    updatedAt
  }
`;
