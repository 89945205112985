import React from 'react';

import './UUpload.css';
import { Button, Modal, Upload } from 'antd';
import { HttpRequestHeader, UploadFile } from 'antd/es/upload/interface';
import { UploadChangeParam, UploadProps } from 'antd/lib/upload';
import { compose } from 'recompose';
import FiltersContextHOC from '../FiltersContextHOC/FiltersContextHOC';
import { IFiltersContextValue } from '../FiltersProvider/FiltersProvider';

const UPLOAD_ENDPOINT = '/api/upload';

interface IExternalProps {
  files: IFile[]; // JSON string of IFile[] like: [{ "fileName | file": "image.jpg", "url": "htpps://fffff" }]

  config?: IUUploadConfig;

  onChange?: (files: IFile[]) => void; // Handle upload manually
  isUploadButtonHidden?: boolean;
}

export interface IUUploadConfig {
  isPrimitive?: boolean;
  endpoint?: string;
  headers?: Record<string, string>;
  requestData?: Record<string, any>;
  getDataFromResponse?: GetDataFromResponse;
  componentProps?: Partial<UploadProps>;
}

export type GetDataFromResponse<TResponse = any> = (uploadedFile: UploadFile<TResponse>) => IFile | null;

export interface IFile {
  url: string;
}

interface IProps extends IExternalProps {
  filtersContext: IFiltersContextValue;
}

interface IState {
  fileList: UploadFile[];
}

class UUpload extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = this.initState();
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>): void {
    if (prevProps.files !== this.props.files) {
      this.setState(this.initState());
    }
  }

  private initState = (): IState => {
    const parsedFiles = this.parseFiles();
    const fileList = parsedFiles.map(this.convertFileToUploadFile);

    return { fileList };
  };

  private callHandlers = () => {
    const { onChange } = this.props;
    const { fileList } = this.state;

    if (onChange) {
      const simplifiedFiles = fileList.map(this.getDataFromResponse);
      const files = simplifiedFiles.filter(file => file !== null) as IFile[];
      onChange(files);
    }
  };

  private getDataFromResponse = (uploadedFile: UploadFile): IFile | null => {
    if (!uploadedFile) {
      return null;
    }

    if (uploadedFile.url) {
      return {
        url: uploadedFile.url,
      };
    }

    if (!uploadedFile.response) {
      return null;
    }

    const { config } = this.props;
    if (config && config.getDataFromResponse) {
      return config.getDataFromResponse(uploadedFile);
    }

    if (!uploadedFile.url) {
      return null;
    }

    return {
      url: uploadedFile.url,
    };
  };

  private handlePreviewClick = (file: UploadFile) => {
    const { i18n } = this.props.filtersContext;

    Modal.info({
      content: this.renderPreviewImage(file),
      title: i18n('h_preview'),
      maskClosable: true,
      className: 'uUpload_previewModal',
      okType: 'default',
      okText: i18n('action_close'),
    });
  };

  private handleChange = ({ fileList, file }: UploadChangeParam<UploadFile>) => {
    const callBack = file.status === 'uploading' ? undefined : this.callHandlers;
    this.setState({ fileList }, callBack);
  };

  private getHeaders = (): HttpRequestHeader => {
    const { config } = this.props;
    const headers = (config && config.headers) || {};

    return {
      Authorization: process.env.REACT_APP_MEDIA_SERVICE_TOKEN || '',
      ...headers,
    };
  };

  private getFileList = () => {
    return this.state.fileList || [];
  };

  private getAction = (): UploadProps['action'] => {
    const { config } = this.props;
    const endpoint = config && config.endpoint;

    return endpoint || UPLOAD_ENDPOINT;
  };

  private getShowUploadList = (): UploadProps['showUploadList'] => {
    return { showDownloadIcon: false };
  };

  private getRequestData = (): UploadProps['data'] => {
    const { config } = this.props;
    const requestData = (config && config.requestData) || {};

    return { type: 'media', ...requestData };
  };

  private isMultiple = () => {
    const { config } = this.props;
    if (!config) {
      return true;
    }

    return !config.isPrimitive;
  };

  private parseFiles = (): IFile[] => {
    const { files, config } = this.props;
    if (!files) {
      return [];
    }

    if (!Array.isArray(files)) {
      return [];
    }

    const isPrimitive = config && config.isPrimitive;
    if (isPrimitive) {
      const [firstFile] = files;
      if (!firstFile || !firstFile.url) {
        return [];
      }

      return [
        {
          url: firstFile.url,
        },
      ];
    }

    return files.map(file => ({ url: file.url }));
  };

  private convertFileToUploadFile = (file: IFile): UploadFile => {
    const now = new Date().toISOString();

    return {
      uid: `${now}-${file.url}`,
      size: 110,
      name: file.url,
      type: '',
      url: file.url,
      preview: file.url,
      fileName: file.url,
    };
  };

  private renderPreviewImage = (file: UploadFile) => {
    return <img src={file.url} alt="preview" className="uUpload_previewImg" />;
  };

  private renderUploader = () => {
    const { config } = this.props;
    const componentProps = config && config.componentProps;

    const fileList = this.getFileList();
    const headers = this.getHeaders();
    const action = this.getAction();
    const multiple = this.isMultiple();
    const data = this.getRequestData();
    const showUploadList = this.getShowUploadList();

    return (
      <Upload
        multiple={multiple}
        fileList={fileList}
        showUploadList={showUploadList}
        onPreview={this.handlePreviewClick}
        onChange={this.handleChange}
        listType="picture"
        headers={headers}
        action={action}
        data={data}
        {...componentProps}
      >
        {this.renderUploadBtn()}
      </Upload>
    );
  };

  private renderUploadBtn = () => {
    const { isUploadButtonHidden, filtersContext, config } = this.props;
    if (isUploadButtonHidden) {
      return null;
    }

    const filesUploaded = this.state.fileList.length;
    const isDisabled = config?.isPrimitive && filesUploaded > 0;

    return (
      <Button disabled={isDisabled} icon="upload">
        {filtersContext.i18n('action_upload')}
      </Button>
    );
  };

  private renderContent = () => {
    return this.renderUploader();
  };

  render() {
    return <div>{this.renderContent()}</div>;
  }
}

export default compose<IProps, IExternalProps>(FiltersContextHOC({}))(UUpload);
