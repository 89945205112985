import React from 'react';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import FloatPanel from '../FloatPanel/FloatPanel';
import Page from '../Page/Page';
import Header from '../Header/Header';
import { i18n } from '../../helpers/I18n';
import DeleteAction from '../../universal/components/DeleteAction/DeleteAction';
import { Col, Row } from 'antd';
import { ROW_GUTTER } from '../../config/constants';
import UForm, { FormComponent, IFormField, UFormState } from '../../universal/components/UForm/UForm';
import {
  CreatePaymentAccountMutation,
  CreatePaymentAccountProps,
  DeletePaymentAccountProps,
  FilterDataType,
  PaymentAccountProps,
  PaymentAccountState,
  UpdatePaymentAccountProps,
  UserFragment,
  withCreatePaymentAccount,
  withDeletePaymentAccount,
  withPaymentAccount,
  withUpdatePaymentAccount,
} from '../../typings/graphql';
import { IParamsWithId } from '../../typings/local';
import { isNewPage } from '../../helpers/isNewPage';
import { getMutateProps } from '../../helpers/mutationOperationOptions';
import { handleFail, handleSuccess, OPERATIONS } from '../../helpers/handleMutation';
import { ALL_USERS_QUERY } from '../../graph/queries/allUsers';
import PaymentTransactionsTable from '../PaymentTransactionsTable/PaymentTransactionsTable';
import { RcmProps } from '../Rcm/Rcm';
import { ALL_LOYALTY_PARTNERS_QUERY } from '../../graph/queries/allLoyaltyPartners';
import { ALL_PAYMENT_CURRENCIES_QUERY } from '../../graph/queries/allPaymentCurrencies';
import { getFullName } from '../../helpers/getFullName';
import { SettingsAction } from '../../universal';
import LoyaltyPointsForm from '../LoyaltyPointsForm/LoyaltyPointsForm';
import { ISettingsActionProps } from '../../universal/components/SettingsAction/SettingsAction';
import LoyaltyPartner from '../LoyaltyPartner/LoyaltyPartner';
import { ModalProps } from 'antd/es/modal';

interface IExternalProps {}

interface IProps extends IExternalProps, RouteComponentProps<IParamsWithId> {
  paymentAccount?: PaymentAccountProps['data'];

  update: UpdatePaymentAccountProps;
  delete: DeletePaymentAccountProps;
  create: CreatePaymentAccountProps;
}

interface IState {}

// We gonna operate FilterInput, so we give him an ID and be able find it later to change value
export const PAYMENT_ACCOUNTS_FILTER_INPUT_ID = 'payment_accounts';

class PaymentAccountPage extends React.PureComponent<IProps, IState> {
  private handleCreate = (formState: UFormState) => {
    this.props.create
      .mutate({
        variables: {
          data: {
            balance: formState.balance,
            spent: formState.spent,
            state: formState.state,
            userId: formState.userId,
            currencyId: formState.currencyId,
            providerId: formState.providerId,
            providerCode: formState.providerCode,
          },
        },
      })
      .then(this.handleCreateSuccess)
      .catch(handleFail(OPERATIONS.CREATE));
  };

  private handleCreateSuccess = (res: any) => {
    if (!res || !res.data || !res.data.createPaymentAccount) {
      return handleFail(OPERATIONS.CREATE)();
    }

    const mutationResult: CreatePaymentAccountMutation = res.data;
    if (!mutationResult.createPaymentAccount || !mutationResult.createPaymentAccount.id) {
      return handleFail(OPERATIONS.CREATE)();
    }

    this.props.history.push(`/payment/accounts/${mutationResult.createPaymentAccount.id}`);
  };

  private handleUpdate = (formState: UFormState) => {
    const { update, match } = this.props;

    update
      .mutate({
        variables: {
          id: Number(match.params.id),
          data: formState as any, // TODO: Set arguments manually
        },
      })
      .then(handleSuccess(OPERATIONS.UPDATE))
      .catch(handleFail(OPERATIONS.UPDATE));
  };

  private handleDelete = () => {
    const { match } = this.props;

    this.props.delete
      .mutate({
        variables: {
          id: Number(match.params.id),
        },
      })
      .then(this.handleDeleteSuccess)
      .catch(handleFail(OPERATIONS.DELETE));
  };

  private handleDeleteSuccess = () => {
    const { paymentAccount } = this.props;
    if (!paymentAccount || !paymentAccount.paymentAccount) {
      return handleSuccess(OPERATIONS.DELETE)();
    }

    this.props.history.push('/payment/accounts');
  };

  private isNewPage = () => isNewPage(this.props);

  private handleSubmitForm = (formState: UFormState) => {
    const isNewPage = this.isNewPage();
    if (isNewPage) {
      return this.handleCreate(formState);
    }

    return this.handleUpdate(formState);
  };

  private getFields = (): IFormField[] => {
    const isNewPage = this.isNewPage();
    const isDisabled = !isNewPage;
    return [
      {
        field: 'userId',
        component: FormComponent.SELECT,
        optionsQuery: {
          query: ALL_USERS_QUERY,
          queryName: 'allUsers',
          render: this.renderUser,
        },
        isDisabled: false,
        isRequired: true,
      },
      {
        field: 'currencyId',
        component: FormComponent.SELECT,
        optionsQuery: {
          query: ALL_PAYMENT_CURRENCIES_QUERY,
          queryName: 'allPaymentCurrencies',
        },
        isRequired: true,
        isDisabled: isDisabled,
      },
      {
        field: 'state',
        component: FormComponent.SELECT,
        enumValue: PaymentAccountState,
      },
      {
        field: 'providerCode',
        component: FormComponent.INPUT,
        isDisabled: isDisabled,
      },
      {
        field: 'providerId',
        component: FormComponent.SELECT,
        optionsQuery: {
          query: ALL_LOYALTY_PARTNERS_QUERY,
          queryName: 'allLoyaltyPartners',
        },
        isDisabled: isDisabled,
      },
      {
        field: 'balance',
        component: FormComponent.INPUT,
        type: FilterDataType.NUMBER,
        isDisabled: true,
      },
      {
        field: 'spent',
        component: FormComponent.INPUT,
        type: FilterDataType.NUMBER,
        isDisabled: true,
      },
    ];
  };

  private getPaymentAccount = () => {
    const { paymentAccount } = this.props;
    if (!paymentAccount || !paymentAccount.paymentAccount) {
      return null;
    }

    return paymentAccount.paymentAccount;
  };

  private isLoading = () => {
    const { paymentAccount } = this.props;

    return paymentAccount && paymentAccount.loading;
  };

  private isPending = (): boolean => {
    const isLoading = this.isLoading();

    const { loading: isUpdating } = this.props.update.result;
    const { loading: isCreating } = this.props.create.result;
    const { loading: isDeleting } = this.props.delete.result;

    return isLoading || isUpdating || isCreating || isDeleting;
  };

  private getError = () => {
    const { paymentAccount } = this.props;
    const errorLoading = paymentAccount && paymentAccount.error;

    const { error: errorUpdating } = this.props.update.result;
    const { error: errorCreating } = this.props.create.result;
    const { error: errorDeleting } = this.props.delete.result;

    return errorLoading || errorCreating || errorUpdating || errorDeleting;
  };

  private getTitle = () => {
    const heading = i18n('h_payment_account');
    const paymentAccount = this.getPaymentAccount();

    if (isNewPage(this.props)) {
      return `${heading}: ${i18n('new')}`;
    }

    if (!paymentAccount) {
      return null;
    }

    return `${heading}: ${paymentAccount.id}`;
  };

  private renderUser = (user: UserFragment) => {
    const fullName = `${getFullName(user)} ${user.id}`;
    const fallBack = `${user.__typename} #${user.id}`;
    return fullName || fallBack;
  };

  private renderLoyaltyPartner = () => {
    const title = i18n('h_loyalty_partner');
    const paymentAccount = this.getPaymentAccount();
    if (!paymentAccount) {
      return null;
    }
    return (
      <FloatPanel title={title}>
        <LoyaltyPartner paymentAccount={paymentAccount} />
      </FloatPanel>
    );
  };

  private renderContent = () => {
    return (
      <>
        <Row gutter={ROW_GUTTER}>
          <Col span={8}>{this.renderFormCard()}</Col>
          <Col span={16}>{this.renderTransactions()}</Col>
        </Row>
        {this.renderLoyaltyPartner()}
      </>
    );
  };

  private renderFormCard = () => {
    return <FloatPanel title={i18n('h_payment_account')}>{this.renderForm()}</FloatPanel>;
  };

  private renderTransactions = () => {
    if (isNewPage(this.props)) {
      return null;
    }

    const accountId = Number(this.props.match.params.id);

    const rcmProps: RcmProps = {
      isWrapped: true,
      title: i18n('payment_transactions'),
    };

    return <PaymentTransactionsTable accountId={accountId} rcmProps={rcmProps} />;
  };

  private renderForm = () => {
    const fields = this.getFields();
    const instance = this.getPaymentAccount();
    const isLoading = this.isPending();
    const error = this.getError();

    return (
      <UForm fields={fields} instance={instance} isLoading={isLoading} error={error} onSubmit={this.handleSubmitForm} />
    );
  };

  private renderCreateModalContent = (
    operation: 'charge' | 'deposit'
  ): ISettingsActionProps['renderContent'] => closeModalFn => {
    const accountId = Number(this.props.match.params.id);
    return (
      <LoyaltyPointsForm accountId={accountId} operation={operation} onCharge={closeModalFn} onDeposit={closeModalFn} />
    );
  };

  private renderPaymentAction = (operation: 'charge' | 'deposit') => {
    const buttonText = i18n(`action_${operation}`);
    const modalProps: ModalProps = { title: i18n(`h_${operation}`) };

    return (
      <SettingsAction
        key={operation}
        type="modal"
        buttonText={buttonText}
        modalProps={modalProps}
        renderContent={this.renderCreateModalContent(operation)}
      />
    );
  };

  private renderDepositButton = () => this.renderPaymentAction('deposit');
  private renderChargeButton = () => this.renderPaymentAction('charge');

  private renderHeaderActions = () => {
    return [
      this.renderDepositButton(),
      this.renderChargeButton(),
      <DeleteAction key="delete" onConfirm={this.handleDelete} />,
    ];
  };

  private renderHeader = () => {
    const placeholder = i18n('h_payment_account');
    const title = this.getTitle();
    const actions = this.renderHeaderActions();

    return <Header placeholder={placeholder} title={title} actions={actions} />;
  };

  render() {
    return (
      <Page>
        {this.renderHeader()}
        <Page.Content>{this.renderContent()}</Page.Content>
      </Page>
    );
  }
}

export default compose<IProps, IExternalProps>(
  withCreatePaymentAccount(getMutateProps('create')),
  withUpdatePaymentAccount(getMutateProps('update')),
  withDeletePaymentAccount(getMutateProps('delete')),
  withPaymentAccount<IProps>({
    name: 'paymentAccount',
    skip: isNewPage,
    options: props => ({
      variables: {
        id: Number(props.match.params.id),
      },
    }),
  })
)(PaymentAccountPage);
