import { gql } from 'apollo-boost';
import { CATALOGUE_ITEM_FLAT_FRAGMENT } from './catalogueItem';
import { USER_FLAT_FRAGMENT } from './user';
import { COMMERCE_ORDER_ITEM_FRAGMENT } from './commerceOrderItem';

// TODO временный фрагмент пока в базе нет поля USER
export const COMMERCE_ORDER_UPDATE_FRAGMENT = gql`
  fragment commerceOrderUpdate on CommerceOrder {
    id
    shopId
    clientId
    state
    paymentState
    userId
    externalEntityId
    code
    total
    paidAmount
    createdAt
    updatedAt
  }
`;

export const COMMERCE_ORDER_FRAGMENT = gql`
  fragment commerceOrder on CommerceOrder {
    id
    shopId
    clientId
    state
    paymentState
    user {
      ...userFlat
    }
    data {
      key
      value
    }
    items {
      ...commerceOrderItem
    }
    userId
    externalEntityId
    code
    total
    paidAmount
    createdAt
    updatedAt
  }
  ${USER_FLAT_FRAGMENT}
  ${CATALOGUE_ITEM_FLAT_FRAGMENT}
  ${COMMERCE_ORDER_ITEM_FRAGMENT}
`;
