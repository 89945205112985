import { gql } from 'apollo-boost';
import { MESSENGER_QUEUE_TASK_FRAGMENT } from './messengerQueueTask';
import { MESSENGER_QUEUE_NOTIFICATION_STAT_FRAGMENT } from './messengerQueueStat';

export const MESSENGER_MAILING_FRAGMENT = gql`
  fragment messengerMailing on MessengerMailing {
    id
    clientId
    name
    sendAfter
    sender
    channel
    preview
    transportId
    image
    stats {
      ...messengerQueueNotificationStat
    }
    currentTask {
      ...messengerQueueTask
    }
    state
    subject
    richText
    metaDesign
    text
    templateId
    createdAt
    updatedAt
  }
  ${MESSENGER_QUEUE_TASK_FRAGMENT}
  ${MESSENGER_QUEUE_NOTIFICATION_STAT_FRAGMENT}
`;
