import { gql } from 'apollo-boost';
import { PAYMENT_CURRENCY_FRAGMENT } from './paymentCurrency';

export const PAYMENT_CURRENCY_CONNECTION_FRAGMENT = gql`
  fragment paymentCurrencyConnection on PaymentCurrencyConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...paymentCurrency
    }
  }
  ${PAYMENT_CURRENCY_FRAGMENT}
`;
