import { gql } from 'apollo-boost';
import { USER_FRAGMENT } from '../fragments/user';

export const USER_QUERY = gql`
  query user($id: Int!) {
    user(id: $id) {
      ...user
    }
  }
  ${USER_FRAGMENT}
`;
