export const getQueryNameFromQuery = (query: any): string => {
  if (!query) {
    throw new Error('gql query file is not provided');
  }

  const definitions = query?.definitions ?? [];
  const operation = definitions.find((def: any) => def?.kind === 'OperationDefinition');
  if (!operation) {
    throw new Error('Operation not found in gql query file');
  }

  const result = operation.name?.value;
  if (typeof result !== 'string') {
    throw new Error('Error extracting query name');
  }

  return result;
};
