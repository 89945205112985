import { gql } from 'apollo-boost';
import { ARTICLE_BLOCK_FRAGMENT } from '../fragments/articleBlock';

export const ARTICLE_BLOCK_QUERY = gql`
  query articleBlock($id: Int!) {
    articleBlock(id: $id) {
      ...articleBlock
    }
  }
  ${ARTICLE_BLOCK_FRAGMENT}
`;
