import * as React from 'react';
import { Avatar, Divider, Dropdown, Menu } from 'antd';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { ClickParam } from 'antd/es/menu';

import SystemUserProfileModal from '../SystemUserProfileModal/SystemUserProfileModal';
import { i18n } from '../../helpers/I18n';
import { withSystemUserProfile, SystemUserProfileProps } from '../../typings/graphql';
import { EVENTS, EventService } from '../../universal/services/EventService';

import './UserAvatar.scss';

const IMAGE_SIZE = 48;

type AvatarMenuItems = 'settings' | 'logout';

interface IExternalProps {
  showFullName?: boolean;
  showDivider?: boolean;
}

interface IProps extends IExternalProps {
  systemUserProfile: SystemUserProfileProps['data'];
}

interface IState {
  isProfileModalVisible: boolean;
}

class UserAvatar extends React.Component<IProps, IState> {
  public readonly state: IState = {
    isProfileModalVisible: false,
  };

  private handleLogout = () => {
    EventService.fire(EVENTS.LOGOUT);
  };

  private getAvatarMenuConfig = (): Record<AvatarMenuItems, string> => ({
    settings: i18n('settings'),
    logout: i18n('logout'),
  });

  private handleOpenModal = (key: keyof IState) => () => {
    this.setState({ ...this.state, [key]: true });
  };

  private handleCloseModal = (key: keyof IState) => () => {
    this.setState({ ...this.state, [key]: false });
  };

  private handleCancelProfileModal = () => {
    this.handleCloseModal('isProfileModalVisible')();
  };

  private renderProfileModal = () => {
    const { isProfileModalVisible } = this.state;

    return <SystemUserProfileModal visible={isProfileModalVisible} onCancel={this.handleCancelProfileModal} />;
  };

  private handleClickAvatarMenu = (param: ClickParam) => {
    const key = param.key as AvatarMenuItems;

    if (key === 'logout') {
      return this.handleLogout();
    }

    this.handleOpenModal('isProfileModalVisible')();
  };

  private renderAvatarOverlay = () => {
    const menuConfig = this.getAvatarMenuConfig();
    const menuKeys = (Object.keys(menuConfig) as unknown) as AvatarMenuItems[];

    return (
      <Menu onClick={this.handleClickAvatarMenu}>
        {menuKeys.map(key => (
          <Menu.Item key={key}>{menuConfig[key]}</Menu.Item>
        ))}
      </Menu>
    );
  };

  private renderDivider = () => {
    if (this.props.showDivider) {
      return <Divider className="header_separator" type="vertical" />;
    }
    return null;
  };

  private renderAvatar = () => {
    const { fullName, text } = this.getAvatarText();
    if (this.props.showFullName) {
      return (
        <div className="avatar">
          <Avatar className="avatar_photo" size={IMAGE_SIZE}>
            {text}
          </Avatar>
          <div className="avatar_fullName" title={fullName}>
            {fullName}
          </div>
        </div>
      );
    } else {
      return <Avatar size={IMAGE_SIZE}>{text}</Avatar>;
    }
  };

  private renderUser = () => {
    const { systemUserProfile } = this.props.systemUserProfile;
    if (!systemUserProfile) {
      return (
        <div className="avatar">
          <Avatar icon="loading" size={IMAGE_SIZE} />
        </div>
      );
    }

    const overlay = this.renderAvatarOverlay();
    return (
      <div className="avatar">
        {this.renderDivider()}
        <Dropdown overlay={overlay} trigger={['click']}>
          {this.renderAvatar()}
        </Dropdown>
      </div>
    );
  };

  private getAvatarText = () => {
    let fullName = i18n('user');
    let text = '';

    const { systemUserProfile } = this.props.systemUserProfile;
    if (!systemUserProfile) {
      return {
        fullName,
        text,
      };
    }

    const firstName = systemUserProfile.firstName || '';
    const lastName = systemUserProfile.lastName || '';

    if (firstName && lastName) {
      fullName = [firstName, lastName].join(' ');

      const initials = [];
      initials.push(firstName[0].toUpperCase(), lastName[0].toUpperCase());
      text = initials.join('');
    }

    return { fullName, text };
  };

  public render() {
    return (
      <>
        {this.renderUser()}
        {this.renderProfileModal()}
      </>
    );
  }
}

export default compose<IProps, IExternalProps>(
  withRouter,
  withSystemUserProfile({ name: 'systemUserProfile' })
)(UserAvatar);
