import * as React from 'react';
import { Button } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import { ButtonProps } from 'antd/lib/button';
import UDrawer from '../UDrawer/UDrawer';
import { withApollo, WithApolloClient } from '@apollo/react-hoc';
import { compose } from 'recompose';
import FiltersContextHOC from '../FiltersContextHOC/FiltersContextHOC';
import { IFiltersContextValue } from '../FiltersProvider/FiltersProvider';
import './FiltersAction.css';
import { FilterItemFragment } from '../../typings/graphql';

interface IExternalProps {
  buttonProps?: ButtonProps;
  onClick?: () => void;
  buttonText?: string;
  queryName: string;
  id?: string;
  customFilters?: FilterItemFragment[] | null;
}

interface IProps extends IExternalProps {
  client: WithApolloClient<{}>['client'];
  filtersContext: IFiltersContextValue;
}

interface IState {
  isDrawerVisible: boolean;
}

class FiltersAction extends React.Component<IProps, IState> {
  state: IState = {
    isDrawerVisible: false,
  };

  private handleClick = () => {
    this.setState({ isDrawerVisible: true }, () => {
      const { onClick } = this.props;
      if (onClick) {
        onClick();
      }
    });
  };

  private handleCloseDrawer = () => {
    this.setState({ isDrawerVisible: false });
  };

  private getButtonText = () => {
    const { buttonText } = this.props;
    return buttonText || null;
  };

  private renderDrawer = () => {
    const drawerProps = { onClose: this.handleCloseDrawer };
    const { isDrawerVisible } = this.state;
    const { queryName, id, customFilters } = this.props;

    return (
      <UDrawer
        queryName={queryName}
        id={id}
        isVisible={isDrawerVisible}
        drawerProps={drawerProps}
        customFilters={customFilters}
      />
    );
  };

  public render() {
    const buttonText = this.getButtonText();
    const { buttonProps } = this.props;

    return (
      <>
        {this.renderDrawer()}
        <Button {...buttonProps} className="filtersAction" onClick={this.handleClick}>
          <FilterOutlined />
          {buttonText}
        </Button>
      </>
    );
  }
}

export default compose<IProps, IExternalProps>(withApollo, FiltersContextHOC())(FiltersAction);
