export const EVENTS = {
  LOGOUT: 'LOGOUT',
  ARTICLE_LINK_CLICK: 'ARTICLE_LINK_CLICK',
  STORAGE_CLEARED: 'STORAGE_CLEARED', // TODO: Unify naming
  COMMERCE_ORDER_UPDATE: 'COMMERCE_ORDER_UPDATE',
  LOYALTY_PARTNER_UPDATE: 'LOYALTY_PARTNER_UPDATE',
  LOYALTY_PARTNER_CREATE: 'LOYALTY_PARTNER_CREATE',
  LOYALTY_PARTNER_DELETE: 'LOYALTY_PARTNER_DELETE',
} as const;

type EventsType = typeof EVENTS;
export type EventNames = keyof EventsType;

export class EventService {
  static buildEvent<TDetail>(event: EventNames, detail?: TDetail) {
    return new CustomEvent<TDetail>(event, { detail });
  }

  static dispatch(event: CustomEvent) {
    document.dispatchEvent(event);
  }

  // TODO: Rename?
  static fire<TDetail>(eventName: EventNames, detail?: TDetail) {
    const event = EventService.buildEvent(eventName, detail);
    return EventService.dispatch(event);
  }
}
