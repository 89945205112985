import { gql } from 'apollo-boost';
import { COMMERCE_SHOP_FRAGMENT } from '../fragments/commerceShop';

export const COMMERCE_SHOP_QUERY = gql`
  query commerceShop($id: Int!) {
    commerceShop(id: $id) {
      ...commerceShop
    }
  }
  ${COMMERCE_SHOP_FRAGMENT}
`;
