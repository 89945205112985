import { gql } from 'apollo-boost';
import { MESSENGER_TEMPLATE_FRAGMENT } from '../fragments/messengerTemplate';

export const MESSENGER_TEMPLATE_QUERY = gql`
  query messengerTemplate($id: Int!) {
    messengerTemplate(id: $id) {
      ...messengerTemplate
    }
  }
  ${MESSENGER_TEMPLATE_FRAGMENT}
`;
