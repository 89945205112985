import { gql } from 'apollo-boost';
import { CURRENCY_FRAGMENT } from './currency';

export const CATALOGUE_ITEM_PRICE_FRAGMENT = gql`
  fragment catalogueItemPrice on CatalogueItemPrice {
    id
    currencyId
    value
    catalogueItemId
    currency {
      ...currency
    }
    createdAt
    updatedAt
  }
  ${CURRENCY_FRAGMENT}
`;
