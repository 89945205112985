import * as React from 'react';
import './Page.scss';
import { CSSProperties } from 'react';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import Overlay from '../Overlay/Overlay';

// Components
type PageComponentType = React.FunctionComponent<{
  className?: string;
}>;
type PageContentComponentType = React.FunctionComponent<{
  className?: string;
  style?: CSSProperties;
  muted?: boolean;
}>;

// Map of attached to main component elements
type SubComponents = {
  Content: PageContentComponentType;
};

// Result
type PageCompositeComponent = PageComponentType & SubComponents;

const Page: PageCompositeComponent = ({ children, className }) => {
  const classNamesCollection = ['page'];
  if (className) {
    classNamesCollection.push(className);
  }

  const classNames = classNamesCollection.join(' ');

  return <div className={classNames}>{children}</div>;
};

const PageContent: PageContentComponentType = ({ children, className, style, muted }) => {
  const classNamesCollection = ['pageContent'];
  if (className) {
    classNamesCollection.push(className);
  }

  const classNames = classNamesCollection.join(' ');
  const isVisible = typeof muted === 'boolean' ? muted : false;

  let content = children;
  if (muted) {
    content = <Overlay isVisible={isVisible}>{children}</Overlay>;
  }

  return (
    <ErrorBoundary>
      <div style={style} className={classNames}>
        {content}
      </div>
    </ErrorBoundary>
  );
};

Page.Content = PageContent;

export default Page;
