import { gql } from 'apollo-boost';
import { EXTENSION_FIELD_TRANSLATION_FRAGMENT } from './extensionFieldTranslation';

export const EXTENSION_FIELD_FRAGMENT = gql`
  fragment extensionField on ExtensionField {
    id
    relationId
    name
    dataType
    isReadOnly
    sort
    i18n
    translations {
      ...extensionFieldTranslation
    }
    createdAt
    updatedAt
  }
  ${EXTENSION_FIELD_TRANSLATION_FRAGMENT}
`;

export const EXTENSION_FIELD_CONTENT_TRANSLATION_FRAGMENT = gql`
  fragment extensionFieldContentTranslation on ExtensionField {
    id
    relationId
    name
    dataType
    isReadOnly
    sort
    i18n
    translations {
      ...extensionFieldTranslation
    }
    createdAt
    updatedAt
  }
  ${EXTENSION_FIELD_TRANSLATION_FRAGMENT}
`;
