import { gql } from 'apollo-boost';
import { TOURNAMENT_TEAM_MEMBER_SMALL_FRAGMENT } from './tournamentTeamMember';
import { TOURNAMENT_TEAM_SMALL_FRAGMENT } from './tournamentTeam';

export const TOURNAMENT_MATCH_EVENT_FRAGMENT = gql`
  fragment tournamentMatchEvent on TournamentMatchEvent {
    id
    matchId
    datetime
    minute
    event
    teamMemberId
    teamId
    comment
    isProtocol
    linkedMatchEventId
    player {
      ...tournamentTeamMemberSmall
    }
    team {
      ...tournamentTeamSmall
    }
    createdAt
    updatedAt
  }
  ${TOURNAMENT_TEAM_MEMBER_SMALL_FRAGMENT}
  ${TOURNAMENT_TEAM_SMALL_FRAGMENT}
`;
