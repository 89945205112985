import { gql } from 'apollo-boost';
import { PAYMENT_CURRENCY_FRAGMENT } from '../fragments/paymentCurrency';

export const PAYMENT_CURRENCY_QUERY = gql`
  query paymentCurrency($id: Int!) {
    paymentCurrency(id: $id) {
      ...paymentCurrency
    }
  }
  ${PAYMENT_CURRENCY_FRAGMENT}
`;
