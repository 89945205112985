import React from 'react';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import { i18n } from '../../helpers/I18n';
import DeleteAction from '../../universal/components/DeleteAction/DeleteAction';
import FloatPanel from '../FloatPanel/FloatPanel';
import { DEFAULT_PAGE_SIZE } from '../../config/constants';
import {
  AllCommerceOrderItemsQuery,
  AllCommerceOrderItemsQueryVariables,
  CommerceOrder as CommerceOrderType,
  CommerceOrderData,
  CommerceOrderFragment,
  CommerceOrderItemFragment,
  CommerceOrderProps,
  CommerceOrderState,
  CommerceOrderUpdateFragment,
  CommerceShop,
  DefaultQueryInput,
  DeleteCommerceOrderItemMutation,
  DeleteCommerceOrderItemProps,
  UpdateCommerceOrderMutation,
  UpdateCommerceOrderProps,
  withCommerceOrder,
  withDeleteCommerceOrderItem,
  withUpdateCommerceOrder,
  CateringFlatFragment,
} from '../../typings/graphql';
import { getMutateProps } from '../../helpers/mutationOperationOptions';
import { handleFail, handleSuccess, OPERATIONS } from '../../helpers/handleMutation';
import { ALL_COMMERCE_ORDER_ITEMS_QUERY } from '../../graph/queries/allCommerceOrderItems';
import { UTable } from '../../universal/index';
import { MutationUpdaterFn } from 'apollo-client';
import { getFromLocalStorage } from '../../helpers/getFromLocalStorage';
import './CommerceOrder.scss';
import { getFullName } from '../../helpers/getFullName';
import InlineLink from '../InlineLink/InlineLink';
import { Button, Col, Modal, Row, Select } from 'antd';
import { renderDate } from '../../helpers/renderDate';
import { ColumnProps } from 'antd/es/table';
import confirm from 'antd/es/modal/confirm';
import { withRouter } from 'react-router-dom';
import { ExecutionResult } from 'graphql';
import Rcm, { RcmProps } from '../Rcm/Rcm';
import CommerceOrderItemForm from '../CommerceOrderItemForm/CommerceOrderItemForm';
import CommerceOrderPrintForm from '../CommerceOrderPrintForm/CommerceOrderPrintForm';
import { UTableProps, UTableAPI } from '../../universal/components/UTable/UTable';
import { COMMERCE_ORDER_QUERY } from '../../graph/queries/commerceOrder';

const { Option } = Select;

interface IExternalProps {
  orderId: CommerceOrderType['id'] | null;
  printReceiptTemplateId?: CateringFlatFragment['printReceiptTemplateId'];
  shopId: CommerceShop['id'];
  colSpan?: number;
  rcmProps?: Partial<RcmProps>;
  onUpdate?: (order: CommerceOrderUpdateFragment) => void;
}

interface IProps extends IExternalProps, RouteComponentProps {
  update: UpdateCommerceOrderProps;
  deleteOrderItem: DeleteCommerceOrderItemProps;
  commerceOrder?: CommerceOrderProps['data'];
}

interface IState {
  itemRecord: CommerceOrderItemFragment | null;
  showPrintModal: boolean;
  showAddItemModal: boolean;
  isSettingsVisible: boolean;
  uTableAPI: UTableAPI | null;
}

class CommerceOrder extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      itemRecord: null,
      showPrintModal: false,
      showAddItemModal: false,
      isSettingsVisible: false,
      uTableAPI: null,
    };
  }

  private getKeys = () => {
    return ['id', 'catalogueItemId', 'createdAt', 'qty', 'price', 'total', 'deleteAction'];
  };

  private getQueryInput = (): DefaultQueryInput => {
    const { orderId } = this.props;

    return {
      filters: [
        {
          field: 'orderId',
          value: JSON.stringify(orderId),
        },
      ],
    };
  };

  private showAddItemModal = () => {
    this.setState({
      showAddItemModal: true,
    });
  };

  private handleCancelAddItemModal = () => {
    this.hideAddItemModal();
  };

  private hideAddItemModal = (callback?: () => void) => {
    this.setState(
      {
        showAddItemModal: false,
      },
      callback
    );
  };

  private handleAddItemClick = () => {
    this.showAddItemModal();
  };

  private handleCreateItemSuccess = () => {
    this.refetchOrders();
    return this.hideAddItemModal();
  };

  private handleUpdateSuccess = (res: ExecutionResult<UpdateCommerceOrderMutation>) => {
    const updated = res && res.data && res.data.updateCommerceOrder;
    if (!updated) {
      return handleFail(OPERATIONS.UPDATE)();
    }

    handleSuccess(OPERATIONS.UPDATE)();

    return this.hideAddItemModal(() => {
      const { onUpdate } = this.props;
      if (onUpdate) {
        onUpdate(updated);
      }
    });
  };

  private renderAddItemModal = () => {
    const order = this.getOrder();
    if (!order) {
      return null;
    }

    const { showAddItemModal } = this.state;
    const title = `${i18n('add_item_to_order')} ${order.id}`;

    return (
      <Modal
        className="commerceOrder addItemsModal"
        visible={showAddItemModal}
        footer={null}
        onCancel={this.handleCancelAddItemModal}
        title={title}
      >
        {this.renderAddItemForm()}
      </Modal>
    );
  };

  private renderAddItemForm = () => {
    const { shopId, orderId } = this.props;

    return (
      <CommerceOrderItemForm
        instanceId={null}
        shopId={shopId}
        orderId={orderId}
        onCreate={this.handleCreateItemSuccess}
      />
    );
  };

  private renderOrderItemsExtra = () => {
    return [
      <Button
        key="add"
        onClick={this.handleAddItemClick}
        className="commerceOrder_addItemBtn commerceOrder_addItemBtn--extra"
      >
        {i18n('action_add_item')}
      </Button>,
    ];
  };

  private handleDeleteSuccess = () => {
    handleSuccess(OPERATIONS.DELETE)();
    this.refetchOrders();
  };

  private handleDeleteOrderItem = () => {
    const { itemRecord } = this.state;
    if (!itemRecord) {
      return;
    }

    this.props.deleteOrderItem
      .mutate({
        variables: {
          id: Number(itemRecord.id),
        },
        refetchQueries: this.getRefetchQuery(),
        awaitRefetchQueries: true,
      })
      .then(this.handleDeleteSuccess)
      .catch(handleFail(OPERATIONS.DELETE));
  };

  private renderCataloguePreview = (itemId: CommerceOrderItemFragment['id'], item: CommerceOrderItemFragment) => {
    const { preview, name } = item.catalogueItem;
    if (!preview) {
      return null;
    }

    return <img src={preview} className="commerceOrder_preview" alt={name} />;
  };

  private renderCatalogueTitle = (itemId: any, item: any) => {
    return item.catalogueItem.title;
  };

  private getCustomColumns = (): ColumnProps<any>[] => {
    return [
      {
        title: i18n('preview'),
        key: 'id',
        render: this.renderCataloguePreview,
      },
      {
        title: i18n('title'),
        key: 'catalogueItemId',
        render: this.renderCatalogueTitle,
      },
      {
        title: null,
        key: 'deleteAction',
        render: this.renderDeleteAction,
      },
    ];
  };

  private renderDeleteAction = () => <DeleteAction onConfirm={this.handleDeleteOrderItem} />;

  private handleClickRow = (record: CommerceOrderItemFragment) => (event: React.MouseEvent<Element, MouseEvent>) => {
    const { shopId, orderId } = this.props;
    const isButtonClicked = event.target instanceof HTMLButtonElement;
    if (!isButtonClicked) {
      const path = `/commerce/shops/${shopId}/orders/${orderId}/items/${record.id}`;
      this.props.history.push(path);
    } else {
      this.setState({
        itemRecord: record,
      });
    }
  };

  private getOrderItemsTableProps = () => {
    return {
      onRow: (record: CommerceOrderItemFragment) => ({
        onClick: this.handleClickRow(record),
      }),
    };
  };

  private refetchOrders = () => {
    // Old implementation
    // нужно в renderOrderItems
    // queryInput={queryInput}
    // query={ALL_COMMERCE_ORDER_ITEMS_QUERY}
    // const { uTableAPI } = this.state;
    // if (!uTableAPI) {
    //   return;
    // }
    // const variables = uTableAPI.getQueryInput();
    // uTableAPI.refetch && uTableAPI.refetch(variables);
  };

  private handleTableReady: UTableProps['onReady'] = uTableAPI => {
    this.setState({ uTableAPI });
  };

  private renderOrderItems = () => {
    if (this.isNew()) {
      return null;
    }

    const tableProps = this.getOrderItemsTableProps();
    const customColumns = this.getCustomColumns();
    const actions = this.renderOrderItemsExtra();
    const keys = this.getKeys();
    const title = i18n('h_order_items');

    const { orderId } = this.props;
    const order = this.getOrder();
    const tableId = `allCommerceOrderItems-${orderId}`;
    return (
      <UTable
        title={title}
        id={tableId}
        tableProps={tableProps}
        actions={actions}
        dataSource={order?.items || []}
        customColumns={customColumns}
        keys={keys}
        onReady={this.handleTableReady}
      />
    );
  };

  private handlePrintClick = () => {
    this.showPrintModal();
  };

  private renderPrintButton = () => {
    return (
      <Button className="orderInfo_printBtn" onClick={this.handlePrintClick}>
        {i18n('action_print')}
      </Button>
    );
  };

  private getRefetchQuery = () => {
    const { orderId } = this.props;
    const formattedOrderId = orderId ? JSON.stringify(orderId) : null;
    const refetchQueries = [];
    if (formattedOrderId) {
      refetchQueries.push({
        query: COMMERCE_ORDER_QUERY,
        variables: { id: orderId },
      });
    }
    return refetchQueries;
  };

  private handleOrderUpdate = (value: CommerceOrderState) => () => {
    const { update } = this.props;
    const order = this.getOrder();
    if (!order) {
      return null;
    }
    update
      .mutate({
        variables: {
          id: Number(order.id),
          data: {
            state: value,
          },
        },
        refetchQueries: this.getRefetchQuery(),
        awaitRefetchQueries: true,
      })
      .then(this.handleUpdateSuccess)
      .catch(handleFail(OPERATIONS.UPDATE));
  };

  private handleOrderStateChange = (state: CommerceOrderState) => {
    const title = i18n('order_change_state_confirm');
    const content = `${i18n('please_confirm_change_state')}: ${i18n(`state_${state}`)}`;

    confirm({
      title: title,
      content: content,
      onOk: this.handleOrderUpdate(state),
    });
  };

  private renderOrderState = () => {
    const order = this.getOrder();
    if (!order) {
      return null;
    }

    const states = (Object.keys(CommerceOrderState) as unknown) as CommerceOrderState[];

    return (
      <Select onChange={this.handleOrderStateChange} value={order.state} style={{ minWidth: '200px' }}>
        {states.map(state => (
          <Option key={state} value={state}>
            {i18n(`state_${state}`)}
          </Option>
        ))}
      </Select>
    );
  };

  private renderOrderInfo = () => {
    const order = this.getOrder();
    if (!order) {
      return null;
    }

    const userPath = `/users/${order.userId}`;
    const text = getFullName(order.user) || i18n('untitled');
    // const code = order.code || order.id;

    return (
      <FloatPanel>
        <div className="orderInfo_content">
          <div>
            {/* <div>
              <span className="orderInfo_label">{i18n('order_code')}</span>
              <div className="orderInfo_text">{code}</div>
            </div> */}
            <div>
              <span className="orderInfo_label">{i18n('id')}</span>
              <div className="orderInfo_text">{order.id}</div>
            </div>
            <div>
              <span className="orderInfo_label">{i18n('customer')}</span>
              <div>
                <InlineLink text={text} to={userPath} />
                <div>{order.user.phone}</div>
                <div>{order.user.email}</div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <span className="orderInfo_label">{i18n('total')}</span>
              <div className="orderInfo_text">{order.total}</div>
            </div>
            <div>
              <span className="orderInfo_label">{i18n('paidAmount')}</span>
              <div className="orderInfo_text">{order.paidAmount}</div>
            </div>
            <div>
              <span className="orderInfo_label">{i18n('order_state')}</span>
              <div className="orderInfo_text">{this.renderOrderState()}</div>
            </div>
          </div>
          <div>
            <div>
              <span className="orderInfo_label">{i18n('createdAt')}</span>
              <div className="orderInfo_text">{renderDate(order.createdAt)}</div>
            </div>
            <div>
              <span className="orderInfo_label">{i18n('updatedAt')}</span>
              <div className="orderInfo_text">{renderDate(order.updatedAt)}</div>
            </div>
          </div>
          <div>{this.renderPrintButton()}</div>
        </div>
        {this.renderData(order)}
      </FloatPanel>
    );
  };

  private renderDataRecord = (order: CommerceOrderFragment) => (item: CommerceOrderData | any, index: number) => {
    const key = `${order.id}-${index}`;
    const label = i18n(item.key || '');

    return (
      <div className="data_item" key={key}>
        <span>{label}: </span>
        {item.value}
      </div>
    );
  };

  private renderData = (order: CommerceOrderFragment) => {
    const { data } = order;
    if (!data) {
      return null;
    }

    return <div className="data">{data.map(this.renderDataRecord(order))}</div>;
  };

  private showPrintModal = () => {
    this.setState({
      showPrintModal: true,
    });
  };

  private hidePrintModal = () => {
    this.setState({
      showPrintModal: false,
    });
  };

  private handlePrintSuccess = () => {
    this.hidePrintModal();
  };

  private renderPrintModal = () => {
    const { showPrintModal } = this.state;
    const title = i18n('h_print_order');

    return (
      <Modal visible={showPrintModal} onCancel={this.hidePrintModal} title={title} footer={null}>
        {this.renderPrintForm()}
      </Modal>
    );
  };

  private renderPrintForm = () => {
    const { orderId } = this.props;
    const { printReceiptTemplateId } = this.props;
    if (!orderId) {
      return null;
    }

    return (
      <CommerceOrderPrintForm
        orderId={orderId}
        onPrint={this.handlePrintSuccess}
        printReceiptTemplateId={printReceiptTemplateId}
      />
    );
  };

  private renderEssence = () => {
    const { colSpan } = this.props;
    const span = colSpan || 24;

    return (
      <div className="commerceOrder">
        {this.renderAddItemModal()}
        {this.renderPrintModal()}
        <Row gutter={20}>
          <Col span={span}>{this.renderOrderInfo()}</Col>
          <Col span={span}>{this.renderOrderItems()}</Col>
        </Row>
      </div>
    );
  };

  private handleQueryInfoParsing = () => {
    let limit = DEFAULT_PAGE_SIZE;
    let cursor = 0;
    const tableConfig = getFromLocalStorage('uTableConfig_allCommerceOrderItems');
    if (tableConfig && tableConfig.rowsCount) {
      limit = tableConfig.rowsCount;
    }
    if (tableConfig && tableConfig.currentPage) {
      cursor = (tableConfig.currentPage - 1) * limit;
    }

    return {
      cursor: cursor,
      limit: limit,
    };
  };

  private handleDeleteFromCache: MutationUpdaterFn<DeleteCommerceOrderItemMutation> = client => {
    const { itemRecord } = this.state;
    if (!itemRecord) {
      return;
    }

    if (!client) {
      return;
    }

    const query = ALL_COMMERCE_ORDER_ITEMS_QUERY;
    const variables: AllCommerceOrderItemsQueryVariables = {
      query: {
        ...this.getQueryInput(),
        ...this.handleQueryInfoParsing(),
      },
    };
    const cache = client.readQuery<AllCommerceOrderItemsQuery, AllCommerceOrderItemsQueryVariables>({
      query: query,
      variables: variables,
    });

    if (!cache || !cache.allCommerceOrderItems) {
      return;
    }

    const updatedOrderItems = cache.allCommerceOrderItems.edges.filter(existed => existed.id !== itemRecord.id);
    client.writeQuery<AllCommerceOrderItemsQuery, AllCommerceOrderItemsQueryVariables>({
      query: query,
      variables: variables,
      data: {
        ...cache,
        allCommerceOrderItems: {
          ...cache.allCommerceOrderItems,
          edges: updatedOrderItems,
        },
      },
    });
  };

  private getOrder = () => {
    const { commerceOrder } = this.props;
    if (!commerceOrder) {
      return null;
    }

    return commerceOrder.commerceOrder || null;
  };

  private isNew = () => this.props.orderId === null;

  private renderRcm = () => {
    const { rcmProps } = this.props;
    return <Rcm {...rcmProps} className="commerceOrder" renderEssence={this.renderEssence} />;
  };

  render() {
    return this.renderRcm();
  }
}

// DOo not fetch if no orderId provided
const skip = (props: IProps) => props.orderId === null;

export default compose<IProps, IExternalProps>(
  withRouter,
  withUpdateCommerceOrder(getMutateProps('update')),
  withDeleteCommerceOrderItem(getMutateProps('deleteOrderItem')),
  withCommerceOrder<IProps>({
    name: 'commerceOrder',
    skip: skip,
    options: props => {
      const { orderId } = props;

      return {
        variables: {
          id: orderId!,
        },
      };
    },
  })
)(CommerceOrder);
