import { DisputeSettingsFragment, useDisputeSettingsQuery, User } from '../typings/graphql';
import { useCallback } from 'react';
import { parseJSON } from '../helpers/parseJSON';

type UserID = User['id'];

const useDisputeSimulators = (): UserID[] => {
  const disputeSettingsResult = useDisputeSettingsQuery();
  const settings = disputeSettingsResult?.data?.disputeSettings ?? null;

  const parseSimulatorId = useCallback((simulatorId: unknown): UserID | null => {
    if (typeof simulatorId === 'number') {
      return simulatorId;
    }

    const parsed = Number(simulatorId);
    if (!isNaN(parsed)) {
      return parsed;
    }

    return null;
  }, []);

  const getSimulatorIdxFromArray = useCallback(
    (arr: unknown[]): UserID[] => {
      return arr.map(parseSimulatorId).filter(simulatorId => simulatorId !== null) as UserID[];
    },
    [parseSimulatorId]
  );

  const getSimulatorIdxFromJSON = useCallback((json: string): UserID[] => {
    const parsed = parseJSON(json);
    if (!parsed) {
      return [];
    }

    if (!Array.isArray(parsed)) {
      return [];
    }

    return parsed.filter(simulatorId => typeof simulatorId === 'number');
  }, []);

  const getSimulatorsFromSettings = useCallback(
    (disputeSettings?: DisputeSettingsFragment | null): UserID[] => {
      const savedSimulators = disputeSettings?.simulators;
      if (!savedSimulators) {
        return [];
      }

      if (Array.isArray(savedSimulators)) {
        return getSimulatorIdxFromArray(savedSimulators);
      }

      if (typeof savedSimulators === 'string') {
        return getSimulatorIdxFromJSON(savedSimulators);
      }

      return [];
    },
    [getSimulatorIdxFromArray, getSimulatorIdxFromJSON]
  );

  return getSimulatorsFromSettings(settings);
};

export default useDisputeSimulators;
