import * as React from 'react';
import { getFromLocalStorage } from '../../helpers/getFromLocalStorage';
import './ThemeProvider.scss';
import { setToLocalStorage } from '../../helpers/setToLocalStorage';

interface IProps {
  children?: React.ReactElement<any>;
}

export enum ThemeVariations {
  BLACK = 'BLACK',
  WHITE = 'WHITE',
}

interface IState {
  themeName: ThemeVariations;
}

export interface IThemeContextValue {
  themeName: ThemeVariations;
  changeTheme: (theme: ThemeVariations) => void;
}

export const ThemeContext = React.createContext<IThemeContextValue>({
  themeName: ThemeVariations.WHITE,
  changeTheme: () => {},
});

ThemeContext.displayName = 'ThemeContext';

class ThemeProvider extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = this.loadState();
  }

  componentDidMount() {
    const stored = getFromLocalStorage('theme');
    if (!stored) {
      this.saveState();
    }

    this.setGlobalScope();
  }

  private setGlobalScope = () => {
    const bodyElem = document.body;
    if (!bodyElem) {
      return;
    }

    bodyElem.className = this.getClassName();
  };

  private changeTheme = (themeName: ThemeVariations) => {
    this.setState({ themeName }, () => {
      this.saveState();
      this.setGlobalScope();
    });
  };

  private saveState = () => {
    setToLocalStorage('theme', this.state);
  };

  private loadState = (): IState => {
    const stored = getFromLocalStorage('theme');

    return stored || { themeName: ThemeVariations.WHITE };
  };

  private getClassName = () => {
    const { themeName } = this.state;

    const classNames = ['themeProvider'];
    classNames.push(`themeProvider--${themeName}`);

    return classNames.join(' ');
  };

  private getContextValue = (): IThemeContextValue => {
    return {
      themeName: this.state.themeName,
      changeTheme: this.changeTheme,
    };
  };

  public render() {
    const { children } = this.props;
    const className = this.getClassName();
    const value = this.getContextValue();

    return (
      <div className={className}>
        <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
      </div>
    );
  }
}

export default ThemeProvider;
