import * as ApolloReactHoc from '@apollo/react-hoc';
import { gql } from 'apollo-boost';
import { ExtensionField, ExtensionFieldTranslationFragment } from '../../typings/graphql';

export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  UUID: any;
  Date: any;
  Upload: any;
  BigInt: any;
};

export enum SortingDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum FilterDataType {
  ARRAY = 'ARRAY',
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  TIME = 'TIME',
  ENUM = 'ENUM',
}

export enum FilterType {
  INPUT = 'INPUT',
  SELECT = 'SELECT',
  SELECT_MULTIPLE = 'SELECT_MULTIPLE',
  CHECKBOX = 'CHECKBOX',
  RANGE = 'RANGE',
}

export enum EntityDataType {
  BOOLEAN = 'BOOLEAN',
  NUMBER = 'NUMBER',
  STRING = 'STRING',
  ENUM = 'ENUM',
  ARRAY = 'ARRAY',
  FILES = 'FILES',
  JSON = 'JSON',
}

export type DefaultSortInput = {
  field: Scalars['String'];
  direction: SortingDirection;
};

export type FilterInput = {
  field: Scalars['String'];
  dataType?: Maybe<FilterDataType>;
  isRange?: Maybe<Scalars['Boolean']>;
  isExclude?: Maybe<Scalars['Boolean']>;
  isSearch?: Maybe<Scalars['Boolean']>;
  value: Scalars['String'];
};

export type DefaultQueryInput = {
  filters?: Maybe<Array<FilterInput>>;
  sort?: Maybe<Array<Maybe<DefaultSortInput>>>;
  cursor?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  allFields?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export type DefaultConnection = {
  total?: Maybe<Scalars['Int']>;
  cursor: Scalars['Int'];
  limit: Scalars['Int'];
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type FiltersQuery = { __typename?: 'Query' } & {
  filters: Maybe<Array<{ __typename?: 'FilterItem' } & FilterItemFragment>>;
};

export type FiltersQueryVariables = {
  queryName: Scalars['String'];
};

export type FilterItem = {
  __typename?: 'FilterItem';
  field: Scalars['String'];
  type: FilterType;
  dataType: FilterDataType;
  options?: Maybe<Array<Maybe<FilterItemOption>>>;
  isRangeAvailable: Scalars['Boolean'];
  isExcludAvailable: Scalars['Boolean'];
  min?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Int']>;
  step?: Maybe<Scalars['Int']>;
};

export type FilterItemOption = {
  __typename?: 'FilterItemOption';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type FilterItemFragment = { __typename: 'FilterItem' } & Pick<
  FilterItem,
  'field' | 'type' | 'dataType' | 'isRangeAvailable' | 'isExcludAvailable' | 'min' | 'max' | 'step'
> & { options: Maybe<Array<Maybe<{ __typename: 'FilterItemOption' } & Pick<FilterItemOption, 'id' | 'name'>>>> };

export type ExtensionFieldFragment = { __typename?: 'ExtensionField' } & Pick<
  ExtensionField,
  'id' | 'relationId' | 'name' | 'dataType' | 'isReadOnly' | 'sort' | 'createdAt' | 'updatedAt'
> & { translations: Array<{ __typename?: 'ExtensionFieldTranslation' } & ExtensionFieldTranslationFragment> };

export type FiltersProps<TChildProps = {}> = ApolloReactHoc.DataProps<FiltersQuery, FiltersQueryVariables> &
  TChildProps;

export const FilterItemFragmentDoc = gql`
  fragment filterItem on FilterItem {
    __typename @skip(if: true)
    field
    type
    dataType
    options {
      __typename @skip(if: true)
      id
      name
    }
    isRangeAvailable
    isExcludAvailable
    min
    max
    step
  }
`;

export const FiltersDocument = gql`
  query filters($queryName: String!) {
    filters(queryName: $queryName) {
      ...filterItem
    }
  }
  ${FilterItemFragmentDoc}
`;

export function withFilters<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FiltersQuery,
    FiltersQueryVariables,
    FiltersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<TProps, FiltersQuery, FiltersQueryVariables, FiltersProps<TChildProps>>(
    FiltersDocument,
    {
      alias: 'filters',
      ...operationOptions,
    }
  );
}
