import { gql } from 'apollo-boost';
import { TEAM_HISTORY_FRAGMENT } from './teamHistory';
import { SEASON_TEAM_STATS_FRAGMENT } from './seasonTeamStats';
import { TOURNAMENT_TEAM_MEMBER_FRAGMENT } from './tournamentTeamMember';

export const TOURNAMENT_TEAM_FRAGMENT = gql`
  fragment tournamentTeam on TournamentTeam {
    id
    clientId
    name
    logo
    altLogo
    coverPhoto
    website
    description
    history
    foundingDate
    # teamMembersCount Have problems like "Cannot read property 'length' of undefined"
    teamForm
    data {
      key
      value
      translations {
        localeId
        value
      }
    }
    homeStadium {
      id
      name
      address
    }
    createdAt
    updatedAt
  }
`;

export const TOURNAMENT_TEAM_WITH_SEASON_FRAGMENT = gql`
  fragment tournamentTeamWithSeason on TournamentTeam {
    id
    clientId
    name
    logo
    altLogo
    coverPhoto
    website
    description
    homeStadium {
      id
      name
      address
    }
    seasons {
      id
      title
    }
    createdAt
    updatedAt
  }
`;

export const TOURNAMENT_TEAM_SMALL_FRAGMENT = gql`
  fragment tournamentTeamSmall on TournamentTeam {
    id
    name
    logo
    website
  }
`;

export const TOURNAMENT_TEAM_DETAILS_FRAGMENT = gql`
  fragment tournamentTeamDetails on TournamentTeam {
    ...tournamentTeam
    teamMembers {
      ...tournamentTeamMember
    }
    teamHistories {
      ...teamHistory
    }
    teamStats {
      ...seasonTeamStats
    }
  }
  ${TOURNAMENT_TEAM_FRAGMENT}
  ${TOURNAMENT_TEAM_MEMBER_FRAGMENT}
  ${TEAM_HISTORY_FRAGMENT}
  ${SEASON_TEAM_STATS_FRAGMENT}
`;
