import { message } from 'antd';

export const OPERATIONS = {
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete',
};

type I18n = (key: string) => string;

export const handleFail = (operation: string, i18n: I18n) => () => {
  return message.error(i18n(`${operation}_fail`));
};

export const handleSuccess = (operation: string, i18n: I18n) => () => {
  return message.success(i18n(`${operation}_success`));
};
