import * as React from 'react';
import { Alert, Divider, message, Modal } from 'antd';
import { ApolloError } from 'apollo-client';
import { ModalFuncProps } from 'antd/lib/modal';
import * as Sentry from '@sentry/browser';
import { IS_PRODUCTION } from '../../config/constants';
import { i18n } from '../../helpers/I18n';
import { getWithExpiry, setWithExpiry } from '../../universal/helpers/withExpiry';

interface IProps {}
interface IState {
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
}

class ErrorBoundary extends React.PureComponent<IProps, IState> {
  public state: IState = {
    error: null,
    errorInfo: null,
  };

  componentDidCatch(error: Error | ApolloError, errorInfo: React.ErrorInfo): void {
    if (IS_PRODUCTION) {
      Sentry.captureException(error);
    }

    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (error?.message && chunkFailedMessage.test(error.message)) {
      if (!getWithExpiry('chunk_failed')) {
        setWithExpiry('chunk_failed', 'true', 10000);
        window.location.reload();
        return;
      }
    }

    if (error instanceof ApolloError) {
      if (error.networkError) {
        const networkError = error.networkError as any;

        if (networkError.result && networkError.result.errors) {
          const serverErrors: any[] = networkError.result.errors;

          if (Array.isArray(serverErrors)) {
            if (serverErrors.length === 1) {
              const [serverError] = serverErrors;
              return this.handleSingleServerError(serverError);
            }

            return this.handleMultipleServerErrors(serverErrors);
          }
        }
      }
    }

    this.setState({ error, errorInfo }, () => {
      message.error(i18n('error'));
    });
  }

  private handleMultipleServerErrors = (serverErrors: any[]) => {
    Modal.error({
      ...this.getModalProps(),
      title: i18n('multiple_errors'),
      content: this.renderModal(serverErrors),
    });
  };

  private handleSingleServerError = (serverError: any) => {
    const { extensions } = serverError;
    const errorMessage = serverError.message || i18n('error_happened');

    // If no extensions data
    if (!extensions) {
      message.error(errorMessage);
      return;
    }

    const priority = extensions.priority || 'HIGH';
    const code = extensions.code || 'UNDEFINED';
    const details = extensions.details || serverError;
    const { source } = extensions;

    if (priority === 'LOW') {
      message.error(`${i18n(code)}. ${i18n('source')}: ${source}`);
      return;
    }

    if (priority === 'NORMAL' || priority === 'HIGH') {
      Modal.error({
        ...this.getModalProps(),
        title: i18n(code),
        content: this.renderModal([details]),
      });
      return;
    }
  };

  private getModalProps = (): ModalFuncProps => ({
    width: 'auto',
    okText: i18n('actions_close'),
    style: { width: 'auto', maxWidth: '70vw' },
  });

  private renderModal = (errors: any[]) => {
    const contentStyle = { maxWidth: '100%', maxHeight: '50vh', overflow: 'auto' };

    return (
      <div style={contentStyle}>
        <p>{i18n('developers_notified')}</p>
        {errors.map((details, index) => (
          <div key={String(index)}>
            <pre>{JSON.stringify(details, null, 2)}</pre>
            <Divider />
          </div>
        ))}
      </div>
    );
  };

  public render() {
    const { error, errorInfo } = this.state;

    if (error && errorInfo) {
      return <Alert type="error" message={error.message} description={errorInfo.componentStack} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
