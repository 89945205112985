import * as React from 'react';
import { SystemUserFragment, SystemUserProfileComponent } from '../../typings/graphql';
import { setToLocalStorage } from '../../helpers/setToLocalStorage';
import PrivateLayout from '../PrivateLayout/PrivateLayout';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Page from '../Page/Page';
import Loader from '../Loader/Loader';
import { i18n } from '../../helpers/I18n';
import { Alert } from 'antd';
import { Redirect } from 'react-router';
import { compose } from 'recompose';
import { withApollo } from '@apollo/react-hoc';
import { EVENTS, EventService } from '../../universal/services/EventService';

interface IProps {
  client: any;
}

// Get SystemUserACL and set it to localStorage
class SystemUserProfileSetter extends React.PureComponent<{ systemUserProfile: SystemUserFragment }> {
  public componentDidMount() {
    const { systemUserProfile } = this.props;
    if (!systemUserProfile || !systemUserProfile.ACL) {
      return undefined;
    }

    if (!localStorage) {
      return undefined;
    }

    setToLocalStorage('systemUserAcl', this.props.systemUserProfile.ACL);
  }

  public render() {
    return this.props.children;
  }
}

// Get variables from local storage and request query SystemUserACL
// Then pass data to Setter, defined above (SystemUserACLSetter)
class SystemUserProfileLoader extends React.PureComponent<IProps> {
  private renderFullPage = (renderContent: () => React.ReactNode) => {
    return (
      <Page>
        <Page.Content>{renderContent()}</Page.Content>
      </Page>
    );
  };

  public render() {
    const isAuthorized = PrivateLayout.isAuthorized();
    if (!isAuthorized) {
      return this.props.children;
    }

    return (
      <SystemUserProfileComponent
        onError={error => {
          if (error.message === 'GraphQL error: UNAUTHENTICATED') {
            // Очень спорный момент, но должно работать
            // Мы не можем вызвать  <Redirect to="/login" /> т.к. мы ещё не имеем роутера тут
            const { client } = this.props;
            const { queryManager } = client;

            if (queryManager) {
              queryManager
                .clearStore() // We need to clear inflight/active queries first
                .then(() => client.resetStore()) // Then clear cache
                .then(() => {
                  localStorage.clear();

                  EventService.fire(EVENTS.STORAGE_CLEARED);
                });
            }
            window.location.replace('/login');
            return null;
          }
        }}
      >
        {({ data, error, loading }) => {
          if (error) {
            return this.renderFullPage(() => <ErrorMessage isFull error={error} />);
          }

          if (loading) {
            return this.renderFullPage(() => <Loader isFull text={i18n('loading_system_user_profile')} />);
          }

          if (!data || !data.systemUserProfile) {
            return this.renderFullPage(() => (
              <Alert message={i18n('system_user_profile')} description={i18n('no_data')} />
            ));
          }

          return (
            <SystemUserProfileSetter systemUserProfile={data.systemUserProfile}>
              {this.props.children}
            </SystemUserProfileSetter>
          );
        }}
      </SystemUserProfileComponent>
    );
  }
}

export default compose<IProps, {}>(withApollo)(SystemUserProfileLoader);
