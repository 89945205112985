import { gql } from 'apollo-boost';

export const PAYMENT_TRANSACTION_FRAGMENT = gql`
  fragment paymentTransaction on PaymentTransaction {
    id
    state
    side
    userId
    user {
      id
      firstName
      middleName
      lastName
    }
    provider {
      id
      name
    }
    currency {
      id
      name
    }
    accountId
    currencyId
    providerId
    comment
    amount
    bankCardId
    orderId
    orderType
    meta
    createdAt
    updatedAt
  }
`;

export const REFUND_PAYMENT_TRANSACTION_FRAGMENT = gql`
  fragment refundPaymentTransaction on PaymentTransaction {
    id
    state
    side
    userId
    accountId
    currencyId
    providerId
    amount
    bankCardId
    orderId
    orderType
    createdAt
    updatedAt
  }
`;
