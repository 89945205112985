import { gql } from 'apollo-boost';

export const PAYMENT_PROVIDER_FRAGMENT = gql`
  fragment paymentProvider on PaymentProvider {
    id
    gatewayId
    clientId
    state
    name
    settings
    createdAt
    updatedAt
  }
`;
