import { RouteComponentProps } from 'react-router-dom';

interface IParamsWithId {
  id: string | 'new';
}

function isNewPage<TProps extends RouteComponentProps<IParamsWithId>>(props: TProps) {
  if (!props || !props.match || !props.match.params) {
    return false;
  }

  return props.match.params.id === 'new';
}

export { isNewPage };
