import * as React from 'react';
import { FiltersContext } from '../FiltersProvider/FiltersProvider';

interface IOptions {
  name?: string;
}

export default function FiltersContextHOC<TProps, TExternalProps>(options?: IOptions) {
  const name = (options && options.name) || 'filtersContext';

  return (WrappedComponent: React.ComponentType<TProps>): React.FunctionComponent<TExternalProps> => props => (
    <FiltersContext.Consumer>
      {contextValue => <WrappedComponent {...(props as any)} {...{ [name]: contextValue }} />}
    </FiltersContext.Consumer>
  );
}
