import { gql } from 'apollo-boost';
import { TOURNAMENT_MATCH_FRAGMENT } from './tournamentMatch';

export const TOURNAMENT_TOUR_FRAGMENT = gql`
  fragment tournamentTour on TournamentTour {
    id
    title
    description
    tournamentId
    seasonId
    number
    startDate
    finishDate
    state
    sort
    createdAt
    updatedAt
  }
`;

export const TOURNAMENT_TOUR_DEEP_FRAGMENT = gql`
  fragment tournamentTourDeep on TournamentTour {
    ...tournamentTour
    matches {
      ...tournamentMatch
    }
  }
  ${TOURNAMENT_TOUR_FRAGMENT}
  ${TOURNAMENT_MATCH_FRAGMENT}
`;
