import { gql } from 'apollo-boost';
import { TOURNAMENT_PLAYER_STAT_FRAGMENT_FULL } from '../fragments/tournamentPlayerStat';

export const TOURNAMENT_PLAYER_STAT_QUERY = gql`
  query tournamentPlayerStat($id: Int!) {
    tournamentPlayerStat(id: $id) {
      ...tournamentPlayerStatFull
    }
  }
  ${TOURNAMENT_PLAYER_STAT_FRAGMENT_FULL}
`;
