import { gql } from 'apollo-boost';
import { LOYALTY_PARTNER_FRAGMENT } from '../fragments/loyaltyPartner';

export const LOYALTY_PARTNER_QUERY = gql`
  query loyaltyPartner($id: Int!) {
    loyaltyPartner(id: $id) {
      ...loyaltyPartner
    }
  }
  ${LOYALTY_PARTNER_FRAGMENT}
`;
