import * as React from 'react';
import { ChangeEvent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Empty,
  Form,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Row,
  Select,
  Switch,
} from 'antd';
import { ExtensionFieldFragment, FilterDataType } from '../../typings/graphql';
import { ButtonProps } from 'antd/es/button';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import './UForm.css';
import { SinglePickerProps } from 'antd/es/date-picker/interface';
import { FORMAT } from '../../config/constants';
import { compose } from 'recompose';
import UUpload, { IFile, IUUploadConfig } from '../UUpload/UUpload';
import { SwitchChangeEventHandler } from 'antd/es/switch';
import RichEditor, { IRichEditorProps } from '../RichEditor/RichEditor';
import FiltersContextHOC from '../FiltersContextHOC/FiltersContextHOC';
import { IFiltersContextValue } from '../FiltersProvider/FiltersProvider';
import QuerySelect from '../QuerySelect/QuerySelect';
import DeleteAction from '../DeleteAction/DeleteAction';
import { OptionProps, SelectProps } from 'antd/es/select';
import Extension, { ExtensionUpdateDataInput } from '../../services/Extension';
import { parseJSON } from '../../helpers/parseJSON';
import { DividerProps } from 'antd/es/divider';
import { TextAreaProps } from 'antd/es/input';
import { getActiveLocale } from '../../helpers/getActiveLocale';
import { changeMomentLocale, moment } from '../../helpers/getMoment';
import 'moment/locale/ru';
import { EntityDataType, ExtensionDataFragment } from '../../../typings/graphql';
import { Card } from '../QuerySelect/QuerySelect';

const activeLocale = getActiveLocale();
changeMomentLocale(activeLocale);

export enum FormComponent {
  INPUT = 'INPUT',
  TEXTAREA = 'TEXTAREA',
  SELECT = 'SELECT',
  DATE_PICKER = 'DATE_PICKER',
  CHECKBOX = 'CHECKBOX',
  UPLOAD = 'UPLOAD',
  RICH_TEXT = 'RICH_TEXT',
}

export interface ISelectOptionsConfig {
  options: OptionProps[];
  componentProps?: SelectProps;
}

export interface IOptionsQuery {
  query?: any; // Gql query
  variables?: OptionsQueryVariablesFn | Record<any, any>;
  optionValuePropertyName?: string;
  queryName?: string;
  view?: 'cards';
  cards?: Card[];
  hideAdvancedSelector?: boolean;

  // Get query result
  getQueryResult?: (queryResult: any) => void;

  // Get path to render link to entity
  getPath?: (value: any) => string | null;

  // Render children of Select.Option tag
  render?: (record: any) => React.ReactNode;
}

export interface IFormField {
  // Property name in instance we want to edit
  field: string;
  label?: string;
  type?: FilterDataType;
  component: FormComponent;
  defaultValue?: any;
  enumValue?: any;
  isMultiSelect?: boolean;
  optionsQuery?: IOptionsQuery;
  format?: string;

  // If u want to provide options to Select component
  selectOptionsConfig?: ISelectOptionsConfig;

  richTextConfig?: IRichTextConfig;

  textAreaConfig?: ITextAreaConfig;

  uploadConfig?: IUUploadConfig;

  // Validate before submitting or not
  isRequired?: boolean | BooleanCalculator;

  // If not provided -- make field visible, otherwise field visibility depends on result of this function
  isVisible?: boolean | BooleanCalculator;

  // Is control disabled and readonly
  isDisabled?: boolean; // TODO: Add BooleanCalculator

  // Additional renders if we want to append/prepend something before/after control, but inside Form.Item
  renderBeforeControl?: AdditionalRender;
  renderAfterControl?: AdditionalRender;

  // Width of column. From 1 to 24. 24 by default
  colSpan?: number;

  // Is it represent ExtensionData
  isExtensionData?: boolean;

  // Add divider inside formField column
  inColumnDivider?: IFormDivider | boolean;

  // Add divider before/outside formField column
  outColumnDivider?: IFormDivider | boolean;

  // Comment control after label, but before 'renderBeforeControl' method. Text will be displayed muted
  comment?: string | null;
}

export type AdditionalRender = (formField: IFormField, value: any, formState: UFormState) => React.ReactNode;

export interface IFormDivider {
  text?: string | React.ReactNode;
  description?: string | React.ReactNode;
  dividerComponentProps?: Partial<DividerProps>;
  render?: () => React.ReactNode;
}

interface IRichTextConfig {
  valueExtractor?: (value: any) => any;
  editorComponentProps?: Partial<IRichEditorProps>;
}

interface ITextAreaConfig {
  componentProps: Partial<TextAreaProps>;
}

type BooleanCalculator = (formState: UFormState) => boolean;
type OptionsQueryVariablesFn = (formState: UFormState) => any;

interface IExternalProps {
  fields: IFormField[];
  renderExtensionLabel?: () => React.ReactNode;

  extensionFields?: ExtensionFieldFragment[];

  // Instance for editing
  instance?: any;

  // Error should be passed from top, so we can render it without blocking whole form
  error?: any;

  // Loading state should be passed from top as well
  isLoading?: boolean;

  // Easy way to set submit button text to "Create".
  // If not provided decide based on "instance" property
  forCreating?: boolean;

  // One way to override text of submit button
  submitBtnText?: string;

  // Submit/Delete button is almost fully configurable, except "disabled" field
  submitBtnProps?: ButtonProps;
  deleteBtnProps?: ButtonProps;

  onDelete?: (state: UFormState, changes: UFormState) => void;
  onSubmit?: (state: UFormState, changes: UFormState, data: ExtensionUpdateDataInput[]) => void;
  onChange?: (state: UFormState) => void;
  onReady?: (state: UFormState, formAPI: UFormAPI) => void;

  hideSubmitAction?: boolean;
  hideDeleteAction?: boolean;

  actionBtns?: React.ReactNode[];
  customRender?: UFormCustomRender;

  // Hide colon ":" after label text
  hideAllLabelColons?: boolean;

  // If we want to completely hide the footer
  hideFooter?: boolean;

  // Parse extensionData[] from instance
  extensionDataKey?: string;

  // enable confirm for submit action
  confirm?: boolean;
}

export interface UFormProps extends IExternalProps {}

export type UFormCustomRender = (renderers: IFieldRenderer[], renderActions: () => React.ReactNode) => React.ReactNode;

// Object passed for custom rendering
export interface IFieldRenderer {
  field: IFormField;
  render: () => React.ReactNode;
}

interface IProps extends IExternalProps, RouteComponentProps {
  filtersContext: IFiltersContextValue;
}

export interface UFormState extends Record<string, any> {}

export interface UFormAPI {
  getFormState: () => UFormState;
  getChanges: () => UFormState;
  isLoading: () => boolean;
  submit: () => void;
  delete: () => void;
}

interface IState {
  unvalidatedKeys: string[];
  isValidated: boolean;
  changes: UFormState;
  extensionData: UFormState;
}

class UForm extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = this.initState();
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
    if (!prevProps.instance && this.props.instance) {
      this.setState(this.initState());
    }
  }

  componentDidMount() {
    const { onReady } = this.props;
    if (onReady) {
      const formState = this.getFormState();
      const formAPI = this.getFormAPI();
      onReady(formState, formAPI);
    }
  }

  private getRenders = (): IFieldRenderer[] => {
    const fields = this.getAllFields();
    return fields.map(field => {
      return {
        field: field,
        render: () => this.renderFormItem(field),
      };
    });
  };

  private isLoading = () => {
    const { isLoading } = this.props;
    return Boolean(isLoading);
  };

  private getFormAPI = (): UFormAPI => {
    return {
      getFormState: this.getFormState,
      getChanges: this.getChanges,
      delete: this.handleDelete,
      submit: this.handleSubmit,
      isLoading: this.isLoading,
    };
  };

  private getFormState = (): UFormState => {
    const fields = this.getAllFields();
    const formState: UFormState = {};

    for (const formField of fields) {
      if (formField.isExtensionData) {
        continue;
      }

      formState[formField.field] = this.getValueByField(formField);
    }

    return formState;
  };

  private initExtensionData = (): IState['extensionData'] => {
    const { instance } = this.props;
    if (!instance) {
      return {};
    }

    const extensionData: ExtensionDataFragment[] = instance.data || [];
    const result: IState['extensionData'] = {};

    for (const extensionDataRecord of extensionData) {
      result[extensionDataRecord.key] = extensionDataRecord.value;

      if (extensionDataRecord.field && extensionDataRecord.field.dataType !== EntityDataType.STRING) {
        result[extensionDataRecord.key] = parseJSON(extensionDataRecord.value, true);
      }
    }

    return result;
  };

  private initState = (): IState => {
    const extensionData = this.initExtensionData();

    return {
      changes: {},
      extensionData: extensionData,
      unvalidatedKeys: [],
      isValidated: true,
    };
  };

  private isNew = (): boolean => {
    const { forCreating, instance } = this.props;

    // Return false if form configured for editing
    if (forCreating === false) {
      return false;
    }

    // Return true if form configured for creating
    if (forCreating === true) {
      return true;
    }

    // Instance provided for editing, so we return false here
    return !instance;
  };

  private getSubmitBtnText = (): string => {
    const { submitBtnText, filtersContext } = this.props;
    if (submitBtnText) {
      return submitBtnText;
    }

    const isNew = this.isNew();
    if (isNew) {
      return filtersContext.i18n('action_create');
    }

    return filtersContext.i18n('action_save');
  };

  private getSubmitBtnProps = (): ButtonProps => {
    const { submitBtnProps, isLoading, confirm } = this.props;
    const onClick = confirm ? undefined : this.handleSubmit;

    const btnProps: ButtonProps = {
      onClick,
      type: 'default',
      // icon: 'save',
      ...submitBtnProps,
      disabled: submitBtnProps?.disabled || isLoading,
    };

    // const isNew = this.isNew();
    // if (isNew) {
    // btnProps.icon = 'form';
    // }

    if (isLoading) {
      btnProps.icon = 'loading';
    }

    return btnProps;
  };

  private getDeleteBtnProps = (): ButtonProps => {
    const { deleteBtnProps, isLoading } = this.props;
    const isNew = this.isNew();

    const isDisabled = deleteBtnProps?.disabled || isNew || isLoading;

    return {
      type: 'danger',
      icon: 'delete',
      ...deleteBtnProps,
      disabled: isDisabled,
    };
  };

  private convertExtensionFieldToFormField = (extensionField: ExtensionFieldFragment, index: number): IFormField => {
    const { filtersContext, fields, renderExtensionLabel } = this.props;
    const { isReadOnly, name: fieldName } = extensionField;
    if (!fieldName) {
      throw new Error('ExtensionField name not found');
    }

    // Add divider before first extension field
    const isFirst = index === 0;

    const configProvidedFromProps = fields.find(formField => formField.field === fieldName);
    const outColumnDivider = isFirst
      ? { text: filtersContext.i18n('extension_fields'), render: renderExtensionLabel }
      : undefined;
    const configByExtensionField = Extension.getFormFieldConfigByExtensionField(extensionField);

    return {
      label: filtersContext.i18n(fieldName),
      component: FormComponent.INPUT,
      isDisabled: isReadOnly,
      ...configByExtensionField,
      ...configProvidedFromProps,
      field: fieldName,
      isExtensionData: true,
      outColumnDivider: outColumnDivider,
    };
  };

  private filterVisibleFormField = (formField: IFormField): boolean => {
    if (typeof formField.isVisible === 'boolean') {
      return formField.isVisible;
    }

    if (typeof formField.isVisible === 'function') {
      const formState = this.getFormState();
      return formField.isVisible(formState);
    }

    return true;
  };

  private isExtensionField = (fieldName: string): boolean => {
    const { extensionFields } = this.props;
    const field = (extensionFields || []).find(field => field.name === fieldName);

    return Boolean(field);
  };

  private getExtensionFields = (): IFormField[] => {
    const { extensionFields: providedExtensionFields } = this.props;
    const extensionFields = providedExtensionFields ?? [];

    return extensionFields.map(this.convertExtensionFieldToFormField);
  };

  private getProvidedFields = (): IFormField[] => {
    return this.props.fields || [];
  };

  private getAllFields = () => {
    const provided = this.getProvidedFields(); // From props
    const extensionFields = this.getExtensionFields(); // From "data" extension

    return [...provided, ...extensionFields];
  };

  private getVisibleFields = (): IFormField[] => {
    const allFields = this.getAllFields();

    return allFields.filter(this.filterVisibleFormField);
  };

  private getFormItemClassName = (formField: IFormField) => {
    const classNames = ['uForm_item'];
    if (formField.isDisabled) {
      classNames.push(`uForm_item--disabled`);
    }

    return classNames.join(' ');
  };

  // For highlighting Form.Item
  private isUnvalidatedKey = (fieldName: string): boolean => {
    const { unvalidatedKeys } = this.state;
    return unvalidatedKeys.indexOf(fieldName) !== -1;
  };

  private isFieldRequired = (formField: IFormField): boolean => {
    const { isRequired } = formField;
    if (typeof isRequired === 'boolean') {
      return isRequired;
    }

    if (typeof isRequired === 'function') {
      const formState = this.getFormState();
      return isRequired(formState);
    }

    return false;
  };

  private isFieldVisible = (formField: IFormField): boolean => {
    const { isVisible } = formField;
    if (typeof isVisible === 'boolean') {
      return isVisible;
    }

    if (typeof isVisible === 'function') {
      const formState = this.getFormState();
      return isVisible(formState);
    }

    return true;
  };

  private validate = (callback?: () => void) => {
    const fields = this.getAllFields();
    const emptyFields = fields.filter(formField => {
      const isVisible = this.isFieldVisible(formField);
      const isRequired = this.isFieldRequired(formField);

      if (!isRequired || !isVisible) {
        return false;
      }

      const value = this.getValueByField(formField);
      // Use it instead of (!value) to prevent triggering on 0
      return value === null || value === undefined || value === '';
    });

    const isValidated = emptyFields.length === 0;
    const unvalidatedKeys = emptyFields.map(({ field }) => field);

    return this.setState({ isValidated, unvalidatedKeys }, callback);
  };

  // Update state and update unvalidated keys
  private updateState = (payload: UFormState) => {
    const extensionData = { ...this.state.extensionData };
    const changes = { ...this.state.changes };

    for (const fieldName in payload) {
      if (!payload.hasOwnProperty(fieldName)) {
        continue;
      }

      const value = (payload as any)[fieldName];
      const isExtensionField = this.isExtensionField(fieldName);
      if (isExtensionField) {
        extensionData[fieldName] = value;
      } else {
        changes[fieldName] = value;
      }
    }

    // Update changes in state
    this.setState({ changes, extensionData }, () => {
      // Validate form
      this.validate(this.useOnChangeCallback);
    });
  };

  private handleDelete = () => {
    const { onDelete } = this.props;
    if (!onDelete) {
      return;
    }

    const { changes } = this.state;
    const formState = this.getFormState();
    onDelete(formState, changes);
  };

  private getUnvalidatedMessage = (): string => {
    const { unvalidatedKeys } = this.state;
    const { i18n } = this.props.filtersContext;

    const emptyFieldsList = unvalidatedKeys.join(', ');
    const description = i18n(`fill_required_fields`);

    return `${description}: ${emptyFieldsList}`;
  };

  private notifyUnvalidated = () => {
    const { isValidated } = this.state;
    if (isValidated) {
      return;
    }

    const text = this.getUnvalidatedMessage();
    message.error(text);
  };

  private handleSubmit = () => {
    this.validate(() => {
      const { isValidated } = this.state;
      if (!isValidated) {
        return this.notifyUnvalidated();
      }

      return this.useSubmitCallback();
    });
  };

  private useOnChangeCallback = () => {
    const { onChange } = this.props;
    if (!onChange) {
      return;
    }

    const formState = this.getFormState();
    onChange(formState);
  };

  private useSubmitCallback = () => {
    const { onSubmit } = this.props;
    if (!onSubmit) {
      return;
    }

    const changes = this.getChanges();
    const formState = this.getFormState();
    const extensionPayload = this.getExtensionPayload();

    onSubmit(formState, changes, extensionPayload);
  };

  private getExtensionPayload = (): ExtensionUpdateDataInput[] => {
    const { extensionData } = this.state;
    const { extensionFields } = this.props;

    return Extension.getUpdateDataInput(extensionData, extensionFields);
  };

  private getChanges = (): UFormState => {
    return this.state.changes;
  };

  private getUploaderConfig = (formField: IFormField): IUUploadConfig => {
    const { getMediaServiceToken } = this.props.filtersContext;
    const tokenGetter = getMediaServiceToken || (() => '');

    const providedConfig = formField.uploadConfig || {};

    return {
      headers: {
        Authorization: tokenGetter(),
        ...providedConfig.headers,
      },
      getDataFromResponse: uploadFile => {
        const data = uploadFile && uploadFile.response && uploadFile.response.data;
        if (!data || !data.url) {
          return null;
        }

        return {
          url: data.url,
        };
      },
      ...providedConfig,
    };
  };

  private getUploaderValue = (formField: IFormField): IFile[] => {
    const rawValue = this.getValueByField(formField);

    const isPrimitive = formField.uploadConfig && formField.uploadConfig.isPrimitive;
    if (isPrimitive && typeof rawValue === 'string') {
      return [{ url: rawValue }];
    }

    if (formField.isExtensionData && typeof rawValue === 'string') {
      const parsedValue = JSON.parse(rawValue);
      return parsedValue ? parsedValue : [];
    }

    if (!Array.isArray(rawValue)) {
      return [];
    }

    return rawValue.map(file => ({ url: (file && file.url) || '' })).filter(file => Boolean(file.url));
  };

  private getValueByField = (formField: IFormField) => {
    const { field: fieldName, isExtensionData } = formField;
    const { changes, extensionData } = this.state;
    const { instance } = this.props;

    if (isExtensionData) {
      return extensionData[fieldName];
    }

    // Check value in state.changes by key
    const changedKeys = Object.keys(changes);
    const isChanged = changedKeys.indexOf(fieldName) !== -1;

    // Return value from state if changes were made
    if (isChanged) {
      return changes[fieldName];
    }

    // Check if default value provided
    const fields = this.getAllFields();
    const field = fields.find(field => field.field === fieldName);
    const defaultValue = field && field.defaultValue;
    const value = instance && instance[fieldName];

    return value || defaultValue;
  };

  private handleChangeInput = (formField: IFormField) => {
    if (formField.type === FilterDataType.NUMBER) {
      return this.handleChangeInputNumber(formField);
    }

    return this.handleChangeInputString(formField);
  };

  private handleChangeInputNumber = (formField: IFormField) => (rawValue: number) => {
    let value: number | null = rawValue;

    if (!value && rawValue !== 0) {
      value = null;
    }

    this.updateState({ [formField.field]: value });
  };

  private handleChangeInputString = (formField: IFormField) => (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.updateState({ [formField.field]: event.target.value });
  };

  private handleChangeDatePicker = (formField: IFormField): SinglePickerProps['onChange'] => date => {
    const value = date ? date.toISOString() : null;
    this.updateState({ [formField.field]: value });
  };

  private handleChangeSelect = (formField: IFormField) => (value: any) => {
    this.updateState({ [formField.field]: value });
  };

  private handleChangeCheckbox = (formField: IFormField): SwitchChangeEventHandler => checked => {
    this.updateState({ [formField.field]: checked });
  };

  private handleUploadChange = (formField: IFormField) => (files: IFile[]) => {
    let payload: string | IFile[] = files || '';

    const config = this.getUploaderConfig(formField);
    if (config.isPrimitive) {
      const [firstFile] = files;
      payload = (firstFile && firstFile.url) || '';
    }

    this.updateState({ [formField.field]: payload });
  };

  private handleChangeRichText = (formField: IFormField) => (value: string) => {
    this.updateState({ [formField.field]: value });
  };

  private renderControlError = (error: any) => {
    let message = (error && error.message) || this.props.filtersContext.i18n('error');
    if (message.length > 200) {
      message = message.substr(0, 200);
    }

    return <Alert message={message} type="error" />;
  };

  private renderDisabledControl = (formField: IFormField) => {
    const value = this.getValueByField(formField);

    return <Input className="uForm_input" disabled value={value} />;
  };

  private renderControlLoader = (component: FormComponent) => {
    if (component === FormComponent.SELECT) {
      return this.renderSelectLoader();
    }

    if (component === FormComponent.TEXTAREA) {
      return this.renderTextAreaLoader();
    }

    if (component === FormComponent.INPUT) {
      return this.renderInputLoader();
    }

    return <p>{this.props.filtersContext.i18n('loading')}...</p>;
  };

  private renderSelectLoader = () => {
    return (
      <Select loading disabled value="loading">
        <Select.Option key="loading">{this.props.filtersContext.i18n('loading')}</Select.Option>
      </Select>
    );
  };

  private renderTextAreaLoader = () => {
    return <Input.TextArea disabled value={this.props.filtersContext.i18n('loading')} />;
  };

  private renderTextarea = (formField: IFormField) => {
    const { isDisabled, textAreaConfig } = formField;
    const value = this.getValueByField(formField);
    const componentProps = textAreaConfig && textAreaConfig.componentProps;

    return (
      <Input.TextArea
        autoSize
        {...componentProps}
        disabled={isDisabled}
        value={value}
        onChange={this.handleChangeInputString(formField)}
      />
    );
  };

  private renderInputLoader = () => {
    return <Input disabled value={this.props.filtersContext.i18n('loading')} />;
  };

  private renderInput = (formField: IFormField) => {
    const { isDisabled, type } = formField;
    const value = this.getValueByField(formField);
    const isNumber = type === FilterDataType.NUMBER;
    const InputComponent = isNumber ? InputNumber : Input;

    return (
      <InputComponent
        disabled={isDisabled}
        className="uForm_input"
        value={value}
        onChange={this.handleChangeInput(formField) as any}
      />
    );
  };

  private renderDatePicker = (formField: IFormField) => {
    const { isDisabled } = formField;
    const rawValue = this.getValueByField(formField);
    const value = rawValue ? moment(rawValue).locale(activeLocale) : undefined;

    return (
      <DatePicker
        disabled={isDisabled}
        className="uForm_datePicker"
        showTime
        value={value}
        format={formField.format || FORMAT.FULL}
        onChange={this.handleChangeDatePicker(formField)}
      />
    );
  };

  private renderCheckbox = (formField: IFormField) => {
    const { isDisabled } = formField;
    let checked = this.getValueByField(formField);

    const parsed = parseJSON(checked, true);
    if (typeof parsed === 'boolean') {
      checked = parsed;
    }

    if (typeof checked !== 'boolean') {
      checked = !!checked;
    }

    return <Switch disabled={isDisabled} checked={checked} onChange={this.handleChangeCheckbox(formField)} />;
  };

  private renderRichText = (formField: IFormField) => {
    if (formField.isDisabled) {
      return this.renderDisabledControl(formField);
    }

    const value = this.getValueByField(formField) || '';
    const config = formField.richTextConfig;
    const props = config && config.editorComponentProps;

    return <RichEditor {...props} value={value} onChange={this.handleChangeRichText(formField)} />;
  };

  private renderUploader = (formField: IFormField) => {
    if (formField.isDisabled) {
      return this.renderDisabledControl(formField);
    }

    const files = this.getUploaderValue(formField);
    const config = this.getUploaderConfig(formField);

    return <UUpload files={files} config={config} onChange={this.handleUploadChange(formField)} />;
  };

  private renderEnumSelect = (formField: IFormField) => {
    const { i18n } = this.props.filtersContext;
    const { isDisabled, isMultiSelect, enumValue } = formField;
    if (!enumValue) {
      return null;
    }

    const value = this.getValueByField(formField);
    const enumKeys = Object.keys(enumValue);
    const options = enumKeys.map(key => enumValue[key]);
    const mode = isMultiSelect ? 'multiple' : undefined;

    return (
      <Select disabled={isDisabled} mode={mode} value={value} onChange={this.handleChangeSelect(formField)}>
        {options.map(option => (
          <Select.Option key={option}>{i18n(option)}</Select.Option>
        ))}
      </Select>
    );
  };

  private renderSelect = (formField: IFormField) => {
    if (formField.enumValue) {
      return this.renderEnumSelect(formField);
    }

    if (formField.optionsQuery) {
      return this.renderOptionsQuery(formField);
    }

    return this.renderOptionsSelect(formField);
  };

  private renderOptionsSelect = (formField: IFormField) => {
    const { selectOptionsConfig: config, isDisabled, isMultiSelect } = formField;
    const onChange = this.handleChangeSelect(formField);
    const value = this.getValueByField(formField);
    const options = config?.options ?? [];
    const componentProps = config?.componentProps;
    const mode = isMultiSelect ? 'tags' : undefined;

    return (
      <Select disabled={isDisabled} mode={mode} value={value} onChange={onChange} {...componentProps}>
        {options.map((option, index) => {
          const text = option.children || option.label;
          const key = String(index);

          return (
            <Select.Option key={key} {...option}>
              {text}
            </Select.Option>
          );
        })}
      </Select>
    );
  };

  private renderOptionsQuery = (formField: IFormField) => {
    const { optionsQuery, isMultiSelect, isDisabled } = formField;
    if (!optionsQuery) {
      return null;
    }
    const { view, cards, getQueryResult, hideAdvancedSelector } = optionsQuery;

    if (!optionsQuery.queryName && !cards && !view) {
      return <Alert message="Provide optionsQuery.queryName or cards prop!" />;
    }

    const value = this.getValueByField(formField);
    const onChange = this.handleChangeSelect(formField);
    const formState = this.getFormState();
    const getPath = optionsQuery.getPath;

    return (
      <QuerySelect
        value={value}
        isDisabled={isDisabled}
        isMultiSelect={isMultiSelect}
        formState={formState}
        renderError={this.renderControlError}
        renderLoader={this.renderSelectLoader}
        getPath={getPath}
        onChange={onChange}
        formField={formField}
        view={view}
        cards={cards}
        getQueryResult={getQueryResult}
        hideAdvancedSelector={hideAdvancedSelector}
      />
    );
  };

  private renderFormItemControl = (formField: IFormField) => {
    const { isLoading } = this.props;
    if (isLoading) {
      return this.renderControlLoader(formField.component);
    }

    if (formField.component === FormComponent.CHECKBOX) {
      return this.renderCheckbox(formField);
    }

    if (formField.component === FormComponent.RICH_TEXT) {
      return this.renderRichText(formField);
    }

    if (formField.component === FormComponent.UPLOAD) {
      return this.renderUploader(formField);
    }

    if (formField.component === FormComponent.DATE_PICKER) {
      return this.renderDatePicker(formField);
    }

    if (formField.component === FormComponent.INPUT) {
      return this.renderInput(formField);
    }

    if (formField.component === FormComponent.TEXTAREA) {
      return this.renderTextarea(formField);
    }

    if (formField.component === FormComponent.SELECT) {
      return this.renderSelect(formField);
    }

    return this.renderInput(formField);
  };

  private renderInColumnDivider = (formField: IFormField) => {
    const dividerConfig = formField.inColumnDivider;
    return this.renderDivider(dividerConfig);
  };

  private renderOutColumnDivider = (formField: IFormField) => {
    const { fields } = this.props;
    const dividerConfig = formField.outColumnDivider;
    if (!dividerConfig || dividerConfig === false || fields.length === 0) {
      return null;
    }

    return <Col span={24}>{this.renderDivider(dividerConfig)}</Col>;
  };

  private renderDivider = (dividerConfig?: IFormDivider | boolean) => {
    if (!dividerConfig || dividerConfig === false) {
      // Do not render if not requested or turned off by passing false
      return null;
    }

    // Render simple version if "true" provided
    if (dividerConfig === true) {
      return <Divider />;
    }

    if (dividerConfig.render) {
      return dividerConfig.render();
    }

    // Otherwise render configurable divider
    const { text, dividerComponentProps } = dividerConfig;
    let description = dividerConfig.description;
    if (typeof dividerConfig.description === 'string') {
      description = <p>{dividerConfig.description}</p>;
    }

    return (
      <>
        <Divider {...dividerComponentProps}>{text}</Divider>
        {description}
      </>
    );
  };

  private renderControlComment = (formField: IFormField) => {
    const { comment } = formField;
    if (!comment) {
      return null;
    }

    return <div className="uForm_comment">{comment}</div>;
  };

  private renderBeforeControl = (formField: IFormField) => {
    const { renderBeforeControl } = formField;
    if (!renderBeforeControl) {
      return null;
    }

    const value = this.getValueByField(formField);
    return renderBeforeControl(formField, value, this.state);
  };

  private renderAfterControl = (formField: IFormField) => {
    const { renderAfterControl } = formField;
    if (!renderAfterControl) {
      return null;
    }

    const value = this.getValueByField(formField);
    return renderAfterControl(formField, value, this.state);
  };

  private renderFormItem = (formField: IFormField) => {
    const { filtersContext, hideAllLabelColons } = this.props;

    const label = formField.label || filtersContext.i18n(formField.field);
    const isUnvalidated = this.isUnvalidatedKey(formField.field);
    const isRequired = this.isFieldRequired(formField);
    const className = this.getFormItemClassName(formField);
    const validateStatus = isUnvalidated ? 'error' : undefined;
    const isColonVisible = !hideAllLabelColons;
    const span = formField.colSpan || 24;

    const key = formField.isExtensionData ? `$extension_${formField.field}` : formField.field;
    return (
      <React.Fragment key={key}>
        {this.renderOutColumnDivider(formField)}
        <Col span={span}>
          {this.renderInColumnDivider(formField)}
          <Form.Item
            className={className}
            colon={isColonVisible}
            label={label}
            required={isRequired}
            validateStatus={validateStatus}
          >
            {this.renderControlComment(formField)}
            {this.renderBeforeControl(formField)}
            {this.renderFormItemControl(formField)}
            {this.renderAfterControl(formField)}
          </Form.Item>
        </Col>
      </React.Fragment>
    );
  };

  private renderFormItems = () => {
    const visibleFields = this.getVisibleFields();
    return visibleFields.map(this.renderFormItem);
  };

  private getConfirmProps = () => {
    const { filtersContext } = this.props;

    const title = filtersContext.i18n('confirm_action');
    const okText = filtersContext.i18n('action_ok');
    const cancelText = filtersContext.i18n('action_cancel');

    return {
      title: title,
      cancelText: cancelText,
      okText: okText,
      onConfirm: this.handleSubmit,
    };
  };

  private renderSubmitBtn = () => {
    const { confirm, onSubmit, hideSubmitAction } = this.props;
    if (!onSubmit || hideSubmitAction) {
      return null;
    }

    const text = this.getSubmitBtnText();
    const btnProps = this.getSubmitBtnProps();
    const button = <Button {...btnProps}>{text}</Button>;

    if (confirm) {
      const confirmProps = this.getConfirmProps();
      return <Popconfirm {...confirmProps}>{button}</Popconfirm>;
    }

    return button;
  };

  private renderDeleteBtn = () => {
    const { instance, onDelete, hideDeleteAction } = this.props;
    if (!instance || !onDelete || hideDeleteAction) {
      return null;
    }

    const buttonProps = this.getDeleteBtnProps();

    return <DeleteAction onConfirm={this.handleDelete} buttonProps={buttonProps} />;
  };

  private renderActionButton = (btn: React.ReactNode, index: number) => {
    const key = String(index);

    return (
      <div className="uForm_actionsBtn" key={key}>
        {btn}
      </div>
    );
  };

  private renderActionButtons = () => {
    const providedButtons = this.props.actionBtns || [];
    const submitBtn = this.renderSubmitBtn();
    const deleteBtn = this.renderDeleteBtn();
    const buttons = [submitBtn, deleteBtn, ...providedButtons];

    return buttons.map(this.renderActionButton);
  };

  private renderActions = () => {
    const { hideFooter } = this.props;
    if (hideFooter) {
      return null;
    }

    return (
      <Col span={24}>
        <div className="uForm_actions">{this.renderActionButtons()}</div>
      </Col>
    );
  };

  private renderEmpty = () => {
    const { i18n } = this.props.filtersContext;
    const description = i18n('no_fields');

    return <Empty description={description} />;
  };

  private renderForm = () => {
    return <Form>{this.renderFormContent()}</Form>;
  };

  private renderByCustomRender = (customRender: NonNullable<IProps['customRender']>) => {
    const renderers = this.getRenders();
    const renderActions = () => this.renderActions();
    return customRender(renderers, renderActions);
  };

  private renderFormContent = () => {
    const { customRender } = this.props;
    if (customRender) {
      this.renderByCustomRender(customRender);
    }

    return (
      <Row gutter={20} type="flex">
        {this.renderFormItems()}
        {this.renderActions()}
      </Row>
    );
  };

  private renderError = () => {
    const { error } = this.props;
    if (!error) {
      return null;
    }

    return <ErrorMessage className="uForm_error" error={error} />;
  };

  private renderContent = () => {
    const fields = this.getAllFields();
    if (fields.length === 0) {
      return this.renderEmpty();
    }

    return (
      <>
        {this.renderError()}
        {this.renderForm()}
      </>
    );
  };

  public render() {
    return <div>{this.renderContent()}</div>;
  }
}

export default compose<IProps, IExternalProps>(withRouter, FiltersContextHOC({}))(UForm);
