import * as React from 'react';

import './FloatPanel.scss';
import { CSSProperties } from 'react';
import { CardProps } from 'antd/lib/card';
import { Card } from 'antd';

interface IExternalProps extends CardProps {
  children: any;
  inline?: boolean;
  className?: string;
  zeroElevation?: boolean; // Remove shadow
  noMargin?: boolean; // Remove margin
  noPadding?: boolean; // Remove padding
  fullHeight?: boolean; // Remove shadow
  style?: CSSProperties;
}

interface IProps extends IExternalProps {}

export type FloatPanelProps = IExternalProps;

class FloatPanel extends React.Component<IProps> {
  private getClassName = () => {
    const { inline, className, zeroElevation, fullHeight, noMargin, noPadding } = this.props;
    const classNames = ['panel'];

    if (className) {
      classNames.push(className);
    }

    if (zeroElevation) {
      classNames.push('panel--no-shadow');
    }

    if (inline) {
      classNames.push('panel--inline');
    }

    if (noMargin) {
      classNames.push('panel--noMargin');
    }

    if (noPadding) {
      classNames.push('panel--noPadding');
    }

    if (fullHeight) {
      classNames.push('panel--fullHeight');
    }

    return classNames.join(' ');
  };

  private renderTitle = (): undefined | React.ReactNode => {
    const { title } = this.props;

    if (!title) {
      return undefined;
    }

    if (typeof title === 'string') {
      return <h2>{title}</h2>;
    }

    return title;
  };

  render() {
    const { children } = this.props;
    const className = this.getClassName();
    const title = this.renderTitle();
    const { zeroElevation, fullHeight, ...cardProps } = this.props;

    return (
      <Card {...cardProps} bordered={false} title={title} className={className}>
        {children}
      </Card>
    );
  }
}

export default FloatPanel;
