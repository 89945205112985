import * as React from 'react';

import { compose } from 'recompose';
import { LocaleEnum, SystemUserFragment } from '../../typings/graphql';
import { Form } from 'antd';
import { i18n } from '../../helpers/I18n';
import EnumSelect from '../../universal/components/EnumSelect/EnumSelect';
import { getFromLocalStorage } from '../../helpers/getFromLocalStorage';
import TranslationContextHOC, { ISystemTranslationsContextValue } from '../TranslationContextHOC/TranslationContextHOC';
import { IThemeContextValue, ThemeVariations } from '../ThemeProvider/ThemeProvider';
import ThemeContextHOC from '../ThemeContextHOC/ThemeContextHOC';

interface IExternalProps {
  userId: NonNullable<SystemUserFragment['id']>;
}

interface IProps extends IExternalProps {
  systemTranslationsContext: ISystemTranslationsContextValue;
  themeContext: IThemeContextValue;
}

interface IState {}

class SystemUserUISettings extends React.PureComponent<IProps, IState> {
  private handleChangeLocale = (value: any) => {
    this.props.systemTranslationsContext.changeLocale(value as LocaleEnum);
  };

  private handleChangeTheme = (value: any) => {
    this.props.themeContext.changeTheme(value as ThemeVariations);
  };

  private renderContent = () => {
    return this.renderForm();
  };

  private renderForm = () => {
    return (
      <Form>
        <Form.Item label={i18n('locale')}>{this.renderLocaleSelect()}</Form.Item>
        <Form.Item label={i18n('theme')}>{this.renderThemeSelect()}</Form.Item>
      </Form>
    );
  };

  private renderThemeSelect = () => {
    let defaultValue = ThemeVariations.WHITE;

    const theme: IThemeContextValue | null = getFromLocalStorage('theme');
    if (theme && theme.themeName) {
      defaultValue = theme.themeName;
    }

    return <EnumSelect defaultValue={defaultValue} enumValue={ThemeVariations} onChange={this.handleChangeTheme} />;
  };

  private renderLocaleSelect = () => {
    let defaultValue = LocaleEnum.RU;

    const translations = getFromLocalStorage('translations');
    if (translations && translations.locale) {
      defaultValue = translations.locale;
    }

    return <EnumSelect defaultValue={defaultValue} enumValue={LocaleEnum} onChange={this.handleChangeLocale} />;
  };

  render() {
    return <div>{this.renderContent()}</div>;
  }
}

export default compose<IProps, IExternalProps>(TranslationContextHOC(), ThemeContextHOC())(SystemUserUISettings);
