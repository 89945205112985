import { gql } from 'apollo-boost';

export const EXTENSION_FIELD_TRANSLATION_FRAGMENT = gql`
  fragment extensionFieldTranslation on ExtensionFieldTranslation {
    id
    localeId
    fieldId
    value
    createdAt
    updatedAt
  }
`;
