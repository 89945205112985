import * as React from 'react';

import './UDrawer.css';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Icon,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
  Slider,
} from 'antd';
import Loader from '../Loader/Loader';
import { FORMAT, SEARCH_SUBMIT_DELAY } from '../../config/constants';
import moment, { Moment } from 'moment';
import { compose } from 'recompose';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { DrawerProps } from 'antd/lib/drawer';
import FiltersProvider, {
  IExtendedFilterInput,
  IFiltersContextValue,
  TranslateHandler,
} from '../FiltersProvider/FiltersProvider';
import FiltersContextHOC from '../FiltersContextHOC/FiltersContextHOC';
import { FilterDataType, FilterItemFragment, FiltersProps, FilterType, withFilters } from '../../typings/graphql';
import { SliderProps } from 'antd/es/slider';
import FloatPanel from '../FloatPanel/FloatPanel';
import QuerySelect from '../QuerySelect/QuerySelect';
import { FormComponent, IFormField } from '../UForm/UForm';
/// @ts-ignore
import { ALL_EVENTS_QUERY } from './../../../graph/queries/allEvents';
import { renderDate } from '../../../helpers/renderDate';

interface IFilterBlock {
  input: IExtendedFilterInput;
  config: FilterItemFragment;
}

interface IExternalProps {
  // Required field, so we can store state in localStorage
  id?: string;

  // Required field of query name
  queryName: string;

  // Props directly to AntD Table
  drawerProps?: Partial<DrawerProps>;

  // Soft match search
  search?: string;

  isVisible?: boolean;

  onChange?: (state: IState) => void;

  customFilters?: FilterItemFragment[] | null;
}

interface IProps extends IExternalProps {
  filtersContext: IFiltersContextValue;
  filters: FiltersProps['data'];
}

interface IState {
  fieldName?: string;
}

class UDrawer extends React.PureComponent<IProps, IState> {
  state: IState = {};

  private timers: Record<string, NodeJS.Timer> = {};

  private handleChangeOneFilter = (filterBlock: IFilterBlock, jsonValue: string) => {
    const { input } = filterBlock;
    const { filtersContext } = this.props;
    const filterId = this.getFilterId();

    const filterInputId = (input && input.id) || this.generateFilterInputId();

    const payload: IExtendedFilterInput = {
      ...input,
      id: filterInputId,
      field: input.field,
      value: jsonValue,
      dataType: input.dataType,
    };

    filtersContext.changeFilter(filterId, payload);
  };

  private isFiltersApplied = () => {
    const { filtersContext } = this.props;
    const filterId = this.getFilterId();

    return filtersContext.isFiltersApplied(filterId);
  };

  private handleResetFilters = () => {
    const filterId = this.getFilterId();
    const { filtersContext } = this.props;

    message.info('filters_reset');
    filtersContext.resetFilter(filterId);
  };

  private handleChangeFieldNameSelect = (fieldName: string) => {
    this.setState({ fieldName });
  };

  private i18n: TranslateHandler = key => {
    const { i18n } = this.props.filtersContext;

    return i18n(key);
  };

  private handleAddField = () => {
    const { filtersContext } = this.props;
    const { fieldName } = this.state;
    if (!fieldName) {
      return message.error(this.i18n('field_not_found'));
    }

    const filterConfig = this.getFilterConfig(fieldName);
    if (!filterConfig) {
      return message.error(this.i18n('filter_not_found'));
    }

    const filterId = this.getFilterId();
    filtersContext.addFilter(filterId, {
      id: this.generateFilterInputId(),
      field: fieldName,
      dataType: filterConfig.dataType,
      value: '',
      isExclude: false,
      isRange: false,
    });
  };

  private handleChangeMultiSelect = (filterBlock: IFilterBlock) => (values: any[]) => {
    const jsonValue = JSON.stringify(values);
    this.handleChangeOneFilter(filterBlock, jsonValue);
  };

  private handleChangeSelect = (filterBlock: IFilterBlock) => (value: any) => {
    this.handleChangeOneFilter(filterBlock, value);
  };

  private handleChangeSlider = (filterBlock: IFilterBlock): SliderProps['onChange'] => sliderValue => {
    const { id } = filterBlock.input;
    const values = Array.isArray(sliderValue) ? sliderValue : [sliderValue];
    const jsonValue = JSON.stringify(values.map(v => String(v)));

    // Clear active timeout if exist
    if (this.timers[id] !== undefined) {
      clearTimeout(this.timers[id]);
    }

    // And set new timer
    this.timers[id] = setTimeout(() => {
      this.handleChangeOneFilter(filterBlock, jsonValue);
    }, SEARCH_SUBMIT_DELAY);
  };

  private handleChangeInputRange = (filterBlock: IFilterBlock, index: 0 | 1) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { id, value: existedValueStr } = filterBlock.input;
    const enteredValue = event.target.value || null;

    // Parse stringed value if DataType is Number
    // const isNumber = dataType === FilterDataType.NUMBER;
    // let enteredValue: number | string | null = isNumber ? Number(event.target.value) : event.target.value;

    // And reset value if input is empty
    // if (event.target.value === '') {
    //   enteredValue = null;
    // }

    // Get existed value and be sure it is an array
    let existedValue = existedValueStr.length >= 1 ? JSON.parse(existedValueStr) : [];
    if (Array.isArray(existedValue) === false) {
      existedValue = [];
    }

    // Clear active timeout if exist
    if (this.timers[id] !== undefined) {
      clearTimeout(this.timers[id]);
    }

    // And set new timer
    this.timers[id] = setTimeout(() => {
      const payload = [...existedValue];
      payload[index] = enteredValue;

      const nonNullablePayload = payload.filter(v => v !== null);
      const jsonValue = JSON.stringify(nonNullablePayload);

      this.handleChangeOneFilter(filterBlock, jsonValue);
    }, SEARCH_SUBMIT_DELAY);
  };

  private handleChangeInput = (filterBlock: IFilterBlock) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = filterBlock.input;
    const { value } = event.target;

    if (this.timers[id] !== undefined) {
      clearTimeout(this.timers[id]);
    }

    if (!value) {
      return this.handleChangeOneFilter(filterBlock, '');
    }

    this.timers[id] = setTimeout(() => {
      this.handleChangeOneFilter(filterBlock, value);
    }, SEARCH_SUBMIT_DELAY);
  };

  private handleChangeCheckbox = (filterBlock: IFilterBlock, side: 'on' | 'off') => (event: CheckboxChangeEvent) => {
    type CheckboxValue = 'true' | 'false';
    const { input } = filterBlock;
    const values = (input.value ? JSON.parse(input.value) : []) as CheckboxValue[];

    const { checked } = event.target;
    let value: undefined | CheckboxValue;
    if (side === 'on') {
      value = checked ? 'true' : undefined;
    } else {
      value = checked ? 'false' : undefined;
    }

    if (value) {
      values.push(value);
    } else {
      const index = values.indexOf(side === 'on' ? 'true' : 'false');
      values.splice(index, 1);
    }

    this.handleChangeOneFilter(filterBlock, JSON.stringify(values));
  };

  private handleChangeDate = (filterBlock: IFilterBlock) => (date: moment.Moment | null, dateString: string) => {
    if (!dateString) {
      // return this.handleChangeOneFilter(filterBlock, '');
      return;
    }

    // Convert dates to ISO format
    const value = moment(dateString).toISOString();
    const jsonValue = JSON.stringify([value]);

    this.handleChangeOneFilter(filterBlock, jsonValue);
  };

  private handleChangeDateRange = (filterBlock: IFilterBlock) => (
    dates: RangePickerValue,
    dateStrings: [string, string]
  ) => {
    // Submit only if both dates set
    if (!dateStrings || dateStrings.length !== 2) {
      return;
    }

    // When reset dates it return dateStrings as ['', '']
    if (!dateStrings[0] && !dateStrings[1]) {
      return this.handleChangeOneFilter(filterBlock, '');
    }

    // Convert dates to ISO format
    const value = dateStrings.map(dateString => moment(dateString, FORMAT.FULL).toISOString());
    const jsonValue = JSON.stringify(value);

    // Update inner AntD Table state
    this.handleChangeOneFilter(filterBlock, jsonValue);
  };

  private handleRemoveFilter = (filterBlock: IFilterBlock) => () => {
    const { input } = filterBlock;
    const { filtersContext } = this.props;
    const filterId = this.getFilterId();

    filtersContext.removeFilter(filterId, input);
  };

  private generateFilterInputId = () => {
    return new Date().toISOString();
  };

  private handleChangeIsExclude = (filterInput: IExtendedFilterInput) =>
    this.handleChangeBooleanInFilterInput('isExclude', filterInput);

  private handleChangeIsRange = (filterInput: IExtendedFilterInput) =>
    this.handleChangeBooleanInFilterInput('isRange', filterInput);

  private handleChangeBooleanInFilterInput = (
    booleanKey: keyof IExtendedFilterInput,
    filterInput: IExtendedFilterInput
  ) => (event: CheckboxChangeEvent) => {
    const { filtersContext } = this.props;
    const filterId = this.getFilterId();

    filtersContext.changeFilter(filterId, {
      ...filterInput,
      [booleanKey]: event.target.checked,
    });
  };

  private getFilters = (): FilterItemFragment[] => {
    const { customFilters } = this.props;
    if (customFilters) {
      return customFilters;
    }
    const { filters } = this.props.filters;
    return filters || [];
  };

  private getFilterConfig = (key: string): FilterItemFragment | undefined => {
    const filters = this.getFilters();

    return filters.find(filterConfig => filterConfig.field === key);
  };

  private getFilterId = () => {
    const { id, queryName } = this.props;

    return FiltersProvider.getFilterId(queryName, id);
  };

  private getFilterState = (): IExtendedFilterInput[] => {
    const { filtersContext } = this.props;
    const filterId = this.getFilterId();

    return filtersContext.getFilterState(filterId);
  };

  private getFilterBlocks = (): IFilterBlock[] => {
    const filterState = this.getFilterState();

    const filterBlocks: IFilterBlock[] = [];
    for (const filterInput of filterState) {
      const filterConfig = this.getFilterConfig(filterInput.field);

      if (filterConfig) {
        filterBlocks.push({
          config: filterConfig,
          input: filterInput,
        });
      }
    }

    return filterBlocks;
  };

  private renderForm = () => {
    const filterBlocks = this.getFilterBlocks();
    return <Form colon={false}>{filterBlocks.map(this.renderFormItem)}</Form>;
  };

  private renderFilterInputCheckboxes = (filterBlock: IFilterBlock) => {
    return (
      <div className="uDrawer_checkboxes">
        <div>{this.renderIsExcludeCheckbox(filterBlock)}</div>
        <div>{this.renderIsRangeCheckbox(filterBlock)}</div>
      </div>
    );
  };

  private renderIsExcludeCheckbox = (filterBlock: IFilterBlock) => {
    const { config, input } = filterBlock;

    if (!config.isExcludAvailable) {
      return null;
    }

    const checked = Boolean(input.isExclude);

    return (
      <Checkbox checked={checked} onChange={this.handleChangeIsExclude(input)}>
        {this.i18n('is_exclude')}
      </Checkbox>
    );
  };

  private renderIsRangeCheckbox = (filterBlock: IFilterBlock) => {
    const { config, input } = filterBlock;
    if (!config.isRangeAvailable) {
      return null;
    }

    const checked = Boolean(input.isRange);

    return (
      <Checkbox checked={checked} onChange={this.handleChangeIsRange(input)}>
        {this.i18n('is_range')}
      </Checkbox>
    );
  };

  private renderLabel = (filterBlock: IFilterBlock) => {
    const labelText = this.i18n(filterBlock.config.field);

    return <div className="uDrawer_label">{labelText}</div>;
  };

  private renderRemoveFilterAction = (filterBlock: IFilterBlock) => {
    const onClick = this.handleRemoveFilter(filterBlock);
    return <Button onClick={onClick} icon="close" size="small" />;
  };

  private renderFormItem = (filterBlock: IFilterBlock) => {
    const { input } = filterBlock;
    const label = this.renderLabel(filterBlock);
    const extra = this.renderRemoveFilterAction(filterBlock);

    return (
      <FloatPanel title={label} key={input.id} extra={extra}>
        <Form.Item>
          {this.renderFilter(filterBlock)}
          {this.renderFilterInputCheckboxes(filterBlock)}
        </Form.Item>
      </FloatPanel>
    );
  };

  private renderFilter = (filterBlock: IFilterBlock) => {
    const { config } = filterBlock;
    if (config.dataType === FilterDataType.DATE) {
      return this.renderDatePicker(filterBlock);
    }

    if (config.type === FilterType.RANGE) {
      return this.renderSlider(filterBlock);
    }

    if (config.type === FilterType.INPUT) {
      return this.renderInput(filterBlock);
    }

    if (config.type === FilterType.CHECKBOX) {
      return this.renderCheckbox(filterBlock);
    }

    if (config.type === FilterType.SELECT) {
      return this.renderSelect(filterBlock);
    }

    if (config.type === FilterType.SELECT_MULTIPLE) {
      return this.renderSelectMultiple(filterBlock);
    }

    return null;
  };

  private renderInputRange = (filterBlock: IFilterBlock) => {
    const { input } = filterBlock;
    const values = input.value ? JSON.parse(input.value) : [];
    const [valueFrom, valueTo] = values;

    return (
      <Row>
        <Col span={12}>
          <Input defaultValue={valueFrom} onChange={this.handleChangeInputRange(filterBlock, 0)} />
        </Col>
        <Col span={12}>
          <Input defaultValue={valueTo} onChange={this.handleChangeInputRange(filterBlock, 1)} />
        </Col>
      </Row>
    );
  };

  private renderInput = (filterBlock: IFilterBlock) => {
    const { config, input } = filterBlock;

    if (input && input.isRange) {
      return this.renderInputRange(filterBlock);
    }

    const value = input.value || '';
    const placeholder = this.i18n(config.field);

    return <Input defaultValue={value} placeholder={placeholder} onChange={this.handleChangeInput(filterBlock)} />;
  };

  private renderSlider = (filterBlock: IFilterBlock) => {
    const { input, config } = filterBlock;
    const values = input.value ? JSON.parse(input.value) : [];
    const minimumValue = config.min || undefined;
    const maximumValue = config.max || undefined;

    const isRange = Boolean(input.isRange);
    const fromValue = Number((values && values[0]) || minimumValue);
    const toValue = Number((values && values[1]) || maximumValue);
    const defaultValue = isRange ? ([fromValue, toValue] as [number, number]) : toValue;

    return (
      <Slider
        range={isRange}
        defaultValue={defaultValue}
        min={minimumValue}
        max={maximumValue}
        onChange={this.handleChangeSlider(filterBlock)}
      />
    );
  };

  private renderCheckbox = (filterBlock: IFilterBlock) => {
    const { input } = filterBlock;
    const values = input.value ? JSON.parse(input.value) : [];

    const valueOn = values.includes('true');
    const valueOff = values.includes('false');

    return (
      <>
        <div>
          <Checkbox checked={valueOn} onChange={this.handleChangeCheckbox(filterBlock, 'on')} />{' '}
          {this.i18n('checkbox_on')}
        </div>
        <div>
          <Checkbox checked={valueOff} onChange={this.handleChangeCheckbox(filterBlock, 'off')} />{' '}
          {this.i18n('checkbox_off')}
        </div>
      </>
    );
  };

  private renderSelect = (filterBlock: IFilterBlock) => {
    const { config, input } = filterBlock;
    const values = input.value ? JSON.parse(input.value) : [];
    const [value] = values;

    // Особый случай сделанный срочно в рамках задачи CRM-2379
    if (config.field === 'custom.eventId' || 'custom.eventAggregate') {
      //const value = [input.value] || [];

      const formField: IFormField = {
        field: 'eventId',
        component: FormComponent.SELECT,
        defaultValue: [],
        optionsQuery: {
          queryName: 'allEvents',
          query: ALL_EVENTS_QUERY,
          optionValuePropertyName: 'id',
          render: (data: any) => {
            return <div>{`${data.title} ${renderDate(data.startDate)}`}</div>;
          },
        },
      };

      return (
        <QuerySelect
          isMultiSelect
          value={values}
          isDisabled={false}
          formState={{}}
          onChange={this.handleChangeMultiSelect(filterBlock)}
          renderError={error => <ErrorMessage error={error} />}
          renderLoader={() => <Select disabled loading />}
          formField={formField}
        />
      );
    }

    if (!config.options) {
      return <Select value={value} disabled />;
    }

    return (
      <Select value={value} onChange={this.handleChangeSelect(filterBlock)}>
        {this.renderSelectOptions(config.options)}
      </Select>
    );
  };

  private renderSelectOptions = (options: FilterItemFragment['options']) => {
    return (options || []).map(option => {
      if (!option) {
        return null;
      }

      const { id, name } = option;
      return (
        <Select.Option key={id} value={id}>
          {name}
        </Select.Option>
      );
    });
  };

  private renderSelectMultiple = (filterBlock: IFilterBlock) => {
    const { config, input } = filterBlock;
    const values = input.value ? JSON.parse(input.value) : [];

    if (!config.options) {
      return <Select value={values} disabled />;
    }

    return (
      <Select value={values} mode="multiple" onChange={this.handleChangeMultiSelect(filterBlock)}>
        {this.renderSelectOptions(config.options)}
      </Select>
    );
  };

  private renderDatePicker = (filterBlock: IFilterBlock) => {
    const { config, input } = filterBlock;
    const values = (input.value ? JSON.parse(input.value) : []) as string[];

    if (config.isRangeAvailable) {
      const value = values.map(v => moment(v)) as [Moment, Moment];

      return (
        <DatePicker.RangePicker
          onChange={this.handleChangeDateRange(filterBlock)}
          onCalendarChange={this.handleChangeDateRange(filterBlock)}
          placeholder={[this.i18n('begin'), this.i18n('end')]}
          format={FORMAT.FULL}
          value={value}
        />
      );
    }

    const value = values[0] ? moment(values[0]) : moment();

    return (
      <DatePicker
        onChange={this.handleChangeDate(filterBlock)}
        placeholder={this.i18n('placeholder_date')}
        format={FORMAT.FULL}
        value={value}
      />
    );
  };

  private renderContent = () => {
    const { queryName, filters } = this.props;
    if (!queryName) {
      const message = this.i18n('query_name_not_provided');
      const description = 'Use UDrawer queryName prop';

      return <Alert type="warning" message={message} description={description} />;
    }

    const { loading, error } = filters;
    if (error) {
      return <ErrorMessage isFull error={error} />;
    }

    if (loading) {
      return <Loader isFull text={this.i18n('loading_filters')} />;
    }

    return (
      <>
        {this.renderHeader()}
        <Divider>{this.i18n('h_filters')}</Divider>
        {this.renderForm()}
      </>
    );
  };

  private renderHeader = () => {
    return (
      <div>
        <h2>{this.i18n('h_udrawer')}</h2>
        {this.renderResetAction()}
        <div className="uDrawer_addControls">{this.renderAddFieldForm()}</div>
      </div>
    );
  };

  private renderDrawer = () => {
    const { isVisible, drawerProps } = this.props;

    return (
      <Drawer closable visible={isVisible} width="40vw" {...drawerProps} className="uDrawer">
        {this.renderContent()}
      </Drawer>
    );
  };

  private renderResetAction = () => {
    const isFiltersApplied = this.isFiltersApplied();
    const isDisabled = !isFiltersApplied;
    const title = this.i18n('h_confirm_reset');

    return (
      <Popconfirm title={title} disabled={isDisabled} onConfirm={this.handleResetFilters}>
        <Button disabled={isDisabled} className="uDrawer_control uDrawer_resetAction" type="default">
          <Icon type="reload" />
          {this.i18n('action_reset_filters')}
        </Button>
      </Popconfirm>
    );
  };

  private renderFieldNameControl = () => {
    const filters = this.getFilters();
    const selectableFilters = filters.filter(({ field }) => !!this.getFilterConfig(field));

    return (
      <Select className="uDrawer_control" onChange={this.handleChangeFieldNameSelect}>
        {selectableFilters.map(({ field }) => (
          <Select.Option key={field} value={field}>
            {this.i18n(field)}
          </Select.Option>
        ))}
      </Select>
    );
  };

  private renderAddFieldForm = () => {
    const { loading, filters } = this.props.filters;
    if (loading) {
      return <Loader isFull text={this.i18n('loading_filters')} />;
    }

    if (!filters || filters.length === 0) {
      return null;
    }

    return (
      <>
        {this.renderFieldNameControl()}
        <Button className="uDrawer_control uDrawer_addAction" icon="plus" onClick={this.handleAddField}>
          {this.i18n('action_add')}
        </Button>
      </>
    );
  };

  private renderModals = () => {
    return <></>;
  };

  render() {
    // Whole table wrapper
    return (
      <div className="uDrawer_wrapper">
        <ErrorBoundary>
          {this.renderModals()}
          {this.renderDrawer()}
        </ErrorBoundary>
      </div>
    );
  }
}

export default compose<IProps, IExternalProps>(
  FiltersContextHOC(),
  withFilters<IExternalProps>({
    name: 'filters',
    options: props => ({
      variables: {
        queryName: props.queryName,
      },
    }),
  })
)(UDrawer);
