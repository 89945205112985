import { gql } from 'apollo-boost';
import { CATALOGUE_CATEGORY_FULL_FRAGMENT } from '../fragments/catalogueCategory';

export const CATALOGUE_CATEGORY_QUERY = gql`
  query catalogueCategory($id: Int!) {
    catalogueCategory(id: $id) {
      ...catalogueCategoryFull
    }
  }
  ${CATALOGUE_CATEGORY_FULL_FRAGMENT}
`;
