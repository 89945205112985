import * as React from 'react';
import { Divider, Icon, Row, Switch } from 'antd';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { Helmet as ReactHelmet } from 'react-helmet';

import { SystemUserProfileProps, withSystemUserProfile } from '../../typings/graphql';
import UserAvatar from '../UserAvatar/UserAvatar';

import './Header.scss';
import ThemeContextHOC from '../ThemeContextHOC/ThemeContextHOC';
import { IThemeContextValue, ThemeVariations } from '../ThemeProvider/ThemeProvider';
import BreadCrumbs from '../BreadCrumbs/BreadCrumbs';
import StatusTag from '../StatusTag/StatusTag';
import { IS_PRODUCTION } from '../../config/constants';
import { SettingsAction } from '../../universal';
import { i18n } from '../../helpers/I18n';
import { CloseModalFn } from '../../universal/components/SettingsAction/SettingsAction';
import SystemTranslationForm from '../SystemTranslationForm/SystemTranslationForm';
import SystemAsyncTasks from '../SystemAsyncTasks/SystemAsyncTasks';
// import BreadCrumbs from '../BreadCrumbs/BreadCrumbs';

interface IExternalProps {
  title: string | React.ReactNode;
  placeholder?: string;
  description?: React.ReactNode;
  actions?: React.ReactNode[];
  state?: string;
}

interface IProps extends IExternalProps {
  systemUserProfile: SystemUserProfileProps['data'];
  themeContext: IThemeContextValue;
}

interface IState {
  isProfileModalVisible: boolean;
  isCollapsed: boolean;
  clientId: string | null;
}

class Header extends React.Component<IProps, IState> {
  private getClassName = () => {
    const classNames = ['header'];

    return classNames.join(' ');
  };

  private handleSwitch = (checked: boolean) => {
    const { themeContext } = this.props;

    const theme = checked ? ThemeVariations.BLACK : ThemeVariations.WHITE;

    themeContext.changeTheme(theme);
  };

  private getTitleText = (): string => {
    const { title, placeholder } = this.props;
    if (placeholder && !title) {
      return placeholder;
    }

    if (typeof title !== 'string') {
      return '';
    }

    return title;
  };

  private renderHeading = () => {
    const { title } = this.props;
    if (title && typeof title !== 'string') {
      return title;
    }

    const titleText = this.getTitleText();

    return (
      <Row type="flex" align="middle">
        <h1 className="header_title">{titleText}</h1>
        <div className="pt-1 pl-1">{this.renderStatus()}</div>
      </Row>
    );
  };

  private renderStatus = () => {
    const { state } = this.props;
    if (!state) {
      return null;
    }
    return <StatusTag value={state} />;
  };

  private renderTitle = () => {
    const { description } = this.props;

    return (
      <div className="header_leftSideContainer">
        {this.renderHeading()}
        {description}
        <BreadCrumbs />
      </div>
    );
  };

  private renderTranslateForm = (closeModalFn: CloseModalFn) => {
    const rcmProps = { isWrapped: false };
    return <SystemTranslationForm rcmProps={rcmProps} onSave={closeModalFn} />;
  };

  private renderTranslateAction = () => {
    if (IS_PRODUCTION) {
      return null;
    }

    const buttonText = i18n('action_add_translation');

    return <SettingsAction type="modal" buttonText={buttonText} renderContent={this.renderTranslateForm} />;
  };

  private renderAsyncTasks = () => {
    return <SystemAsyncTasks />;
  };

  private renderActions = () => {
    const providedActions = this.props.actions || [];
    const actions: React.ReactNode[] = [this.renderAsyncTasks(), ...providedActions, this.renderTranslateAction()];

    return (
      <div className="header_actions">
        {actions.map((action, index) => (
          <div className="header_action" key={index}>
            {action}
          </div>
        ))}
      </div>
    );
  };

  private renderUserAvatar = () => {
    return <UserAvatar showDivider />;
  };

  private renderThemeSwitch = () => {
    const { themeContext } = this.props;
    if (new Date()) {
      return null; // TODO: Move this switch somewhere else?
    }

    const isChecked = themeContext.themeName === ThemeVariations.BLACK;
    const unCheckedChildren = <Icon type="eye" />;
    const checkedChildren = <Icon type="eye-invisible" />;

    return (
      <>
        <Divider className="header_separator" type="vertical" />
        <Switch
          checkedChildren={checkedChildren}
          unCheckedChildren={unCheckedChildren}
          checked={isChecked}
          size="default"
          onClick={this.handleSwitch}
        />
      </>
    );
  };

  private renderRightSide = () => {
    return (
      <div className="header_rightSideContainer">
        {this.renderActions()}
        {this.renderThemeSwitch()}
        {this.renderUserAvatar()}
      </div>
    );
  };

  private renderHelmet = () => {
    const { title } = this.props;

    // If no titleText provided and title is JSX | undefined, means no data to display
    if (typeof title !== 'string') {
      return null;
    }

    return (
      <ReactHelmet>
        <title>{title}</title>
      </ReactHelmet>
    );
  };

  public render() {
    const className = this.getClassName();

    return (
      <div className={className}>
        <Row type="flex" align="middle" justify="space-between">
          {this.renderHelmet()}
          {this.renderTitle()}
          {this.renderRightSide()}
        </Row>
      </div>
    );
  }
}

export default compose<IProps, IExternalProps>(
  withRouter,
  ThemeContextHOC({}),
  withSystemUserProfile({ name: 'systemUserProfile' })
)(Header);
