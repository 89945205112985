import { gql } from 'apollo-boost';
import { COMMERCE_ORDER_CONNECTION_FRAGMENT } from '../fragments/commerceOrderConnection';

export const ALL_COMMERCE_ORDERS_QUERY = gql`
  query allCommerceOrders($query: DefaultQueryInput) {
    allCommerceOrders(query: $query) {
      ...commerceOrderConnection
    }
  }
  ${COMMERCE_ORDER_CONNECTION_FRAGMENT}
`;
