import { gql } from 'apollo-boost';
import { CATALOGUE_FRAGMENT } from './catalogue';

export const CATALOGUE_CONNECTION_FRAGMENT = gql`
  fragment catalogueConnection on CatalogueConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...catalogue
    }
  }
  ${CATALOGUE_FRAGMENT}
`;
