import * as React from 'react';

import './FloatPanel.css';
import { CSSProperties } from 'react';
import { CardProps } from 'antd/lib/card';
import { Card } from 'antd';

interface Props extends CardProps {
  children: any;
  inline?: boolean;
  className?: string;
  style?: CSSProperties;
}

class FloatPanel extends React.Component<Props> {
  private getClassName = () => {
    const { inline, className } = this.props;
    const classNames = ['panel'];

    if (className) {
      classNames.push(className);
    }

    if (inline) {
      classNames.push('panel--inline');
    }

    return classNames.join(' ');
  };

  private renderTitle = (): undefined | React.ReactNode => {
    const { title } = this.props;

    if (!title) {
      return undefined;
    }

    if (typeof title === 'string') {
      return <h2>{title}</h2>;
    }

    return title;
  };

  render() {
    const { children } = this.props;
    const className = this.getClassName();
    const title = this.renderTitle();

    return (
      <Card {...this.props} bordered={false} title={title} className={className}>
        {children}
      </Card>
    );
  }
}

export default FloatPanel;
