import { gql } from 'apollo-boost';
import { EXTENSION_DATA_FRAGMENT } from './extensionData';

export const CATALOGUE_CATEGORY_FRAGMENT = gql`
  fragment catalogueCategory on CatalogueCategory {
    id
    name
    catalogueId
    state
    createdAt
    updatedAt
  }
`;

export const CATALOGUE_CATEGORY_FULL_FRAGMENT = gql`
  fragment catalogueCategoryFull on CatalogueCategory {
    id
    name
    catalogueId
    state
    data {
      ...extensionData
    }
    createdAt
    updatedAt
  }
  ${EXTENSION_DATA_FRAGMENT}
`;
