import { gql } from 'apollo-boost';
import { PAYMENT_PROVIDER_FRAGMENT } from '../fragments/paymentProvider';

export const PAYMENT_PROVIDER_QUERY = gql`
  query paymentProvider($id: Int!) {
    paymentProvider(id: $id) {
      ...paymentProvider
    }
  }
  ${PAYMENT_PROVIDER_FRAGMENT}
`;
