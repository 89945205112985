import { gql } from 'apollo-boost';
import { CATERING_FRAGMENT } from '../fragments/catering';

export const CATERING_QUERY = gql`
  query catering($id: Int!) {
    catering(id: $id) {
      ...catering
    }
  }
  ${CATERING_FRAGMENT}
`;
