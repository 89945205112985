import React from 'react';
import { Row, Col, Drawer } from 'antd';
import { COMMON_DRAWER_PROPS, ROW_GUTTER } from '../../config/constants';
import CommerceOrdersWidgets from '../CommerceOrdersWidgets/CommerceOrdersWidgets';
import {
  CommerceOrderFragment,
  CommerceShop,
  CommerceOrderUpdateFragment,
  withCommerceShop,
  CommerceShopProps,
  Catering,
  withCommerceOrderFilters,
  CommerceOrderFiltersProps,
} from '../../typings/graphql';
import { compose } from 'recompose';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import CommerceOrderComponent from '../CommerceOrder/CommerceOrder';
import { UTableAPI, UTableProps } from '../../universal/components/UTable/UTable';
import { EVENTS, EventService } from '../../universal/services/EventService';
import CommerceOrdersTable from '../CommerceOrdersTable/CommerceOrdersTable';
import { i18n } from '../../helpers/I18n';

const POLLING_INTERVAL = 15000;

export type OrderUpdateEvent = CustomEvent<{ order: CommerceOrderUpdateFragment }>;

interface IExternalProps {
  shopId: CommerceShop['id'];
  printReceiptTemplateId?: Catering['printReceiptTemplateId'];
  onOrderClick?: (order: CommerceOrderFragment) => void;
  onOrderEditClick?: (order: CommerceOrderFragment) => void;
}

interface IProps extends IExternalProps, RouteComponentProps {
  commerceShop: CommerceShopProps['data'];
  commerceOrderFilters: CommerceOrderFiltersProps['data'];
}

interface IState {
  order: CommerceOrderFragment | null;
  uTableAPI: UTableAPI | null;
  searchString: string;
}

class ShopOrders extends React.PureComponent<IProps, IState> {
  state: IState = {
    searchString: '',
    order: null,
    uTableAPI: null,
  };

  componentDidMount() {
    document.addEventListener(EVENTS.COMMERCE_ORDER_UPDATE, this.refetchOnOrderUpdate);
  }

  private refetchOnOrderUpdate = async (event: Event) => {
    const refetchEvent = (event as unknown) as OrderUpdateEvent;
    if (!refetchEvent || !refetchEvent.detail || !refetchEvent.detail.order) {
      return;
    }

    const { uTableAPI } = this.state;
    if (!uTableAPI || !uTableAPI.refetch) {
      return;
    }

    const queryInput = uTableAPI.getQueryInput();
    if (!queryInput) {
      return;
    }

    uTableAPI.refetch(queryInput);
  };

  private handleRowClick = (order: CommerceOrderFragment) => {
    this.setState(
      {
        order,
      },
      this.handleOrderClick(order)
    );
  };

  private handleCloseDrawer = () => {
    this.setState({ order: null });
  };

  private handleOrderUpdateSuccess = (order: CommerceOrderUpdateFragment) => {
    if (!order) {
      return;
    }

    EventService.fire(EVENTS.COMMERCE_ORDER_UPDATE, { order });
  };

  private getShop = () => {
    const { commerceShop } = this.props.commerceShop;
    return commerceShop || null;
  };

  private getCodeMask = () => {
    const shop = this.getShop();
    if (!shop || !shop.codeMask) {
      return undefined;
    }

    return shop.codeMask;
  };

  private renderWidgets = () => {
    const { shopId } = this.props;
    return <CommerceOrdersWidgets shopId={Number(shopId)} />;
  };

  private renderOrder = () => {
    const { printReceiptTemplateId } = this.props;
    const { order } = this.state;
    if (!order) {
      return null;
    }

    const { shopId, id: orderId } = order;

    return (
      <CommerceOrderComponent
        orderId={orderId}
        shopId={shopId}
        onUpdate={this.handleOrderUpdateSuccess}
        printReceiptTemplateId={printReceiptTemplateId}
      />
    );
  };

  private renderDrawer = () => {
    const { order } = this.state;
    const isVisible = Boolean(order);

    return (
      <Drawer {...COMMON_DRAWER_PROPS} width="65vw" visible={isVisible} onClose={this.handleCloseDrawer}>
        {this.renderOrder()}
      </Drawer>
    );
  };

  private handleTableReady: UTableProps['onReady'] = uTableAPI => {
    this.setState({ uTableAPI });
  };

  private handleOrderClick = (order: CommerceOrderFragment) => () => {
    const { onOrderClick } = this.props;
    if (onOrderClick) {
      return onOrderClick(order);
    }
  };

  private renderTable = () => {
    const { shopId } = this.props;
    const codeMask = this.getCodeMask();
    const queryComponentProps = { pollInterval: POLLING_INTERVAL };
    const uTableProps = {
      queryComponentProps,
      onReady: this.handleTableReady,
      title: i18n('h_orders'),
      showSearch: false,
    };

    return (
      <CommerceOrdersTable
        codeMask={codeMask}
        uTableProps={uTableProps}
        shopId={shopId}
        onRowClick={this.handleRowClick}
      />
    );
  };

  private renderOrders = () => {
    return (
      <Row gutter={ROW_GUTTER}>
        <Col span={24}>{this.renderWidgets()}</Col>
        <Col span={24}>{this.renderTable()}</Col>
      </Row>
    );
  };

  private renderContent = () => {
    return (
      <>
        {this.renderOrders()}
        {this.renderDrawer()}
      </>
    );
  };

  render() {
    return this.renderContent();
  }
}

export default compose<IProps, IExternalProps>(
  withRouter,
  withCommerceOrderFilters<IExternalProps>({
    name: 'commerceOrderFilters',
    options: props => ({
      variables: {
        shopId: props.shopId,
      },
    }),
  }),
  withCommerceShop<IExternalProps>({
    name: 'commerceShop',
    options: props => ({
      variables: {
        id: props.shopId,
      },
    }),
  })
)(ShopOrders);
