import * as React from 'react';

import './UTable.css';
import {
  Alert,
  Badge,
  Button,
  Checkbox,
  DatePicker,
  Form,
  Icon,
  Input,
  message,
  Modal,
  Pagination,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import { PaginationConfig } from 'antd/lib/pagination';
import {
  ColumnFilterItem,
  ColumnProps,
  FilterDropdownProps,
  SorterResult,
  SortOrder,
  TableProps,
} from 'antd/lib/table';
import { Moment } from 'moment';
import {
  DefaultSortInput,
  FilterDataType,
  FilterItemOption,
  FilterItemFragment,
  withFilters,
  FiltersProps,
  FilterType,
  SortingDirection,
  FilterInput,
  DefaultQueryInput,
  DefaultConnection,
} from '../../typings/graphql';
import { getFromLocalStorage } from '../../helpers/getFromLocalStorage';
import { compose } from 'recompose';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import FiltersProvider, {
  FilterID,
  IExtendedFilterInput,
  IFiltersContextValue,
  TranslateHandler,
} from '../FiltersProvider/FiltersProvider';
import FloatPanel from '../FloatPanel/FloatPanel';
import { MouseEvent } from 'react';
import { DEFAULT_PAGE_SIZE, EVENTS, FORMAT, SEARCH_SUBMIT_DELAY } from '../../config/constants';
import { Query, QueryComponentOptions } from '@apollo/react-components';
import { DataValue, withApollo } from '@apollo/react-hoc';
import FiltersContextHOC from '../FiltersContextHOC/FiltersContextHOC';
import { setToLocalStorage } from '../../helpers/setToLocalStorage';
import { WithApolloClient } from '@apollo/react-hoc/lib/types';
import RefetchCatcher from './RefetchCatcher';
import { parseJSON } from '../../helpers/parseJSON';
import { ExtensionData } from '../../services/Extension';
import { changeMomentLocale, moment } from '../../helpers/getMoment';
import { getActiveLocale } from '../../helpers/getActiveLocale';
import { TableRowSelection } from 'antd/lib/table/interface';
import { FiltersAction } from '../../index';

interface IExternalProps<TInstance = any> {
  // If you want to use not query, but an array of instances
  dataSource?: TInstance[];

  // Required field, so we can store state in localStorage
  id: string;

  // Gql query itself
  query?: any;

  // Required field of query name
  queryName?: string;

  // Initial variables, will be extended with pagination and sort
  queryInput?: DefaultQueryInput;

  // If u want to render ONLY exact keys of instance
  // Visibility of this keys CAN BE changed in config
  keys?: InstanceKeys<TInstance>;

  // Exclude some keys from instance, like if u don't want to render carId column of Telemetry
  // Visibility of this keys CAN BE changed in config
  hideKeys?: InstanceKeys<TInstance>;

  // Banned keys CAN'T BE selected in config
  bannedKeys?: InstanceKeys<TInstance>;

  // Array of custom configs for columns. If u need to override some of the columns
  customColumns?: ColumnProps<TInstance>[];

  // Props directly to AntD Table
  tableProps?: Partial<TableProps<TInstance>>;

  // Soft match search
  search?: string;

  hideConfigButton?: boolean;

  rowsCount?: number | null;

  // Options to Query component, except query, variables and onComplete
  queryComponentProps?: QueryComponentOptions<any, any> | any;

  // For cases where filters requested not by Query.filters
  customFilters?: FilterItemFragment[];

  // JSX to put in footer (right from config and reset buttons)
  footerActions?: React.ReactNode[];

  // Header actions
  actions?: React.ReactNode[];

  onReady?: (uTableAPI: UTableAPI) => void;

  onRowClick?: (row: TInstance, event: MouseEvent) => void;

  onRowSelect?: (rows: TInstance[], selectedRows: TInstance[]) => void;

  onAllRowsSelect?: () => void;

  showSearch?: boolean;

  onUpdate?: (payload: ITableState) => void;

  // property to extract extension data from
  extensionDataKey?: string;

  getDataSourceFromDataValue?: (dataValue: DataValue<any>) => TInstance[];

  title?: string;

  zeroElevation?: boolean; // Removes shadow

  searchDebounce?: boolean;

  sortablePrefix?: string; // Add prefix to sortable fields, look at allTournamentTeams table for example

  hideFiltersAction?: boolean; // Hide default filters action
}

interface IProps<TInstance = any> extends IExternalProps<TInstance> {
  client: WithApolloClient<any>['client'];
  filters: NonNullable<FiltersProps['data']>;
  filtersContext: IFiltersContextValue;
}

type Connection = DefaultConnection & { edges: any[] };

interface IState<TInstance = any> {
  data: Connection | null;
  currentPage: number;
  sort: DefaultSortInput | null;
  rowsCount: number;
  isConfigModalVisible: boolean;
  visibleFields: InstanceKeys<TInstance> | null;
  search: string;
  searchDebouncePlaceholder: string;
  refetchFn: DataValue<any>['refetch'] | null;
  showSearch: boolean;

  selectedRowKeys: RowKeys<TInstance>;
}

type RowKeys<TInstance = any> = NonNullable<TableRowSelection<TInstance>['selectedRowKeys']>;

// Keys of instance, but without number and symbols
export type InstanceKey<TInstance> = string | Exclude<keyof TInstance, number | symbol>;
export type InstanceKeys<TInstance> = InstanceKey<TInstance>[];
export interface UTableProps<TInstance = any> extends IExternalProps<TInstance> {}

export interface ITableState {
  currentPage: IState['currentPage'];
  sort: IState['sort'];
  visibleFields: IState['visibleFields'];
  rowsCount: IState['rowsCount'];
  search: IState['search'];
}

interface ISpecialColumnRenderer {
  type: 'date' | 'collection' | 'enum' | 'boolean' | 'namedStructure' | 'structure';
  render: () => React.ReactNode;
}

const SORT: Record<SortOrder, SortingDirection> = {
  ascend: SortingDirection.ASC,
  descend: SortingDirection.DESC,
};

export const UTABLE_CONFIG_PREFIX = 'uTableConfig';
const COLORS = {
  NONE: '#c3c3c3',
  TRUE: '#87d068',
  FALSE: '#f50',
};
enum DateColums {
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
}

export interface UTableAPI {
  refetch: null | ((queryInput?: DefaultQueryInput) => void);
  getQueryInput: () => DefaultQueryInput;
  getData: () => IState['data'];
}

class UTable<TInstance> extends React.PureComponent<IProps<TInstance>, IState> {
  private timer: NodeJS.Timer | null = null;
  private searchInputRef = React.createRef<Input>();

  constructor(props: IProps<TInstance>) {
    super(props);
    this.state = this.getInitialState();
    window.document.addEventListener(EVENTS.FILTERS_CHANGED, this.handleFiltersChange);
  }

  componentDidMount() {
    const { onReady } = this.props;
    if (onReady) {
      const api = this.getAPI();
      onReady(api);
    }
  }

  componentWillUnmount(): void {
    window.document.removeEventListener(EVENTS.FILTERS_CHANGED, this.handleFiltersChange);
  }

  componentDidUpdate(prevProps: Readonly<IProps<TInstance>>, prevState: Readonly<IState>) {
    const { search: currentSearch, rowsCount } = this.props;
    const { search: previousSearch } = prevProps;

    const isSearchPropChanged = previousSearch !== currentSearch;
    if (isSearchPropChanged) {
      const value = currentSearch || '';

      this.updateState({ search: value }, () => {
        this.updateSearchInputValue(value);
      });
    }

    if (rowsCount && rowsCount !== this.state.rowsCount) {
      this.setState({ rowsCount });
    }
  }

  static getQueryNameFromProps = (props: IExternalProps): string | null => {
    const { query, queryName } = props;

    if (queryName) {
      return queryName;
    }

    const definitions = query?.definitions ?? [];
    const operation = definitions.find((def: any) => def?.kind === 'OperationDefinition');
    if (!operation) {
      return null;
    }

    const result = operation.name?.value;
    if (typeof result !== 'string') {
      return null;
    }

    return result;
  };

  private refetch: UTableAPI['refetch'] = queryInput => {
    const { refetchFn } = this.state;
    if (!refetchFn) {
      return;
    }

    return refetchFn({
      query: queryInput,
    });
  };

  private getTableState = (): ITableState => {
    const { currentPage, search, sort, visibleFields, rowsCount } = this.state;
    return {
      currentPage,
      search,
      sort,
      visibleFields,
      rowsCount,
    };
  };

  private getQueryInput = () => {
    const variables = this.getVariables();
    return variables.query;
  };

  private getDataFromInternalState = () => {
    return this.state.data;
  };

  private getAPI = (): UTableAPI => {
    return {
      refetch: this.refetch,
      getQueryInput: this.getQueryInput,
      getData: this.getDataFromInternalState,
    };
  };

  private handleFiltersChange = (event: Event) => {
    const detail = (event as CustomEvent<{ filterId: FilterID }>).detail;
    if (detail && detail.filterId === this.getFilterId()) {
      // Reset pagination whenever filters changed
      this.updateState({ currentPage: 1 });
    }
  };

  // Get state from localStorage or initial state
  // so we can support redirecting without loosing table state
  private getInitialState = (): IState => {
    const search = this.props.search || '';
    const showSearch = Boolean(this.props.showSearch);

    const initialState: IState = {
      data: null,
      currentPage: 1,
      rowsCount: DEFAULT_PAGE_SIZE,
      sort: null,
      isConfigModalVisible: false,
      visibleFields: this.getVisibleFields(),
      search: search,
      searchDebouncePlaceholder: search,
      refetchFn: null,
      showSearch: showSearch,
      selectedRowKeys: [],
    };

    const tableState = this.getStateFromStorage();

    return {
      ...initialState,
      ...tableState,
    };
  };

  private getFilterId = () => {
    const { id } = this.props;
    const queryName = UTable.getQueryNameFromProps(this.props);
    if (!queryName) {
      throw new Error(`queryName props is not provided`);
    }

    return FiltersProvider.getFilterId(queryName, id);
  };

  private getFilterState = (): IExtendedFilterInput[] => {
    const { filtersContext } = this.props;
    const filterId = this.getFilterId();

    const filterState = filtersContext.getFilterState(filterId);
    const emptyValue = '';

    return filterState.filter(({ value }) => value !== emptyValue);
  };

  private resetState = () => {
    // Key in local storage
    const key = this.getLocalStorageKey();

    window.localStorage.removeItem(key);

    const { data } = this.state;
    const initialState = this.getInitialState();
    const clearedState = {
      ...initialState,
      data: data,
      visibleFields: this.getVisibleFields(data),
    };

    this.setState(clearedState, () => {
      message.success('table_settings_reset_success');
    });
  };

  // Wrapper of state setter to keep localStorage state up to date
  private updateState = (payload: Partial<IState>, callback?: () => void) => {
    const { onUpdate } = this.props;
    this.setState({ ...this.state, ...payload }, () => {
      this.saveStateToStorage();

      if (callback) {
        callback();
      }
      if (onUpdate) {
        const state = this.getTableState();
        onUpdate(state);
      }
    });
  };

  private i18n: TranslateHandler = key => {
    const { i18n } = this.props.filtersContext;

    return i18n(key);
  };

  private handleChangeRowsCount = (rowsCount: number) => {
    this.updateState({ rowsCount });
  };

  private handleChangeVisibleFields = (event: CheckboxChangeEvent) => {
    const visibleFields = this.state.visibleFields || [];
    let result = [...visibleFields];
    const { value, checked } = event.target;

    if (!checked) {
      // Remove from state
      result = result.filter(key => key !== value);
    } else {
      // Add to state only if not added before
      if (!result.includes(value)) {
        result.push(value);
      }
    }

    this.updateState({ visibleFields: result });
  };

  private handleResetFilters = () => {
    const { filtersContext } = this.props;
    const filterId = this.getFilterId();

    message.info('filters_reset');
    filtersContext.resetFilter(filterId);
  };

  private handleOpenModal = (key: keyof IState) => () => {
    this.setState({ ...this.state, [key]: true });
  };

  private handleCloseModal = (key: keyof IState) => () => {
    this.setState({ ...this.state, [key]: false });
  };

  private handleChangeCustomPagination = (currentPage: number) => {
    this.updateState({ currentPage });
  };

  private generateFilterInputId = () => {
    return new Date().toISOString();
  };

  private handleChangeFilters = (inTableFiltersState: Partial<Record<keyof TInstance, string[]>>) => {
    if (!inTableFiltersState) {
      return;
    }

    // Get existed filter inputs
    const existedFilterInputs = this.getFilterState();

    // define map [column.key]: json[]
    let tableFiltersState: Record<string, string[] | undefined | null> = {};

    // Fill map with filters from FiltersProvider
    for (const filterInput of existedFilterInputs) {
      const { field, value } = filterInput;
      tableFiltersState[field] = parseJSON(value, true);
    }

    // Override filters by data from table
    tableFiltersState = { ...tableFiltersState, ...inTableFiltersState };

    const filterId = this.getFilterId();
    const filterInputs: IExtendedFilterInput[] = [];

    for (const field in tableFiltersState) {
      if (!tableFiltersState.hasOwnProperty(field)) {
        continue;
      }

      const value: string[] | undefined | null = tableFiltersState[field];
      if (!value || value.length === 0) {
        continue;
      }

      const filterConfig = this.getFilterConfig(field);
      if (!filterConfig) {
        continue;
      }

      const filterInput = this.getFilterInput(field);
      const filterInputId = (filterInput && filterInput.id) || this.generateFilterInputId();

      // Payload ids necessary, so we wont override here isExclude/isRange or any other fields
      // Already stored in Provider's state
      const payload = {
        ...filterInput,
        id: filterInputId,
        value: JSON.stringify(value),
        dataType: filterConfig.dataType,
        field: field,
      };

      filterInputs.push(payload);
    }

    this.props.filtersContext.setFilter(filterId, filterInputs);
  };

  private handleRowClick = (elem: TInstance) => (event: MouseEvent) => {
    const { onRowClick } = this.props;
    if (!onRowClick) {
      return;
    }

    onRowClick(elem, event);
  };

  // Common handler for table changes
  private handleChange: TableProps<TInstance>['onChange'] = (pagination, filters, sorter) => {
    let payload: Partial<IState> = {};

    if (pagination) {
      payload = { ...payload, ...this.handleChangePage(pagination) };
    }

    if (sorter) {
      payload = { ...payload, ...this.handleChangeSort(sorter) };
    }

    if (filters) {
      payload = { ...payload, currentPage: 1 };
    }

    this.updateState(payload, () => {
      this.handleChangeFilters(filters);
    });
  };

  private handleChangePage = (pagination: PaginationConfig): Partial<IState> => {
    const { currentPage } = this.state;

    return { currentPage: pagination.current || currentPage };
  };

  private handleChangeSort = (sorter: SorterResult<TInstance>): Partial<IState> => {
    const direction = SORT[sorter.order];
    const key = sorter.columnKey;

    // Clear state if sorter removed
    if (!sorter.order) {
      return { sort: null };
    }

    return {
      sort: {
        field: key,
        direction,
      },
    };
  };

  private handleChangeInputFilter = (key: string, propsToFilter: FilterDropdownProps) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!propsToFilter.setSelectedKeys || !propsToFilter.confirm) {
      return;
    }

    const { value } = event.target;
    if (!value) {
      return propsToFilter.setSelectedKeys([]);
    }

    // Update inner AntD Table state
    propsToFilter.setSelectedKeys([value]);
  };

  private handleChangeDateFilter = (key: string, propsToFilter: FilterDropdownProps) => (
    date: Moment | null,
    dateString: string
  ) => {
    if (!propsToFilter.setSelectedKeys || !propsToFilter.confirm || !propsToFilter.clearFilters) {
      return;
    }

    if (!dateString) {
      propsToFilter.setSelectedKeys([]);
      return propsToFilter.confirm();
    }

    // Convert dates to ISO format
    const value = moment(dateString).toISOString();

    // Update inner AntD Table state
    propsToFilter.setSelectedKeys([value]);
    propsToFilter.confirm();
  };

  private handleChangeDateRangeFilter = (key: string, propsToFilter: FilterDropdownProps) => (
    dates: RangePickerValue,
    dateStrings: [string, string]
  ) => {
    if (!propsToFilter.setSelectedKeys || !propsToFilter.confirm || !propsToFilter.clearFilters) {
      return;
    }

    // Submit only if both dates set
    if (dateStrings.length !== 2) {
      return;
    }

    // When reset dates it return dateStrings as ['', '']
    if (!dateStrings[0] && !dateStrings[1]) {
      propsToFilter.setSelectedKeys([]);
      return propsToFilter.confirm();
    }

    // Convert dates to ISO format
    const value = dateStrings.map(dateString => moment(dateString, FORMAT.FULL).toISOString());

    // Update inner AntD Table state
    propsToFilter.setSelectedKeys(value);
    propsToFilter.confirm();
  };

  private handleCatchRefetch = (dataValue: DataValue<any>) => {
    this.setState({ refetchFn: dataValue.refetch });
  };

  private getLocalStorageKey = () => {
    // Key in local storage
    const { id } = this.props;
    const queryName = UTable.getQueryNameFromProps(this.props);
    const unique = id || queryName;

    return `${UTABLE_CONFIG_PREFIX}_${unique}`;
  };

  private getStateFromStorage = () => {
    const key = this.getLocalStorageKey();
    const data = getFromLocalStorage(key);
    if (!data) {
      return null;
    }

    return data as ITableState;
  };

  private saveStateToStorage = () => {
    const { currentPage, visibleFields, sort, rowsCount, search } = this.state;
    if (!visibleFields || visibleFields.length === 0) {
      return;
    }

    // Key in local storage
    const key = this.getLocalStorageKey();

    // Config itself
    const config: ITableState = {
      currentPage: currentPage,
      sort: sort,
      visibleFields: visibleFields,
      rowsCount: rowsCount,
      search: search,
    };

    setToLocalStorage(key, config);
  };

  private getTotal = () => {
    const { data } = this.state;
    const { dataSource } = this.props;

    let total;

    if (dataSource) {
      total = dataSource?.length ?? 0;
    } else {
      total = data?.total ?? 0;
    }

    return total;
  };

  // Get pagination for table and hide, if no data fetched
  private getPagination = (): PaginationConfig | null => {
    const { currentPage, rowsCount } = this.state;

    // Hide if no data
    const total = this.getTotal();
    if (total < rowsCount) {
      return null;
    }

    return {
      size: 'small',
      position: 'bottom',
      hideOnSinglePage: true,
      pageSize: rowsCount,
      total: total,
      current: currentPage,
      showTotal: this.showTotal,
    };
  };

  private showTotal = (total: number, range: [number, number]) => {
    return `${range[0]}-${range[1]} ${this.i18n('from')} ${total}`;
  };

  private getProvidedDataSource = (dataSource: NonNullable<IProps['dataSource']>) => {
    const { rowsCount, currentPage } = this.state;

    const indexFrom = (currentPage - 1) * rowsCount;
    const indexTo = indexFrom + rowsCount;

    return dataSource.slice(indexFrom, indexTo);
  };

  private handleSelectAllRowsOnPage = (event: CheckboxChangeEvent) => {
    const isSelected = event.target.checked;

    const edges = this.state.data?.edges ?? [];
    const selectedRows = isSelected ? edges : [];

    this.handleSelectMultipleRows(isSelected, selectedRows, selectedRows);
  };

  private getRowKeys = (rows: TInstance[]): RowKeys => {
    return rows
      .map((row: any, index: number) => {
        const { tableProps } = this.props;
        if (tableProps?.rowKey && typeof tableProps?.rowKey === 'function') {
          return tableProps.rowKey(row, index);
        }

        return row?.id ?? null;
      })
      .filter(row => row !== null);
  };

  private isAllRowsOnPageSelected = (): boolean => {
    const { selectedRowKeys, data } = this.state;

    const rowsOnThePage = data?.edges ?? [];
    if (rowsOnThePage.length === 0) {
      return false;
    }

    const keysOnThePage = this.getRowKeys(rowsOnThePage);
    const isSelectStatuses = (keysOnThePage as any[]).map(rowKey => (selectedRowKeys as any[]).includes(rowKey));

    return isSelectStatuses.indexOf(false) === -1;
  };

  private handleSelectWholeTable = () => {
    const { onAllRowsSelect } = this.props;
    if (!onAllRowsSelect) {
      return;
    }

    onAllRowsSelect();
  };

  private handleSelectRow: TableRowSelection<TInstance>['onSelect'] = (row, isSelected, selectedRows) => {
    const selectedRowKeys = this.getRowKeys(selectedRows as TInstance[]);

    this.setState({ selectedRowKeys }, () => {
      const { onRowSelect } = this.props;
      if (!onRowSelect) {
        return;
      }

      onRowSelect([row], selectedRows as TInstance[]);
    });
  };

  private handleSelectMultipleRows: NonNullable<TableRowSelection<TInstance>['onSelectMultiple']> = (
    isSelected,
    selectedRows,
    changedRows
  ) => {
    const selectedRowKeys = this.getRowKeys(selectedRows as TInstance[]);

    this.setState({ selectedRowKeys }, () => {
      const { onRowSelect } = this.props;
      if (!onRowSelect) {
        return;
      }

      onRowSelect(changedRows, selectedRows);
    });
  };

  private getTableComponentRowSelection = (): TableRowSelection<TInstance> | undefined => {
    const { onRowSelect, onAllRowsSelect } = this.props;
    if (!onRowSelect && !onAllRowsSelect) {
      return undefined;
    }

    return {
      type: 'checkbox',
      columnTitle: this.renderSelectAllRowsCheckbox,
      onSelect: this.handleSelectRow,
      // onSelectAll: this.handleSelectAllRowsOnPage,
      onSelectMultiple: this.handleSelectMultipleRows,
      selectedRowKeys: this.state.selectedRowKeys,
    };
  };

  private getTableComponentProps = (dataValue?: DataValue<any>): TableProps<TInstance> => {
    const { tableProps } = this.props;
    const loading = dataValue && dataValue.loading;
    const dataSource = this.getDataSource(dataValue);
    const columns = this.getColumns();
    const footer = this.renderFooter;
    const rowSelection = this.getTableComponentRowSelection();
    const scroll = {
      x: 'auto',
    };

    const onRow: TableProps<TInstance>['onRow'] = elem => ({
      onClick: this.handleRowClick(elem),
    });

    return {
      size: 'small',
      rowKey: 'id',
      loading: loading,
      dataSource: dataSource,
      columns: columns,
      scroll: scroll,
      footer: footer,
      bordered: false,
      pagination: false,
      onChange: this.handleChange,
      onRow: onRow,
      rowSelection: rowSelection,
      ...tableProps,
    };
  };

  private getTableContainerClassname = (): string => {
    const classNames = ['uTable_container'];

    const { zeroElevation } = this.props;
    if (zeroElevation) {
      classNames.push('uTable_container--no-shadow');
    }

    return classNames.join(' ');
  };

  private getDataSource = (dataValue?: DataValue<any>) => {
    const { dataSource } = this.props;
    if (dataSource) {
      return this.getProvidedDataSource(dataSource);
    }

    const queryName = UTable.getQueryNameFromProps(this.props);

    // Extract from dataValue if exist
    if (dataValue && queryName) {
      return this.getDataSourceFromDataValue(dataValue, queryName);
    }

    return [];
  };

  // Get dataSource from query result
  private getDataSourceFromDataValue = (dataValue: DataValue<any>, queryName: string) => {
    const { getDataSourceFromDataValue } = this.props;
    if (getDataSourceFromDataValue) {
      return getDataSourceFromDataValue(dataValue);
    }

    const response = dataValue && dataValue.data && dataValue.data[queryName];
    if (!response) {
      return [];
    }

    return response.edges || [];
  };

  private getFiltersForVariables = () => {
    const { queryInput } = this.props;
    const filterState = this.getFilterState();

    const filtersFromProps = (queryInput && queryInput.filters) || [];
    const filtersFromState: FilterInput[] = filterState.map(({ id, ...input }) => input);

    return [...filtersFromProps, ...filtersFromState];
  };

  // Get variables for refetch after pagination and filtration
  private getVariables = () => {
    const { currentPage, sort, rowsCount, search } = this.state;
    const { queryInput } = this.props;
    const isFiltersApplied = this.isFiltersApplied();
    const query: DefaultQueryInput = {
      ...queryInput,
    };

    // Pagination
    query.cursor = (currentPage - 1) * rowsCount;

    // Page size
    query.limit = rowsCount;

    // Search
    query.search = search;

    // Sort
    if (sort) {
      query.sort = [sort];
    }

    // Filters
    if (isFiltersApplied) {
      query.filters = this.getFiltersForVariables();
    }

    return { query };
  };

  private filterVisibleKeys = (instance: any) => (key: InstanceKey<TInstance>): boolean => {
    const { hideKeys } = this.props;

    // Exclude column from visible if contains nonPrimitive structure we can't render
    const value = this.getValueFromRecord(instance, key);
    const specialColumnRenderer = this.getSpecialColumnRenderer(key, value);
    if (specialColumnRenderer && specialColumnRenderer.type === 'structure') {
      return false;
    }

    // Otherwise render all keys, except:
    const hidden = hideKeys || [];
    const ignoreKeys = ['__typename', 'id', 'clientId', ...hidden];
    return !ignoreKeys.includes(key);
  };

  private isColumnSortable = (key: string): boolean => {
    const { sortablePrefix } = this.props;
    const { data } = this.state;
    if (!data || !data.sortable) {
      return false;
    }

    const localKey = sortablePrefix ? `${sortablePrefix}.${key}` : key;

    return data.sortable.includes(localKey);
  };

  private isSearchable = (): boolean => {
    const { data } = this.state;
    return Boolean(data && data.searchable);
  };

  private getDefaultSortOrder = (key: string): 'descend' | 'ascend' | undefined => {
    const { sort } = this.state;
    if (!sort) {
      return;
    }

    if (sort.field !== key) {
      return;
    }

    return sort.direction === SortingDirection.ASC ? 'ascend' : 'descend';
  };

  // System columns for all the tables, like index
  private getSystemColumns = (): ColumnProps<any>[] => {
    return [
      {
        title: this.i18n('#'),
        dataIndex: 'index',
        render: this.renderColumnIndex,
      },
    ];
  };

  private getFilters = () => {
    const { filters, customFilters } = this.props;
    if (customFilters && Array.isArray(customFilters)) {
      return customFilters;
    }

    if (filters && filters.filters) {
      return filters.filters;
    }

    return [];
  };

  private getFilterConfig = (key: string) => {
    const filters = this.getFilters();

    return filters.find(filterConfig => filterConfig.field === key);
  };

  private getFilterInput = (key: string): IExtendedFilterInput | null => {
    const state = this.getFilterState();
    const filterInput = state.find(({ field }) => field === key);

    return filterInput || null;
  };

  private getColumnFilters = (key: string): ColumnFilterItem[] | undefined => {
    const filterConfig = this.getFilterConfig(key);
    if (!filterConfig) {
      return;
    }

    if (filterConfig.type === FilterType.SELECT_MULTIPLE || filterConfig.type === FilterType.SELECT) {
      const options = (filterConfig.options as FilterItemOption[]) || [];
      const isEnum = filterConfig.dataType === FilterDataType.ENUM;

      return options.map(option => ({
        text: isEnum ? this.i18n(`${filterConfig.field}_${option.name}`) : option.name,
        value: option.id,
      }));
    }

    if (filterConfig.type === FilterType.CHECKBOX) {
      return [
        {
          text: this.i18n('checkbox_on'),
          value: String(true),
        },
        {
          text: this.i18n('checkbox_off'),
          value: String(false),
        },
      ];
    }

    return;
  };

  private isFiltersApplied = () => {
    const { filtersContext } = this.props;
    const filterId = this.getFilterId();

    return filtersContext.isFiltersApplied(filterId);
  };

  private isFilterMultiple = (key: string): boolean => {
    const filterConfig = this.getFilterConfig(key);
    if (!filterConfig) {
      return false;
    }

    if (filterConfig.type === FilterType.CHECKBOX) {
      return true;
    }

    return filterConfig.type === FilterType.SELECT_MULTIPLE;
  };

  private getFilterDropDown = (key: string): ColumnProps<any>['filterDropdown'] => {
    const filterConfig = this.getFilterConfig(key);
    if (!filterConfig) {
      return undefined;
    }

    if (filterConfig.type === FilterType.INPUT) {
      if (filterConfig.dataType === FilterDataType.DATE) {
        return this.renderFilterDatePicker(key, filterConfig);
      }

      return this.renderFilterSearch(key);
    }
  };

  private getFilterValue = (key: string): string[] | null => {
    const { dataSource } = this.props;
    if (dataSource) {
      return null; // You cant have filters if dataSource provided
    }

    const filterState = this.getFilterState();

    const storedFilter = filterState.find(({ field }) => field === key);
    if (!storedFilter || !storedFilter.value) {
      return null;
    }

    return parseJSON(storedFilter.value);
  };

  private getDefaultColumnConfig = (key: string): ColumnProps<any> => ({
    title: this.i18n(key),
    dataIndex: key,
    key: key,
    render: this.renderColumn(key),
    sorter: this.isColumnSortable(key),
    defaultSortOrder: this.getDefaultSortOrder(key),
    filters: this.getColumnFilters(key),
    filterMultiple: this.isFilterMultiple(key),
    filterDropdown: this.getFilterDropDown(key),
    filteredValue: this.getFilterValue(key),
  });

  private getCustomColumnConfig = (key: string): ColumnProps<any> | null => {
    const { customColumns } = this.props;
    if (!customColumns) {
      return null;
    }

    const customColumnConfig = customColumns.find(customColumn => customColumn.key === key);

    return customColumnConfig || null;
  };

  private getColumnConfigFromKey = (key: string): ColumnProps<any> => {
    const defaultColumnConfig = this.getDefaultColumnConfig(key);
    const customColumnConfig = this.getCustomColumnConfig(key);

    return {
      ...defaultColumnConfig,
      ...customColumnConfig,
    };
  };

  private getDataInstance = () => {
    const { data } = this.state;
    if (!data || !data.edges) {
      return null;
    }

    // Get first instance of response collection and get instance keys
    const [firstInstance] = data.edges;
    return firstInstance || null;
  };

  private getBannedKeys = () => {
    const bannedByCustomerKeys = this.props.bannedKeys || [];
    const systemBannedKeys = ['__typename'];

    return [...systemBannedKeys, ...bannedByCustomerKeys];
  };

  private isBannedKey = (key: string) => {
    const bannedKeys = this.getBannedKeys();

    return bannedKeys.includes(key);
  };

  private isNotBannedKey = (key: string) => !this.isBannedKey(key);

  private getDataInstanceKeys = (): string[] => {
    let instance = this.getDataInstance();
    if (!instance) {
      return [];
    }

    if (typeof instance === 'string') {
      const parsed = parseJSON(instance, true);
      if (!parsed) {
        return [];
      }

      instance = parsed;
    }

    const instanceKeys = Object.keys(instance);
    const extensionDataKeys = this.getExtensionFieldNamesFromInstance(instance);

    const keys = [...instanceKeys, ...extensionDataKeys];

    // filterVisibleKeys method just turn off field visibility, while here we have
    // Fully banned keys, which can't be selected in config
    return keys.filter(this.isNotBannedKey);
  };

  private getColumns = (): ColumnProps<TInstance>[] => {
    const systemColumns = this.getSystemColumns();

    const { visibleFields } = this.state;
    if (!visibleFields) {
      return [...systemColumns];
    }

    const columnsFromInstance = visibleFields.map(this.getColumnConfigFromKey);

    return [...systemColumns, ...columnsFromInstance];
  };

  private getVisibleFields = (connection?: Connection | null) => {
    const { keys, dataSource } = this.props;

    // Use provided keys
    if (keys) {
      return keys;
    }

    let instance: any; // (TInstance or Record<string, any> or string) | undefined

    if (dataSource && dataSource[0]) {
      // Use if dataSource provided
      instance = dataSource[0];
    } else {
      // Or get keys from instance
      instance = connection && connection.edges && connection.edges[0];
    }

    if (!instance) {
      return [];
    }

    // If JSON string
    if (typeof instance === 'string') {
      const parsedInstance = parseJSON(instance, true);
      if (!parsedInstance) {
        return [];
      }

      if (typeof parsedInstance !== 'object') {
        return [];
      }

      instance = parsedInstance;
    }

    const instanceKeys = Object.keys(instance);
    const extensionDataFieldNames = this.getExtensionFieldNamesFromInstance(instance);

    const visibleFields = [...instanceKeys, ...extensionDataFieldNames];

    // And filter them
    return visibleFields.filter(this.filterVisibleKeys(instance));
  };

  private getExtensionData = (): ExtensionData[] => {
    const instance = this.getDataInstance();
    if (!instance) {
      return [];
    }

    return this.getExtensionDataFromInstance(instance);
  };

  private getExtensionDataFromInstance = (instance: TInstance): ExtensionData[] => {
    if (!instance) {
      return [];
    }

    const { extensionDataKey } = this.props;
    if (!extensionDataKey) {
      return [];
    }

    const extensionData = (instance as Record<string, any>)[extensionDataKey] as ExtensionData[] | undefined;
    if (!Array.isArray(extensionData)) {
      return [];
    }

    return extensionData;
  };

  private getExtensionFieldNamesFromInstance = (instance: TInstance): string[] => {
    const { extensionDataKey } = this.props;
    if (!extensionDataKey) {
      return [];
    }

    if (!instance) {
      return [];
    }

    const extensionData = this.getExtensionDataFromInstance(instance);

    return extensionData.map(extensionData => {
      return `${extensionDataKey}.${extensionData.key}`; // 'data.infotechId' for example
    });
  };

  // Set fetched data to state to prevent arguments hell
  private handleComplete = (dataValue: any) => {
    const queryName = UTable.getQueryNameFromProps(this.props);
    if (!queryName) {
      throw new Error(`queryName prop is not provided!`);
    }

    const data = dataValue[queryName];
    const payload: Partial<IState> = {};

    payload.data = data;

    if (!this.state.visibleFields) {
      payload.visibleFields = this.getVisibleFields(data);
    }

    this.updateState({ ...this.state, ...payload });
  };

  private handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { searchDebounce } = this.props;

    if (this.timer !== null) {
      clearTimeout(this.timer);
    }

    event.persist();
    const searchString = event.target.value;

    if (searchDebounce) {
      this.setState({
        searchDebouncePlaceholder: searchString,
      });

      this.timer = setTimeout(() => {
        this.updateState({
          search: searchString,
          currentPage: 1,
        });
      }, SEARCH_SUBMIT_DELAY);

      return;
    }

    this.updateState({
      search: searchString,
      currentPage: 1,
    });
    return;
  };

  private updateSearchInputValue = (value: string) => {
    const { current: searchInput } = this.searchInputRef;
    if (!searchInput) {
      return;
    }

    searchInput.setValue(value);
  };

  private handleSearchClear = () => {
    const value = '';
    this.updateSearchInputValue(value);

    this.updateState({
      search: value,
      searchDebouncePlaceholder: value,
    });
  };

  private renderColumnIndex = (_: any, __: any, index: number) => {
    const { currentPage, rowsCount } = this.state;
    const rowNumberInPage = index + 1;
    const rowsSkipped = (currentPage - 1) * rowsCount;

    return rowsSkipped + rowNumberInPage;
  };

  private renderFilterSearch = (key: string) => (propsToFilter: FilterDropdownProps) => {
    const values = propsToFilter.selectedKeys || [];
    const value = values[0];

    return (
      <div className="uTable_customFilter">
        <Input
          value={value}
          onChange={this.handleChangeInputFilter(key, propsToFilter)}
          placeholder={this.i18n('search')}
        />
        <Button type="primary" icon="search" onClick={propsToFilter.confirm} />
      </div>
    );
  };

  private renderFilterDatePicker = (key: string, filterConfig: FilterItemFragment) => (
    propsToFilter: FilterDropdownProps
  ) => {
    const { isRangeAvailable } = filterConfig;
    const { selectedKeys } = propsToFilter;

    if (isRangeAvailable) {
      const value = (selectedKeys || []).map(v => moment(v)) as [Moment, Moment];

      return (
        <div className="uTable_customFilter">
          <DatePicker.RangePicker
            onChange={this.handleChangeDateRangeFilter(key, propsToFilter)}
            placeholder={[this.i18n('begin'), this.i18n('end')]}
            format={FORMAT.FULL}
            value={value}
            showTime
          />
        </div>
      );
    }

    const selectedKey = selectedKeys && selectedKeys[0];
    const value = selectedKey ? moment(selectedKey) : undefined;
    return (
      <div className="uTable_customFilter">
        <DatePicker
          onChange={this.handleChangeDateFilter(key, propsToFilter)}
          placeholder={this.i18n('placeholder_date')}
          format={FORMAT.FULL}
          value={value}
          showTime
        />
      </div>
    );
  };

  // Render function for each cell
  private renderColumn = (key: string): ColumnProps<TInstance>['render'] => (_, record: TInstance) => {
    const placeholder = '-';

    const value = this.getValueFromRecord(record, key);

    // Render placeholder if no value
    if (!value) {
      return placeholder;
    }

    const specialColumnRenderer = this.getSpecialColumnRenderer(key, value);
    if (specialColumnRenderer) {
      return specialColumnRenderer.render();
    }

    return value;
  };

  private getValueFromRecord = (record: TInstance, key: string) => {
    let value = (record as Record<string, any>)[key];

    const { extensionDataKey } = this.props;
    if (!extensionDataKey) {
      return value;
    }

    const keysParts = key.split('.');
    const [firstPart, ...otherParts] = keysParts;
    const extensionFieldName = otherParts.join('.');
    const isAbleToSplit = keysParts.length >= 2;
    const isFirstPartEqualToExtensionDataKey = firstPart && firstPart.indexOf(extensionDataKey) === 0;
    const isExtensionKey = isAbleToSplit && isFirstPartEqualToExtensionDataKey;

    if (!isExtensionKey) {
      return value;
    }

    const extensionData = this.getExtensionData();
    const dataRecord = extensionData.find(dr => dr.key === extensionFieldName);
    if (!dataRecord) {
      return value;
    }

    return dataRecord.value;
  };

  private getSpecialColumnRenderer = (key: string, value: any): undefined | ISpecialColumnRenderer => {
    const filterConfig = this.getFilterConfig(key);

    if (key in DateColums) {
      return {
        type: 'date',
        render: () => {
          const activeLocale = getActiveLocale();
          changeMomentLocale(activeLocale);
          return moment(value).format(FORMAT.MODIFY_DATE_FULL);
        },
      };
    }
    // If date
    if (value && filterConfig && filterConfig.dataType === FilterDataType.DATE) {
      return {
        type: 'date',
        render: () => {
          const activeLocale = getActiveLocale();
          changeMomentLocale(activeLocale);
          return moment(value).format(FORMAT.FULL);
        },
      };
    }

    // If date
    if (value && filterConfig && filterConfig.dataType === FilterDataType.ENUM) {
      return {
        type: 'enum',
        render: () => <Tag>{this.i18n(`${filterConfig.field}_${value}`)}</Tag>,
      };
    }

    // If collection of something
    if (Array.isArray(value)) {
      if (value.length === 0) {
        return {
          type: 'collection',
          render: () => null,
        };
      }

      if (value[0] && value[0].name) {
        return {
          type: 'collection',
          render: () => value.map(({ name }, index) => <Tag key={String(index)}>{name}</Tag>),
        };
      }

      if (value[0] && typeof value[0] === 'string') {
        const stringValues: string[] = value.filter(v => typeof v === 'string');

        return {
          type: 'collection',
          render: () => stringValues.map((value, index) => <Tag key={String(index)}>{value}</Tag>),
        };
      }
    }

    if (typeof value === 'boolean') {
      const type = value ? 'check-circle' : 'close-circle';
      const twoToneColor = value ? COLORS.TRUE : COLORS.FALSE;
      return {
        type: 'boolean',
        render: () => <Icon theme="twoTone" twoToneColor={twoToneColor} type={type} />,
      };
    }

    // Do not render arrays, object and other non primitives
    if (typeof value !== 'string' && typeof value !== 'number') {
      const name = value && value.name;
      if (name) {
        return {
          type: 'namedStructure',
          render: () => name,
        };
      }

      return {
        type: 'structure',
        render: () => (
          <Button size="small" icon="info-circle" onClick={this.handleShowNonPrimitive(key, value)}>
            {this.i18n('action_show')}
          </Button>
        ),
      };
    }
  };

  private handleShowNonPrimitive = (key: string, value: any) => (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    Modal.info({
      title: this.i18n(key),
      content: <pre>{JSON.stringify(value, null, 2)}</pre>,
    });
  };

  private renderContentWithQuery = () => {
    const { query, queryComponentProps } = this.props;
    const variables = this.getVariables();

    if (!query) {
      const message = this.i18n('query_not_provided');
      const description = 'Use UTable query prop';

      return <Alert type="warning" message={message} description={description} />;
    }

    // Execute query
    return (
      <Query {...queryComponentProps} query={query} variables={variables} onCompleted={this.handleComplete}>
        {this.renderQueryContent}
      </Query>
    );
  };

  private renderContentWithDataSource = () => {
    return this.renderTable();
  };

  // Render warning if something is not provided and execute query
  private renderContent = () => {
    const { dataSource } = this.props;
    if (dataSource) {
      return this.renderContentWithDataSource();
    }

    return this.renderContentWithQuery();
  };

  // Render loading state, render error and other request status info
  private renderQueryContent = (dataValue: DataValue<any>) => {
    // Finally render table itself
    return (
      <>
        {this.renderRefetchCatcher(dataValue)}
        {this.renderError(dataValue)}
        {this.renderTable(dataValue)}
      </>
    );
  };

  private renderRefetchCatcher = (dataValue: DataValue<any>) => {
    return <RefetchCatcher dataValue={dataValue} onCatch={this.handleCatchRefetch} />;
  };

  private renderError = (dataValue: DataValue<any>) => {
    if (!dataValue.error) {
      return null;
    }

    return (
      <>
        <div className="uTable_errorOverlay" />
        <div className="uTable_errorModal">
          <FloatPanel title={this.i18n('error_happened')}>
            <ErrorMessage error={dataValue.error} />
            <div className="uTable_errorActions">
              <h2>{this.i18n('actions')}</h2>
              {this.renderResetFiltersAction()}
              {this.renderResetControl()}
            </div>
          </FloatPanel>
        </div>
      </>
    );
  };

  private renderResetFiltersAction = () => {
    const { dataSource, filtersContext } = this.props;
    if (dataSource) {
      return null; // You cant set/reset filters if dataSource provided
    }

    const isFiltersApplied = this.isFiltersApplied();
    if (!isFiltersApplied) {
      return null;
    }

    const text = filtersContext.i18n('action_reset_filters');

    return (
      <Button className="uTable_footerAction uTable_footerBtn" type="default" onClick={this.handleResetFilters}>
        {text}
      </Button>
    );
  };

  private renderFiltersAction = () => {
    const { dataSource, id, hideFiltersAction } = this.props;
    if (hideFiltersAction) {
      return null;
    }

    if (dataSource) {
      return null; // You cant set/reset filters if dataSource provided
    }

    const queryName = UTable.getQueryNameFromProps(this.props);
    if (!queryName) {
      return null;
    }

    const isFiltersApplied = this.isFiltersApplied();

    return (
      <Badge dot={isFiltersApplied}>
        <FiltersAction queryName={queryName} id={id} />
      </Badge>
    );
  };

  private renderPagination = () => {
    const pagination = this.getPagination();
    if (!pagination) {
      return null;
    }

    return (
      <div className="uTable_footerAction uTable_pagination">
        <Pagination {...pagination} onChange={this.handleChangeCustomPagination} />
      </div>
    );
  };

  private renderConfigureAction = () => {
    const { hideConfigButton } = this.props;
    if (hideConfigButton) {
      return null;
    }

    return (
      <Button className="uTable_footerBtn" type="default" onClick={this.handleOpenModal('isConfigModalVisible')}>
        <Icon type="setting" />
      </Button>
    );
  };

  private renderFooterActions = () => {
    const { footerActions } = this.props;
    if (!footerActions) {
      return null;
    }

    return footerActions.map(this.renderAction);
  };

  private renderAction = (action: React.ReactNode, index: number) => {
    const key = `action-${index}`;

    return (
      <div key={key} className="uTable_action">
        {action}
      </div>
    );
  };

  private renderSelectAllRowsCheckbox = () => {
    const isAllRowsOnPageSelected = this.isAllRowsOnPageSelected();

    const label = this.i18n('select_all');
    const total = this.getTotal();
    const title = (
      <div className="uTable_checkboxTooltipContent">
        <span>{label}</span>
        <span>&nbsp;</span>
        <span className="uTable_checkboxTotal" onClick={this.handleSelectWholeTable}>
          {total}
        </span>
      </div>
    );

    let isIndeterminate = this.state.selectedRowKeys.length > 0;
    if (isAllRowsOnPageSelected) {
      isIndeterminate = false;
    }

    return (
      <Tooltip title={title} className="uTable_checkboxTooltip">
        <Checkbox
          indeterminate={isIndeterminate}
          checked={isAllRowsOnPageSelected}
          onChange={this.handleSelectAllRowsOnPage}
        />
      </Tooltip>
    );
  };

  private renderActions = () => {
    const providedActions = this.props.actions || [];
    const actions = [
      this.renderSearch(),
      ...providedActions,
      this.renderFiltersAction(),
      this.renderResetFiltersAction(),
      this.renderConfigureAction(),
    ];

    const nonNullableActions = actions.filter(action => action !== null);

    return <div className="uTable_headerActions">{nonNullableActions.map(this.renderAction)}</div>;
  };

  private renderFooter = () => {
    return (
      <Row type="flex" justify="start" className="uTable_footer">
        {this.renderFooterActions()}
        {this.renderPagination()}
      </Row>
    );
  };

  private renderResetControl = () => {
    return (
      <Button icon="control" onClick={this.resetState}>
        {this.i18n('action_reset_settings')}
      </Button>
    );
  };

  private renderRowsCountControl = () => {
    const { rowsCount } = this.state;

    return (
      <Select value={rowsCount} onChange={this.handleChangeRowsCount}>
        <Select.Option value={5}>5</Select.Option>
        <Select.Option value={10}>10</Select.Option>
        <Select.Option value={25}>25</Select.Option>
        <Select.Option value={50}>50</Select.Option>
        <Select.Option value={100}>100</Select.Option>
        <Select.Option value={250}>250</Select.Option>
        <Select.Option value={500}>500</Select.Option>
      </Select>
    );
  };

  private renderFieldsControl = () => {
    const instanceKeys = this.getDataInstanceKeys();
    const visibleFields = this.state.visibleFields || [];
    const providedKeys = this.props.keys || [];

    const keysSet = new Set([...providedKeys, ...instanceKeys]);
    const allKeys = Array.from(keysSet);

    // Sort keys before render
    const sortedKeys = allKeys.sort((a, b) => this.i18n(a).localeCompare(this.i18n(b)));

    return sortedKeys.map(key => {
      const checked = visibleFields.includes(key);

      return (
        <div key={key}>
          <Checkbox onChange={this.handleChangeVisibleFields} value={key} checked={checked}>
            {this.i18n(key)}
          </Checkbox>
        </div>
      );
    });
  };

  private handleSearchVisibilityChange = (event: CheckboxChangeEvent) => {
    const { checked } = event.target;
    let payload: Partial<IState> = {
      showSearch: checked,
    };
    if (!checked) {
      payload = {
        ...payload,
        search: '',
        currentPage: 1,
      };
    }

    this.updateState(payload);
  };

  private renderSearchControl = () => {
    const { showSearch } = this.state;
    const isSearchable = this.isSearchable();
    const isDisabled = !isSearchable;

    return (
      <Checkbox
        disabled={isDisabled}
        onChange={this.handleSearchVisibilityChange}
        value={'search'}
        checked={showSearch}
      >
        {this.i18n('show_search')}
      </Checkbox>
    );
  };

  private renderConfigModal = () => {
    const { isConfigModalVisible } = this.state;
    const footer = null;
    const title = this.i18n('h_table_configuration');

    return (
      <Modal
        title={title}
        footer={footer}
        visible={isConfigModalVisible}
        onCancel={this.handleCloseModal('isConfigModalVisible')}
      >
        <Form>
          <Form.Item label={this.i18n('rows_count')}>{this.renderRowsCountControl()}</Form.Item>
          <Form.Item label={this.i18n('search')}>{this.renderSearchControl()}</Form.Item>
          <Form.Item label={this.i18n('fields')}>{this.renderFieldsControl()}</Form.Item>
          <Form.Item label={this.i18n('reset')}>{this.renderResetControl()}</Form.Item>
        </Form>
      </Modal>
    );
  };

  private renderModals = () => {
    return <>{this.renderConfigModal()}</>;
  };

  private renderSearch = () => {
    const { searchDebounce } = this.props;
    const { showSearch, search, searchDebouncePlaceholder } = this.state;
    const isSearchable = this.isSearchable();

    if (!showSearch || !isSearchable) {
      return null;
    }

    const title = this.i18n('action_clear_search');
    const suffix = (
      <Tooltip title={title}>
        <Icon type="close" onClick={this.handleSearchClear} />
      </Tooltip>
    );

    const searchValue = searchDebounce ? searchDebouncePlaceholder : search;

    return (
      <Input
        className="uTable_search"
        placeholder={this.i18n('search')}
        onChange={this.handleSearch}
        value={searchValue}
        ref={this.searchInputRef}
        suffix={suffix}
      />
    );
  };

  private renderTitle = () => {
    const { title } = this.props;

    return <h4 className="uTable_title">{title}</h4>;
  };

  // Gather data and pass it to AntDesign table
  private renderTable = (dataValue?: DataValue<any>) => {
    const tableProps = this.getTableComponentProps(dataValue);
    const containerClassName = this.getTableContainerClassname();

    return (
      <>
        <div className="uTable_header">
          {this.renderTitle()}
          {this.renderActions()}
        </div>
        <div className={containerClassName}>
          <Table {...tableProps} />
        </div>
      </>
    );
  };

  render() {
    // Whole table wrapper
    return (
      <ErrorBoundary>
        <div className="uTable_wrapper">
          {this.renderModals()}
          {this.renderContent()}
        </div>
      </ErrorBoundary>
    );
  }
}

export default compose<IProps, IExternalProps>(
  withApollo,
  FiltersContextHOC({}),
  withFilters<IExternalProps>({
    name: 'filters',
    skip: props => {
      const queryName = UTable.getQueryNameFromProps(props)!;
      return !queryName;
    },
    options: props => {
      const queryName = UTable.getQueryNameFromProps(props)!;

      return {
        variables: {
          queryName: queryName,
        },
      };
    },
  })
)(UTable);
