import { gql } from 'apollo-boost';
import { PAYMENT_CURRENCY_CONNECTION_FRAGMENT } from '../fragments/paymentCurrencyConnection';

export const ALL_PAYMENT_CURRENCIES_QUERY = gql`
  query allPaymentCurrencies($query: DefaultQueryInput) {
    allPaymentCurrencies(query: $query) {
      ...paymentCurrencyConnection
    }
  }
  ${PAYMENT_CURRENCY_CONNECTION_FRAGMENT}
`;
