import * as React from 'react';

import { compose } from 'recompose';
import {
  AclFragment,
  AclProps,
  GrantAccessProps,
  RevokeAccessProps,
  SystemUserAclProps,
  SystemUserAclQueryVariables,
  SystemUserFragment,
  SystemUserProfileProps,
  withAcl,
  withGrantAccess,
  withRevokeAccess,
  withSystemUserAcl,
  withSystemUserProfile,
} from '../../typings/graphql';
import { getMutateProps } from '../../helpers/mutationOperationOptions';
import { getFromLocalStorage } from '../../helpers/getFromLocalStorage';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Checkbox } from 'antd';
import { i18n } from '../../helpers/I18n';
import Loader from '../Loader/Loader';

interface IExternalProps {
  userId: NonNullable<SystemUserFragment['id']>;
}

interface IProps extends IExternalProps {
  systemUserAcl: SystemUserAclProps['data'];
  systemUserProfile: SystemUserProfileProps['data'];
  acl: AclProps['data'];

  grantAccess: GrantAccessProps;
  revokeAccess: RevokeAccessProps;
}

interface IState {}

class SystemUserACL extends React.Component<IProps, IState> {
  private getSystemUserAclVariables = (): SystemUserAclQueryVariables => {
    const { userId } = this.props;
    const clientId = getFromLocalStorage('clientId');
    if (!clientId) {
      throw new Error('ClientId not found');
    }

    return {
      clientId: clientId,
      userId: userId,
    };
  };

  private groupAcl = () => {
    const acl = this.getAllAcl();

    type ParentIdKey = NonNullable<AclFragment['parentId']>;
    interface Group {
      parent: AclFragment | null;
      children: AclFragment[];
    }

    const groupedAcl: Record<ParentIdKey, Group> = {};

    // Add groups
    acl.forEach(access => {
      if (access.parentId === null) {
        groupedAcl[access.id] = {
          parent: access,
          children: [],
        };
      }
    });

    // Fill group children
    acl.forEach(access => {
      if (access.parentId !== null && access.parentId !== undefined) {
        if (!groupedAcl[access.parentId]) {
          groupedAcl[access.parentId] = {
            parent: null,
            children: [],
          };
        }

        groupedAcl[access.parentId].children.push(access);
      }
    });

    return groupedAcl;
  };

  private isChecked = (accessId: AclFragment['id']): boolean => {
    const { systemUserACL } = this.props.systemUserAcl;
    if (!systemUserACL) {
      return false;
    }

    const result = systemUserACL.find(({ id }) => id === accessId);
    return !!result;
  };

  private isEditable = () => {
    const systemUserAcl: AclFragment[] = getFromLocalStorage('systemUserAcl');
    if (!systemUserAcl) {
      return false;
    }

    const writeAccess = systemUserAcl.find(access => access.id === 'acl.write');
    return !!writeAccess;
  };

  private getAllAcl = (): AclFragment[] => {
    const { acl } = this.props.acl;

    return acl || [];
  };

  private isLoading = () => {
    const { loading: isLoadingSystemUserProfile } = this.props.systemUserProfile;
    const { loading: isLoadingSystemUserAcl } = this.props.systemUserAcl;
    const { loading: isLoadingAcl } = this.props.acl;

    return isLoadingSystemUserProfile || isLoadingSystemUserAcl || isLoadingAcl;
  };

  private isPending = (): boolean => {
    const isLoading = this.isLoading();

    const { loading: isRevoking } = this.props.revokeAccess.result;
    const { loading: isGranting } = this.props.grantAccess.result;

    return isLoading || isRevoking || isGranting;
  };

  private isDisabled = () => {
    const isEditable = this.isEditable();
    const isPending = this.isPending();

    return !isEditable || isPending;
  };

  private handleChangeAccess = (value: AclFragment) => async (e: CheckboxChangeEvent) => {
    const acl = this.getAllAcl();
    const { userId, grantAccess, revokeAccess } = this.props;
    const { checked } = e.target;
    const actionFn = checked ? grantAccess.mutate : revokeAccess.mutate;

    if (value.parentId !== null) {
      await actionFn({
        variables: {
          userId,
          aclId: value.id,
        },
      });
    } else {
      const children = acl.filter(({ parentId }) => parentId === value.id).concat(value);
      const promises = [];

      for (const access of children) {
        promises.push(
          actionFn({
            variables: {
              userId,
              aclId: access.id,
            },
          })
        );
      }

      // @ts-ignore
      await Promise.all(promises);
    }

    await this.props.systemUserAcl.refetch(this.getSystemUserAclVariables());
  };

  private renderNotFound = (groupName: string) => {
    return (
      // TODO: Translate
      <div>
        <strong>WARNING:</strong> ACL Category <strong>{groupName}</strong> not found
      </div>
    );
  };

  private renderList = (children: AclFragment[]) => {
    return (
      <ul style={{ listStyle: 'none' }}>
        {children.map(access => (
          <li key={access.id}>{this.renderCheckbox(access)}</li>
        ))}
      </ul>
    );
  };

  private renderCheckbox = (access: AclFragment, isParent?: boolean) => {
    const isDisabled = this.isDisabled();
    const style: React.CSSProperties = {
      fontWeight: isParent ? 'bold' : undefined,
    };

    return (
      <Checkbox onChange={this.handleChangeAccess(access)} disabled={isDisabled} checked={this.isChecked(access.id)}>
        <span style={style}>{i18n(access.name)}</span>
      </Checkbox>
    );
  };

  public render() {
    const isLoading = this.isLoading();
    if (isLoading) {
      return <Loader text={i18n('loading_acl')} />;
    }

    const groupedAcl = this.groupAcl();
    const groupNames = Object.keys(groupedAcl);

    return (
      <div>
        {groupNames.map(groupName => {
          const group = groupedAcl[groupName];
          const access = group.parent;

          if (!access) {
            return this.renderNotFound(groupName);
          }

          return (
            <div key={access.id}>
              {this.renderCheckbox(access, true)}
              {this.renderList(group.children)}
            </div>
          );
        })}
      </div>
    );
  }
}

export default compose<IProps, IExternalProps>(
  withAcl({ name: 'acl' }),
  withSystemUserProfile({ name: 'systemUserProfile' }),
  withSystemUserAcl<IExternalProps>({
    name: 'systemUserAcl',
    options: props => ({
      variables: {
        userId: props.userId,
        clientId: getFromLocalStorage('clientId'),
      },
    }),
  }),

  withGrantAccess(getMutateProps('grantAccess')),
  withRevokeAccess(getMutateProps('revokeAccess'))
)(SystemUserACL);
