import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { getFromLocalStorage } from '../helpers/getFromLocalStorage';
import { GraphQLError } from 'graphql';
import { createUploadLink } from 'apollo-upload-client';
import { EVENTS, EventService } from '../universal/services/EventService';
import { IdGetter } from 'apollo-boost';

export default (): ApolloClient<any> => {
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = getFromLocalStorage('token');
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        'x-system-user-token': token || undefined,
      },
    };
  });

  // remove cached token on 401 from the server
  interface INetworkError extends Error {
    statusCode?: number;
  }
  const resetTokenLink = onError(({ networkError, graphQLErrors }) => {
    const errors = graphQLErrors || [];
    let needReset = false;

    // Check if Unauthorized HTTP status code received
    const netError = networkError as INetworkError;
    if (netError && netError.statusCode === 401) {
      needReset = true;
    }

    // Check if errors contains UNAUTHENTICATED
    const filterUnauthenticated = ({ extensions }: GraphQLError) => extensions && extensions.code === 'UNAUTHENTICATED';
    const isUnauthenticatedErrorsFound = errors.some(filterUnauthenticated);
    if (isUnauthenticatedErrorsFound) {
      needReset = true;
    }

    if (needReset) {
      // clear token in cache and storage
      localStorage.removeItem('token');
      // const { queryManager } = client;

      // if (queryManager) {
      //   queryManager
      //     .clearStore() // We need to clear inflight/active queries first
      //     .then(() => client.resetStore()); // Then clear cache
      // }

      if (window) {
        // Throw event and catch it in PrivateRoute component, so we can redirect to Login through React
        EventService.fire(EVENTS.LOGOUT);
      }
    }
  });

  const dataIdFromObject: IdGetter = value => {
    if (!value || !value.id || !value.__typename) {
      return null;
    }

    if (value.__typename === 'ExtensionField') {
      return null;
    }

    return `${value.__typename}:${value.id}`;
  };

  const uploadLink = createUploadLink({ uri: '/graphql' });
  const cache = new InMemoryCache({
    dataIdFromObject: dataIdFromObject,
  });

  return new ApolloClient({
    cache: cache,
    link: ApolloLink.from([authLink, resetTokenLink, uploadLink]),
  });
};
