import { gql } from 'apollo-boost';
import { DISPUTE_DISPUTE_RESPONSE_FRAGMENT } from './disputeDisputeResponse';

export const DISPUTE_DISPUTE_RESPONSE_CONNECTION_FRAGMENT = gql`
  fragment disputeDisputeResponseConnection on DisputeDisputeResponseConnection {
    total
    limit
    cursor
    sortable
    searchable
    edges {
      ...disputeDisputeResponse
    }
  }
  ${DISPUTE_DISPUTE_RESPONSE_FRAGMENT}
`;
