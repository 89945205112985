import { gql } from 'apollo-boost';
import { CATALOGUE_CONNECTION_FRAGMENT } from '../fragments/catalogueConnection';

export const ALL_CATALOGUES_QUERY = gql`
  query allCatalogues($query: DefaultQueryInput) {
    allCatalogues(query: $query) {
      ...catalogueConnection
    }
  }
  ${CATALOGUE_CONNECTION_FRAGMENT}
`;
