import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import './BreadCrumbs.scss';
import { i18n } from '../../helpers/I18n';
import { compose } from 'recompose';
import InlineLink from '../InlineLink/InlineLink';
import { BreadCrumbsArgumentQueryContext, IArgumentQuery } from '../PrivateRoute/PrivateRoute';
import { Query } from '@apollo/react-components';
import { TOURNAMENT_TEAM_QUERY } from '../../graph/queries/tournamentTeam';
import { TOURNAMENT_TEAM_MEMBER_QUERY } from '../../graph/queries/tournamentTeamMember';
import { TOURNAMENT_SEASON_QUERY } from '../../graph/queries/tournamentSeason';
import { TOURNAMENT_TOUR_QUERY } from '../../graph/queries/tournamentTour';
import { TOURNAMENT_MATCH_QUERY } from '../../graph/queries/tournamentMatch';
import { CATALOGUE_QUERY } from '../../graph/queries/catalogue';
import { COMMERCE_SHOP_QUERY } from '../../graph/queries/commerceShop';
import { ARTICLE_QUERY } from '../../graph/queries/article';
import { EVENT_PLACE_QUERY } from '../../graph/queries/eventPlace';
import { CATERING_QUERY } from '../../graph/queries/catering';
import { getFullName } from '../../helpers/getFullName';
import { getTournamentMatchName } from '../../helpers/getMatchName';
import { MESSENGER_TRANSPORT_QUERY } from '../../graph/queries/messengerTransport';
import { MESSENGER_MAILING_QUERY } from '../../graph/queries/messengerMailing';
import { DISPUTE_QUERY } from '../../graph/queries/dispute';
import { ARTICLE_CATEGORY_QUERY } from '../../graph/queries/articleCategory';

interface IExternalProps {}

interface IProps extends IExternalProps, RouteComponentProps {}

interface IState {}

interface INavigationGroup {
  isArgument: boolean;
  isExcluded: boolean; // /catalogues/:catalogueId/(items?)/
  pathSchema: string; // /teams/:teamId
  path: string; // /teams/2
  urlElement: string;
  keyElement: string;
  keyElementLabel: string;
}

// path => keyElements[] => navigationGroup[] => JSX

class BreadCrumbs extends React.PureComponent<IProps, IState> {
  private isKeyElementArgument = (keyElement: string): boolean => {
    const { params } = this.props.match;
    const paramKeys = Object.keys(params);

    const isFirstCharColon = keyElement.indexOf(':') === 0;
    const isKeyOfParams = paramKeys.indexOf(keyElement.substring(1)) !== -1;
    return isFirstCharColon && isKeyOfParams;
  };

  private isKeyElementExcluded = (keyElement: string): boolean => {
    const isQuestion = keyElement.indexOf('?') !== -1;
    if (isQuestion) {
      return true;
    }

    const lastIndex = keyElement.length - 1;
    const isFirstCharBrace = keyElement.indexOf('(') === 0;
    const isLastCharBrace = keyElement.indexOf(')') === lastIndex;

    return isFirstCharBrace && isLastCharBrace;
  };

  private getKeyElementLabel = (keyElement: string) => {
    const isArgument = this.isKeyElementArgument(keyElement);
    if (!isArgument) {
      return i18n(keyElement);
    }

    // Convert argument ':teamId' to 'teamId'
    const key = keyElement.substring(1);
    const value = this.getArgumentValue(keyElement);

    return `${key} ${value}`;
  };

  private buildPathToGroup = (groupIndex: number): string => {
    const { urlElements } = this.getElements();

    const relativePath = urlElements.slice(0, groupIndex).join('/');

    return `/${relativePath}`;
  };

  private convertKeyElementToNavigationGroup = (keyElement: string, keyElementIndex: number): INavigationGroup => {
    const { keyElements, urlElements } = this.getElements();

    // Define is it argument of route and get it label
    const isExcluded = this.isKeyElementExcluded(keyElement);
    const isArgument = this.isKeyElementArgument(keyElement);
    const keyElementLabel = this.getKeyElementLabel(keyElement);
    const urlElement = urlElements[keyElementIndex];

    const groupIndex = keyElementIndex + 1;

    // Get pathSchema
    const relativePathSchema = keyElements.slice(0, groupIndex).join('/');
    const pathSchema = `/${relativePathSchema}`;

    // And get path
    const path = this.buildPathToGroup(groupIndex);

    return {
      isArgument: isArgument,
      isExcluded: isExcluded,
      pathSchema: pathSchema,
      path: path,
      urlElement: urlElement,
      keyElement: keyElement,
      keyElementLabel: keyElementLabel,
    };
  };

  // Accept string with ':' (Colon) as first char
  private getArgumentValue = (keyElement: string) => {
    const { params } = this.props.match;
    const key = keyElement.substring(1); // Remove colon from :teamId argument

    // @ts-ignore
    return params[key] as string;
  };

  // Convert excluded keyElement "(items?)" to urlElement "items" for example
  private convertExcludedKeyElementToUrlElement = (keyElement: string) => {
    const isExcluded = this.isKeyElementExcluded(keyElement);
    if (!isExcluded) {
      return keyElement;
    }

    const lastCharIndex = keyElement.length - 1;
    const sliceFrom = 1;
    const sliceTo = lastCharIndex - 1;

    return keyElement.slice(sliceFrom, sliceTo);
  };

  // Convert keyElement ":shopId" to urlElement "2" for example
  private convertKeyElementToUrlElement = (keyElement: string) => {
    const isArgument = this.isKeyElementArgument(keyElement);
    if (isArgument) {
      return this.getArgumentValue(keyElement);
    }

    const isExcluded = this.isKeyElementExcluded(keyElement);
    if (isExcluded) {
      return this.convertExcludedKeyElementToUrlElement(keyElement);
    }

    return keyElement;
  };

  private getKeyElements = () => {
    const { path: route } = this.props.match;
    if (route === '/') {
      return [];
    }

    return route.split('/').slice(1);
  };

  private getUrlElements = () => {
    const keyElements = this.getKeyElements();

    return keyElements.map(this.convertKeyElementToUrlElement);
  };

  private getElements = () => {
    const keyElements = this.getKeyElements();
    const urlElements = this.getUrlElements();

    return { keyElements, urlElements };
  };

  private getInstanceFromResponse = (data: any) => {
    if (!data) {
      return null;
    }

    const keys = Object.keys(data);
    const [key] = keys;

    const instance = data[key];

    return instance || null;
  };

  private getTextFromInstance = (instance: any): string | null => {
    if (!instance) {
      return null;
    }

    if (instance.name) {
      return instance.name;
    }

    if (instance.title) {
      return instance.title;
    }

    if (instance.__typename === 'Dispute') {
      return instance.text || i18n('untitled');
    }

    const fullName = getFullName(instance);
    if (fullName) {
      return fullName;
    }

    const isMatch = instance.__typename === 'TournamentMatch';
    const matchName = getTournamentMatchName(instance);
    if (isMatch && matchName) {
      return matchName;
    }

    if (instance.__typename) {
      return i18n(instance.__typename);
    }

    return null;
  };

  private getTextFromResponse = (data: any) => {
    const instance = this.getInstanceFromResponse(data);
    if (!instance) {
      return null;
    }

    const textFromInstance = this.getTextFromInstance(instance);
    if (textFromInstance && typeof textFromInstance === 'string') {
      return textFromInstance;
    }

    return null;
  };

  private getNavigationGroups = (): INavigationGroup[] => {
    const { keyElements } = this.getElements();

    const allGroups = keyElements.map(this.convertKeyElementToNavigationGroup);

    return allGroups.filter(this.filterOptionalGroups);
  };

  private composeArgumentQuery = (argumentQuery?: IArgumentQuery): IArgumentQuery => {
    return {
      memberId: TOURNAMENT_TEAM_MEMBER_QUERY,
      transportId: MESSENGER_TRANSPORT_QUERY,
      tournamentId: TOURNAMENT_TEAM_QUERY,
      mailingId: MESSENGER_MAILING_QUERY,
      seasonId: TOURNAMENT_SEASON_QUERY,
      matchId: TOURNAMENT_MATCH_QUERY,
      teamId: TOURNAMENT_TEAM_QUERY,
      tourId: TOURNAMENT_TOUR_QUERY,
      catalogueId: CATALOGUE_QUERY,
      shopId: COMMERCE_SHOP_QUERY,
      articleId: ARTICLE_QUERY,
      articleCategoryId: ARTICLE_CATEGORY_QUERY,
      placeId: EVENT_PLACE_QUERY,
      cateringId: CATERING_QUERY,
      disputeId: DISPUTE_QUERY,
      ...argumentQuery,
    };
  };

  private filterOptionalGroups = (navigationGroup: INavigationGroup): boolean => {
    return !navigationGroup.isExcluded;
  };

  private handleClickCrumb = (navigationGroup: INavigationGroup) => () => {
    this.props.history.push(navigationGroup.path);
  };

  private renderSeparator = (index: number, arrLength: number) => {
    const isLast = index === arrLength - 1;
    if (isLast) {
      return null;
    }

    return <span className="breadCrumbs_separator">&nbsp;/&nbsp;</span>;
  };

  private renderText = (navigationGroup: INavigationGroup, argumentQuery: IArgumentQuery) => {
    const { keyElement, keyElementLabel, isArgument, urlElement, path } = navigationGroup;
    const fallbackText = isArgument ? keyElementLabel : i18n(keyElementLabel);
    const fallback = <InlineLink to={path} text={fallbackText} />;

    if (!isArgument) {
      return fallback;
    }

    const key = keyElement.slice(1); // Remove ":" from argument
    const query = argumentQuery && argumentQuery[key];
    if (!query) {
      return fallback;
    }

    const variables = {
      id: Number(urlElement),
    };

    return (
      <Query<any> query={query} variables={variables}>
        {({ data, loading, error }) => {
          if (loading) {
            return <>{i18n('loading')}</>;
          }

          if (error || !data) {
            return fallback;
          }

          const text = this.getTextFromResponse(data) || fallbackText;

          return <InlineLink to={path} text={text} />;
        }}
      </Query>
    );
  };

  private renderNavigationGroup = (argumentQuery: IArgumentQuery) => (
    navigationGroup: INavigationGroup,
    index: number,
    arr: INavigationGroup[]
  ) => {
    const separator = this.renderSeparator(index, arr.length);
    const onClick = this.handleClickCrumb(navigationGroup);
    const text = this.renderText(navigationGroup, argumentQuery);
    const { path } = navigationGroup;

    return (
      <div key={path} onClick={onClick}>
        {text}
        {separator}
      </div>
    );
  };

  private renderCrumbs = (argumentQuery: IArgumentQuery) => {
    const navigationGroups = this.getNavigationGroups();

    // Do not render if only one element
    // For preventing useless title duplication
    if (navigationGroups.length <= 1) {
      return null;
    }

    return navigationGroups.map(this.renderNavigationGroup(argumentQuery));
  };

  private renderContent = (argumentQuery: IArgumentQuery) => {
    const fullArgumentQuery = this.composeArgumentQuery(argumentQuery);

    return this.renderCrumbs(fullArgumentQuery);
  };

  render() {
    return (
      <div className="breadCrumbs">
        <BreadCrumbsArgumentQueryContext.Consumer>{this.renderContent}</BreadCrumbsArgumentQueryContext.Consumer>
      </div>
    );
  }
}

export default compose<IProps, IExternalProps>(withRouter)(BreadCrumbs);
