import { gql } from 'apollo-boost';
import { CATALOGUE_ITEM_FLAT_FRAGMENT } from './catalogueItem';

export const COMMERCE_ORDER_ITEM_FLAT_FRAGMENT = gql`
  fragment commerceOrderItemFlat on CommerceOrderItem {
    id
    orderId
    state
    catalogueItemId
    discountId
    currencyId
    qty
    price
    discountedPrice
    total
    createdAt
    updatedAt
  }
`;

export const COMMERCE_ORDER_ITEM_FRAGMENT = gql`
  fragment commerceOrderItem on CommerceOrderItem {
    ...commerceOrderItemFlat
    catalogueItem {
      ...catalogueItemFlat
    }
  }
  ${COMMERCE_ORDER_ITEM_FLAT_FRAGMENT}
  ${CATALOGUE_ITEM_FLAT_FRAGMENT}
`;
