import { gql } from 'apollo-boost';
import { CATALOGUE_FULL_FRAGMENT } from '../fragments/catalogue';

export const CATALOGUE_QUERY = gql`
  query catalogue($id: Int!) {
    catalogue(id: $id) {
      ...catalogueFull
    }
  }
  ${CATALOGUE_FULL_FRAGMENT}
`;
