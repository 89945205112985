import { gql } from 'apollo-boost';
import { MESSENGER_MAILING_FRAGMENT } from '../fragments/messengerMailing';

export const MESSENGER_MAILING_QUERY = gql`
  query messengerMailing($id: Int!) {
    messengerMailing(id: $id) {
      ...messengerMailing
    }
  }
  ${MESSENGER_MAILING_FRAGMENT}
`;
