import { gql } from 'apollo-boost';
import { COMMERCE_ORDER_ITEM_CONNECTION_FRAGMENT } from '../fragments/commerceOrderItemConnection';

export const ALL_COMMERCE_ORDER_ITEMS_QUERY = gql`
  query allCommerceOrderItems($query: DefaultQueryInput) {
    allCommerceOrderItems(query: $query) {
      ...commerceOrderItemConnection
    }
  }
  ${COMMERCE_ORDER_ITEM_CONNECTION_FRAGMENT}
`;
