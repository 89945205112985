import { print } from 'graphql';
import { getFromLocalStorage } from './getFromLocalStorage';
import { ApolloError } from 'apollo-client';

interface IProps<TMutation = any, TVariables = any> {
  mutation: any;
  variables: TVariables;
  onSuccess?: (data: TMutation) => void;
  onFail?: (error: ApolloError) => void;
}

export default function mutate<TMutation = any, TVariables = any>(props: IProps<TVariables>) {
  const ENDPOINT = '/graphql';
  const METHOD = 'POST';
  const token = getFromLocalStorage('token') || '';

  return fetch(ENDPOINT, {
    method: METHOD,
    headers: {
      'Content-Type': 'application/json',
      // 'x-user-token': token,
      'x-system-user-token': token,
    },
    body: JSON.stringify({
      query: print(props.mutation),
      variables: props.variables,
    }),
  })
    .then(res => res.json())
    .then(res => {
      if (props.onSuccess) {
        props.onSuccess(res);
      }

      return Promise.resolve<TMutation>(res);
    })
    .catch(err => {
      if (props.onFail) {
        props.onFail(err);
      }
    });
}
