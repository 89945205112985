import { gql } from 'apollo-boost';
import { SYSTEM_USER_FRAGMENT } from '../fragments/systemUser';

export const SYSTEM_USER_QUERY = gql`
  query systemUser($id: ID!) {
    systemUser(id: $id) {
      ...systemUser
    }
  }
  ${SYSTEM_USER_FRAGMENT}
`;
