import * as React from 'react';
import { Button, Layout } from 'antd';

import './Sidebar.scss';
import MainMenu from '../../components/MainMenu/MainMenu';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Loader from '../Loader/Loader';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';
import { ClientFragment, NavigationComponent, NavigationQueryResult } from '../../typings/graphql';
import { getFromLocalStorage } from '../../helpers/getFromLocalStorage';
import SystemUserProfileModal from '../SystemUserProfileModal/SystemUserProfileModal';

interface IExternalProps {}

interface IProps extends IExternalProps, RouteComponentProps {}

interface IState {
  isProfileModalVisible: boolean;
  isCollapsed: boolean;
  clientId: string | null;
}

const IMAGE_SIZE = 42;

class Sidebar extends React.Component<IProps, IState> {
  public readonly state: IState = {
    isCollapsed: true,
    isProfileModalVisible: false,
    clientId: getFromLocalStorage('clientId'),
  };

  private getClient = (): ClientFragment | null => {
    const client = getFromLocalStorage('client');

    return client || null;
  };

  private getLogo = () => {
    const client = this.getClient();
    if (!client) {
      return null;
    }

    return client.logo || null;
  };

  private getSiderClassName = (): string => {
    const { isCollapsed } = this.state;
    const classNames = ['sidebar'];

    if (isCollapsed) {
      classNames.push('sidebar--collapsed');
    } else {
      classNames.push('sidebar--open');
    }

    return classNames.join(' ');
  };

  private handleCloseModal = (key: keyof IState) => () => {
    this.setState({ ...this.state, [key]: false });
  };

  private handleClickLogo = () => {
    this.props.history.push('/');
  };

  private handleCancelProfileModal = () => {
    this.handleCloseModal('isProfileModalVisible')();
  };

  private toggleCollapse = () => {
    this.setState(({ isCollapsed }) => ({ isCollapsed: !isCollapsed }));
  };

  private renderNavigation = ({ data, loading, error }: NavigationQueryResult) => {
    if (loading) {
      return <Loader isFull />;
    }

    if (error) {
      return <ErrorMessage isFull error={error} />;
    }

    if (!data || !data.navigation) {
      return null;
    }

    const { navigation } = data;
    const { isCollapsed } = this.state;

    const helpSort = navigation.length + 1;
    const result = [
      ...navigation,
      // {
      //   id: 999,
      //   children: null,
      //   icon: 'question-circle',
      //   name: 'help',
      //   sort: helpSort,
      //   parentId: 0,
      //   external: true,
      //   url: 'https://help.' + window.location.hostname,
      // },
    ];

    return <MainMenu isCollapsed={isCollapsed} items={result} />;
  };

  private renderLogoContent = () => {
    const logo = this.getLogo();
    const client = this.getClient();
    const clientName = (client && client.title) || 'Sport Star';

    if (!logo) {
      return (
        <div className="sidebar_clientName" title={clientName}>
          {clientName}
        </div>
      );
    }

    return <img alt="sidebar logo" src={logo} height={IMAGE_SIZE} />;
  };

  private renderLogo = () => {
    return (
      <div className="sidebar_logo" onClick={this.handleClickLogo}>
        {this.renderLogoContent()}
      </div>
    );
  };

  private renderMenu = () => (
    <div className="sidebar_menu">
      <NavigationComponent>{this.renderNavigation}</NavigationComponent>
    </div>
  );

  private renderProfileModal = () => {
    const { isProfileModalVisible } = this.state;

    return <SystemUserProfileModal visible={isProfileModalVisible} onCancel={this.handleCancelProfileModal} />;
  };

  private renderTrigger = () => {
    const { isCollapsed } = this.state;
    const icon = isCollapsed ? 'menu' : 'left';

    return (
      <Button
        type="default"
        className="sidebar_trigger"
        shape="circle"
        icon={icon}
        size="small"
        onClick={this.toggleCollapse}
      />
    );
  };

  private renderSidebar = () => {
    const { isCollapsed } = this.state;
    const className = this.getSiderClassName();

    return (
      <>
        {this.renderProfileModal()}
        {this.renderTrigger()}
        <Layout.Sider
          collapsed={isCollapsed}
          theme="light"
          className={className}
          width="250"
          collapsedWidth="100"
          trigger={null}
        >
          {this.renderLogo()}
          {this.renderMenu()}
        </Layout.Sider>
      </>
    );
  };

  public render() {
    return <>{this.renderSidebar()}</>;
  }
}

export default compose<IProps, IExternalProps>(withRouter)(Sidebar);
