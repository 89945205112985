import { gql } from 'apollo-boost';

export const CLIENT_FRAGMENT = gql`
  fragment client on Client {
    id
    title
    role
    type
    logo
    website
    colors
    domain
    createdAt
    updatedAt
  }
`;
