import { gql } from 'apollo-boost';
import { EVENT_TYPE_FRAGMENT } from './eventType';
import { TOURNAMENT_MATCH_WIDGET_FRAGMENT } from './tournamentMatch';
import { EXTENSION_DATA_FRAGMENT } from './extensionData';

export const EVENT_FRAGMENT = gql`
  fragment event on Event {
    id
    clientId
    image
    state
    title
    eventTypeId
    eventPlaceId
    description
    saleStartDate
    saleFinishDate
    startDate
    finishDate
    availableSlots
    saleLimit
    createdAt
    updatedAt
    templates
    isShowPublic
    data {
      ...extensionData
    }
  }
  ${EXTENSION_DATA_FRAGMENT}
`;

export const EVENT_FULL_FRAGMENT = gql`
  fragment eventFull on Event {
    ...event
    eventType {
      ...eventType
    }
    tournamentMatch {
      ...tournamentMatchWidget
    }
  }
  ${EVENT_FRAGMENT}
  ${EVENT_TYPE_FRAGMENT}
  ${TOURNAMENT_MATCH_WIDGET_FRAGMENT}
`;

export const EVENT_WIDGET_FRAGMENT = gql`
  fragment eventWidget on Event {
    id
    clientId
    state
    title
    eventTypeId
    description
    saleStartDate
    saleFinishDate
    startDate
    finishDate
    availableSlots
    saleLimit
    templates
    eventType {
      ...eventType
    }
    tournamentMatch {
      ...tournamentMatchWidget
    }
    createdAt
    updatedAt
  }
  ${EVENT_TYPE_FRAGMENT}
  ${TOURNAMENT_MATCH_WIDGET_FRAGMENT}
`;

export const NEAREST_MATCH_FRAGMENT = gql`
  fragment nearestMatch on Event {
    id
    state
    title
    eventTypeId
    description
    saleStartDate
    saleFinishDate
    startDate
    finishDate
    templates
    tournamentMatch {
      ...tournamentMatchWidget
    }
    createdAt
    updatedAt
  }
  ${TOURNAMENT_MATCH_WIDGET_FRAGMENT}
`;
