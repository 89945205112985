import * as React from 'react';
import './PrivateLayout.scss';
import { getFromLocalStorage } from '../../helpers/getFromLocalStorage';
import Sidebar from '../Sidebar/Sidebar';
import { Redirect } from 'react-router-dom';
import Helmet from '../Helmet/Helmet';

interface IProps {}
interface IState {}

class PrivateLayout extends React.PureComponent<IProps, IState> {
  static isAuthorized = (): boolean => {
    const token = getFromLocalStorage('token');
    const clientId = getFromLocalStorage('clientId');

    return Boolean(token && clientId);
  };

  private renderSidebar = () => {
    return (
      <div className="privateLayout_sidebar">
        <Sidebar />
      </div>
    );
  };

  public render() {
    const { children } = this.props;
    const isisAuthorized = PrivateLayout.isAuthorized();
    if (!isisAuthorized) {
      return <Redirect to="/login" />;
    }

    return (
      <div className="privateLayout">
        <Helmet />
        {this.renderSidebar()}
        <div className="privateLayout_content">{children}</div>
      </div>
    );
  }
}

export default PrivateLayout;
