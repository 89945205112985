import { gql } from 'apollo-boost';
import { TOURNAMENT_MATCH_FRAGMENT } from './tournamentMatch';
import { TOURNAMENT_TEAM_MEMBER_FRAGMENT } from './tournamentTeamMember';
import { TOURNAMENT_TEAM_FRAGMENT } from './tournamentTeam';

export const TOURNAMENT_MATCH_PROTOCOL_FRAGMENT = gql`
  fragment tournamentMatchProtocol on TournamentMatchProtocol {
    id
    matchId
    minute
    event
    teamMemberId
    teamId
    createdAt
    updatedAt
  }
`;

export const TOURNAMENT_MATCH_PROTOCOL_DETAILS_FRAGMENT = gql`
  fragment tournamentMatchProtocolDetails on TournamentMatchProtocol {
    id
    matchId
    minute
    event
    teamMemberId
    teamId
    match {
      ...tournamentMatch
    }
    player {
      ...tournamentTeamMember
    }
    team {
      ...tournamentTeam
    }
    createdAt
    updatedAt
  }
  ${TOURNAMENT_MATCH_FRAGMENT}
  ${TOURNAMENT_TEAM_MEMBER_FRAGMENT}
  ${TOURNAMENT_TEAM_FRAGMENT}
`;
