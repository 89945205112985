import { gql } from 'apollo-boost';
import { USER_FRAGMENT } from './user';

export const USER_CONNECTION_FRAGMENT = gql`
  fragment userConnection on UserConnection {
    total
    limit
    cursor
    sortable
    searchable
    edges {
      ...user
    }
  }
  ${USER_FRAGMENT}
`;
