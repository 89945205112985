import { getFromLocalStorage } from './getFromLocalStorage';

export function getActiveLocale(): string {
  let result = 'EN';

  try {
    const translations = getFromLocalStorage('translations');
    if (translations && translations.locale) {
      result = translations.locale;
    }
  } catch (e) {
    console.error(e);
  }

  return result;
}
