import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Button, Popconfirm, Modal } from 'antd';
import { compose } from 'recompose';
import { ButtonProps } from 'antd/es/button';
import FiltersContextHOC from '../FiltersContextHOC/FiltersContextHOC';
import { IFiltersContextValue } from '../FiltersProvider/FiltersProvider';
import './DeleteAction.css';
import { PopconfirmProps } from 'antd/es/popconfirm';
import { isNewPage } from '../../helpers/isNewPage';

const { confirm } = Modal;

interface IExternalProps {
  onConfirm: () => void;
  buttonProps?: ButtonProps;
  minimalistic?: boolean;
  buttonText?: string;
  modal?: boolean;
  popConfirmProps?: Partial<PopconfirmProps>;
}

interface IProps extends IExternalProps, RouteComponentProps<any> {
  filtersContext: IFiltersContextValue;
}

interface IState {}

class DeleteAction extends React.Component<IProps, IState> {
  private handleConfirm = () => {
    const { onConfirm } = this.props;

    onConfirm();
  };

  private getPopConfirmProps = (): PopconfirmProps => {
    const { buttonProps, popConfirmProps } = this.props;
    const { i18n } = this.props.filtersContext;
    const title = i18n('confirm_delete');
    const okText = i18n('action_delete');
    const cancelText = i18n('action_cancel');
    const okButtonProps: ButtonProps = { type: 'danger' };

    return {
      title: title,
      onConfirm: this.handleConfirm,
      cancelText: cancelText,
      okText: okText,
      okButtonProps: okButtonProps,
      disabled: buttonProps?.disabled,
      ...popConfirmProps,
    };
  };

  private getButtonProps = (): ButtonProps => {
    const { buttonProps } = this.props;
    const icon = 'delete';

    return {
      type: 'danger',
      icon: icon,
      ...buttonProps,
      className: 'deleteAction',
    };
  };

  private getButtonText = () => {
    const { minimalistic, buttonText } = this.props;
    if (minimalistic) {
      return null;
    }

    if (buttonText) {
      return buttonText;
    }

    const { i18n } = this.props.filtersContext;
    return i18n('action_delete');
  };

  private showConfirm = () => {
    const popConfirmProps = this.getPopConfirmProps();

    confirm({ ...popConfirmProps, onOk: popConfirmProps.onConfirm });
  };

  private renderModalConfirm = () => {
    const buttonProps = this.getButtonProps();
    const text = this.getButtonText();
    return (
      <Button onClick={this.showConfirm} {...buttonProps}>
        {text}
      </Button>
    );
  };

  private renderEssence = () => {
    const { modal } = this.props;
    if (modal) {
      return this.renderModalConfirm();
    }
    return this.renderPopConfirm();
  };

  private renderPopConfirm = () => {
    const popConfirmProps = this.getPopConfirmProps();
    const buttonProps = this.getButtonProps();
    const text = this.getButtonText();

    return (
      <Popconfirm {...popConfirmProps}>
        <Button {...buttonProps}>{text}</Button>
      </Popconfirm>
    );
  };

  public render() {
    if (isNewPage(this.props)) {
      return null;
    }

    return this.renderEssence();
  }
}

export default compose<IProps, IExternalProps>(withRouter, FiltersContextHOC({}))(DeleteAction);
