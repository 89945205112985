import { gql } from 'apollo-boost';
import { CATALOGUE_ITEM_FRAGMENT } from '../fragments/catalogueItem';

export const CATALOGUE_ITEM_QUERY = gql`
  query catalogueItem($id: Int!) {
    catalogueItem(id: $id) {
      ...catalogueItem
    }
  }
  ${CATALOGUE_ITEM_FRAGMENT}
`;
