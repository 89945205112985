import { gql } from 'apollo-boost';
import { SYSTEM_LOCALE_FRAGMENT } from './systemLocale';

export const SYSTEM_LOCALE_CONNECTION_FRAGMENT = gql`
  fragment systemLocaleConnection on SystemLocaleConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...systemLocale
    }
  }
  ${SYSTEM_LOCALE_FRAGMENT}
`;
