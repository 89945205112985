import React, { useCallback } from 'react';

import './DisputesTable.scss';
import { ALL_DISPUTES_QUERY } from '../../graph/queries/allDisputes';
import { UTable } from '../../universal';
import { DefaultQueryInput, DisputeFragment, SortingDirection, UserFlatFragment } from '../../typings/graphql';
import { ColumnProps } from 'antd/es/table';
import InlineLink from '../InlineLink/InlineLink';
import { getFullName } from '../../helpers/getFullName';
import { renderDate } from '../../helpers/renderDate';
import StatusTag from '../StatusTag/StatusTag';
import { useHistory } from 'react-router-dom';
import CreateDisputeAction from '../CreateDisputeAction/CreateDisputeAction';
import MatchScore from '../MatchScore/MatchScore';
import ModerateDisputeAction from '../ModerateDisputeAction/ModerateDisputeAction';

interface IExternalProps {
  tableId: string;
}

interface IProps extends IExternalProps {}

const DisputesTable: React.FC<IProps> = props => {
  const { tableId } = props;

  const history = useHistory();

  const queryInput: DefaultQueryInput = {
    // filters: [
    // {
    //   field: 'state',
    //   value: JSON.stringify(DisputeState.ACTIVE),
    // },
    // ],
    sort: [
      {
        direction: SortingDirection.DESC,
        field: 'createdAt',
      },
    ],
  };

  const handleRowClick = useCallback(
    (dispute?: DisputeFragment | null) => {
      if (!dispute) {
        return;
      }

      const path = `/tournaments/disputes/${dispute.id}`;
      history.push(path);
    },
    [history]
  );

  const renderUser = useCallback((user?: UserFlatFragment | null) => {
    if (!user) {
      return null;
    }

    const text = getFullName(user);
    const to = `/users/${user.id}`;

    return <InlineLink to={to} text={text} />;
  }, []);

  const renderEnum = useCallback((value: string) => <StatusTag value={value} />, []);

  const renderCreateAction = useCallback(() => <CreateDisputeAction />, []);
  const renderMatch = useCallback((match: DisputeFragment['match']) => <MatchScore match={match} />, []);
  const renderActions = useCallback((_: any, dispute: DisputeFragment) => {
    if (!dispute) {
      return null;
    }

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      event.preventDefault();
    };

    return (
      <div className="d-flex" onClick={handleClick}>
        <ModerateDisputeAction className="mr-1" type="win" disputeId={dispute.id} />
        <ModerateDisputeAction className="mr-1" type="loss" disputeId={dispute.id} />
        <ModerateDisputeAction className="mr-1" type="cancel" disputeId={dispute.id} />
      </div>
    );
  }, []);

  const customColumns: ColumnProps<DisputeFragment>[] = [
    {
      key: 'user',
      render: renderUser,
    },
    {
      key: 'match',
      render: renderMatch,
    },
    {
      key: 'state',
      render: renderEnum,
    },
    {
      key: 'result',
      render: renderEnum,
    },
    {
      key: 'createdAt',
      render: date => renderDate(date),
    },
    {
      key: 'actions',
      render: renderActions,
    },
  ];

  const keys = ['user', 'text', 'match', 'state', 'result', 'amount', 'createdAt', 'actions'];
  const actions = [renderCreateAction()];

  return (
    <UTable
      keys={keys}
      actions={actions}
      customColumns={customColumns}
      id={tableId}
      query={ALL_DISPUTES_QUERY}
      queryInput={queryInput}
      onRowClick={handleRowClick}
      queryComponentProps={{ fetchPolicy: 'network-only' }}
    />
  );
};

export default DisputesTable;
