import { gql } from 'apollo-boost';

export const USER_DEVICE_FRAGMENT = gql`
  fragment userDevice on UserDevice {
    userId
    # deviceId
    installId
    bundleId
    brand
    deviceLocale
    deviceName
    deviceModel
    installReferrer
    manufacturer
    systemName
    systemVersion
    appVersion
    userAgent
    pushToken
    expoPushToken
    createdAt
    updatedAt
  }
`;
