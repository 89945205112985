import { gql } from 'apollo-boost';

export const ARTICLE_BLOCK_FRAGMENT = gql`
  fragment articleBlock on ArticleBlock {
    id
    articleId
    authorId
    name
    type
    data
    isPreview
    sort
    #versions: [ArticleBlock!]
    createdAt
    updatedAt
  }
`;
