import { gql } from 'apollo-boost';

export const SYSTEM_PRINTER_FRAGMENT = gql`
  fragment systemPrinter on SystemPrinter {
    id
    clientId
    name
    title
    model
    createdAt
    updatedAt
  }
`;
