import { gql } from 'apollo-boost';

export const ARTICLE_CATEGORY_FRAGMENT = gql`
  fragment articleCategory on ArticleCategory {
    id
    clientId
    name
    parentId
    createdAt
  }
`;
