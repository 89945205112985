import * as React from 'react';
import { getFromLocalStorage } from '../../helpers/getFromLocalStorage';
import { DefaultQueryInput, FilterInput } from '../../typings/graphql';
import { EVENTS } from '../../config/constants';
import { ITableState, UTABLE_CONFIG_PREFIX } from '../UTable/UTable';

interface IFiltersProviderConfig {
  i18n?: TranslateHandler;
  localStorageKeyPrefix?: string;
  getMediaServiceToken: () => string;
}

interface IExternalProps {
  children?: React.ReactElement;
  config?: IFiltersProviderConfig;

  // For initial configuration, such as excluding deleted cars by default
  // or exclude YouDrive customers from allCustomers and etc
  initialStates?: FiltersStates;
}

interface IProps extends IExternalProps {}

export type FilterID = string;

export interface IExtendedFilterInput extends FilterInput {
  id: string;
}

export type FiltersStates = Record<FilterID, IExtendedFilterInput[]>;

export type SetFilterHandler = (filterId: FilterID, filterInputs: IExtendedFilterInput[]) => void;
export type GetFilterStateHandler = (filterId: FilterID) => IExtendedFilterInput[];
export type ResetFilterHandler = (filterId: FilterID) => void;
export type RemoveFilterHandler = (filterId: FilterID, value: IExtendedFilterInput) => void;
export type ChangeFilterHandler = (filterId: FilterID, value: IExtendedFilterInput) => void;
export type AddFilterHandler = (filterId: FilterID, value: IExtendedFilterInput) => void;
export type IsFiltersAppliedHandler = (filterId: FilterID) => boolean;
export type GetUTableQueryInput = (queryName: string, id?: string) => DefaultQueryInput;
export type TranslateHandler = (key: string) => string;

interface IState extends FiltersStates {}

export type IFiltersContextValue = {
  filters: IState;
  setFilter: SetFilterHandler;
  resetFilter: ResetFilterHandler;
  changeFilter: ChangeFilterHandler;
  removeFilter: RemoveFilterHandler;
  addFilter: AddFilterHandler;
  getFilterState: GetFilterStateHandler;
  isFiltersApplied: IsFiltersAppliedHandler;
  getUTableQueryInput: GetUTableQueryInput;
  i18n: TranslateHandler;
  getMediaServiceToken: () => string;
};

export const FiltersContext = React.createContext<IFiltersContextValue>({
  filters: {},
  setFilter: () => {},
  resetFilter: () => {},
  changeFilter: () => {},
  removeFilter: () => {},
  addFilter: () => {},
  getUTableQueryInput: () => ({}),
  getFilterState: () => [],
  isFiltersApplied: () => false,
  i18n: key => key,
  getMediaServiceToken: () => '',
});

FiltersContext.displayName = 'FiltersContext';

export const FILTERS_PREFIX = 'filters';

class FiltersProvider extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = this.loadState();
    window.addEventListener(EVENTS.STORAGE_CLEARED, this.reloadStorage);
  }

  private reloadStorage = () => {
    this.setState(this.loadState());
  };

  private getLocalStorageKeyPrefix = (): string => {
    const { config } = this.props;
    if (!config || !config.localStorageKeyPrefix) {
      return FILTERS_PREFIX;
    }

    return config.localStorageKeyPrefix;
  };

  private loadState = () => {
    const { initialStates } = this.props;
    const localStorageKeyPrefix = this.getLocalStorageKeyPrefix();
    const keys = Object.keys(localStorage);
    const state: IState = { ...initialStates };

    // Iterate over local storage keys
    for (const key of keys) {
      // Use only keys from localStorage and ignore prototype's keys
      if (localStorage.hasOwnProperty(key)) {
        // If key matches
        if (key.indexOf(`${localStorageKeyPrefix}_`) === 0) {
          // Extract value by key
          const value = getFromLocalStorage(key);

          if (value !== null) {
            state[key] = value;
          }
        }
      }
    }

    return state;
  };

  static getFilterId = (queryName: string, id?: string) => {
    // Key in local storage
    const unique = id || queryName;

    return `${FILTERS_PREFIX}_${unique}`;
  };

  private getMediaServiceToken = () => {
    const { config } = this.props;
    if (!config || !config.getMediaServiceToken) {
      return '';
    }

    return config.getMediaServiceToken();
  };

  private getUTableQueryInput: GetUTableQueryInput = (queryName, id) => {
    const queryInput: DefaultQueryInput = {};

    // Get filterId
    const filterId = FiltersProvider.getFilterId(queryName, id);

    // Find FilterInput[] in state
    const filterInputs = this.getFilterState(filterId);
    if (filterInputs) {
      // Add to queryInput
      queryInput.filters = filterInputs;
    }

    // Get prefix of localStorage keys
    const prefix = this.getLocalStorageKeyPrefix();

    // Get uTableConfigKey from filterId
    const uTableConfigKey = filterId.replace(prefix, UTABLE_CONFIG_PREFIX);

    // Extract uTableConfig from localStorage
    const uTableConfig: ITableState | null = getFromLocalStorage(uTableConfigKey);
    if (uTableConfig) {
      // Calculate limit and cursor
      const limit = uTableConfig.rowsCount;
      const page = uTableConfig.currentPage || 1;
      const cursor = (page - 1) * limit;

      queryInput.limit = limit;
      queryInput.cursor = cursor;

      if (uTableConfig.search) {
        queryInput.search = uTableConfig.search;
      }
    }

    return queryInput;
  };

  private getFilterState: GetFilterStateHandler = filterId => {
    const result = this.state[filterId];
    if (!result) {
      return [];
    }

    if (!Array.isArray(result)) {
      return [];
    }

    return result;
  };

  private notify = (filterId: FilterID) => {
    const customEvent = new CustomEvent(EVENTS.FILTERS_CHANGED, { detail: { filterId } });
    window.document.dispatchEvent(customEvent);
  };

  private updateState = (filterId: FilterID, value: IExtendedFilterInput[]) => {
    const updatedState = { ...this.state, [filterId]: value };
    this.setState(updatedState, () => {
      localStorage.setItem(filterId, JSON.stringify(value));
      this.notify(filterId);
    });
  };

  private setFilter: SetFilterHandler = (filterId, filterInputs) => {
    this.updateState(filterId, filterInputs);
  };

  private resetFilter: ResetFilterHandler = filterId => {
    this.updateState(filterId, []);
  };

  private removeFilter: RemoveFilterHandler = (filterId, value) => {
    const filterInputs = this.state[filterId] || [];
    const updated = filterInputs.filter(filterInput => filterInput.id !== value.id);

    this.updateState(filterId, updated);
  };

  private addFilter: AddFilterHandler = (filterId, value) => {
    const filterInputs = this.state[filterId] || [];
    const updated = [value, ...filterInputs];

    this.updateState(filterId, updated);
  };

  private changeFilter: ChangeFilterHandler = (filterId, filterInput) => {
    const filterInputs = this.state[filterId] || [];
    const filterToReplace = filterInputs.find(existed => existed.id === filterInput.id);
    if (!filterToReplace) {
      return;
    }

    const index = filterInputs.indexOf(filterToReplace);
    const beforeIndex = filterInputs.slice(0, index);
    const afterIndex = filterInputs.slice(index + 1);

    this.setFilter(filterId, [...beforeIndex, filterInput, ...afterIndex]);
  };

  private isFiltersApplied = (filterId: FilterID) => {
    const filterInputs = this.state[filterId] || [];
    return filterInputs.length > 0;
  };

  private i18n: TranslateHandler = key => {
    const { config } = this.props;
    if (!config || !config.i18n) {
      return key;
    }

    const translated = config.i18n(key);
    if (typeof translated === 'string') {
      return translated;
    }

    return key;
  };

  private getContextValue = (): IFiltersContextValue => ({
    filters: this.state,
    setFilter: this.setFilter,
    resetFilter: this.resetFilter,
    changeFilter: this.changeFilter,
    removeFilter: this.removeFilter,
    addFilter: this.addFilter,
    getFilterState: this.getFilterState,
    isFiltersApplied: this.isFiltersApplied,
    getUTableQueryInput: this.getUTableQueryInput,
    i18n: this.i18n,
    getMediaServiceToken: this.getMediaServiceToken,
  });

  public render() {
    const { children } = this.props;
    const contextValue = this.getContextValue();

    return <FiltersContext.Provider value={contextValue}>{children}</FiltersContext.Provider>;
  }
}

export default FiltersProvider;
