import React, { useCallback } from 'react';
import './MatchScore.scss';
import { TournamentMatchFragment } from '../../typings/graphql';
import InlineLink from '../InlineLink/InlineLink';
import { renderDate } from '../../helpers/renderDate';
import { FORMAT } from '../../config/constants';

interface IExternalProps {
  match: TournamentMatchFragment;
}

interface IProps extends IExternalProps {}

const MatchScore: React.FC<IProps> = props => {
  const { match } = props;

  const getTeamClassName = useCallback((isReverse: boolean) => {
    const classNames = ['matchScore_team'];
    if (isReverse) {
      classNames.push('matchScore_team--reverse');
    }

    return classNames.join(' ');
  }, []);

  const renderTeamLogo = useCallback((team: TournamentMatchFragment['team1']) => {
    if (!team || !team.logo) {
      return null;
    }

    return <img className="matchScore_teamLogo" src={team.logo} alt={team.name} />;
  }, []);

  const renderTeam = useCallback(
    (team: TournamentMatchFragment['team1'], isReverse: boolean) => {
      const { name: text, id: teamId } = team;
      const to = `/tournaments/teams/${teamId}`;
      const className = getTeamClassName(isReverse);

      return (
        <div className={className}>
          <InlineLink text={text} to={to} />
          {renderTeamLogo(team)}
        </div>
      );
    },
    [renderTeamLogo, getTeamClassName]
  );

  const renderScore = useCallback((match: TournamentMatchFragment) => {
    const { team1IdGoals, team2IdGoals } = match;
    // const to = `/tournaments/${tournamentId}/seasons/${seasonId}/tours/${tourId}/matches/${matchId}`;

    return (
      <div className="matchScore_score">
        {team1IdGoals} : {team2IdGoals}
      </div>
    );
  }, []);

  const renderMatchDate = useCallback((match: TournamentMatchFragment) => {
    const { startDate } = match;

    return <div className="matchScore_date">{renderDate(startDate, { format: FORMAT.DATE_WITH_YEAR })}</div>;
  }, []);

  const team1 = renderTeam(match.team1, false);
  const team2 = renderTeam(match.team2, true);
  const score = renderScore(match);
  const date = renderMatchDate(match);

  return (
    <div className="matchScore">
      <div className="matchScore_info">
        {team1}
        {score}
        {team2}
      </div>
      <div className="matchScore_date">{date}</div>
    </div>
  );
};

export default React.memo(MatchScore);
