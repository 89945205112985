import React from 'react';
import './AntConfigProvider.scss';
import { getActiveLocale } from '../../universal/helpers/getActiveLocale';
import { ConfigProvider } from 'antd';
import { getPopupContainer } from '../../helpers/getPopupContainer';

interface IExternalProps {}

interface IProps extends IExternalProps {}

const AntConfigProvider: React.FC<IProps> = props => {
  const locale = getActiveLocale().toLowerCase();
  const localeConfig = { locale };

  return (
    <ConfigProvider locale={localeConfig} getPopupContainer={getPopupContainer}>
      {props.children}
    </ConfigProvider>
  );
};

export default AntConfigProvider;
