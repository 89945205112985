import { gql } from 'apollo-boost';
import { DISPUTE_FRAGMENT } from '../fragments/dispute';

export const DISPUTE_QUERY = gql`
  query dispute($id: Int!) {
    dispute(id: $id) {
      ...dispute
    }
  }
  ${DISPUTE_FRAGMENT}
`;
