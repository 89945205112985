import { gql } from 'apollo-boost';
import { TOURNAMENT_SEASON_TITLE_FRAGMENT } from './season';

export const TOURNAMENT_FRAGMENT_FULL = gql`
  fragment tournamentFull on Tournament {
    id
    clientId
    title
    description
    website
    state
    preview
    sort
    seasons {
      ...tournamentSeasonTitle
    }
    createdAt
    updatedAt
  }
  ${TOURNAMENT_SEASON_TITLE_FRAGMENT}
`;

export const TOURNAMENT_FRAGMENT = gql`
  fragment tournament on Tournament {
    id
    clientId
    title
    description
    website
    state
    preview
    sort
    createdAt
    updatedAt
  }
`;
