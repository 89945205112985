import { gql } from 'apollo-boost';
import { EVENT_ORDER_FRAGMENT } from '../fragments/eventOrder';

export const EVENT_ORDER_QUERY = gql`
  query eventOrder($id: Int!) {
    eventOrder(id: $id) {
      ...eventOrder
    }
  }
  ${EVENT_ORDER_FRAGMENT}
`;
