import { gql } from 'apollo-boost';

export const MOBILE_TRANSLATION_FRAGMENT = gql`
  fragment mobileTranslation on MobileTranslation {
    id
    locale
    key
    value
    updatedAt
  }
`;
