import { gql } from 'apollo-boost';
import { SYSTEM_LOCALE_FRAGMENT } from './systemLocale';

export const EVENT_PLACE_FRAGMENT = gql`
  fragment eventPlace on EventPlace {
    id
    clientId
    name
    address
    createdAt
    updatedAt
    locales {
      ...systemLocale
    }
  }
  ${SYSTEM_LOCALE_FRAGMENT}
`;
