export function parseJSON<TResult = any>(json: string): null | TResult {
  let result = null;

  try {
    result = JSON.parse(json);
  } catch (e) {
    console.error(e);
  }

  return result as TResult;
}
