import './Loader.scss';
import * as React from 'react';
import { Spin } from 'antd';

interface Props {
  className?: string;
  size?: 'small' | 'default' | 'large';
  text?: string;
  isFull?: boolean;
}

class Loader extends React.PureComponent<Props> {
  private getClassName = (): string => {
    const { className, isFull } = this.props;
    const classNames = ['loader', className];
    if (isFull) {
      classNames.push('container');
      classNames.push('container--center');
    }

    return classNames.join(' ');
  };

  render() {
    const { size, text } = this.props;
    const className = this.getClassName();

    return (
      <div className={className}>
        <Spin size={size} />
        {text && <p className="loader_text">{text}</p>}
      </div>
    );
  }
}

export default Loader;
