import { gql } from 'apollo-boost';
import { SYSTEM_TRANSLATION_FRAGMENT } from './systemTranslation';

export const SYSTEM_TRANSLATION_CONNECTION_FRAGMENT = gql`
  fragment systemTranslationConnection on SystemTranslationConnection {
    total
    limit
    cursor
    searchable
    sortable
    edges {
      ...systemTranslation
    }
  }
  ${SYSTEM_TRANSLATION_FRAGMENT}
`;
