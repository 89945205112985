import React from 'react';
import { compose } from 'recompose';
import UForm, { IFormField, UFormState, FormComponent } from '../../universal/components/UForm/UForm';
import { handleFail, OPERATIONS, handleSuccess } from '../../helpers/handleMutation';
import { getMutateProps } from '../../helpers/mutationOperationOptions';
import { addToCache, IManipulateCacheConfig, removeFromCache } from '../../helpers/updateInCache';
import { ExecutionResult } from 'graphql';
import {
  DeleteSystemTranslationMutation,
  DeleteSystemTranslationProps,
  withDeleteSystemTranslation,
  SystemTranslation,
  SystemTranslationFragment,
  withSaveSystemTranslation,
  SaveSystemTranslationProps,
  SystemTranslationSaveInput,
  SaveSystemTranslationMutation,
} from '../../typings/graphql';
import { ALL_SYSTEM_TRANSLATIONS_QUERY } from '../../graph/queries/allSystemTranslations';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Rcm, { RcmProps } from '../Rcm/Rcm';
import { FilterDataType } from '../../universal/typings/graphql';
import { ALL_SYSTEM_LOCALES_QUERY } from '../../graph/queries/allSystemLocales';

type Elem = SystemTranslationFragment;

interface IExternalProps {
  instanceId?: SystemTranslation['id'];
  instance?: Elem | null;
  onSave?: (entity: Elem) => void;
  onDelete?: () => void;
  rcmProps?: Partial<RcmProps>;
}

interface IProps extends IExternalProps, RouteComponentProps {
  save: SaveSystemTranslationProps;
  delete: DeleteSystemTranslationProps;
}

interface IState {}

const addToCacheConfig: IManipulateCacheConfig = {
  collectionQueryName: 'systemTranslations',
  collectionQuery: ALL_SYSTEM_TRANSLATIONS_QUERY,
  mutationQueryName: 'createSystemTranslation',
};

const removeFromCacheConfig: IManipulateCacheConfig = {
  collectionQueryName: 'systemTranslations',
  collectionQuery: ALL_SYSTEM_TRANSLATIONS_QUERY,
  mutationQueryName: 'deleteSystemTranslation',
};

class SystemTranslationForm extends React.PureComponent<IProps, IState> {
  private handleSaveSuccess = (res: ExecutionResult<SaveSystemTranslationMutation>) => {
    const saved = res && res.data && res.data.saveSystemTranslation;
    if (!saved) {
      return handleFail(OPERATIONS.UPDATE)();
    }

    handleSuccess(OPERATIONS.UPDATE)();

    const { onSave } = this.props;
    if (onSave) {
      onSave(saved);
    }
  };

  private handleDeleteSuccess = (res: ExecutionResult<DeleteSystemTranslationMutation>) => {
    const deleted = res && res.data && res.data.deleteSystemTranslation;
    if (!deleted) {
      return handleFail(OPERATIONS.DELETE)();
    }
    handleSuccess(OPERATIONS.DELETE)();

    const { onDelete } = this.props;
    if (onDelete) {
      onDelete();
    }
  };

  private handleSave = (formState: UFormState) => {
    this.props.save
      .mutate({
        variables: {
          data: formState as SystemTranslationSaveInput,
        },
        update: addToCache(addToCacheConfig),
      })
      .then(this.handleSaveSuccess)
      .catch(handleFail(OPERATIONS.UPDATE));
  };

  private handleSubmit = (formState: UFormState) => {
    this.handleSave(formState);
  };

  private handleDelete = () => {
    const instanceId = this.getInstanceId();
    if (!instanceId) {
      return handleFail(OPERATIONS.DELETE)();
    }

    this.props.delete
      .mutate({
        variables: {
          id: instanceId,
        },
        update: removeFromCache(removeFromCacheConfig),
      })
      .then(this.handleDeleteSuccess)
      .catch(handleFail(OPERATIONS.DELETE));
  };

  private getInstanceId = () => {
    const { instanceId, instance } = this.props;
    if (instanceId) {
      return instanceId;
    }

    if (instance && instance.id) {
      return instance.id;
    }

    return null;
  };

  private getFields = (): IFormField[] => {
    return [
      {
        field: 'key',
        component: FormComponent.INPUT,
        type: FilterDataType.STRING,
        isRequired: true,
      },
      {
        field: 'value',
        component: FormComponent.INPUT,
        type: FilterDataType.STRING,
        isRequired: true,
      },
      {
        field: 'locale',
        component: FormComponent.SELECT,
        optionsQuery: {
          query: ALL_SYSTEM_LOCALES_QUERY,
          queryName: 'allSystemLocales',
          optionValuePropertyName: 'iso',
        },
        isRequired: true,
      },
    ];
  };

  private isPending = (): boolean => {
    const { loading: isSaving } = this.props.save.result;
    const { loading: isDeleting } = this.props.delete.result;

    return isSaving || isDeleting;
  };

  private getError = () => {
    const { error: errorSaving } = this.props.save.result;
    const { error: errorDeleting } = this.props.delete.result;

    return errorSaving || errorDeleting;
  };

  private renderEssence = () => {
    const fields = this.getFields();
    const isLoading = this.isPending();
    const error = this.getError();

    return (
      <UForm
        fields={fields}
        isLoading={isLoading}
        error={error}
        onDelete={this.handleDelete}
        onSubmit={this.handleSubmit}
      />
    );
  };

  private renderRcm = () => {
    const { rcmProps } = this.props;
    return <Rcm isWrapped renderEssence={this.renderEssence} {...rcmProps} />;
  };

  render() {
    return this.renderRcm();
  }
}

export default compose<IProps, IExternalProps>(
  withRouter,
  withSaveSystemTranslation(getMutateProps('save')),
  withDeleteSystemTranslation(getMutateProps('delete'))
)(SystemTranslationForm);
